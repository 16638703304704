import { ICommonApiErrorHandler } from "@eblsoft/react-toolkit";
import AuthStore from "Stores/AuthStore";
import NotificationStore from "Stores/NotificationStore";

class CommonApiErrorHandler implements ICommonApiErrorHandler {
    public handleUnauthorizedAsync(): Promise<boolean> {
        window.location.reload();
        return Promise.resolve(true);
    }

    public handleMaintenanceAsync(): Promise<boolean> {
        NotificationStore.error("A rendszer karbantartás alatt áll, kérjük próbálja meg később.");
        return Promise.resolve(true);
    }

    public handleOtherErrorAsync(statusCode: number, responseJson: any): Promise<boolean> {
        window.location.href = `${window.location.protocol}//${window.location.host}/`;
        return Promise.resolve(false);
    }

    public handleBusinessError(error: any): Promise<boolean> {
        NotificationStore.error(error.message);
        return Promise.resolve(true);
    }
}

export default new CommonApiErrorHandler();