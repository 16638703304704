import React from "react";
import { toast } from "react-toastify";

class NotificationStore {
    public success(message: React.ReactNode): void {
        toast.success(message);
    }

    public successfullySaved(message?: React.ReactNode): void {
        toast.success(message ?? "Sikeres mentés");
    }
    
    public error(message: React.ReactNode): void {
        toast.error(message);
    }

    public warning(message: React.ReactNode): void {
        toast.warning(message);
    }

    public info(message: React.ReactNode): void {
        toast.info(message);
    }
}

export default new NotificationStore();