import { Alert, Button, CheckboxOptionType, Col, DatePicker, PageHeader, Radio, Row, Space, Table, TablePaginationConfig, Tabs } from "antd";
import Title from "antd/lib/typography/Title";
import AccountedRideDetails from "Models/AccountedRideDetails";
import { getVoucherTypeDisplayValue } from "Models/VoucherType";
import PartnerView from "Pages/CommonControls/PartnerView";
import ReadonlyInput from "Pages/CommonControls/ReadonlyInput";
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { formatHuf } from "Util/PriceFormatter";
import { FilterOutlined, LinkOutlined } from "@ant-design/icons";
import TextBox from "Pages/CommonControls/TextBox";
import TaxiDriver from "Models/TaxiDriver";
import ManualRideStore from "Models/ManualRideStore";
import { RideSearcherMode } from "Models/RideSearcherMode";
import { RideListColumns } from "./RideListColumns";
import { useRideSearcherStore, withRideSearcherStore } from "./RideSearcherStore";
import DateRangePicker from "../DateRangePicker";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import RideLitsItem from "Models/RideListItem";
import { observer, Observer } from "mobx-react";
import { getFullScreenTableScroll } from "Util/TableHelpers";

export interface IRideSearcherProps {
    selectedRide: RideLitsItem | null;
    rideSearcherMode?: RideSearcherMode;
    defaultRidesFrom?: moment.Moment | null;
    defaultRidesTo?: moment.Moment | null;
    manualRideStore: ManualRideStore;
    callbacks: IRideSearcherCallbacks;
    taxiDriverId: number;
}

export interface IRideSearcherCallbacks {
    selectRide: (ride: RideLitsItem) => void;
    setRideSearcherMode: (rideSearcherMode: RideSearcherMode) => void;
}

function RideSearcher(props: IRideSearcherProps) {
    const store = useRideSearcherStore();
    const setTab = useCallback((activeKey: string) => props.callbacks.setRideSearcherMode(activeKey === RideSearcherMode.Manul ? RideSearcherMode.Manul : RideSearcherMode.Ride), [props.callbacks.setRideSearcherMode]);

    const paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    }

    const tableScroll = getFullScreenTableScroll(RideListColumns.reduce((sum, col) => sum + (col.width as number ?? 100), 0));

    return (
        <>
            <Tabs onChange={setTab} activeKey={props.rideSearcherMode}>
                <Tabs.TabPane tab="Átküldött fuvar alapján" key={RideSearcherMode.Ride}>
                    <Row gutter={16}>
                        <Col span={9}>
                            <DateRangePicker
                                label="Szűrés időpontra:"
                                onChange={store.setRidesDateTimeFilter}
                                from={store.rideFilter.rideFrom}
                                to={store.rideFilter.rideTo}
                                format={"YYYY.MM.DD HH:mm:ss"}
                                allowClear
                                showTime
                            />
                        </Col>
                        <Col span={5}>
                            <TextBox
                                label="Rendelésszám:"
                                value={store.rideFilter.externalId}
                                onChange={store.rideFilter.setExternalId}
                                onEnter={store.filterResult} />
                        </Col>
                        <Col span={5}>
                            <TextBox
                                label="Név:"
                                value={store.rideFilter.name}
                                onChange={store.rideFilter.setName}
                                onEnter={store.filterResult} />
                        </Col>
                        <Col span={5}>
                            <TextBox
                                label="Telefonszám:"
                                value={store.rideFilter.phoneNumber}
                                onChange={store.rideFilter.setPhoneNumber}
                                onEnter={store.filterResult} />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={3} lg={3} md={5} xs={6}>
                            <TextBox
                                label="ir.Sz:"
                                value={store.rideFilter.zipCode}
                                onChange={store.rideFilter.setZipCode}
                                onEnter={store.filterResult} />
                        </Col>
                        <Col xl={5} lg={7} md={9} xs={10}>
                            <TextBox
                                label="Település:"
                                value={store.rideFilter.settlement}
                                onChange={store.rideFilter.setSettlement}
                                onEnter={store.filterResult} />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={8} lg={10} md={14} xs={16}>
                            <TextBox
                                label="Utca, házszám:"
                                value={store.rideFilter.addressLine}
                                onChange={store.rideFilter.setAddressLine}
                                onEnter={store.filterResult} />
                        </Col>
                    </Row>

                    <Space style={{ marginTop: "16px", marginBottom: "16px" }}>
                        <Button style={{ width: "100%" }} type="primary" onClick={store.filterResult} icon={<FilterOutlined />}>
                            Szűrés
                        </Button>
                    </Space>

                    <Table
                        style={{ marginTop: 24 }}
                        size="middle"
                        columns={RideListColumns}
                        dataSource={store.rides}
                        onChange={store.reloadList}
                        pagination={paginationConfig}
                        rowKey="id"
                        onRow={(ride) => ({
                            onClick: () => props.callbacks.selectRide(ride),
                            style: { cursor: "pointer" }
                        })}
                        rowSelection={{
                            type: "radio",
                            selectedRowKeys: props.selectedRide ? [props.selectedRide.id] : [],
                            onSelect: props.callbacks.selectRide
                        }}
                        scroll={tableScroll}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Kézi bevitel alapján" key={RideSearcherMode.Manul}>

                    <Alert
                        message="Figyelem"
                        style={{ margin: "2rem 1rem" }}
                        description={(
                            <p>A fuvar adatok kézi rögzítését csak akkor kell elvégezni, ha a diszpécser rendszerből nem sikerült a fuvart átküldeni az eTaxi rendszerbe amely viszonylag ritka. Ha az átküldött fuvarok között szeretne keresni, használja az "Átküldött fuvar alapján" tabfület (pl. kereshet rendelésszámra). </p>
                        )}
                        type="warning"
                    />

                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <TextBox value={props.manualRideStore.rideExternalIdentifier} onChange={props.manualRideStore.setRideExternalIdentifier} label="Rendelésszám:" />
                        </Col>
                        <Col span={12}></Col>
                        <Col span={12}>
                            <TextBox value={props.manualRideStore.rideAdditionalInfo} onChange={props.manualRideStore.setRideAdditionalInfo} multiline label="Egyéb információk:" />
                        </Col>
                    </Row>

                </Tabs.TabPane>
            </Tabs>
        </>);

}

export default withRideSearcherStore(RideSearcher)