import { bound, createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import AdminConsentApiAdapter from "Adapters/AdminAdapter/AdminConsentApiAdapter";
import { observable, runInAction } from "mobx";
import { SavedConsent } from "Models/SavedConsent";
import { IConsentDetailProps } from "Pages/Admin/Consents/Detail/ConsentDetail";

class ConsentDetailStore extends StoreBase<IConsentDetailProps> {

    @observable.ref public consent: SavedConsent | null = null;

    public readonly vLoadAsync = this.async(async () => {
        const consent = await AdminConsentApiAdapter.getAsync(this.props.consentId);
        runInAction(() => {
            this.consent = consent;
        });
    });

}

const { ContextComponent: ConsentDetailStoreContext, StoreProvider: ConsentDetailStoreProvider, useStore: useConsentDetailStore, withStore: withConsentDetailStore } = createStoreProvider<IConsentDetailProps, ConsentDetailStore>(() => new ConsentDetailStore());

export {
    ConsentDetailStoreContext,
    ConsentDetailStoreProvider,
    useConsentDetailStore,
    withConsentDetailStore
};