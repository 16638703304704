export interface ITableScroll {
    x?: number | true | string;
    y?: number | string;
}

export const fullScreenTableScroll: ITableScroll = {
    y: "calc(100vh - 200px)",
    // x: 2000,
};

export function getFullScreenTableScroll(x: number) {
    return {
        y: fullScreenTableScroll.y,
        x
    }
}