import { action, makeObservable, observable } from "mobx";
import { AddressType } from "Models/AddressType";
import { CostPlacementType } from "Models/CostPlacementType";

class PartnerFilter {
    @observable.ref public bpId: string = "";
    @observable.ref public name: string = "";
    @observable.ref public bpName: string = "";
    @observable.ref public taxNumber: string = "";
    @observable public areFiltersDirty = false;
    @observable.ref public showInActive: boolean = false;
    @observable.ref public addressType: AddressType = AddressType.Any;
    @observable.ref public settlement: string = "";
    @observable.ref public addressLine: string = "";
    @observable.ref public zipCode: string = "";
    public constructor(
    ) {
        makeObservable(this);
    }

    @action.bound
    public setBpId(bpId: string) {
        this.bpId = bpId;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setSettlement(settlement: string) {
        this.settlement = settlement;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setAddressLine(addressLine: string) {
        this.addressLine = addressLine;
        this.areFiltersDirty = true;
    }
    
    @action.bound
    public setZipCode(zipCode: string) {
        this.zipCode = zipCode;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setName(name: string) {
        this.name = name;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setBpName(bpName: string) {
        this.bpName = bpName;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setTaxNumber(taxNumber: string) {
        this.taxNumber = taxNumber;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setAddressType(addressType: AddressType) {
        this.addressType = addressType;
        this.areFiltersDirty = true;
    }
}
export default PartnerFilter;