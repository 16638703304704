import { Partner } from "Models/Partner";
import TaxiDriver from "Models/TaxiDriver";
import { VoucherType } from "Models/VoucherType";

export default class AccountedRideDetails {
    constructor(
        public readonly id: number,
        public readonly publicIdentifier: string,
        public readonly voucherType: VoucherType,
        public readonly voucherInfo: string | null,
        public readonly createdAt: moment.Moment,
        public readonly costPlacement: string | null,
        public readonly grossPrice: number,
        public readonly taxiDriver: TaxiDriver,
        public readonly partner: Partner
    ) {}

    
}