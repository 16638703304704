import { createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import AdminRideAdapter from "Adapters/AdminAdapter/AdminRideAdapter";
import { action, observable, runInAction } from "mobx";
import AccountedRideDetails from "Models/AccountedRideDetails";
import { IOfflineRideDetailCoreProps } from "./OfflineRideDetail";
import Ride from "Models/Ride";
import moment from "moment";
import NavigationStore from "Stores/NavigationStore";
import NotificationStore from "Stores/NotificationStore";
import RideLitsItem from "Models/RideListItem";
import ManualRideStore from "Models/ManualRideStore";
import { RideSearcherMode } from "Models/RideSearcherMode";
import { IRideSearcherCallbacks } from "Pages/CommonControls/RideSearcher/RideSearcher";

export default class OfflineRideDetailStore extends StoreBase<IOfflineRideDetailCoreProps> {

    @observable.ref public rideDetails: AccountedRideDetails | null = null;

    @observable.ref public ridesFrom: moment.Moment | null = null;
    @observable.ref public ridesTo: moment.Moment | null = null;
    public manualRideStore = new ManualRideStore();
    @observable.ref public selectedRide: RideLitsItem | null = null;
    @observable.ref public rideSearcherMode = RideSearcherMode.Ride;

    @observable.ref public modificationReason = "";
    @observable.ref public modifierName = "";
    @observable.ref public isDeleteConfirmationModalOpen = false;

    public readonly vLoadAsync = this.async(async () => {
        const offlineRideDetails = await AdminRideAdapter.getOfflineDetailAsync(this.props.offlineRideId);

        const from = offlineRideDetails.createdAt.clone().subtract(1, "hours");
        const to = offlineRideDetails.createdAt.clone();

        runInAction(() => {
            this.rideDetails = offlineRideDetails;
            this.ridesFrom = from;
            this.ridesTo = to;
        });
    });

    public selectRide = (ride: RideLitsItem) => {
        runInAction(() => {
            this.selectedRide = ride;
        });
    }

    public selectRideSearcherMode = (rideSearcherMode: RideSearcherMode) => {
        runInAction(() => {
            this.rideSearcherMode = rideSearcherMode;
        });
    }

    public readonly saveConnectedToRideOrManuallyAsync = this.async(async () => {
        if (!this.rideDetails) {
            return;
        }

        if (this.rideSearcherMode === RideSearcherMode.Ride) {
            if (!this.selectedRide) {
                alert("Kérjük válasszon ki egy fuvart!");
                return;
            }
            await AdminRideAdapter.connectOfflineRideToRideAsync(this.rideDetails.id, this.selectedRide.id);
        } else {
            await AdminRideAdapter.connectOfflineRideManualAsync(this.rideDetails.id, this.manualRideStore.rideExternalIdentifier, this.manualRideStore.rideAdditionalInfo);
        }

        NotificationStore.successfullySaved();
        NavigationStore.navigateBack();
    });

    public readonly deleteAsync = this.async(async () => {
        if (!this.rideDetails) {
            return;
        }

        if (!this.modifierName || this.modifierName.length == 0) {
            NotificationStore.error("Kérjük adja meg a törlést végző nevét!");
            return;
        }

        await AdminRideAdapter.deleteOfflineRideManualAsync(this.rideDetails.id, this.modifierName, this.modificationReason);

        NotificationStore.success("Sikeres törlés");
        NavigationStore.navigateTo("/admin/offlineRides");
    });

    public cancel() {
        NavigationStore.navigateBack();
    }

    public callbacks: IRideSearcherCallbacks = {
        selectRide: this.selectRide,
        setRideSearcherMode: this.selectRideSearcherMode
    }

    @action.bound setModificationReason(value: string | null) { this.modificationReason = value ?? ""; }
    @action.bound setModifierName(value: string | null) { this.modifierName = value ?? ""; }

    @action.bound showDeleteConfirmation() {
        this.isDeleteConfirmationModalOpen = true;
    }

    @action.bound cancelDeleteConfirmation() {
        this.isDeleteConfirmationModalOpen = false;
    }
}

const { ContextComponent: OfflineRideDetailStoreContext, StoreProvider: OfflineRideDetailStoreProvider, useStore: useOfflineRideDetailStore, withStore: withOfflineRideDetailStore } =
    createStoreProvider<IOfflineRideDetailCoreProps, OfflineRideDetailStore>(() => new OfflineRideDetailStore());

export {
    OfflineRideDetailStoreContext,
    OfflineRideDetailStoreProvider,
    useOfflineRideDetailStore,
    withOfflineRideDetailStore
};