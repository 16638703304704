import { observer } from "mobx-react";
import { getCostPlacementTypeDisplayValue } from "Models/CostPlacementType";
import PhoneNumber from "Models/PhoneNumber";
import { CostPlacementTypeSelector } from "Pages/CommonControls/CostPlacementTypeSelector";

export interface ICostPlacementTypeColumnProps {
    record: PhoneNumber;
    isUnderEdit: boolean;
}

function CostPlacementTypeColumn(props: ICostPlacementTypeColumnProps) {
    
    const getValue = () => {
        let res = getCostPlacementTypeDisplayValue(props.record.costPlacementType);
        if (props.record.costPlacement?.length) {
            res += ` (${props.record.costPlacement})`;
        }
        return res;
    }

    return (
        <>
            {props.isUnderEdit ?
                <CostPlacementTypeSelector 
                    costPlacementType={props.record.costPlacementType}
                    costPlacementValue={props.record.costPlacement}
                    onCostPlacementTypeChange={props.record.setCostPlacementType}
                    onCostplacementChange={props.record.setCostPlacement}
                /> :
                <span>{getValue()}</span>
            }
        </>
    )
}

export default observer(CostPlacementTypeColumn);