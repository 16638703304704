import { EnumHelper } from "Util/EnumHelper";

// Copy from Api.ts:
export enum InvoiceStatus {
    /// <summary>
    /// Számla elkészítve (alap állapot)
    /// </summary>
    Created = "Created",

    /// <summary>
    /// Pénzügyi rendszer átvette
    /// </summary>
    Received = "Received",

    /// <summary>
    /// Pénzügyi rendszer feldolgozta
    /// </summary>
    Processed = "Processed",

    /// <summary>
    /// Taxis kifizetve
    /// </summary>
    DriverPaid = "DriverPaid",

    /// <summary>
    /// Partner által kiegyenlítve
    /// </summary>
    Completed = "Completed"
}

function getInvoiceStatusEntries() {
    return EnumHelper.getEnumEnumerableEntries<InvoiceStatus>(InvoiceStatus, getInvoiceStatusDisplayValue);
}

export function getInvoiceStatusDisplayValue(value: InvoiceStatus) {

    switch(value) {
        case InvoiceStatus.Created: return "1. Létrehozva";
        case InvoiceStatus.Received: return "2. Pénzügy átvette";
        case InvoiceStatus.Processed: return "3. Pénzügy feldolgozta";
        case InvoiceStatus.DriverPaid: return "4. Kolléga kifizetve";
        case InvoiceStatus.Completed: return "5. Kiegyenlítve";
    }
}

export const InvoiceStatusEntries = getInvoiceStatusEntries();