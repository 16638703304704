import { Col, Row, Typography } from "antd";
import TextBox from "./TextBox";

const { Title } = Typography;

interface IAddressBoxProps {
    zipCode: string;
    onZipCodeChange: (value: string) => void;
    settlement: string;
    onSettlementChange: (value: string) => void;
    addressLine: string;
    onAddressLineChange: (value: string) => void;
    isRequired?: boolean;
    isHighlighted?: boolean;
    isReadOnly?: boolean;
}

function AddressBox(props: IAddressBoxProps) {
    return (
        <>
            <Row gutter={[16, 8]}>
                <Col span={7}>
                    <TextBox label="Ir.Sz:" onChange={props.onZipCodeChange} value={props.zipCode} maxLength={16} isRequired={props.isRequired} isReadOnly={props.isReadOnly} isHighlighted={props.isHighlighted} />
                </Col>
                <Col span={17}>
                    <TextBox label="Település:" onChange={props.onSettlementChange} value={props.settlement} maxLength={64} isRequired={props.isRequired} isReadOnly={props.isReadOnly} isHighlighted={props.isHighlighted} />
                </Col>

                <Col span={24}>
                    <TextBox label="Utca, házszám:" onChange={props.onAddressLineChange} value={props.addressLine} maxLength={128} isRequired={props.isRequired} isReadOnly={props.isReadOnly} isHighlighted={props.isHighlighted} />
                </Col>
            </Row>
        </>
    );
}

export default AddressBox;