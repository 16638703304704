import InvoiceForPartnerListItem from "Models/InvoiceForPartnerListItem";
import IInvoiceApiAdapter from "Adapters/IInvoiceApiAdapter";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import { mapInvoiceDetailForPartnerListItemDtoToDomainModel, mapInvoiceForPartnerListItemDtoToDomainModel, mapInvoiceListFilterToDto } from "Adapters/Mapping/Map";
import Ordering from "Adapters/QueryHelpers/Ordering";
import { PrintOptions } from "Models/PrintOptions";
import CreatedInvoice from "Models/CreatedInvoice";
import Paging from "Adapters/QueryHelpers/Paging";
import { ICreatedInvoiceResponse, IDownloadInvoiceRequest, IGetInvoiceListRequest, IInvoiceListItemDetailDto, IAccountedRideModificationDto } from "Adapters/Api/Api.g";
import Guid from "Util/Guid";
import InvoiceFilter from "Models/Filters/InvoiceFilter";
import InvoiceDetailForPartnerListItem from "Models/InvoiceDetailForPartnerListItem";

export class AdminInvoiceApiAdapter extends ProtectedApiAdapterBase implements IInvoiceApiAdapter {

    private readonly mainRoute = "api/web/admin/invoice";

    public getListAsync(paging: Paging, ordering: Ordering, filter: InvoiceFilter): Promise<{ totalCount: number, invoices: InvoiceForPartnerListItem[], totalGrossPrice: number }> {
        const request: IGetInvoiceListRequest = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapInvoiceListFilterToDto(filter)
        }

        return this.httpPost<{ totalCount: number, invoices: [], totalGrossPrice: number }>(this.mainRoute, undefined, request).map(paginatedResult => {
            return {
                totalCount: paginatedResult.totalCount,
                invoices: paginatedResult.invoices.map((i: any) => {
                    return mapInvoiceForPartnerListItemDtoToDomainModel(i);
                }),
                totalGrossPrice: paginatedResult.stats.totalGrossPrice
            };
        }).performOperationAsync();
    }

    public printInvoicesAsync(ordering: Ordering, filter: any, printOption: PrintOptions, showExtendedColumns: boolean) {
        const request: IDownloadInvoiceRequest = {
            printOption,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapInvoiceListFilterToDto(filter),
            showExtendedColumns
        };
        return this.httpPost<string>(this.mainRoute, ["print"], request).map(result => {
            return result;
        }).performOperationAsync();
    }

    // public stornoAsync(invoiceId: number) {
    //     return this.httpPost<CreatedInvoice>(this.mainRoute, [invoiceId.toString(), "storno", Guid.newGuid()])
    //         .map((response: ICreatedInvoiceResponse) => new CreatedInvoice(response.invoiceId))
    //         .performOperationAsync();
    // }

    // public correctAsync(invoiceId: number, accountedRideId: number, grossPrice: number, costCenter: string) {
    //     const request: ICreateInvoiceManuallyRequest = {
    //         requestId: Guid.newGuid(),
    //         costPlacement: costCenter,
    //         grossPrice: grossPrice.toString(),
    //         accountedRideId
    //     };

    //     return this.httpPost<CreatedInvoice>(this.mainRoute, [invoiceId.toString(), "correct"], request)
    //         .map((response: ICreatedInvoiceResponse) => new CreatedInvoice(response.invoiceId))
    //         .performOperationAsync();
    // }

    public compositeModifyAsync(invoiceId: number, data: IAccountedRideModificationDto): Promise<CreatedInvoice> {
        return this.httpPost<CreatedInvoice>(this.mainRoute, [invoiceId.toString(), "compositeModify"], data)
            .map((response: ICreatedInvoiceResponse) => new CreatedInvoice(response.newInvoiceId))
            .performOperationAsync();
    }

    public getPdfAsync(invoiceId: number): Promise<string> {
        return this.httpGet<string>(this.mainRoute, [invoiceId.toString(), "pdf"]).map(result => {
            return result;
        }).performOperationAsync();
    }

    public getDetailsAsync(invoiceId: number): Promise<InvoiceDetailForPartnerListItem> {
        return this.httpGet<InvoiceDetailForPartnerListItem>(this.mainRoute, [invoiceId.toString()]).map((result: IInvoiceListItemDetailDto) => {
            return mapInvoiceDetailForPartnerListItemDtoToDomainModel(result);
        }).performOperationAsync();
    }
}

export default new AdminInvoiceApiAdapter();
