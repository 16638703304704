import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import IPollApiAdapter from "Adapters/IPollApiAdapter";
import { Button, Col, Divider, Dropdown, Menu, PageHeader, Row, Space, Statistic, Typography } from "antd";
import Table, { ColumnsType, ColumnType, TablePaginationConfig } from "antd/lib/table";
import { Observer, observer } from "mobx-react";
import VoteListItem from "Models/VoteListItem";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { getFullScreenTableScroll } from "Util/TableHelpers";
import { usePollsStore } from "../PollsStore";
import { useVotesStore, withVotesStore } from "./VotesStore";
import Title from "antd/lib/typography/Title";
import Answer from "Models/Answer";
import ReadonlyInput from "Pages/CommonControls/ReadonlyInput";
import PieChartComponent from "Pages/CommonControls/Chart/PieChart";
import DateRangePicker from "Pages/CommonControls/DateRangePicker";
import TextBox from "Pages/CommonControls/TextBox";
import { DownOutlined, DownloadOutlined, FileExcelOutlined, FilePdfOutlined, FilterOutlined } from "@ant-design/icons";
import NumberBox from "Pages/CommonControls/NumberBox";
import { getTargetAudienceDisplayValue, TargetAudience } from "Models/TargetAudience";
import CheckBox from "Pages/CommonControls/CheckBox";
import { PrintOptions } from "Models/PrintOptions";


const { Text } = Typography;
export interface IVotesProps {
    pollApiAdapter: IPollApiAdapter;
}
export interface IVotesCoreProps extends IVotesProps {
    pollId?: number;
}


function VotesCore(props: IVotesCoreProps) {
    const store = useVotesStore();
    const isVoted = (record: VoteListItem) => !isNullOrUndefined(record.answeredAt);
    const notVotedRowColor = 'rgb(255, 127, 127)';

    const _paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    }

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    let paginationConfig = _paginationConfig;

    const votesColumn: Array<ColumnType<VoteListItem>> = [
        {
            title: "BPID",
            dataIndex: "bpId",
            key: "bpId",
            render: (_, record: VoteListItem) => {
                const voted = isVoted(record);
                const style = voted ? {} : { backgroundColor: notVotedRowColor };
                return {
                    props: { style },
                    children: (<span>{record.bpId}</span>)
                }
            },
            sorter: true,
        },
        {
            title: "Név",
            dataIndex: "name",
            key: "name",
            render: (_, record: VoteListItem) => {
                const voted = isVoted(record);
                const style = voted ? {} : { backgroundColor: notVotedRowColor };
                return {
                    props: { style },
                    children: (<span>{record.name}</span>)
                }
            },
            sorter: true
        },
        {
            title: "Leadva",
            dataIndex: "answeredAt",
            key: "answeredAt",
            render: (_, record: VoteListItem) => {
                const voted = isVoted(record);
                const style = voted ? {} : { backgroundColor: notVotedRowColor };
                return {
                    props: { style },
                    children: (<span>{isNullOrUndefined(record.answeredAt) ? "" : record.answeredAt!.format("L LT")}</span>)
                }
            },
            sorter: true
        },
        {
            title: "Válasz",
            dataIndex: "answerNumber",
            key: "answerNumber",
            render: (_, record: VoteListItem) => {
                const voted = isVoted(record);
                const style = voted ? {} : { backgroundColor: notVotedRowColor };
                return {
                    props: { style },
                    children: (<span>{isNullOrUndefined(record.answerNumber) ? "" : record.answerNumber}</span>)
                }
            },
            sorter: true
        },
        {
            title: "Szövetkezeti tag",
            dataIndex: "isCooperativeMember",
            key: "isCooperativeMember",
            render: (isCooperativeMember: boolean, record: VoteListItem) => {
                const voted = isVoted(record);
                const style = voted ? {} : { backgroundColor: notVotedRowColor };
                return {
                    props: { style },
                    children: <span>{isCooperativeMember ? "Igen" : "Nem"}</span>
                }
            },
            sorter: true
        },
    ];

    if (store.poll?.allowCustomAnswer === true) {
        votesColumn.push({
            title: "Egyedi válasz",
            dataIndex: "customAnswer",
            key: "customAnswer",
            render: (customAnswer: string, record: VoteListItem) => {
                const voted = isVoted(record);
                const style = voted ? {} : { backgroundColor: notVotedRowColor };
                return {
                    props: { style },
                    children: <span>{customAnswer}</span>
                }
            },
            sorter: true
        });
    }

    const downloadMenu = (
        <Menu>
            <div>
                <Button onClick={() => store.printVotesAsync.fireAndForget(PrintOptions.PrintToPdf)} icon={<FilePdfOutlined />} style={{ width: "100%" }}>
                    Lista letöltése PDF-ben
                </Button>
            </div>
            <div>
                <Button onClick={() => store.printVotesAsync.fireAndForget(PrintOptions.PrintToExcel)} icon={<FileExcelOutlined />} style={{ width: "100%" }}>
                    Lista letöltése Excel-ben
                </Button>
            </div>
        </Menu>
    );


    const AnswerColumns: ColumnsType<Answer> = [

        {
            title: "Szám",
            dataIndex: "number",
            key: "number",
            width: 75
        },
        {
            title: "Név",
            dataIndex: "text",
            key: "text",
        },

    ];

    const pollTableScroll = getFullScreenTableScroll(votesColumn.reduce((sum, col) => sum + (col.width as number ?? 100), 0));
    const voteTableScroll = getFullScreenTableScroll(AnswerColumns.reduce((sum, col) => sum + (col.width as number ?? 100), 0));

    return (
        <>
            <PageHeader title={<span id="page_title">Szavazás követése</span>} >
                <>
                    <Row gutter={[16, 8]}>
                        <Divider orientation="left">Szavazás adatai</Divider>
                        <Col xxl={14} xl={14} lg={24} xs={24} sm={24}>
                            <Row gutter={[16, 8]}>
                                <Col xxl={12} xl={12} lg={12} xs={12} sm={12}>
                                    <ReadonlyInput label="Szavazás indításának ideje:" value={store.poll?.startedAt?.format("YYYY.MM.DD HH:mm") ?? ""} />
                                </Col>
                                <Col xxl={12} xl={12} lg={12} xs={12} sm={12}>
                                    <ReadonlyInput label="Szavazás vége:" value={store.poll?.endOfVoteDate?.format("YYYY.MM.DD HH:mm") ?? ""} />
                                </Col>
                                <Col xxl={12} xl={12} lg={12} xs={12} sm={12}>
                                    <ReadonlyInput label="Célcsoport:" value={isNullOrUndefined(store.poll?.targetAudience) ? "" : getTargetAudienceDisplayValue(store.poll!.targetAudience)} />
                                </Col>
                                <Col xxl={12} xl={12} lg={12} xs={12} sm={12}>
                                    <ReadonlyInput label="Szavazás címe:" value={store.poll?.name ?? ""} />
                                </Col>
                                <Col xxl={12} xl={12} lg={12} xs={12} sm={12}>
                                    <ReadonlyInput multiline label="Kérdés:" value={store.poll?.question ?? ""} />
                                </Col>
                                <Col xxl={12} xl={12} lg={12} xs={12} sm={12}>
                                    <Button style={{ width: "100%" }} onClick={store.printPollAsync.fireAndForget} icon={<DownloadOutlined />} id="download_poll">
                                        Letöltés PDF-ben
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={10} xl={10} lg={24} xs={24} sm={24}>
                            <Text>Válaszlehetőségek:</Text>
                            <Table size="middle" columns={AnswerColumns} scroll={voteTableScroll} dataSource={store.poll?.answers} rowKey="number" />
                        </Col>
                        <Divider orientation="left">Szavazás megoszlásai</Divider>
                        <Col xxl={7} xl={7} lg={14} xs={24} sm={14}>
                            <Text>Leadott szavazatok válaszainak megoszlása:</Text>
                            <PieChartComponent
                                distribution={store.pollDistribution?.answerDistribution ?? []} />
                        </Col>
                        <Col xxl={5} xl={5} lg={10} xs={24} sm={10}>
                            {store.pollDistribution?.answerDistribution.map(ad => (
                                <Statistic title={`${ad.name}. válasz:`} value={`${ad.quantity} kolléga`} />
                            ))}
                        </Col>

                        <Col xxl={7} xl={7} lg={14} xs={24} sm={14}>
                            <Text>Leadott/nem leadott szavazatok megoszlása:</Text>
                            <PieChartComponent
                                distribution={store.pollDistribution?.pollDistribution ?? []} />
                        </Col>
                        <Col xxl={5} xl={5} lg={10} xs={24} sm={10}>
                            {store.pollDistribution?.pollDistribution.map(ad => (
                                <Statistic title={ad.name + ":"} value={`${ad.quantity} kolléga`} />
                            ))}
                        </Col>
                        {store.poll?.targetAudience === TargetAudience.AllEmployee &&
                            <>
                                <Col xxl={7} xl={7} lg={14} xs={24} sm={14}>
                                    <Text>Leadott szavazatok válaszainak megoszlása a szövetkezeti tagok között:</Text>
                                    <PieChartComponent
                                        distribution={store.pollDistribution?.cooperativeMemberAnswerDistribution ?? []} />
                                </Col>
                                <Col xxl={5} xl={5} lg={10} xs={24} sm={10}>
                                    {store.pollDistribution?.cooperativeMemberAnswerDistribution?.map(ad => (
                                        <Statistic title={`${ad.name}. válasz:`} value={`${ad.quantity} kolléga`} />
                                    ))}
                                </Col>

                                <Col xxl={7} xl={7} lg={14} xs={24} sm={14}>
                                    <Text>Leadott/nem leadott szavazatok megoszlása a szövetkezeti tagok között:</Text>
                                    <PieChartComponent
                                        distribution={store.pollDistribution?.pollCooperativeMemberDistribution ?? []} />
                                </Col>
                                <Col xxl={5} xl={5} lg={10} xs={24} sm={10}>
                                    {store.pollDistribution?.pollCooperativeMemberDistribution?.map(ad => (
                                        <Statistic title={ad.name + ":"} value={`${ad.quantity} kolléga`} />
                                    ))}
                                </Col>
                            </>
                        }
                    </Row>
                </>
                <Divider orientation="left">Szavazaztok</Divider>
                <Observer>
                    {() => (
                        <>
                            <Row gutter={16}>
                                <Col xl={6} lg={8} md={10} xs={12}>
                                    <DateRangePicker
                                        label="Leadva időszak:"
                                        from={store.voteFilter.anseweredAtFrom}
                                        to={store.voteFilter.anseweredAtTo}
                                        onChange={store.voteFilter.setAnseweredAtFilterRange}
                                        style={{ width: "100%" }}
                                        onEnter={store.filterResult}
                                        allowClear
                                        id="filter_vote_answeredAt"
                                    />
                                </Col>
                                <Col xl={6} lg={8} md={10} xs={12}>
                                    <TextBox
                                        label="BPID:"
                                        onChange={store.voteFilter.setBpId}
                                        value={store.voteFilter.bpId}
                                        onEnter={store.filterResult}
                                        id="filter_vote_bpid"
                                    />
                                </Col>
                                <Col xl={6} lg={8} md={10} xs={12}>
                                    <TextBox
                                        label="Név:"
                                        onChange={store.voteFilter.setName}
                                        value={store.voteFilter.name}
                                        onEnter={store.filterResult}
                                        id="filter_vote_name"
                                    />
                                </Col>
                                <Col xl={6} lg={8} md={10} xs={12}>
                                    <NumberBox
                                        label="Válasz:"
                                        onChange={store.voteFilter.setAnswerNumber}
                                        value={store.voteFilter.answerNumber}
                                        onEnter={store.filterResult}
                                        id="filter_poll_answerNumber"
                                    />
                                </Col>
                            </Row>
                            {store.poll?.targetAudience === TargetAudience.AllEmployee &&
                                <Row>
                                    <Col>
                                        <CheckBox
                                            label={"Csak szövetkezeti tagok megjelenítése"}
                                            style={{ marginTop: "16px" }}
                                            onChange={store.voteFilter.setOnlyShowCooperativeMember}
                                            value={store.voteFilter.onlyShowCooperativeMember} />
                                    </Col>
                                </Row>
                            }
                            {<Space style={{ marginTop: "16px", marginBottom: "16px" }}>
                                <Button key="1" style={{ width: "100%" }} type="primary" onClick={store.filterResult} icon={<FilterOutlined />} id="filter_vote_list">
                                    Szűrés
                                </Button>

                                <Dropdown.Button
                                    style={{ width: "100%" }}
                                    onClick={() => store.printVotesAsync.fireAndForget(PrintOptions.PrintToExcel)}
                                    icon={<DownOutlined />}
                                    overlay={downloadMenu}
                                >
                                    Lista letöltése
                                    <DownloadOutlined />
                                </Dropdown.Button>
                            </Space>
                            }
                        </>
                    )}
                </Observer>
                <TableSummaryHeader items={summaryItems} />
                <Table
                    onChange={store.reloadList}
                    pagination={paginationConfig}
                    size="middle"
                    columns={votesColumn}
                    dataSource={store.votes}
                    scroll={pollTableScroll}
                    rowKey="id" />
            </PageHeader >
        </>
    );
}

const VotesCoreWithStore = withVotesStore(VotesCore);

export default function Votes(props: IVotesProps) {
    const { pollId } = useParams();
    return (
        <VotesCoreWithStore {...props} pollId={parseInt(pollId!)} />
    )
}