import { DownloadOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, PageHeader, Select, Table, TablePaginationConfig, Typography } from "antd";
import moment from "moment";
import { useOfflineRideListStore, withOfflineRideListStore } from "./OfflineRideListStore";
import { OfflineRideListColumns } from "./OfflineRideListColumns";
import { useMemo } from "react";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";

const { RangePicker } = DatePicker;
const { Title } = Typography;

export interface IOfflineRideListProps {
}

function RideListCore(props: IOfflineRideListProps) {
    const dateFormat = "YYYY.MM.DD";

    const store = useOfflineRideListStore();

    const paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    };

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    return (
        <>
            <PageHeader
                title={<span id="page_title">Fuvar nélküli elszámolások</span>}
                // subTitle="Offline vagy átküldött cím nélkül"
            >
                <TableSummaryHeader items={summaryItems} />
                <Table onChange={store.reloadList} pagination={paginationConfig} size="middle" columns={OfflineRideListColumns} dataSource={store.rides} rowKey="id" />
            </PageHeader>
        </>
    );
}

export default withOfflineRideListStore(RideListCore);