import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { DatePicker, Typography } from "antd";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { ValidationMessages } from "StaticResources/ValidationMessages";
import { useValidationStore } from "Stores/ValidationStore";

const { Text } = Typography;

export interface IDatePickerBoxProps {
    onChange: (date: moment.Moment | null) => void;
    date: moment.Moment | null;
    disabled?: boolean;
    style?: React.CSSProperties;
    onEnter?: () => void;
    label?: string;
    allowClear?: boolean;
    showTime?: boolean;
    defaultValue?: moment.Moment;
    format?: string;
    id?: string;
    isRequired?: boolean;
    preventIsBeforeOrSame?: boolean;
}

function DatePickerBox(props: IDatePickerBoxProps) {
    const { date, onChange } = props;
    const dateFormat = isNullOrUndefined(props.format) ? "YYYY.MM.DD" : props.format;
    const [error, setError] = useState<string | null>(null);

    const setValue = useCallback((date: any, dateStrings: string) => {
        onChange(date);
        setError(validate(date, props.isRequired ?? false, props.preventIsBeforeOrSame ?? false));


    }, [onChange]);

    function validate(date: moment.Moment | null, isRequired: boolean, preventIsBeforeOrSame: boolean): string | null {
        if (isRequired && isNullOrUndefined(date)) {
            return ValidationMessages.isRequired;
        }
        if (preventIsBeforeOrSame && date?.isSameOrBefore(moment())) {
            return ValidationMessages.isBeforeOrSameDate;
        }
        return null;
    }

    const validationStore = useValidationStore();

    useEffect(() => {
        return validationStore?.registerValidateCallbackWithDisposer(() => {
            const result = validate(date, props.isRequired ?? false, props.preventIsBeforeOrSame ?? false);
            setError(result);
            return result === null;
        });
    });

    const keyDown = useCallback((e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            props.onEnter?.();
        }
    }, [props.onEnter]);

    return (
        <div style={{ width: "100%", ...props.style }}>
            <Text>{props.label}{props.isRequired ? "*" : ""}</Text>

            <DatePicker
                showTime={props.showTime}
                style={{ width: "100%" }}
                disabled={props.disabled}
                status={error ? "error" : undefined}
                onChange={setValue}
                value={date}
                defaultValue={props.defaultValue}
                format={dateFormat}
                onKeyDown={keyDown}
                allowClear={props.allowClear}
                id={props.id}
            />
            {error && !props.disabled && <Text type="danger">{error}</Text>}
        </div>
    )
}

export default DatePickerBox;