import { PageHeader, Table, TablePaginationConfig, Button, Space, Tooltip, Row, Col, } from "antd";
import { EditOutlined, FilterOutlined } from "@ant-design/icons";
import { ColumnType } from "antd/lib/table";
import { Observer } from "mobx-react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import TextBox from "Pages/CommonControls/TextBox";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";
import { getFullScreenTableScroll } from "Util/TableHelpers";
import { DocumentStatus, getDocumentStatusDisplayValue } from "Models/DocumentStatus";
import VehicleConditionListItem from "Models/VehicleConditionListItem";
import { VehicleConditionType, getVehicleConditionTypeDisplayValue } from "Models/VehicleConditionType";
import { VehicleConditionStatus, getVehicleConditionStatusDisplayValue } from "Models/VehicleConditionStatus";
import { useVehicleConditionListStore } from "./VehicleConditionListStore";
import { VehicleConditionStatusSelectBox } from "Pages/CommonControls/VehicleConditionStatusSelectBox";
import { VehicleConditionTypeSelectBox } from "Pages/CommonControls/VehicleConditionTypeSelectBox";
import DateRangePicker from "Pages/CommonControls/DateRangePicker";

function VehicleConditionList() {
    const store = useVehicleConditionListStore();

    const _paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    }

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    let paginationConfig = _paginationConfig;

    const vehicleConditionColumns: Array<ColumnType<VehicleConditionListItem>> = [
        {
            title: "Kolléga BPID",
            dataIndex: "bpId",
            key: "bpId",
            sorter: true,
        },
        {
            title: "Kolléga neve",
            dataIndex: "name",
            key: "name",
            sorter: true
        },
        {
            title: "Bejelentés ideje",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (value: moment.Moment) => <span> {value?.format("YYYY.MM.DD HH:mm") ?? ""}</span>,
            sorter: true
        },
        {
            title: "Bejelentés típusa",
            dataIndex: "type",
            key: "type",
            render: (value: VehicleConditionType) => <span> {getVehicleConditionTypeDisplayValue(value)}</span>,
            sorter: true
        },
        {
            title: "Állapot",
            dataIndex: "status",
            key: "status",
            render: (value: VehicleConditionStatus) => <span> {getVehicleConditionStatusDisplayValue(value)}</span>,
            sorter: true
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            width: 100,
            render: (_, record: VehicleConditionListItem) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Szerkeztés">
                            <Link to={`${record.id}`}>
                                <Button key={`${record.id}`} icon={<EditOutlined />} type="primary" />
                            </Link>
                        </Tooltip>
                    </Space>
                );
            }
        }

    ];

    const tableScroll = getFullScreenTableScroll(vehicleConditionColumns.reduce((sum, col) => sum + (col.width as number ?? 100), 0));

    return (
        <PageHeader title={"Jármű állapotok lista"} >
            <Observer>
                {() => (
                    <>
                        <Row gutter={16}>
                            <Col xxl={8} xl={10} xs={12}>
                                <TextBox
                                    label="Kolléga neve:"
                                    onChange={store.vehicleConditionFilter.setPartnerName}
                                    value={store.vehicleConditionFilter.partnerName}
                                    onEnter={store.filterResult}
                                    id="filter_vehicleConditions_partnerName"
                                />
                            </Col>
                            <Col xxl={8} xl={10} xs={12}>
                                <TextBox
                                    label="Kolléga BPID:"
                                    onChange={store.vehicleConditionFilter.setPartnerBpid}
                                    value={store.vehicleConditionFilter.partnerBpid}
                                    onEnter={store.filterResult}
                                    id="filter_vehicleConditions_partnerBpId"
                                />
                            </Col>
                            <Col xxl={8} xl={10} xs={12}>
                                <VehicleConditionTypeSelectBox
                                    style={{ width: "100%" }}
                                    label="Bejelentés típusa:"
                                    onChange={store.vehicleConditionFilter.setType}
                                    vehicleConditionType={store.vehicleConditionFilter.type}
                                    allowClear
                                />
                            </Col>
                            <Col xxl={8} xl={10} xs={12}>
                                <DateRangePicker
                                    label="Bejelentés ideje:"
                                    onChange={store.vehicleConditionFilter.setCreatedAtRange}
                                    from={store.vehicleConditionFilter.createdAtFrom}
                                    to={store.vehicleConditionFilter.createdAtTo}
                                    format="YYYY.MM.DD HH:mm:ss"
                                    id="filter_vehicleConditions_createdAtRange"
                                    allowClear
                                    showTime
                                />
                            </Col>
                            <Col xxl={8} xl={10} xs={12}>
                                <VehicleConditionStatusSelectBox
                                    style={{ width: "100%" }}
                                    label="Állapot:"
                                    onChange={store.vehicleConditionFilter.setStatus}
                                    vehicleConditionStatus={store.vehicleConditionFilter.status}
                                    allowClear
                                />
                            </Col>
                        </Row>
                        {<Space style={{ marginTop: "16px", marginBottom: "16px" }}>
                            <Button key="1" style={{ width: "100%" }} type="primary" onClick={store.filterResult} icon={<FilterOutlined />} id="filter_document_list">
                                Szűrés
                            </Button>
                        </Space>
                        }
                    </>
                )}
            </Observer>
            <TableSummaryHeader items={summaryItems} />
            <Table
                onChange={store.reloadList}
                pagination={paginationConfig}
                size="middle"
                columns={vehicleConditionColumns}
                dataSource={store.vehicleConditions}
                scroll={tableScroll}
                rowKey="id" />
        </PageHeader>
    );
}


export default VehicleConditionList;
