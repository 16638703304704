
export default class VoteListItem {
    constructor(
        public readonly id: number,
        public readonly bpId: string,
        public readonly name: string,
        public readonly answeredAt: moment.Moment | null,
        public readonly answerNumber: number | null,
        public readonly isCooperativeMember: boolean,
        public readonly customAnswer: string | null,
    ) { }
}