import { IQueryParameters } from "@eblsoft/react-toolkit";
import { IUpdatePartnerBaseDataRequest, IUpdatePartnerEmailPreferencesRequest, IUserInvitationUrlDto } from "Adapters/Api/Api.g";
import IPartnerApiAdapter from "Adapters/IPartnerApiAdapter";
import { mapPartnerListItemDtoToDomainModel, mapPartnerWithEmailPreferencesDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { PartnerWithEmailPreferences } from "Models/Partner";
import PartnerListItem from "Models/PartnerListItem";
import UserInvitationUrl from "Models/UserInvitationUrl";
import moment from "moment";

class AdminPartnerAdapter extends ProtectedApiAdapterBase implements IPartnerApiAdapter {
    private readonly mainRoute = "api/web/admin/Partner";

    public getListAsync(paging: Paging, ordering: Ordering, filter: any): Promise<{ totalCount: number, partners: PartnerListItem[] }> {
        const request: IQueryParameters = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            ...filter
        }
        return this.httpGet<{ totalCount: number, partners: PartnerListItem[] }>(this.mainRoute, undefined, request).map(paginatedResult => {
            return {
                totalCount: paginatedResult.totalCount,
                partners: paginatedResult.partners.map((p: any) => {
                    return mapPartnerListItemDtoToDomainModel(p);
                })
            };
        }
        ).performOperationAsync();
    }

    public getAsync(partnerId?: number): Promise<PartnerWithEmailPreferences> {

        return this
            .httpGet<PartnerWithEmailPreferences>(this.mainRoute, [partnerId!.toString(), "baseData"], undefined)
            .map(result => mapPartnerWithEmailPreferencesDtoToDomainModel(result))
            .performOperationAsync();
    }

    public getOrCreateInvitationAsync(partnerId: number): Promise<UserInvitationUrl> {
        return this
            .httpPost<UserInvitationUrl>(this.mainRoute, [partnerId.toString(), "user", "invitation"], undefined)
            .map((result: IUserInvitationUrlDto) => new UserInvitationUrl(result.url, moment(result.validTo)))
            .performOperationAsync();
    }

    public resetInvitationExpirationAsync(partnerId: number): Promise<UserInvitationUrl> {
        return this
            .httpPost<UserInvitationUrl>(this.mainRoute, [partnerId.toString(), "user", "invitation", "reset"], undefined)
            .map((result: IUserInvitationUrlDto) => new UserInvitationUrl(result.url, moment(result.validTo)))
            .performOperationAsync();
    }

    public updateEmailPreferencesAsync(invoiceSummaryEmail: string, eTaxiContactEmail: string, includeRideDataInInvoiceSummary: boolean, partnerId: number): Promise<PartnerWithEmailPreferences> {
        const request: IUpdatePartnerEmailPreferencesRequest = {
            eTaxiContactEmail,
            invoiceSummaryEmail,
            includeRideDataInInvoiceSummary
        };

        return this
            .httpPost<PartnerWithEmailPreferences>(this.mainRoute, [partnerId.toString(), "email"], request)
            .map(result => mapPartnerWithEmailPreferencesDtoToDomainModel(result))
            .performOperationAsync();
    }

    public updateBaseDataAsync(cardPermanentCancellationFee: number, partnerId: number): Promise<PartnerWithEmailPreferences> {
        const request: IUpdatePartnerBaseDataRequest = {
            cardPermanentCancellationFee
        };

        return this
            .httpPost<PartnerWithEmailPreferences>(this.mainRoute, [partnerId.toString(), "baseData"], request)
            .map(result => mapPartnerWithEmailPreferencesDtoToDomainModel(result))
            .performOperationAsync();
    }
}

export default new AdminPartnerAdapter();