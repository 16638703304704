import { IDownloadTaxiCardOrderRequest, IGetTaxiCardOrdersRequest, IUpdateTaxiCardOrderRequest, PrintOptions } from "Adapters/Api/Api.g";
import { mapTaxiCardOrderListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import TaxiCardOrderListItem from "Models/TaxiCardOrderListItem";
import { TaxiCardOrderStatus } from "Models/TaxiCardOrderStatus";

class AdminTaxiCardOrderAdapter extends ProtectedApiAdapterBase {
    private readonly mainRoute = "api/web/admin/TaxiCardOrder";

    public getListAsync(paging: Paging, ordering: Ordering, filter: any): Promise<{ totalCount: number, taxiCardOrders: TaxiCardOrderListItem[] }> {
        const body: IGetTaxiCardOrdersRequest = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            ...filter
        }

        return this.httpPost<{ totalCount: number, taxiCardOrders: TaxiCardOrderListItem[] }>(this.mainRoute, undefined, body).map(paginatedResult => {
            return {
                totalCount: paginatedResult.totalCount,
                taxiCardOrders: paginatedResult.taxiCardOrders.map((p: any) => {
                    return mapTaxiCardOrderListItemDtoToDomainModel(p);
                })
            };
        }
        ).performOperationAsync();
    }

    public printAsync(ordering: Ordering, printOption: PrintOptions, filter: any) {
        const queryParameters: IDownloadTaxiCardOrderRequest = {
            printOption: printOption,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            ...filter
        };
        return this.httpPost<string>(this.mainRoute, ["print"], queryParameters).map(result => {
            return result;
        }).performOperationAsync();
    }

    public updateAsync(id: number, status: TaxiCardOrderStatus) {
        const body: IUpdateTaxiCardOrderRequest = {
            id,
            taxiCardOrderStatus: status
        }

        return this.httpPost<boolean>(this.mainRoute, ["update"], body).map((res) => {
            return true;
        }).performOperationAsync();
    }

    public printReceiptAsync(taxiCardOrderId: number) {
        return this.httpGet<string>(this.mainRoute, [taxiCardOrderId.toString(), "receipt"]).map(result => {
            return result;
        }).performOperationAsync();
    }
}

export default new AdminTaxiCardOrderAdapter();