export default class TaxNumber {
    constructor(
        public readonly identifier: string,
        public readonly vatCode: string,
        public readonly countyCode: string,
    ) {}

    public toString() {
        return `${this.identifier}-${this.vatCode}-${this.countyCode}`;
    }
}