import { action, computed, makeObservable, observable } from "mobx";
import { DocumentStatus } from "Models/DocumentStatus";
import moment from "moment";

class AdminDocumentFilter {

    @observable.ref public name: string = "";
    @observable.ref public driverName: string = "";
    @observable.ref public bpid: string = "";
    @observable.ref public licensePlateNumber: string = "";
    @observable.ref public documentStatuses: DocumentStatus[] = [];

    @observable public areFiltersDirty = false;

    public constructor(
    ) {
        makeObservable(this);
    }

    @computed public get isEmpty() {
        return this.name === "" &&
            this.driverName === "" &&
            this.bpid === "" &&
            this.licensePlateNumber === "" &&
            this.documentStatuses.length == 0;
    }

    @action.bound
    public clear() {
        this.name = "";
        this.driverName = "";
        this.bpid = "";
        this.licensePlateNumber = "";
        this.documentStatuses = [];
        this.areFiltersDirty = true;
    }

    @action.bound
    public setName(value: string) {
        this.name = value;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setDriverName(value: string) {
        this.driverName = value;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setBpid(value: string) {
        this.bpid = value;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setLicensePlateNumber(value: string) {
        this.licensePlateNumber = value;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setDocumentStatuses(value: DocumentStatus[]) {
        this.documentStatuses = value;
        this.areFiltersDirty = true;
    }

}

export default AdminDocumentFilter;