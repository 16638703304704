import { bound, createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import AdminSimpleDocumentAdapter from "Adapters/AdminAdapter/AdminSimpleDocumentAdapter";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import SimpleDocumentFilter from "Models/Filters/SimpleDocumentFilter";
import SimpleDocumentListItem from "Models/SimpleDocumentListItem";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { action, observable, runInAction } from "mobx";
import { ISimpleDocumentListCoreProps } from "./SimpleDocumentList";
import { applyDocumentListFilterDtoTo, mapSimpleDocumentListFilterToDto } from "Adapters/Mapping/Map";


const filterSessionKey = "simpleDocument_list_filter_v1";

export default class SimpleDocumentsStore extends StoreBase<ISimpleDocumentListCoreProps> {
    @observable.ref public documents: SimpleDocumentListItem[] = [];

    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    public orderedBy = "bpId";
    public orderDirection = "ascend";

    public documentFilter = new SimpleDocumentFilter();

    public readonly vLoadAsync = this.async(async () => {
        await this.restoreState();
        await this.loadListAsync();
    });
    
   private readonly loadListAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await AdminSimpleDocumentAdapter.getListAsync(this.props.simpleDocumentType, paging, ordering, this.documentFilter);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.documents = result.simpleDocuments;
        });
    });

    private restoreState() {
        try {
            const saved = sessionStorage.getItem(filterSessionKey);
            if (saved) {
                const stateStorage = JSON.parse(saved);
                runInAction(() => {
                    applyDocumentListFilterDtoTo(stateStorage.filter, this.documentFilter);
                    this.orderedBy = stateStorage.orderedBy;
                    this.orderDirection = stateStorage.orderDirection;
                });
            }
        } catch { }
    }

    private saveState() {
        try {
            const filterStorage = mapSimpleDocumentListFilterToDto(this.documentFilter);
            sessionStorage.setItem(filterSessionKey, JSON.stringify({
                filter: filterStorage,
                orderedBy: this.orderedBy,
                orderDirection: this.orderDirection
            }));
        }
        catch { }
    }

    public vGetReloadTriggerProps(props?: ISimpleDocumentListCoreProps) {
        return [props!.simpleDocumentType];
    }

    @bound
    public clearState() {
        this.documentFilter.clear();
        sessionStorage.removeItem(filterSessionKey);
        this.filterResult();
    }

    @action.bound
    public filterResult() {
        this.saveState();
        this.currentPage = 1;
        this.documentFilter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<SimpleDocumentListItem> | SorterResult<SimpleDocumentListItem>[], extra: TableCurrentDataSource<SimpleDocumentListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (!sorter) {
            this.orderedBy = "bpId";
        } else {
            if (Array.isArray(sorter)) {
                this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
                this.orderDirection = sorter[0].order!;
            } else {
                this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
                this.orderDirection = sorter.order!;
            }
        }
        this.saveState();
        this.vLoadAsync.fireAndForget();
    }

}

const { ContextComponent: SimpleDocumentsStoreContext, StoreProvider: SimpleDocumentsStoreProvider, useStore: useSimpleDocumentsStore, withStore: withSimpleDocumentsStore } = createStoreProvider<ISimpleDocumentListCoreProps, SimpleDocumentsStore>(() => new SimpleDocumentsStore());

export {
    SimpleDocumentsStoreContext,
    SimpleDocumentsStoreProvider,
    useSimpleDocumentsStore,
    withSimpleDocumentsStore
};