import { IQueryParameters } from "@eblsoft/react-toolkit";
import { ITogglePhoneNumberProhibitionRequest } from "Adapters/Api/Api.g";
import IPhoneNumberAdapter from "Adapters/IPhoneNumberAdapter";
import { mapPhoneNumberDtoToDomainModel, mapPhoneNumberListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { IPhoneNumberEditData } from "Models/IPhoneNumberEditData";
import PhoneNumber from "Models/PhoneNumber";
import PhoneNumberListItem from "Models/PhoneNumberListItem";
import { PrintOptions } from "Models/PrintOptions";

class AdminPhoneNumberAdapter extends ProtectedApiAdapterBase implements IPhoneNumberAdapter {
    private readonly mainRoute = "api/web/admin/phoneNumber";

    public getListAsync(paging: Paging, ordering: Ordering, filter: any): Promise<{ totalCount: number; phoneNumbers: PhoneNumberListItem[]; }> {
        const queryParameters: IQueryParameters = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            ...filter
        };
        return this.httpGet<{ totalCount: number; phoneNumbers: PhoneNumberListItem[] }>(
            this.mainRoute,
            undefined,
            queryParameters
        )
            .map((paginatedResult) => {
                return {
                    totalCount: paginatedResult.totalCount,
                    phoneNumbers: paginatedResult.phoneNumbers.map((p: any) => {
                        return mapPhoneNumberListItemDtoToDomainModel(p);
                    }),
                };
            })
            .performOperationAsync();
    }

    public printPhoneNumbersAsync(ordering: Ordering, filter: any, printOption: PrintOptions, showPin: boolean) {
        const queryParameters: IQueryParameters = {
            printOption,
            showPin,
            OrderBy: ordering.orderBy,
            OrderAsc: ordering.orderAsc,
            ...filter
        };
        return this.httpGet<string>(this.mainRoute, ["print"], queryParameters).map(result => {
            return result;
        }).performOperationAsync();
    }
}

export default new AdminPhoneNumberAdapter();