import { Button, CheckboxOptionType, Col, DatePicker, PageHeader, Popconfirm, Radio, Row, Space, Table } from "antd";
import AccountedRideDetails from "Models/AccountedRideDetails";
import { getVoucherTypeDisplayValue } from "Models/VoucherType";
import ReadonlyInput from "Pages/CommonControls/ReadonlyInput";
import React from "react";
import { useParams } from "react-router-dom";
import { useFailedRideDetailStore, withFailedRideDetailStore } from "./FailedRideDetailStore";
import { formatHuf } from "Util/PriceFormatter";
import { CheckOutlined, LinkOutlined, StopOutlined } from "@ant-design/icons";
import PartnerSelector from "Pages/CommonControls/PartnerSelector/PartnerSelector";
import Title from "antd/lib/typography/Title";
import { isNullOrUndefined } from "@eblsoft/react-toolkit"
import TextArea from "antd/lib/input/TextArea";
import ReadonlyAddress from "Pages/Partner/PartnerData/ReadonlyAddress";
import TextBox from "Pages/CommonControls/TextBox";
import NumberBox from "Pages/CommonControls/NumberBox";
import { ValidationStoreProvider } from "Stores/ValidationStore";

export interface IFailedRideDetailCoreProps {
    failedRideId: number;
}

export interface IFailedRideDetailProps {

}

function FailedRideDetailCore(props: IFailedRideDetailCoreProps) {

    const store = useFailedRideDetailStore();

    return (
        <PageHeader title="Meghiúsult fuvar">
            {store.details && (
                <ValidationStoreProvider value={store.validationStore}>
                    <Title level={4}>Elszámolás adatok:</Title>

                    <Row gutter={32}>
                        <Col md={12}>
                            <ReadonlyInput label="Rendelésszám:" value={store.details.rideIdentifier} />
                            <ReadonlyInput label="Sofőr:" value={`${store.details.driverName} (${store.details.driverBpid}) - ${store.details.taxiDriverName}`} />
                            <ReadonlyInput label="Fuvar egyéb adatok:" value={store.details.rideAdditionalInfo} multiline={5} />
                        </Col>
                        <Col md={12}>
                            <ReadonlyInput label="Elszámolás azonosító:" value={store.details.publicIdentifier} />
                            <ReadonlyInput label="Időpont:" value={store.details.createdAt.format("L LT")} />

                            <NumberBox addonAfter="Ft." label="Összeg:" value={store.grossPrice} onChange={store.setGrossPrice} />
                            <TextBox label="Költséghely:" value={store.costPlacement} onChange={store.setCostPlacement} maxLength={128} />

                            <ReadonlyInput label="Sofőr megjegyzés:" value={store.details.driverComment} multiline={2} />
                        </Col>
                    </Row>

                    {store.details.customerFromRide && (
                        <>
                            <Title level={4} style={{ marginTop: 16 }}>Rendelési adatok:</Title>
                            <Row gutter={32}>
                                <Col md={12}>
                                    <ReadonlyInput label="Név:" value={store.details.customerFromRide.name} />
                                    <ReadonlyInput label={isNullOrUndefined(store.details.customerFromRide.groupMemberTaxNumber) ? "Adószám:" : "ÁFA csoportazonosító:"} value={store.details.customerFromRide.taxNumber?.toString() ?? ""} />
                                    {!isNullOrUndefined(store.details.customerFromRide.groupMemberTaxNumber) && (
                                        <ReadonlyInput label="Csoporttag azonosító:" value={store.details.customerFromRide.groupMemberTaxNumber!.toString()} />
                                    )}
                                </Col>
                                <Col md={12}>
                                    <ReadonlyAddress
                                        zipCode={store.details.customerFromRide.address.zipCode}
                                        settlement={store.details.customerFromRide.address.settlement}
                                        addressLine={store.details.customerFromRide.address.addressLine} />
                                </Col>
                            </Row>
                        </>
                    )}

                    <Title level={4} style={{ marginTop: 16 }}>Elbírálás:</Title>
                    <Row gutter={32}>

                        <Col xs={24} md={12}>
                            <TextBox label="Bíráló neve:" isRequired value={store.reviewedBy} onChange={store.setReviewedBy} maxLength={64} />
                            <PartnerSelector label="Partner:" partnerId={store.partnerId} onChange={store.setPartnerId} />
                            <TextBox label="Engedélyező a partner részéről:" value={store.partnerAllowedBy} onChange={store.setPartnerAllowedBy} maxLength={128} />
                        </Col>

                        <Col xs={24} md={12}>
                            <TextBox label="Bírálói megjegyzés (csak a sofőrnek):" multiline={4} value={store.reviewerComment ?? ""} onChange={store.setReviewerComment} />
                        </Col>

                    </Row>

                    <Space style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: 24 }}>

                        <Button type="primary" icon={<CheckOutlined />} onClick={store.approveAsync.fireAndForget}>
                            Elfogad
                        </Button>

                        <Popconfirm title="Biztosan elutasítja a meghiúsult fuvart?" okText="Igen" cancelText="Nem" onConfirm={store.declineAsync.fireAndForget}>
                            <Button danger type="primary" icon={<StopOutlined />}>
                                Elutasít
                            </Button>
                        </Popconfirm>

                        <Button type="default" onClick={store.cancel}>
                            Mégsem
                        </Button>
                    </Space>
                </ValidationStoreProvider>
            )}
        </PageHeader>
    );
}

function FailedRideView({ ride }: { ride: AccountedRideDetails }) {
    return (
        <>
            <Row gutter={[16, 8]}>
                <Col span={24}><ReadonlyInput label="Elszámolás azonosító:" value={ride.publicIdentifier} /></Col>

                <Col span={10}><ReadonlyInput label="Időpont:" value={ride.createdAt.format("L LT")} /></Col>
                <Col span={14}><ReadonlyInput label="Azonosító:" value={`${ride.voucherInfo} (${getVoucherTypeDisplayValue(ride.voucherType)})`} /></Col>

                <Col span={10}><ReadonlyInput label="Összeg:" value={formatHuf(ride.grossPrice)} /></Col>
                <Col span={14}><ReadonlyInput label="Költséghely:" value={ride.costPlacement ?? "Nincs"} /></Col>
            </Row>
        </>
    );
}

const FailedRideDetailWithStore = withFailedRideDetailStore(FailedRideDetailCore);

export default function FailedRideDetail(props: IFailedRideDetailProps) {
    const { failedRideId } = useParams();
    return (
        <FailedRideDetailWithStore {...props} failedRideId={parseInt(failedRideId!)} />
    )
}