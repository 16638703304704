import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import FailedRide from "Models/FailedRide";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { LinkOutlined } from "@ant-design/icons";

export const FailedRideListColumns: ColumnsType<FailedRide> = [
    {
        title: "Azonosító",
        dataIndex: "publicIdentifier",
        key: "publicIdentifier",
        sorter: true,
    },
    {
        title: "Rendelésszám",
        dataIndex: "rideIdentifier",
        key: "rideIdentifier",
        sorter: true,
    },
    {
        title: "Meghiúsult",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (date: moment.Moment) => <span>{date.format('YYYY.MM.DD. HH:mm:ss')}</span>,
        sorter: true,
    },
    {
        title: "Bruttó összeg",
        dataIndex: "grossPrice",
        key: "grossPrice",
        sorter: true,
    },
    {
        title: "Szállító cégnév",
        dataIndex: "driverName",
        key: "driverName",
        sorter: true,
    },
    {
        title: "Szállító BPID",
        dataIndex: "driverBpid",
        key: "driverBpid",
        sorter: true,
    },
    {
        title: "Szállító név",
        dataIndex: "taxiDriverName",
        key: "taxiDriverName",
        sorter: true,
    },
    {
        title: "",
        dataIndex: "id",
        key: "id",
        sorter: false,
        render: (id, record) => (
            <>
                <Tooltip title="Részletek">
                    <Link to={`/admin/failedRides/${record.id}`}>
                        <Button key={`${record.id}`} icon={<LinkOutlined />} type="primary" />
                    </Link>
                </Tooltip>
            </>
        )
    },
];