import { observer } from "mobx-react";
import { getTaxiCardProhibitionEventStatusDisplayValue, TaxiCardProhibitionEventStatus } from "Models/TaxiCardProhibitionEventStatus";
import { useMemo } from "react";
import { TaxiCardOrderStatusSelectBox } from "./TaxiCardOrderStatusSelectBox";
import { TaxiCardProhibitionEventStatusSelectBox } from "./TaxiCardProhibitionEventStatusSelectBox";

export interface ITaxiCardProhibtionEventStatusColumnProps {
    status: TaxiCardProhibitionEventStatus,
    onChange: (status: TaxiCardProhibitionEventStatus) => void;
    isUnderEdit: boolean;
}

function TaxiCardProhibtionEventStatusColumn(props: ITaxiCardProhibtionEventStatusColumnProps) {

    const value = useMemo(() => {
        let res = getTaxiCardProhibitionEventStatusDisplayValue(props.status);
        return res;
    }, [props.onChange, props.status, props.isUnderEdit]);
    return (
        <>
            {props.isUnderEdit ?
                <TaxiCardProhibitionEventStatusSelectBox
                    taxiCardProhibitionEventStatus={props.status}
                    onChange={props.onChange}
                /> :
                <span>{value}</span>
            }
        </>
    )
}

export default observer(TaxiCardProhibtionEventStatusColumn);