import Address from "Models/Address";
import { InvoiceType } from "Models/InvoiceType";
import { VoucherType } from "Models/VoucherType";
import moment from "moment";
import { InvoiceStatus } from "./InvoiceStatus";

export default class InvoiceForPartnerListItem {
    constructor(
        public readonly id: number,
        public readonly invoiceType: InvoiceType,
        public readonly referencedInvoiceNumber: string | null,
        public readonly accountedRideId: number,
        public readonly accountedRidePublicIdentifier: string,
        public readonly rideExternalIdentifier: string,
        public readonly sellerName: string,
        public readonly sellerBpid: string,
        public readonly invoiceNumber: string,
        public readonly netPrice: number,
        public readonly vatPercentage: number,
        public readonly vatExcemptionType: string | null,
        public readonly grossPrice: number,
        public readonly createdOn: moment.Moment,
        public readonly fulfilledOn: moment.Moment,
        public readonly status: InvoiceStatus,
        public readonly costCenter: string | null,
        public readonly customerName: string | null,
        public readonly customerBpid: string | null,

        public readonly accountedAt: moment.Moment,
        public readonly voucherType: VoucherType,
        public readonly voucherInfo: string | null,
        public readonly partnerInvoiceSummaryIdentifier: string | null,
        public readonly startAddress: Address | null,
        public readonly intermediateAddresses: Address[] | null,
        public readonly finalAddress: Address | null,
        public readonly rideAdditionalInfo: string | null,
        public readonly rideCustomerName: string | null,
    ) { }
}

