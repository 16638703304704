import { ColumnsType } from "antd/lib/table";
import InvoiceForPartnerListItem from "Models/InvoiceForPartnerListItem";
import moment from "moment";
import InvoiceActions from "./InvoiceActions";

export const InvoiceColumns: ColumnsType<InvoiceForPartnerListItem> = [
    {
        title: "Számlaszám",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
    },
    {
        title: "Utazás azon.",
        dataIndex: "accountedRidePublicIdentifier",
        key: "accountedRidePublicIdentifier",
    },
    {
        title: "Rendelésszám",
        dataIndex: "rideExternalIdentifier",
        key: "rideExternalIdentifier",
    },
    {
        title: "Eladó",
        dataIndex: "sellerName",
        key: "sellerName",
        render: (_: any, row: InvoiceForPartnerListItem) => <span>{row.sellerName} ({row.sellerBpid})</span>,
    },
    {
        title: "Számla dátuma",
        dataIndex: "createdOn",
        key: "createdOn",
        render: (date: moment.Moment) => <span>{date.format('L')}</span>,
        sorter: true,
    },
    {
        title: "Teljesítés",
        dataIndex: "fulfilledOn",
        key: "fulfilledOn",
        render: (date: moment.Moment) => <span>{date.format('L')}</span>,
        sorter: true,
    },
    // {
    //     title: "Költséghely",
    //     dataIndex: "costPlacementType",
    //     key: "costPlacementType",
    //     render: (type: CostPlacementType, column: InvoiceForPartnerListItem) => {
    //         let res = getCostPlacementTypeDisplayValue(type);
    //         if (column.costPlacement?.length) {
    //             res += ` (${column.costPlacement})`;
    //         }
    //         return <span>{res}</span>
    //     },
    // },
    {
        title: "Nettó összeg",
        dataIndex: "netPrice",
        key: "netPrice",
    },
    {
        title: "Bruttó összeg",
        dataIndex: "grossPrice",
        key: "grossPrice",
    },
    {
        title: "",
        render: (_, invoice) => <InvoiceActions invoice={invoice} />
    }
];