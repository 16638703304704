import moment from "moment";
import { CostPlacementType } from "./CostPlacementType";
import { TaxiVoucherLimit } from "./TaxiVoucherLimit";

export default class VoucherListItem {
    constructor(
        public readonly id: number,
        public readonly identifier: string,
        public readonly requestDate: moment.Moment | null,
        public readonly validity: moment.Moment | null,
        public readonly cost: TaxiVoucherLimit,
        public readonly costPlacement: string,
        public readonly costPlacementType: CostPlacementType,
        public readonly prohibitedAt: moment.Moment | null,
        public readonly accountedRideId: number | null,
        public readonly customerName: string,
        public readonly customerBpid: string
    ) { }
}