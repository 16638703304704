import { DownloadOutlined, DownOutlined, FileExcelOutlined, FilePdfOutlined, FilterOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, DatePicker, Dropdown, Input, Menu, PageHeader, Row, Select, Space, Table, TablePaginationConfig, Typography } from "antd";
import { observer } from "mobx-react";
import { PrintOptions } from "Models/PrintOptions";
import { TaxiVoucherLimit, TaxiVoucherLimitEntries } from "Models/TaxiVoucherLimit";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getVoucherColumns } from "./Common/VoucherColumns";
import { CostplacementTypeSelectBox } from "Pages/CommonControls/CostPlacementTypeSelectBox";
import { useVouchersStore } from "./VouchersStore";
import PageWrapper from "Pages/CommonControls/PageWrapper/PageWrapper";
import Voucher from "Models/Voucher";
import { TableRowSelection } from "antd/lib/table/interface";
import ProhibitionInfoModal from "Pages/CommonControls/ProhibitionInfoModal";
import DateRangePicker from "Pages/CommonControls/DateRangePicker";
import TextBox from "Pages/CommonControls/TextBox";
import { CostPlacementTypeSelector } from "Pages/CommonControls/CostPlacementTypeSelector";
import VoucherListItem from "Models/VoucherListItem";
import { IVoucherListCoreProps, IVoucherListProps } from "./Vouchers";
import React from "react";
import { fullScreenTableScroll } from "Util/TableHelpers";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title } = Typography;

function VoucherList(props: IVoucherListCoreProps) {

    const navigate = useNavigate();
    const store = useVouchersStore();


    const paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    }

    const rowSelection: TableRowSelection<VoucherListItem> = {
        type: "checkbox",
        selections: true,
        selectedRowKeys: store.selectedVouchers.map(v => v.identifier),
        onChange: (selectedRowKeys: React.Key[], selectedRows: VoucherListItem[]) => {
            store.selectVouchers(selectedRows);
        }
    };

    const downloadMenu = (
        <Menu>
            <div>
                <Button onClick={() => store.print(PrintOptions.PrintToPdf)} icon={<FilePdfOutlined />} >
                    Lista letöltése PDF-ben
                </Button>
            </div>
            <div>
                <Button onClick={() => store.print(PrintOptions.PrintToExcel)} icon={<FileExcelOutlined />} >
                    Lista letöltése Excelben
                </Button>
            </div>
        </Menu>
    )
    const navigateToCreate = useCallback(() => navigate('new'), [navigate]);
    const setLimit = useCallback((value: TaxiVoucherLimit) => {
        store.taxiVoucherFilter.setVoucherLimit(value);
        store.filterResult();
    }, [store.filterResult, store.taxiVoucherFilter.setVoucherLimit]);

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    return (
        <>
            <ProhibitionInfoModal
                prohibitionInfo={store.prohibitionInfo!}
                title="Tiltás információ"
                visible={store.modalIsVisible}
                onOk={store.toggleProhibit}
                confirmLoading={store.confirmIsLoading}
                onCancel={store.cancelModal}
            />
            <PageHeader
               title={<span id="page_title">Azonosító szelvények</span>}
                extra={!props.isSummary && <Button type="primary" onClick={navigateToCreate}>
                    Új azonosító igénylés
                </Button>}
            >
                <Row gutter={16}>
                    <Col xl={10} xs={12}>
                        <DateRangePicker
                            label="Időszak:"
                            from={store.taxiVoucherFilter.validFrom}
                            to={store.taxiVoucherFilter.validTo}
                            onChange={store.taxiVoucherFilter.setDateRangeFilter}
                            style={{ width: "100%" }}
                            onEnter={store.filterResult}
                            allowClear
                        />
                    </Col>
                    {props.isSummary &&
                        <Col xl={10} xs={12}>
                            <TextBox
                                label="Partner (név vagy BPID):"
                                style={{ width: "100%" }}
                                onChange={store.taxiVoucherFilter.setCustomer}
                                value={store.taxiVoucherFilter.customer}
                                onEnter={store.filterResult}
                            />
                        </Col>}
                </Row>
                <Row gutter={16}>
                    <Col xl={10} xs={12}>
                        <TextBox
                            label="Azonosító szám:"
                            style={{ width: "100%" }}
                            onChange={store.taxiVoucherFilter.setIdentifier}
                            value={store.taxiVoucherFilter.identifier}
                            onEnter={store.filterResult}
                        />
                    </Col>
                    <Col xl={10} xs={12}>
                        <Typography.Text>Fix összeg:</Typography.Text>
                        <Select
                            style={{ width: "100%" }}
                            onChange={setLimit}
                            value={store.taxiVoucherFilter.voucherLimit}
                        >
                            {TaxiVoucherLimitEntries.map(i =>
                                <Option key={i.enumValue} value={i.enumValue}>
                                    {i.displayValue}
                                </Option>
                            )}
                        </Select>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xl={10} xs={24}>
                        <CostPlacementTypeSelector
                            showLabels
                            costPlacementType={store.taxiVoucherFilter.costPlacementType}
                            costPlacementValue={store.taxiVoucherFilter.costPlacement}
                            onCostPlacementTypeChange={store.taxiVoucherFilter.setCostPlacementType}
                            onCostplacementChange={store.taxiVoucherFilter.setCostPlacement}
                            onSearch={store.filterResult}
                        />
                    </Col>
                </Row>

                <div>
                    <Checkbox style={{ marginTop: "16px" }} onChange={store.setShowProhibited} checked={store.taxiVoucherFilter.showProhibited}>Letiltott szelvények megjelenítése</Checkbox>
                    <Checkbox style={{ marginTop: "16px" }} onChange={store.setShowUsed} checked={store.taxiVoucherFilter.showUsed}>Felhasznált szelvények megjelenítése</Checkbox>
                </div>

                <Space style={{ marginTop: "16px", marginBottom: "16px", display: "flex" }}>
                    <Dropdown.Button disabled={store.taxiVoucherFilter.areFiltersDirty} onClick={() => store.print(PrintOptions.PrintToPdf)} icon={<DownOutlined />} overlay={downloadMenu}>
                        Lista letöltése
                        <DownloadOutlined />
                    </Dropdown.Button>
                    {/* <Button disabled={store.taxiVoucherFilter.areFiltersDirty} icon={<PrinterOutlined />} onClick={store.printTicketsAsync.fireAndForget}>
                        Nyomtatás
                    </Button> */}
                    <Button type="primary" onClick={store.filterResult} icon={<FilterOutlined />}>
                        Szűrés
                    </Button>
                </Space>

                {store.selectedVouchers.length > 0 ? (
                    <Space style={{ marginTop: "-8px", marginBottom: "16px", display: "block" }}>
                        <Button onClick={() => store.isEverySelectedVoucherProhibited ? store.toggleProhibitSelectedVouchersAsync.fireAndForget() : store.setProhibitionModalIsVisibleForSelectedVouchers()}>
                            {store.isEverySelectedVoucherProhibited ? "Kijelöltek aktíválása" : "Kijelöltek letiltása"}
                        </Button>
                    </Space>
                ) : (
                    <div style={{ height: 32 }} />
                )}

                <TableSummaryHeader items={summaryItems} />

                <Table
                    onChange={store.reloadList}
                    pagination={paginationConfig}
                    size="middle"
                    columns={getVoucherColumns(props.isSummary)}
                    dataSource={store.vouchers}
                    rowKey="identifier"
                    rowSelection={rowSelection}
                    scroll={fullScreenTableScroll}
                />
            </PageHeader>
        </>
    );
}


export default observer(VoucherList);
