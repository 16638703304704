import React, { useMemo } from "react";
import { useConsentListStore, withConsentListStore } from "./ConsentListStore";
import { Observer, observer } from "mobx-react";
import { Button, Col, PageHeader, Row, Select, Space, TablePaginationConfig, Tooltip, Typography } from "antd";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";
import Table, { ColumnType } from "antd/lib/table";
import ConsentListItem from "Models/ConsentListItem";
import { getStatementTypeDisplayValue, StatementType, StatementTypeEntries } from "Models/StatementType";
import { ConsentSummary, getConsentSummaryDisplayValue } from "Models/ConsentSummary";
import { getFullScreenTableScroll } from "Util/TableHelpers";
import DateRangePicker from "Pages/CommonControls/DateRangePicker";
import TextBox from "Pages/CommonControls/TextBox";
import { FileSearchOutlined, FilterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;
const { Option } = Select;

function ConsentList() {
    const store = useConsentListStore();

    const _paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    }

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    let paginationConfig = _paginationConfig;

    const cols: Array<ColumnType<ConsentListItem>> = [
        {
            title: "Kolléga BPID",
            dataIndex: "driverBpid",
            key: "driverBpid",
            sorter: true,
        },
        {
            title: "Kolléga neve",
            dataIndex: "driverName",
            key: "driverName",
            sorter: true
        },
        {
            title: "Nyilatkozat típusa",
            dataIndex: "statementType",
            key: "statementType",
            sorter: true,
            render: (value: StatementType) => <span>{getStatementTypeDisplayValue(value)}</span>,
        },
        {
            title: "Időpont",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (value: moment.Moment) => <span>{value?.format("L LT") ?? ""}</span>,
            sorter: true
        },
        {
            title: "Elfogadva",
            dataIndex: "summary",
            key: "summary",
            render: (value: ConsentSummary) => <span>{getConsentSummaryDisplayValue(value)}</span>,
            sorter: true
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            width: 100,
            render: (_, record: ConsentListItem) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Megtekintés">
                            <Link to={`${record.consentId}`}>
                                <Button key={`${record.consentId}`} icon={<FileSearchOutlined />} type="primary" />
                            </Link>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    const tableScroll = getFullScreenTableScroll(cols.reduce((sum, col) => sum + (col.width as number ?? 100), 0));

    return (
        <PageHeader title="Nyilatkozatok" >
            <Observer>
                {() => (
                    <>
                        <Row gutter={16}>
                            <Col xxl={8} xl={10} xs={12}>
                                <DateRangePicker
                                    label="Nyilatkozattétel ideje:"
                                    from={store.filter.createdAtFrom}
                                    to={store.filter.createdAtTo}
                                    onChange={store.filter.setCreatedAtRange}
                                    style={{ width: "100%" }}
                                    onEnter={store.filterResult}
                                    allowClear
                                />
                            </Col>
                            <Col xxl={8} xl={10} xs={12}>
                                <TextBox
                                    label="Kolléga név vagy BPID:"
                                    onChange={store.filter.setDriverNameOrBpid}
                                    value={store.filter.driverNameOrBpid}
                                    onEnter={store.filterResult}
                                />
                            </Col>
                            <Col xxl={8} xl={10} xs={12}>
                                <div style={{ width: "100%" }}>
                                    <Text>Nyilatkozat típusa:</Text>
                                    <Select
                                        style={{ width: "100%" }}
                                        onChange={store.filter.setStatementType}
                                        value={store.filter.statementType}
                                        allowClear
                                    >
                                        {StatementTypeEntries.map(i =>
                                            <Option key={i.enumValue} value={i.enumValue}>
                                                {i.displayValue}
                                            </Option>
                                        )}
                                    </Select>
                                </div>
                            </Col>
                        </Row>
                        {<Space style={{ marginTop: "16px", marginBottom: "16px" }}>
                            <Button key="1" style={{ width: "100%" }} type="primary" onClick={store.filterResult} icon={<FilterOutlined />}>
                                Szűrés
                            </Button>
                        </Space>
                        }
                    </>
                )}
            </Observer>
            <TableSummaryHeader items={summaryItems} />
            <Table
                onChange={store.reloadList}
                pagination={paginationConfig}
                size="middle"
                columns={cols}
                dataSource={store.consents}
                scroll={tableScroll}
                rowKey="id" />
        </PageHeader>
    );
}

export default withConsentListStore(ConsentList);