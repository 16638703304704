import { action, makeObservable, observable } from "mobx";
import { InvoiceStatus } from "Models/InvoiceStatus";
import { VoucherType } from "Models/VoucherType";
import moment from "moment";

class PollFilter {
    @observable.ref public name: string = "";
    @observable.ref public endOfVoteDateFrom: moment.Moment | null = null;
    @observable.ref public endOfVoteDateTo: moment.Moment | null = null;
    @observable public areFiltersDirty = false;

    public constructor(
    ) {
        makeObservable(this);
    }

    @action.bound
    public setName(name: string) {
        this.name = name;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setEndOfVoteDateRange(from: moment.Moment | null, to: moment.Moment | null) {
        this.endOfVoteDateFrom = from?.clone().hour(0).minute(0).second(0).millisecond(0) ?? null;
        this.endOfVoteDateTo = to?.clone().hour(23).minute(59).second(59).millisecond(999) ?? null;
        this.areFiltersDirty = true;
    }
}
export default PollFilter;