import { action, makeObservable, observable } from "mobx";
import { VehicleConditionStatus } from "./VehicleConditionStatus";
import { VehicleConditionType } from "./VehicleConditionType";


export default class VehicleCondition {
    @observable.ref public status: VehicleConditionStatus | null = null;
    constructor(
        public readonly id: number,
        public readonly createdAt: moment.Moment,
        status: VehicleConditionStatus,
        public readonly type: VehicleConditionType,
        public readonly images: string[],
        public readonly description: string | null,
    ) {
        makeObservable(this);
        this.status = status;
    }

    @action.bound
    public setStatus(status: VehicleConditionStatus) {
        this.status = status;
    }
}