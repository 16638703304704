import moment from "moment";
import { ConsentSummary } from "./ConsentSummary";
import { StatementType } from "./StatementType";

export default class ConsentListItem {
    constructor(
        public readonly consentId: number,
        public readonly statementType: StatementType,
        public readonly createdAt: moment.Moment,
        public readonly revokedAt: moment.Moment | null,
        public readonly driverName: string,
        public readonly driverBpid: string,
        public readonly summary: ConsentSummary,
    ) { }
}