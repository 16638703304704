import { observer } from "mobx-react";
import { CostPlacementType, getCostPlacementTypeDisplayValue } from "Models/CostPlacementType";
import CreatedTaxiCardListItem from "Models/CreatedTaxiCardListItem";
import PhoneNumber from "Models/PhoneNumber";
import { CostPlacementTypeSelector } from "Pages/CommonControls/CostPlacementTypeSelector";
import { useMemo } from "react";

export interface ICostPlacementTypeColumnProps {
    costPlacementType: CostPlacementType,
    costPlacement: string,
    setCostPlacementType?: (type: CostPlacementType) => void;
    setCostPlacement?: (type: string) => void;
    isUnderEdit: boolean;
}

function CostPlacementTypeColumn(props: ICostPlacementTypeColumnProps) {

    const value = useMemo(() => {
        let res = getCostPlacementTypeDisplayValue(props.costPlacementType);
        if (props.costPlacement?.length) {
            res += ` (${props.costPlacement})`;
        }
        return res;
    }, [props, props.costPlacement, props.costPlacementType, props.isUnderEdit]);
    return (
        <>
            {props.isUnderEdit ?
                <CostPlacementTypeSelector
                    costPlacementType={props.costPlacementType}
                    costPlacementValue={props.costPlacement}
                    onCostPlacementTypeChange={props.setCostPlacementType!}
                    onCostplacementChange={props.setCostPlacement!}
                /> :
                <span>{value}</span>
            }
        </>
    )
}

export default observer(CostPlacementTypeColumn);