import { CheckOutlined, DownOutlined, DownloadOutlined, EditOutlined, FileExcelOutlined, FilePdfOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, PageHeader, Popconfirm, Row, Space, Table, TablePaginationConfig, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import TaxiCardProhibitionEventListItem from "Models/TaxiCardProhibitionEventListItem";
import { TaxiCardOrderStatus } from "Models/TaxiCardOrderStatus";
import DateRangePicker from "Pages/CommonControls/DateRangePicker";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";
import TextBox from "Pages/CommonControls/TextBox";
import { useMemo } from "react";
import { useTaxiCardProhibitionEventStore, withTaxiCardProhibitionEventStore } from "./TaxiCardProhibitionEventStore";
import TaxiCardProhibtionEventStatusColumn from "Pages/CommonControls/TaxiCardProhibtionEventStatusColumn";
import { TaxiCardProhibitionEventStatusSelectBox } from "Pages/CommonControls/TaxiCardProhibitionEventStatusSelectBox";
import { TaxiCardProhibitionEventStatus } from "Models/TaxiCardProhibitionEventStatus";
import { PrintOptions } from "Models/PrintOptions";


function TaxiCardOrderList() {
    const selectedRowColor = 'rgb(37, 184, 100)';
    const permenantlyCancelledTaxiCard = 'rgb(255, 127, 127)';

    const store = useTaxiCardProhibitionEventStore();

    const paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    }


    const edit = (record: TaxiCardProhibitionEventListItem | null) => {
        if (record) {
            store.setRecordUnderEdit(record.id);
        } else {
            store.setRecordUnderEdit(null);
        }
    };

    const isUnderEdit = (record: TaxiCardProhibitionEventListItem) => store.recordIdUnderEdit === record.id;
    const isPermanentlyCancelledTaxiCard = (record: TaxiCardProhibitionEventListItem) => record.status === TaxiCardProhibitionEventStatus.PermanentlyCancelled;

    const computedBackgroundColor = (record: TaxiCardProhibitionEventListItem) => isUnderEdit(record) ?
        { backgroundColor: selectedRowColor, color: "white" } : isPermanentlyCancelledTaxiCard(record) ?
            { backgroundColor: permenantlyCancelledTaxiCard } : {};

    const TaxiCardOrderColumns: ColumnsType<TaxiCardProhibitionEventListItem> = [
        {
            title: "Partner BPID",
            dataIndex: "partnerBpid",
            key: "partnerBpid",
            render: (partnerBpid: string, column: TaxiCardProhibitionEventListItem) => {
                const style = computedBackgroundColor(column);
                return {
                    props: { style },
                    children: <span>{partnerBpid}</span>
                }
            },
            sorter: true,
        },
        {
            title: "Partner Név",
            dataIndex: "partnerName",
            key: "partnerName",
            render: (partnerName: string, column: TaxiCardProhibitionEventListItem) => {
                const style = computedBackgroundColor(column);
                return {
                    props: { style },
                    children: <span>{partnerName}</span>
                }
            },
            sorter: true
        },
        {
            title: "Kártyaszám",
            dataIndex: "identifier",
            key: "identifier",
            sorter: true,
            render: (identifier: string, column: TaxiCardProhibitionEventListItem) => {
                const style = computedBackgroundColor(column);
                return {
                    props: { style },
                    children: <span>{identifier}</span>
                }
            }
        },
        {
            title: "Letiltás időpontja",
            dataIndex: "occuredAt",
            key: "occuredAt",
            sorter: true,
            render: (date: moment.Moment, column: TaxiCardProhibitionEventListItem) => {
                const style = computedBackgroundColor(column);
                return {
                    props: { style },
                    children: <span > {date!.format("YYYY.MM.DD HH:mm")}</span>
                }
            }
        },
        {
            title: "Letiltó felhasználó",
            dataIndex: "prohibitedByUserName",
            key: "prohibitedByUserName",
            sorter: true,
            render: (prohibitedByUserName: string, column: TaxiCardProhibitionEventListItem) => {
                const style = computedBackgroundColor(column);
                return {
                    props: { style },
                    children: <span>{prohibitedByUserName}</span>
                }
            }
        },
        {
            title: "Letiltó neve",
            dataIndex: "prohibitedByName",
            key: "prohibitedByName",
            sorter: true,
            render: (prohibitedByName: string, column: TaxiCardProhibitionEventListItem) => {
                const style = computedBackgroundColor(column);
                return {
                    props: { style },
                    children: <span>{prohibitedByName}</span>
                }
            }
        },
        {
            title: "Letiltás oka",
            dataIndex: "prohibitionReason",
            key: "prohibitionReason",
            sorter: true,
            render: (prohibitionReason: string, column: TaxiCardProhibitionEventListItem) => {
                const style = computedBackgroundColor(column);
                return {
                    props: { style },
                    children: <span>{prohibitionReason}</span>
                }
            }
        },
        {
            title: "Állapot",
            dataIndex: "status",
            key: "status",
            render: (type: TaxiCardOrderStatus, column: TaxiCardProhibitionEventListItem) => {
                const style = computedBackgroundColor(column);
                const recordUnderEdit = isUnderEdit(column);
                return {
                    props: { style },
                    children: <TaxiCardProhibtionEventStatusColumn
                        isUnderEdit={recordUnderEdit}
                        status={column.status}
                        onChange={column.setStatus} />
                }
            },
            sorter: true,
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (_, record: TaxiCardProhibitionEventListItem) => {
                if (isUnderEdit(record)) {
                    return {
                        props: { style: { backgroundColor: selectedRowColor } },
                        children:
                            <Space size="middle">
                                <span>
                                    <Typography.Link
                                        onClick={store.saveRecordUnderEdit}
                                        style={{
                                            marginRight: 8,
                                            color: 'white'
                                        }}
                                    >
                                        Mentés
                                    </Typography.Link>
                                    <Popconfirm title="Biztosan elveti a módosításokat?" okText="Igen" cancelText="Nem" onConfirm={() => edit(null)}>
                                        <a style={{ color: 'white' }}>Mégsem</a>
                                    </Popconfirm>
                                </span>
                            </Space>
                    }
                }
                return (
                    <Space size="middle">
                        <Tooltip title="Szerkesztés">
                            <Button onClick={() => edit(record)} icon={<EditOutlined />} />
                        </Tooltip>
                    </Space>
                )
            }
        }
    ];

    const downloadMenu = (
        <Menu>
            <div>
                <Button onClick={() => store.print(PrintOptions.PrintToPdf)} icon={<FilePdfOutlined />} style={{ width: "100%" }}>
                    Lista letöltése PDF-ben
                </Button>
            </div>
            <div>
                <Button onClick={() => store.print(PrintOptions.PrintToExcel)} icon={<FileExcelOutlined />} style={{ width: "100%" }}>
                    Lista letöltése Excelben
                </Button>
            </div>
        </Menu>
    );

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    return (
        <>

            <PageHeader title={<span id="page_title">Taxikártya letiltások</span>}>
                <Row gutter={16}>
                    <Col xxl={8} xl={10} xs={12}>
                        <DateRangePicker
                            label="Letiltás időszak:"
                            from={store.taxiCardProhibitionEventFilter.prohibitionOccuredAtFrom}
                            to={store.taxiCardProhibitionEventFilter.prohibitionOccuredAtTo}
                            onChange={store.taxiCardProhibitionEventFilter.setProhibitionOccuredAtRange}
                            allowClear
                        />
                    </Col>
                    <Col xxl={8} xl={10} xs={12}>
                        <TextBox
                            label="Kártyaszám:"
                            onChange={store.taxiCardProhibitionEventFilter.setIdentifier}
                            value={store.taxiCardProhibitionEventFilter.identifier}
                            onEnter={store.filterResult}
                        />
                    </Col>
                    <Col xxl={8} xl={10} xs={12}>
                        <TextBox
                            label="BPID:"
                            onChange={store.taxiCardProhibitionEventFilter.setPartnerBpid}
                            value={store.taxiCardProhibitionEventFilter.partnerBpid}
                            onEnter={store.filterResult}
                            allowClear
                        />
                    </Col>
                    <Col xxl={8} xl={10} xs={12}>
                        <TextBox
                            label="Név:"
                            onChange={store.taxiCardProhibitionEventFilter.setPartnerName}
                            value={store.taxiCardProhibitionEventFilter.partnerName}
                            onEnter={store.filterResult}
                            allowClear
                        />
                    </Col>
                    <Col xxl={8} xl={10} xs={12}>
                        <TaxiCardProhibitionEventStatusSelectBox
                            style={{ width: "100%" }}
                            label="Állapot"
                            onChange={store.taxiCardProhibitionEventFilter.setStatus}
                            taxiCardProhibitionEventStatus={store.taxiCardProhibitionEventFilter.status}
                            allowClear
                        />
                    </Col>

                    <Col xxl={8} xl={10} xs={12}>
                        <TextBox
                            label="Letiltó felhasználó:"
                            onChange={store.taxiCardProhibitionEventFilter.setProhibitedByUserName}
                            value={store.taxiCardProhibitionEventFilter.prohibitedByUserName}
                            onEnter={store.filterResult}
                            allowClear
                        />
                    </Col>
                    <Col xxl={8} xl={10} xs={12}>
                        <TextBox
                            label="Letiltó neve:"
                            onChange={store.taxiCardProhibitionEventFilter.setProhibitedByName}
                            value={store.taxiCardProhibitionEventFilter.prohibitedByName}
                            onEnter={store.filterResult}
                            allowClear
                        />
                    </Col>
                    <Col xxl={8} xl={10} xs={12}>
                        <TextBox
                            label="Letiltás oka:"
                            multiline
                            onChange={store.taxiCardProhibitionEventFilter.setProhibitionReason}
                            value={store.taxiCardProhibitionEventFilter.prohibitionReason}
                            onEnter={store.filterResult}
                        />
                    </Col>
                </Row>

                <Space style={{ marginTop: "16px", marginBottom: "16px" }}>
                    <Dropdown.Button style={{ width: "100%" }} onClick={() => store.print(PrintOptions.PrintToExcel)} icon={<DownOutlined />} overlay={downloadMenu}>

                        Lista letöltése
                        <DownloadOutlined />

                    </Dropdown.Button>

                    <Button style={{ width: "100%" }} type="primary" onClick={store.filterResult} icon={<FilterOutlined />}>
                        Szűrés
                    </Button>
                </Space>

                <TableSummaryHeader items={summaryItems} />
                <Table
                    onChange={store.reloadList}
                    pagination={paginationConfig}
                    size="middle"
                    columns={TaxiCardOrderColumns}
                    dataSource={store.taxiCardProhibitionEvents}
                    rowKey="id"
                    key={store.recordIdUnderEdit}
                />
            </PageHeader>
        </>
    );
}


export default withTaxiCardProhibitionEventStore(TaxiCardOrderList);