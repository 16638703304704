import { History } from "history";

class NavigationStore {

    public readonly navigateToHome = this.createNavigator("/");

    public navigateTo(path: string, replace?: boolean) {
        if (replace)
            this._history?.replace(path);
        else
            this._history?.push(path);
    }

    public navigateBack() {
        this._history?.back();
    }

    // ---

    private _history: History | null = null;
    init(history: History) {
        this._history = history;
    }

    private createNavigator(path: string) {
        return (replace?: boolean) => {
            if (replace)
                this._history?.replace(path);
            else
                this._history?.push(path);
        };
    }
}

export default new NavigationStore();