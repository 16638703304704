import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { Col, Row, Typography } from "antd";
import { Partner } from "Models/Partner";
import PartnerSelector from "Pages/CommonControls/PartnerSelector/PartnerSelector";
import ReadonlyInput from "Pages/CommonControls/ReadonlyInput";
import ReadonlyAddress from "Pages/Partner/PartnerData/ReadonlyAddress";
import React from "react";

const { Title } = Typography;

interface IPartnerViewProps {
    partner: Partner;
    showPartnerName?: boolean;
    showMailingaddress?: boolean;
}

export default function PartnerView(props: IPartnerViewProps) {
    const { partner } = props;
    return (
        <>
            <Row gutter={[16, 8]}>
                <Col span={8}>
                    <ReadonlyInput label="BPID:" value={partner.bpid} />
                </Col>
                {props.showPartnerName ?
                    <>
                        <Col span={8}><ReadonlyInput label="Név:" value={partner.name} /></Col>
                        <Col span={8}><ReadonlyInput label="Számlázási Név:" value={partner.bpName} /></Col>
                    </> :
                    <Col span={16}><ReadonlyInput label="Név:" value={partner.bpName} /></Col>
                }
                <Col span={12}><ReadonlyInput label={isNullOrUndefined(partner.groupMemberTaxNumber) ? "Adószám:" : "ÁFA csoportazonosító:"} value={partner.isPrivatePerson ? "Magánszemély" : partner.taxNumber?.toString()} /></Col>
                {!isNullOrUndefined(partner.groupMemberTaxNumber) && (
                    <Col span={12}><ReadonlyInput label="Csoporttag azonosító:" value={partner.groupMemberTaxNumber!.toString()} /></Col>
                )}
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={props.showMailingaddress ? 12 : 24}>
                    <Title level={5} style={{ marginTop: 16 }}>Székhely:</Title>
                    <ReadonlyAddress
                        showCountryCode={true}
                        countryCode={partner.address.countryCode}
                        zipCode={partner.address.zipCode}
                        settlement={partner.address.settlement}
                        addressLine={partner.address.addressLine} />
                </Col>
                {props.showMailingaddress &&
                    <Col span={12}>
                        <Title level={5} style={{ marginTop: 16 }}>Levelezési cím:{!partner.mailingAddress && " Nincs"}</Title>
                        {partner.mailingAddress && (
                            <ReadonlyAddress
                                zipCode={partner.mailingAddress.zipCode}
                                settlement={partner.mailingAddress.settlement}
                                addressLine={partner.mailingAddress.addressLine} />
                        )}
                    </Col>
                }
            </Row>
        </>
    );
}