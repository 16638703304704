import { EnumHelper } from "Util/EnumHelper";

export enum VehicleConditionType {
    Normal = "Normal",
    Damage = "Damage",
    Repair = "Repair"
}

function getVehicleConditionTypeEntries() {
    return EnumHelper.getEnumEnumerableEntries<VehicleConditionType>(VehicleConditionType, getVehicleConditionTypeDisplayValue);
}

export function getVehicleConditionTypeDisplayValue(value: VehicleConditionType) {

    switch(value) {
        case VehicleConditionType.Normal: return "Normál";
        case VehicleConditionType.Damage: return "Sérülés";
        case VehicleConditionType.Repair: return "Javítás";
    }
}

export const VehicleConditionTypeEntries = getVehicleConditionTypeEntries();