import { DownloadOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, PageHeader, Select, Table, TablePaginationConfig, Typography } from "antd";
import moment from "moment";
import { useFailedRideListStore, withFailedRideListStore } from "./FailedRideListStore";
import { FailedRideListColumns } from "./FailedRideListColumns";
import { useMemo } from "react";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";

const { RangePicker } = DatePicker;
const { Title } = Typography;

export interface IFailedRideListProps {
}

function FailedRideListCore(props: IFailedRideListProps) {
    const dateFormat = "YYYY.MM.DD";

    const store = useFailedRideListStore();

    const paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    }

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    return (
        <>
            <PageHeader
                title={<span id="page_title">Meghiúsult fuvarok</span>}
            >
                <TableSummaryHeader items={summaryItems} />
                <Table onChange={store.reloadList} pagination={paginationConfig} size="middle" columns={FailedRideListColumns} dataSource={store.rides} rowKey="id" />
            </PageHeader>
        </>
    );
}

export default withFailedRideListStore(FailedRideListCore);