import { IInvoiceSummaryDetailCoreProps } from "./InvoiceSummaryDetail";
import { createStoreProvider, isNullOrUndefined, StoreBase } from "@eblsoft/react-toolkit";
import { observable, runInAction } from "mobx";
import InvoiceSummaryDetails from "Models/InvoiceSummaryDetails";
import InvoiceForPartnerListItem from "Models/InvoiceForPartnerListItem";
import { downloadBase64Async } from "Util/FileDownloader";
import InvoiceSummaryForPartnerListItem from "Models/InvoiceSummaryForPartnerListItem";

export default class InvoiceSummaryDetailStore extends StoreBase<IInvoiceSummaryDetailCoreProps> {

    @observable.ref public details: InvoiceSummaryDetails | null = null;

    public readonly vLoadAsync = this.async(async () => {
        const result = await this.props.invoiceSummaryApiApadter.getDetailsAsync(this.props.invoiceSummaryId, this.props.partnerId);

        runInAction(() => {
            this.details = result;
        });
    });

    public readonly downloadInvoicePdfAsync = this.async(async (invoice: InvoiceForPartnerListItem) => {
        const result = await this.props.invoiceApiApadter.getPdfAsync!(invoice.id, this.props.partnerId);
        await downloadBase64Async(result, "application/pdf", `eTaxi_szla__${invoice.invoiceNumber.replace(/\\\//gi, "_")}.pdf`);
    });

    public readonly downloadPdfAsync = this.async(async () => {
        const result = await this.props.invoiceSummaryApiApadter.getPdfAsync(this.details!.id, this.props.partnerId);
        await downloadBase64Async(result, "application/pdf", `eTaxi_szamlaosszesito__${this.details!.identifier.replace(/\\\//gi, "_")}.pdf`);
    });

    public readonly downloadExcelAsync = this.async(async () => {
        const result = await this.props.invoiceSummaryApiApadter.getExcelAsync(this.details!.id, this.props.partnerId);
        await downloadBase64Async(result, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `eTaxi_szamlaosszesito__${this.details!.identifier.replace(/\\\//gi, "_")}.xlsx`);
    });
}

const { ContextComponent: InvoiceSummaryDetailStoreContext, StoreProvider: InvoiceSummaryDetailStoreProvider, useStore: useInvoiceSummaryDetailStore, withStore: withInvoiceSummaryDetailStore } =
    createStoreProvider<IInvoiceSummaryDetailCoreProps, InvoiceSummaryDetailStore>(() => new InvoiceSummaryDetailStore());

export {
    InvoiceSummaryDetailStoreContext,
    InvoiceSummaryDetailStoreProvider,
    useInvoiceSummaryDetailStore,
    withInvoiceSummaryDetailStore
};