
export type Role = "Driver" | "Admin" | "PartnerAdmin" | "None" | "RideManager" | "DocRev" | "Reb" | "Feb";

export default class User {
    constructor(
        public readonly identifier: string,
        public readonly roles: Role[] 
    ) {}

    public hasRole(role: Role) {
        return this.roles.includes(role);
    }
}