import { BorderOutlined, CheckSquareOutlined, CloseSquareOutlined } from "@ant-design/icons";
import { Button, PageHeader, Space } from "antd";
import { getStatementTypeDisplayValue } from "Models/StatementType";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useConsentDetailStore, withConsentDetailStore } from "./ConsentDetailStore";

export interface IConsentDetailProps {
    consentId: number;
}

function ConsentDetailCore(props: IConsentDetailProps) {

    const store = useConsentDetailStore();
    const navigate = useNavigate();

    if (store.consent == null) {
        return (<PageHeader title="Nyilatkozat">Betöltés...</PageHeader>);
    }

    return (
        <PageHeader title={`'${getStatementTypeDisplayValue(store.consent.statementType)}' nyilatkozat`} >
            <div dangerouslySetInnerHTML={{ __html: store.consent?.statementContentHtml }} style={{ padding: 8, backgroundColor: "white", marginBottom: 16, marginLeft: "auto", marginRight: "auto", maxWidth: "60vw", overflow: "auto", maxHeight: "60vh" }}></div>

            {store.consent.items.map(i => (
                <div style={{ padding: 6, borderLeft: `4px solid ${i.isAccepted ? "#00ff00" : "#ff0000"}`, marginBottom: 8, marginLeft: "auto", marginRight: "auto", maxWidth: "60vw", fontSize: 18 }}>
                    {i.isAccepted ? <CheckSquareOutlined title="Elfogadva" /> : <BorderOutlined title="Elutasítva" />}&nbsp;&nbsp;{i.statementItemDisplayValue}<br />
                    <small>{i.checkedAt?.format("L LT")}</small>
                </div>
            ))}

            <Space size="middle" style={{ marginTop: 32 }}>
                <Button onClick={() => navigate(-1)}>Vissza a listára</Button>
            </Space>
        </PageHeader>
    );
}

const ConsentDetailWithStore = withConsentDetailStore(ConsentDetailCore);

export default function ConsentDetail() {
    const { consentId } = useParams();
    return (
        <ConsentDetailWithStore consentId={parseInt(consentId!)} />
    )
}