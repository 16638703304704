import { Input, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useMemo } from "react";

const { Text } = Typography;

interface IReadonlyInput {
    label: string;
    value: any;
    style?: React.CSSProperties;
    multiline?: boolean | number;
    isHighlighted?: boolean;
}

function ReadonlyInput(props: IReadonlyInput) {

    const { isHighlighted, style } = props;

    const color = useMemo(() => {
        if (isHighlighted) {
            return "#fdb813";
        }

        return style?.backgroundColor ?? "#f3f3f3";
    }, [isHighlighted, style?.backgroundColor]);

    return (
        <div style={{ width: "100%" }}>
            <Text>{props.label}</Text>
            {props.multiline ? (
                <TextArea
                    readOnly
                    rows={typeof (props.multiline) === "number" ? props.multiline : 4}
                    value={props.value}
                    style={{ width: "100%", backgroundColor: color, ...props.style }}
                />
            ) : (
                <Input
                    value={props.value}
                    readOnly={true}
                    style={{ width: "100%", backgroundColor: color, ...props.style }}
                />
            )}

        </div>
    );
}
export default ReadonlyInput;