import { PageHeader, Table, TablePaginationConfig, Button, Space, Input, Select, Typography, Popconfirm, Dropdown, Menu, Tooltip, Row, Col, Checkbox } from "antd";
import { DownloadOutlined, DownOutlined, EditOutlined, EyeOutlined, FileExcelOutlined, FilePdfOutlined, FilterOutlined, MoreOutlined, StopOutlined } from "@ant-design/icons";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { Observer, observer } from "mobx-react";
import { useTaxiCardsStore } from "Pages/Partner/TaxiCards/TaxiCardsStore";
import { CostPlacementTypeEntries } from "Models/CostPlacementType";
import Title from "antd/lib/typography/Title";
import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PrintOptions } from "Models/PrintOptions";
import ProhibitionInfoModal from "Pages/CommonControls/ProhibitionInfoModal";
import { CostPlacementTypeSelector } from "Pages/CommonControls/CostPlacementTypeSelector";
import TextBox from "Pages/CommonControls/TextBox";
import TaxiCardListItem from "Models/TaxiCardListItem";
import Styles from "./TaxiCards.module.css";
import { formatIdentifier } from "Util/IdentifierHelper";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";
import { IPollListProps } from "./Polls";
import Poll from "Models/Poll";
import { usePollsStore } from "./PollsStore";
import PollListItem from "Models/PollListItem";
import { getFullScreenTableScroll } from "Util/TableHelpers";
import DateRangePicker from "Pages/CommonControls/DateRangePicker";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";

const { Option } = Select;


function PollList(props: IPollListProps) {
    const store = usePollsStore();
    const navigate = useNavigate();

    const _paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    }

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    let paginationConfig = _paginationConfig;

    const pollColumns: Array<ColumnType<PollListItem>> = [
        {
            title: "Szavazás címe",
            dataIndex: "name",
            key: "name",
            sorter: true,
        },
        {
            title: "Szavazás vége",
            dataIndex: "endOfVoteDate",
            key: "endOfVoteDate",
            render: (date: moment.Moment | null) => isNullOrUndefined(date) ? "" : <span > {date!.format("YYYY.MM.DD HH:mm")}</span>,
            sorter: true
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            width: 100,
            render: (_, record: PollListItem) => {
                return (
                    isNullOrUndefined(record.startedAt) ?
                        <Space size="middle">
                            <Tooltip title="Szerkeztés">
                                <Link to={`${record.id}`}>
                                    <Button key={`${record.id}`} icon={<EditOutlined />} type="primary" />
                                </Link>
                            </Tooltip>
                        </Space> :
                        <Space size="middle" >
                            <Tooltip title="Szavazás követés">
                                <Link to={`${record.id}/votes`}>
                                    <Button key={`${record.id}`} icon={<MoreOutlined />} type="primary" />
                                </Link>
                            </Tooltip>
                        </Space >
                );
            }
        }

    ];

    const tableScroll = getFullScreenTableScroll(pollColumns.reduce((sum, col) => sum + (col.width as number ?? 100), 0));

    const navigateToCreate = useCallback(() => navigate('new'), [navigate]);

    return (
        <>
            <PageHeader
                title={<span id="page_title">Szavazás</span>}
                extra={
                    <Button key="newPoll" type="primary" onClick={navigateToCreate}>
                        Új szavazás
                    </Button>
                }
            >
                <Observer>
                    {() => (
                        <>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <DateRangePicker
                                        label="Szavazás vége időszak:"
                                        from={store.pollFilter.endOfVoteDateFrom}
                                        to={store.pollFilter.endOfVoteDateTo}
                                        onChange={store.pollFilter.setEndOfVoteDateRange}
                                        style={{ width: "100%" }}
                                        onEnter={store.filterResult}
                                        allowClear
                                        id="filter_poll_validityTo"
                                    />
                                </Col>
                                <Col span={12}>
                                    <TextBox
                                        label="Szavazás címe:"
                                        onChange={store.pollFilter.setName}
                                        value={store.pollFilter.name}
                                        onEnter={store.filterResult}
                                        id="filter_poll_name"
                                    />
                                </Col>
                            </Row>
                            {<Space style={{ marginTop: "16px", marginBottom: "16px" }}>
                                <Button key="1" style={{ width: "100%" }} type="primary" onClick={store.filterResult} icon={<FilterOutlined />} id="filter_poll_list">
                                    Szűrés
                                </Button>
                            </Space>
                            }
                        </>
                    )}
                </Observer>
                <TableSummaryHeader items={summaryItems} />
                <Table
                    onChange={store.reloadList}
                    pagination={paginationConfig}
                    size="middle"
                    columns={pollColumns}
                    dataSource={store.polls}
                    scroll={tableScroll}
                    rowKey="id" />
            </PageHeader>
        </>
    );
}

export default observer(PollList);

