import { IQueryParameters, isNullOrUndefined } from "@eblsoft/react-toolkit";
import { IAddress, ICreateTaxiCardRequest, ITaxiCard, IToggleTaxiCardProhibitionRequest, IUpdateTaxiCardRequest } from "Adapters/Api/Api.g";
import ITaxiCardApiAdapter from "Adapters/ITaxiCardApiAdapter";
import { mapTaxiCardListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import { CostPlacementType } from "Models/CostPlacementType";
import Ordering from "Adapters/QueryHelpers/Ordering";
import { PrintOptions } from "Models/PrintOptions";
import Paging from "Adapters/QueryHelpers/Paging";
import Address from "Models/Address";
import TaxiCardListItem from "Models/TaxiCardListItem";

class AdminTaxiCardAdapter extends ProtectedApiAdapterBase implements ITaxiCardApiAdapter {

    private readonly mainRoute = "api/web/admin/TaxiCards";

    public getTaxiCardListAsync(paging: Paging, ordering: Ordering, filter: any): Promise<{ totalCount: number, taxiCards: TaxiCardListItem[] }> {

        const queryParameters: IQueryParameters = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            ...filter
        }
        return this
            .httpGet<{ totalCount: number, taxiCards: TaxiCardListItem[] }>(this.mainRoute, undefined, queryParameters)
            .map(paginatedResult => {
                return {
                    totalCount: paginatedResult.totalCount,
                    taxiCards: paginatedResult.taxiCards.map((v: any) => {
                        return mapTaxiCardListItemDtoToDomainModel(v);
                    })
                };
            }
            ).performOperationAsync();
    }

    // public updateTaxiCardAsync(taxiCard: TaxiCard, partnerId: number) {
    //     const body: IUpdateTaxiCardRequest = {
    //         id: taxiCard.id,
    //         ownerName: taxiCard.ownerName,
    //         pin: taxiCard.pin,
    //         costPlacementType: taxiCard.costPlacementType,
    //         costPlacement: taxiCard.costPlacement,
    //     } as ITaxiCard;
    //     return this.httpPost<boolean>(
    //         this.mainRoute,
    //         [partnerId.toString(), "TaxiCards", "update"],
    //         body
    //     ).map((res) => {
    //         return true;
    //     }).performOperationAsync();
    // }

    // public createTaxiCardsAsync(
    //     amount: number,
    //     costPlacementType: CostPlacementType,
    //     costPlacement: string = "",
    //     pin: string,
    //     requesterName: string,
    //     requesterPhoneNumber: string,
    //     referenceNumber: string,
    //     deliveryAddress: IAddress,
    //     partnerId: number): Promise<{ totalCount: number, taxiCards: TaxiCard[] }> {
    //     const body: ICreateTaxiCardRequest = {
    //         amount,
    //         costPlacementType,
    //         costPlacement,
    //         pin,
    //         requesterName,
    //         referenceNumber,
    //         requesterPhoneNumber,
    //         deliveryAddress
    //     };
    //     return this.httpPost<{ totalCount: number, taxiCards: TaxiCard[] }>(this.mainRoute, [partnerId!.toString(), "TaxiCards", "create"], body).map(paginatedResult => {
    //         return {
    //             totalCount: paginatedResult.totalCount,
    //             taxiCards: paginatedResult.taxiCards.map((v: any) => {
    //                 return mapTaxiCardListItemDtoToDomainModel(v);
    //             })
    //         };
    //     }
    //     ).performOperationAsync();
    // }

    // public toggleProhibitTaxiCardAsync(id: number, prohibitionReason?: string, userName?: string, partnerId?: number): Promise<boolean> {
    //     const body: IToggleTaxiCardProhibitionRequest = {
    //         id,
    //         prohibitionReason: prohibitionReason ?? "",
    //         userName: userName ?? ""
    //     };
    //     return this.httpPost<boolean>(this.mainRoute, [partnerId!.toString(), "TaxiCards", "toggleProhibition"], body).map(result => {
    //         return result;
    //     }).performOperationAsync();
    // }

    // public getPartnerAddressAsync(partnerId: number): Promise<Address | null> {
    //     return this.httpGet<Address | null>(this.mainRoute, [partnerId!.toString(), "TaxiCards", "PartnerAddress"])
    //         .map(result => isNullOrUndefined(result.address) ? null : mapAddressDtoToDomainModel(result.address)).performOperationAsync();
    // }

    public printTaxiCardsAsync(ordering: Ordering, filter: any, printOption: PrintOptions, showPin: boolean) {
        const queryParameters: IQueryParameters = {
            printOption,
            showPin,
            OrderBy: ordering.orderBy,
            OrderAsc: ordering.orderAsc,
            ...filter
        };
        return this.httpGet<string>(this.mainRoute, ["print"], queryParameters).map(result => {
            return result;
        }).performOperationAsync();
    }


}

export default new AdminTaxiCardAdapter();