import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";
import { AuditEventType } from "Models/AuditEventType";

export default class AuditEventFilter {

    @observable.ref public occuredAtFrom: moment.Moment | null = null;
    @observable.ref public occuredAtTo: moment.Moment | null = null;
    @observable.ref public user: string = "";
    @observable.ref public eventTypes: AuditEventType[] = [];

    @observable public areFiltersDirty = false;

    public constructor(
    ) {
        makeObservable(this);
    }

    @computed public get isEmpty() {
        return this.user === "" &&
            this.occuredAtFrom === null &&
            this.occuredAtTo === null &&
            this.eventTypes.length === 0;
    }

    @action.bound
    public clear() {
        this.user = "";
        this.occuredAtFrom = null;
        this.occuredAtTo = null;
        this.eventTypes = [];
    }

    @action.bound
    public setUser(value: string) {
        this.user = value;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setEventTypes(value: AuditEventType[] | null) {
        this.eventTypes = value ?? [];
        this.areFiltersDirty = true;
    }

    @action.bound
    public setOccuredAtRange(from: moment.Moment | null, to: moment.Moment | null) {
        this.occuredAtFrom = from?.clone().hour(0).minute(0).second(0).millisecond(0) ?? null;
        this.occuredAtTo = to?.clone().hour(23).minute(59).second(59).millisecond(999) ?? null;
        this.areFiltersDirty = true;
    }
}
