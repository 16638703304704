import TaxNumber from "./TaxNumber";
import Address from "./Address";

export default class Customer {
    constructor(
        public readonly name: string,
        public readonly isPrivatePerson: boolean,
        public readonly taxNumber: TaxNumber,
        public readonly groupMemberTaxNumber: TaxNumber | null,
        public readonly address: Address,
    ) { }
}