import { EnumHelper } from "Util/EnumHelper";

// Copy from Api.ts:
export enum TaxiCardProhibitionEventStatus {
  Prohibited = "Prohibited",
  Billed = "Billed",
  Pending = "Pending",
  PermanentlyCancelled = "PermanentlyCancelled",
  NotBillable = "NotBillable"
}


function getTaxiCardProhibitionEventStatusEntries() {
  return EnumHelper.getEnumEnumerableEntries<TaxiCardProhibitionEventStatus>(TaxiCardProhibitionEventStatus, getTaxiCardProhibitionEventStatusDisplayValue);
}

export function getTaxiCardProhibitionEventStatusDisplayValue(value: TaxiCardProhibitionEventStatus) {

  switch (value) {
      case TaxiCardProhibitionEventStatus.Prohibited: return "Tiltott";
      case TaxiCardProhibitionEventStatus.Billed: return "Kiszámlázott";
      case TaxiCardProhibitionEventStatus.Pending: return "Függőben";
      case TaxiCardProhibitionEventStatus.PermanentlyCancelled: return "Véglegesen letiltott";
      case TaxiCardProhibitionEventStatus.NotBillable: return "Nem számlázandó";
  }
}

export const TaxiCardProhibitionEventStatusEntries = getTaxiCardProhibitionEventStatusEntries();
