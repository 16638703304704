import { EnumHelper } from "Util/EnumHelper";

// Copy from Api.ts:
export enum TaxiCardOrderStatus {
  Created = "Created",
  Completed = "Completed",
  Pending = "Pending"
}


function getTaxiCardOrderStatusEntries() {
  return EnumHelper.getEnumEnumerableEntries<TaxiCardOrderStatus>(TaxiCardOrderStatus, getTaxiCardOrderStatusDisplayValue);
}

export function getTaxiCardOrderStatusDisplayValue(value: TaxiCardOrderStatus) {

  switch (value) {
      case TaxiCardOrderStatus.Created: return "Igényelt";
      case TaxiCardOrderStatus.Completed: return "Kész";
      case TaxiCardOrderStatus.Pending: return "Függőben";
  }
}

export const TaxiCardOrderStatusEntries = getTaxiCardOrderStatusEntries();
