/* eslint-disable react-hooks/rules-of-hooks */
import { uniqueId } from "lodash";
import React, { useEffect } from "react";

export class ValidationStore {

    private readonly _validationCallbacks = new Map<string, () => boolean>();

    public registerValidateCallbackWithDisposer(validateCallback: () => boolean): () => void {
        const id = uniqueId();
        this._validationCallbacks.set(id, validateCallback);
        return () => {
            this._validationCallbacks.delete(id);
        }
    }

    public isValid() {
        let isValid = true;

        Array.from(this._validationCallbacks.values()).forEach(cb => {
            if (!cb()) {
                isValid = false;
            }
        })

        return isValid;
    }
}

const ValidationStoreContext = React.createContext<ValidationStore | null>(null);
const ValidationStoreProvider = ValidationStoreContext.Provider;

export function useValidationStore() {
    return React.useContext(ValidationStoreContext);
}

export {
    ValidationStoreContext,
    ValidationStoreProvider
}