import { action, makeObservable, observable } from "mobx";
import { CostPlacementType } from "Models/CostPlacementType";

class TaxiCardListItem {
    public readonly id: number;
    public readonly partnerId: number;
    @observable.ref public identifier: string;
    @observable.ref public pin: string;
    @observable.ref public costPlacementType: CostPlacementType;
    @observable.ref public costPlacement: string;
    @observable.ref public isPhysical: boolean;
    @observable.ref public isActive: boolean;
    @observable.ref public ownerName: string = "";
    @observable.ref public isPinVisible: boolean = false;

    public constructor(
        id: number,
        partnerId: number,
        identifier: string,
        pin: string,
        isPhysical: boolean,
        isActive: boolean,
        costPlacementType: CostPlacementType,
        costPlacement: string,
        public readonly permanentlyCancelled: boolean,
        ownerName: string | null,
        public readonly customerName: string | null,
        public readonly customerBpid: string | null,
        public readonly prohibitionReason: string | null,
        public readonly prohibitedBy: string | null,
        public readonly orderedAt: moment.Moment | null,
        public readonly preMadeCardIndex: number | null,

    ) {
        this.id = id;
        this.partnerId = partnerId;
        this.identifier = identifier;
        this.pin = pin;
        this.isPhysical = isPhysical;
        this.isActive = isActive;
        this.costPlacementType = costPlacementType;
        this.costPlacement = costPlacement;
        this.ownerName = ownerName ?? "";
        makeObservable(this);
    }

    @action.bound
    public setCostPlacementType(type: CostPlacementType) {
        this.costPlacementType = type;
    }

    @action.bound
    public setCostPlacement(value: string) {
        this.costPlacement = value;
    }

    @action.bound
    public setOwnerName(value: string) {
        this.ownerName = value;
    }

    @action.bound
    public setPin(value: string) {
        this.pin = value;
    }

    @action.bound
    public setIsPinVisible() {
        this.isPinVisible = !this.isPinVisible;
    }

    @action.bound
    public setIsActive() {
        this.isActive = !this.isActive;
    }
}


export default TaxiCardListItem;
