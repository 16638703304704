import { action, makeObservable, observable } from "mobx";
import { CostPlacementType } from "./CostPlacementType";


export default class PhoneNumberListItem {  
    @observable.ref public number: string;
    @observable.ref public isActive: boolean;
    @observable.ref public costPlacementType: CostPlacementType;
    @observable.ref public costPlacement: string;
    @observable.ref public pin: string | null;
    @observable.ref public name: string;

    constructor(
        public id: number,
        number: string,
        isActive: boolean,
        costPlacementType: CostPlacementType,
        costPlacement: string,
        pin: string | null,
        name: string,
        public readonly customerName: string | null,
        public readonly customerBpid: string | null,
    ) {
        this.number = number;
        this.isActive = isActive;
        this.costPlacementType = costPlacementType;
        this.costPlacement = costPlacement;
        this.pin = pin;
        this.name = name;
        makeObservable(this);
    }

    @action.bound
    public setCostPlacementType(type: CostPlacementType) {
        this.costPlacementType = type;
    }

    @action.bound
    public setCostPlacement(value: string) {
        this.costPlacement = value;
    }

    @action.bound
    public setNumber(value :string) {
        this.number = value;
    }

    @action.bound
    public setName(value: string) {
        this.name = value;
    }

    @action.bound
    public setPin(value: string) {
        this.pin = value.length === 0 ? null : value;
    }
}