import { Input } from "antd";
import TextBox from "Pages/CommonControls/TextBox";
import React, { useCallback } from "react";

export interface IPinInputProps {
    onChange: (value: string) => void;
    value: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    isRequired?: boolean;
    showLabel?: boolean;
}

function PinInputBox(props: IPinInputProps) {
    const { value, onChange } = props;

    const setValue = useCallback((newValue: string) => {
        onChange(newValue.replace(/\D/gi, ""));
    }, [onChange]);

    return (
        <TextBox
            label={props.showLabel ? "PIN:" : undefined}
            style={props.style}
            disabled={props.disabled}
            onChange={setValue}
            value={value}
            minLength={4}
            maxLength={4}
            isRequired={props.isRequired}
        />
    )
}

export default PinInputBox;