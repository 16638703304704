import { createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { action, observable, runInAction } from "mobx";
import TrafficLicenseFilter from "Models/Filters/TrafficLicenseFilter";
import TrafficLicenseListItem from "Models/TrafficLicenseListItem";
import AdminTrafficLicenseAdapter from "Adapters/AdminAdapter/AdminTrafficLicenseAdapter";

export default class TrafficLicensesStore extends StoreBase {
    @observable.ref public trafficLicenses: TrafficLicenseListItem[] = [];

    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    public orderedBy = "bpId";
    public orderDirection = "ascend";

    public trafficLicenseFilter = new TrafficLicenseFilter();

    public readonly vLoadAsync = this.async(async () => {
        await this.loadListAsync();
    });
    
   private readonly loadListAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await AdminTrafficLicenseAdapter.getListAsync( paging, ordering, this.trafficLicenseFilter);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.trafficLicenses = result.trafficLicenses;
        });
    });

    @action.bound
    public filterResult() {
        this.currentPage = 1;
        this.trafficLicenseFilter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<TrafficLicenseListItem> | SorterResult<TrafficLicenseListItem>[], extra: TableCurrentDataSource<TrafficLicenseListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (!sorter) {
            this.orderedBy = "bpId";
        } else {
            if (Array.isArray(sorter)) {
                this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
                this.orderDirection = sorter[0].order!;
            } else {
                this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
                this.orderDirection = sorter.order!;
            }
        }
        this.vLoadAsync.fireAndForget();
    }

}

const { ContextComponent: TrafficLicensesStoreContext, StoreProvider: TrafficLicensesStoreProvider, useStore: useTrafficLicensesStore, withStore: withTrafficLicensesStore } = createStoreProvider(() => new TrafficLicensesStore());

export {
    TrafficLicensesStoreContext,
    TrafficLicensesStoreProvider,
    useTrafficLicensesStore,
    withTrafficLicensesStore
};