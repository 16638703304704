import { createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { action, observable, runInAction } from "mobx";
import VehicleConditionListItem from "Models/VehicleConditionListItem";
import AdminVehicleConditionAdapter from "Adapters/AdminAdapter/AdminVehicleConditionAdapter";
import VehicleConditionFilter from "Models/Filters/VehicleConditionFilter";

export default class VehicleConditionListStore extends StoreBase {
    @observable.ref public vehicleConditions: VehicleConditionListItem[] = [];

    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    public orderedBy = "bpId";
    public orderDirection = "ascend";

    public vehicleConditionFilter = new VehicleConditionFilter();

    public readonly vLoadAsync = this.async(async () => {
        await this.loadListAsync();
    });
    
   private readonly loadListAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await AdminVehicleConditionAdapter.getListAsync( paging, ordering, this.vehicleConditionFilter);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.vehicleConditions = result.vehicleConditions;
        });
    });

    @action.bound
    public filterResult() {
        this.currentPage = 1;
        this.vehicleConditionFilter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<VehicleConditionListItem> | SorterResult<VehicleConditionListItem>[], extra: TableCurrentDataSource<VehicleConditionListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (!sorter) {
            this.orderedBy = "bpId";
        } else {
            if (Array.isArray(sorter)) {
                this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
                this.orderDirection = sorter[0].order!;
            } else {
                this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
                this.orderDirection = sorter.order!;
            }
        }
        this.vLoadAsync.fireAndForget();
    }

}

const { ContextComponent: VehicleConditionListStoreContext, StoreProvider: VehicleConditionListStoreProvider, useStore: useVehicleConditionListStore, withStore: withVehicleConditionListStore } = createStoreProvider(() => new VehicleConditionListStore());

export {
    VehicleConditionListStoreContext,
    VehicleConditionListStoreProvider,
    useVehicleConditionListStore,
    withVehicleConditionListStore
};