import { FileExcelFilled, FilePdfFilled, MoreOutlined } from "@ant-design/icons";
import { Button, Space, Tooltip } from "antd";
import { useCallback } from "react";
import InvoiceSummaryForPartnerListItem from "Models/InvoiceSummaryForPartnerListItem";
import { useInvoiceSummaryListStore } from "Pages/Partner/InvoiceSummaries/InvoiceSummaryListStore";
import { Link } from "react-router-dom";

export default function InvoiceSummaryActions({ invoiceSummary }: { invoiceSummary: InvoiceSummaryForPartnerListItem }) {

    const store = useInvoiceSummaryListStore();

    const getPdf = useCallback(() => {
        store.downloadPdfAsync.fireAndForget(invoiceSummary);
    }, [invoiceSummary]);

    const getExcel = useCallback(() => {
        store.downloadExcelAsync.fireAndForget(invoiceSummary);
    }, [invoiceSummary]);

    return (
        <Space size="middle">
            <Tooltip title="Összesítés letöltése PDF-ben">
                <Button onClick={getPdf} icon={<FilePdfFilled style={{ color: "#F40F02" }} />}>PDF</Button>
            </Tooltip>
            <Tooltip title="Összesítés letöltése Excel-ben">
                <Button onClick={getExcel} icon={<FileExcelFilled style={{ color: "#1D6F42" }} />}>EXCEL</Button>
            </Tooltip>

            <Tooltip title="Részletek">
                <Link to={`${invoiceSummary.id}`}>
                    <Button icon={<MoreOutlined />} type="primary" />
                </Link>
            </Tooltip>
        </Space>
    );
}
