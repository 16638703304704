import { createStoreProvider, isNullOrUndefined, StoreBase } from "@eblsoft/react-toolkit";
import { action, observable, runInAction } from "mobx";
import NotificationStore from "Stores/NotificationStore";
import { ValidationStore } from "Stores/ValidationStore";
import { DocumentStatus } from "Models/DocumentStatus";
import AdminSimpleDocumentAdapter from "Adapters/AdminAdapter/AdminSimpleDocumentAdapter";
import AdminTrafficLicenseAdapter from "Adapters/AdminAdapter/AdminTrafficLicenseAdapter";
import { ITrafficLicenseDetailCoreProps } from "./TrafficLicenseDetail";
import AdminDocumentApiAdapter from "Adapters/AdminAdapter/AdminDocumentApiAdapter";

export default class TrafficLicenseDetailStore extends StoreBase<ITrafficLicenseDetailCoreProps> {
    public id: number | null = null;
    @observable.ref public identifier: string = "";
    @observable.ref public makeOfCar: string = "";
    @observable.ref public typeOfCar: string = "";
    @observable.ref public licensePlateNumber: string = "";
    @observable.ref public status: DocumentStatus | null = null;
    @observable.ref public validTo: moment.Moment | null = null;
    @observable.ref public lastConfirmedValidTo: moment.Moment | null = null;
    @observable.ref public validFrom: moment.Moment | null = null;
    public images: string[] = [];

    @observable.ref public isAuthorized = AdminDocumentApiAdapter.getDocumentReviewToken() != null;
    @observable.ref public tfaCode: string = "";
    @action.bound public setTfaCode(value: string) { this.tfaCode = value; }
    public readonly authenticateAsync = this.async(async () => {
        const isOk = await AdminDocumentApiAdapter.authorizeAsync(this.tfaCode);
        if (!isOk) {
            NotificationStore.error("Hibás hitelesítő kód");
            return;
        }
        runInAction(() => {
            this.isAuthorized = true;
        });
        await this.vLoadAsync();
    });

    public readonly validationStore = new ValidationStore();

    @action.bound
    public setIdentifier(value: string) {
        this.identifier = value;
    }

    @action.bound
    public setMakeOfCar(value: string) {
        this.makeOfCar = value;
    }

    @action.bound
    public setTypeOfCar(value: string) {
        this.typeOfCar = value;
    }

    @action.bound
    public setLicensePlateNumber(value: string) {
        this.licensePlateNumber = value;
    }

    @action.bound
    public setSimpleDocumentStatus(value: DocumentStatus) {
        this.status = value;
    }

    @action.bound
    public setValidTo(value: moment.Moment | null) {
        this.validTo = value;
    }

    @action.bound
    public setValidFrom(value: moment.Moment | null) {
        this.validFrom = value;
    }

    public readonly vLoadAsync = this.async(async () => {
        if (!isNullOrUndefined(this.props.trafficLicenseId) && this.isAuthorized) {
            const result = await AdminTrafficLicenseAdapter.getDetailsAsync(AdminDocumentApiAdapter.getDocumentReviewToken()!, this.props.trafficLicenseId!);
            runInAction(() => {
                this.id = this.props.trafficLicenseId ?? null
                this.identifier = result?.identifier ?? "";
                this.makeOfCar = result?.makeOfCar ?? "";
                this.typeOfCar = result?.typeOfCar ?? "";
                this.licensePlateNumber = result?.licensePlateNumber ?? "";
                this.status = result?.status ?? "";
                this.validTo = result?.validTo ?? null;
                this.validFrom = result?.validFrom ?? null;
                this.images = result?.images ?? [];
                this.lastConfirmedValidTo = result?.lastConfirmedValidTo;
            });
        }
    });

    public saveAsync = this.async(async () => {

        if (!this.isAuthorized) {
            return false;
        }

        if (!this.validationStore.isValid() || this.status === null) {
            NotificationStore.error("Hibás vagy kitöltetlen adatok");
            return false;
        }

        await AdminTrafficLicenseAdapter.updateTrafficLicenseAsync(
            AdminDocumentApiAdapter.getDocumentReviewToken()!,
            this.id!,
            this.identifier,
            this.makeOfCar,
            this.typeOfCar,
            this.licensePlateNumber,
            this.status!,
            this.validTo!,
            this.validFrom!);

        NotificationStore.success("Sikeres mentés");
        return true;
    });
}



const { ContextComponent: TrafficLicenseDetailStoreContext, StoreProvider: TrafficLicenseDetailStoreProvider, useStore: useTrafficLicenseDetailStore, withStore: withTrafficLicenseDetailStore } =
    createStoreProvider<ITrafficLicenseDetailCoreProps, TrafficLicenseDetailStore>(() => new TrafficLicenseDetailStore());

export {
    TrafficLicenseDetailStoreContext,
    TrafficLicenseDetailStoreProvider,
    useTrafficLicenseDetailStore,
    withTrafficLicenseDetailStore
};