import Distribution from "Models/Distribution";
import { Cell, Pie, PieChart, Tooltip, Legend, TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";

interface IPieChartProps {
    distribution: Distribution[]
}


function PieChartComponent(props: IPieChartProps) {
    const COLORS = ["#efb51d", "#000000", "#0756ac", "#543025", "#be2621", "#5e94c8", "#254774", "#2f395f"];
    const values = props.distribution.map(a => {
        return {
            name: a.name,
            value: a.percentage,
            quantity: a.quantity
        }
    });

    const customToolTip = ({
        active,
        payload,
        label,
    }: TooltipProps<ValueType, NameType>) => {
        if (active) {
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: "#ffff",
                        padding: "5px",
                        border: "1px solid #cccc"
                    }}
                >
                    {payload && (<label>{`${payload[0].name} : ${Math.round(payload[0].value as number * 100) / 100}%  ${payload[0].payload.quantity}db `}</label>)}
                </div>
            );
        }
        return null;
    }

    return (
        <PieChart width={730 * 0.4} height={300 * 0.8}>
            <Pie
                data={values}
                color="#000000"
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={120 * 0.8}
                fill="#8884d8"
            >
                {values.map((entry, index) => (
                    <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                    />
                ))}
            </Pie>
            <Legend />

            <Tooltip content={customToolTip} />
        </PieChart>
    );
}

export default PieChartComponent;