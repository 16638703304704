import { EnumHelper } from "Util/EnumHelper";

export enum AuditEventType {
    DeleteOfflineRide = "DeleteOfflineRide",
    ModifyInvoiceOrRide = "ModifyInvoiceOrRide",
    ProhibitTaxiVouchers = "ProhibitTaxiVouchers",
    ReActivateTaxiVouchers = "ReActivateTaxiVouchers",
    ProhibitTaxiCards = "ProhibitTaxiCards",
    ReActivateTaxiCards = "ReActivateTaxiCards",
    PermanentlyCancelledTaxiCard = "PermanentlyCancelledTaxiCard",
    ProhibitPhoneNumber = "ProhibitPhoneNumber",
    ReActivatePhoneNumber = "ReActivatePhoneNumber",
    DeletePhoneNumber = "DeletePhoneNumber",
    SimpleDocumentDetailsViewed = "SimpleDocumentDetailsViewed",
    SimpleDocumentDetailsWithPhotosViewed = "SimpleDocumentDetailsWithPhotosViewed",
    SimpleDocumentDetailsChanged = "SimpleDocumentDetailsChanged",
    SimpleDocumentStatusChanged = "SimpleDocumentStatusChanged",
    TrafficLicenseDetailsViewed = "TrafficLicenseDetailsViewed",
    TrafficLicenseDetailsWithPhotosViewed = "TrafficLicenseDetailsWithPhotosViewed",
    TrafficLicenseDetailsChanged = "TrafficLicenseDetailsChanged",
    TrafficLicenseStatusChanged = "TrafficLicenseStatusChanged"
}

function getAuditEventTypeEntries() {
    return EnumHelper.getEnumEnumerableEntries<AuditEventType>(AuditEventType, getAuditEventTypeDisplayValue);
}

export function getAuditEventTypeDisplayValue(value: AuditEventType) {

    switch (value) {
        case AuditEventType.DeleteOfflineRide: return "Fuvar nélküli elszámolás törlése";
        case AuditEventType.ModifyInvoiceOrRide: return "Számla vagy fuvaradat módosítása";
        case AuditEventType.ProhibitTaxiVouchers: return "Azonosító szelvények letiltása";
        case AuditEventType.ReActivateTaxiVouchers: return "Azonosító szelvények újra aktiválása";
        case AuditEventType.ProhibitTaxiCards: return "Taxikártyák letiltása";
        case AuditEventType.ReActivateTaxiCards: return "Taxikártyák újra aktiválása";
        case AuditEventType.PermanentlyCancelledTaxiCard: return "Véglegesen letiltott taxikártyák";
        case AuditEventType.ProhibitPhoneNumber: return "Telefonszámok letiltása";
        case AuditEventType.ReActivatePhoneNumber: return "Telefonszámok újra aktiválása";
        case AuditEventType.DeletePhoneNumber: return "Telefonszámok végleges törlése";
        case AuditEventType.SimpleDocumentDetailsViewed: return "Egyszerű irat adatainak megtekintése";
        case AuditEventType.SimpleDocumentDetailsWithPhotosViewed: return "Egyszerű irat adatainak és fényképének megtekintése";
        case AuditEventType.SimpleDocumentDetailsChanged: return "Egyszerű irat adatainak módosítása";
        case AuditEventType.SimpleDocumentStatusChanged: return "Egyszerű irat állapot módosítása";
        case AuditEventType.TrafficLicenseDetailsViewed: return "Forgalmi engedély adatainak megtekintése";
        case AuditEventType.TrafficLicenseDetailsWithPhotosViewed: return "Forgalmi engedély adatainak és fényképének megtekintése";
        case AuditEventType.TrafficLicenseDetailsChanged: return "Forgalmi engedély adatainak módosítása";
        case AuditEventType.TrafficLicenseStatusChanged: return "Forgalmi engedély állapot módosítása";
    }
}

export const AuditEventTypeEntries = getAuditEventTypeEntries();