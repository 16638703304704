import { DocumentStatus } from "./DocumentStatus";

export default class SimpleDocument {
    constructor(
        public readonly id: number,
        public readonly identifier: string | null,
        public readonly validTo: moment.Moment | null,
        public readonly status: DocumentStatus,
        public readonly images: string[],
        public readonly lastConfirmedValidTo: moment.Moment | null,
    ) { }
}