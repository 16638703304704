import ISettings from "Models/ISettings";

const isDevelopment = process.env.NODE_ENV === "development";

const Config = {
    appServerAddress: isDevelopment ? "https://localhost:7117/" : `${window.location.protocol}//${window.location.host}/`,
    isDevelopment: isDevelopment,
    settings: {} as ISettings
};

export default Config;