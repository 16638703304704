import { StopOutlined } from "@ant-design/icons";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { Button, Space, Tooltip } from "antd";
import Voucher from "Models/Voucher";
import { useVouchersStore } from "Pages/Partner/Vouchers/VouchersStore";
import { useCallback } from "react";

interface IVoucherRowActionsProps {
    voucher: Voucher;
}

export default function VoucherRowActions(props: IVoucherRowActionsProps) {

    const store = useVouchersStore();
    const prohibit = useCallback(() => {
        isNullOrUndefined(props.voucher.prohibitedAt) ? store.setProhibitionModalIsVisible([props.voucher.id]) : store.toggleProhibitSingleVoucherAsync.fireAndForget(props.voucher.id);
    }, [props.voucher]);

    return (
        <Space size="middle">
            <Tooltip title="Letiltás / aktiválás">
                <Button onClick={prohibit} icon={<StopOutlined />} />
            </Tooltip>
        </Space>
    );
}