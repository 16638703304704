import { BusinessError, TechnicalError } from "@eblsoft/react-toolkit";
import React from "react"

export default class UnhandledErrorHandler extends React.PureComponent<{}, { hasError: boolean }> {

    constructor(props: {}) {
        super(props);

        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: UnhandledErrorHandler.errorIsUnhandled(error) };
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {

        if (UnhandledErrorHandler.errorIsUnhandled(error)) {
            console.error(error);
            console.error(errorInfo);
        }
    }

    private static errorIsUnhandled(error: Error) {
        if (error instanceof BusinessError) {
            return !error.isErrorHandled;
        }

        if (error instanceof TechnicalError) {
            return !error.isErrorHandled;
        }

        return true;
    }

    public render(): React.ReactNode {

        if (this.state.hasError) {
            return (
                <div style={{ margin: 8 }}>
                    <h2>Hiba</h2>
                    <p>Az eTaxi rendszer jelenleg nem elérhető! Kérjük próbálja meg később.<br />
                        Ha a rendszer továbbra is elérhetetlen marad, kérjük jelezze a support@etaxi.eblsoft.net e-mail címen.</p>
                    <small>City Taxi - eTaxi 2.0 ©{new Date().getFullYear()}</small>
                </div>
            );
        }

        return this.props.children;
    }
}