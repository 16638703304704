import { VehicleConditionStatus } from "./VehicleConditionStatus";
import { VehicleConditionType } from "./VehicleConditionType";

export default class VehicleConditionListItem {
    constructor(
        public readonly id: number,
        public readonly bpId: string,
        public readonly name: string,
        public readonly createdAt: moment.Moment,
        public readonly status: VehicleConditionStatus,
        public readonly type: VehicleConditionType
    ) { }
}