import moment from "moment";
import FailedRideDetails from "./FailedRideDetails";
import Customer from "./Customer";

export default class FailedRideDetailsForReview extends FailedRideDetails {
    constructor(
        id: number,
        publicIdentifier: string,
        rideIdentifier: string,
        driverName: string,
        driverBpid: string,
        taxiDriverName: string,
        grossPrice: number,
        createdAt: moment.Moment,
        deletedAt: moment.Moment | null,
        isApproved: boolean | null,
        reviewedAt: moment.Moment | null,
        rideAdditionalInfo: string | null,
        driverComment: string | null,
        reviewerComment: string | null,
        public readonly partnerIdFromRide: number | null,
        public readonly customerFromRide: Customer | null
    ) {
        super(
            id,
            publicIdentifier,
            rideIdentifier,
            driverName,
            driverBpid,
            taxiDriverName,
            grossPrice,
            createdAt,
            deletedAt,
            isApproved,
            reviewedAt,
            rideAdditionalInfo,
            driverComment,
            reviewerComment,
        );
    }
}