import { PageHeader, Table, TablePaginationConfig, Button, Space, Input, Select, Typography, Popconfirm, Dropdown, Menu, Tooltip, Row, Col, Checkbox } from "antd";
import { DeleteOutlined, DownloadOutlined, DownOutlined, EditOutlined, EyeOutlined, FileExcelOutlined, FilePdfOutlined, FilterOutlined, StopOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { Observer, observer } from "mobx-react";
import { useTaxiCardsStore } from "Pages/Partner/TaxiCards/TaxiCardsStore";
import { CostPlacementTypeEntries } from "Models/CostPlacementType";
import Title from "antd/lib/typography/Title";
import CostPlacementTypeColumn from "./Controls/CostPlacementTypeColumn";
import PinColumn from "./Controls/PinColumn";
import NameColumn from "./Controls/NameColumn";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { PrintOptions } from "Models/PrintOptions";
import ProhibitionInfoModal from "Pages/CommonControls/ProhibitionInfoModal";
import { CostPlacementTypeSelector } from "Pages/CommonControls/CostPlacementTypeSelector";
import TextBox from "Pages/CommonControls/TextBox";
import { ITaxiCardListCoreProps } from "./TaxiCardCreation/TaxiCards";
import TaxiCardListItem from "Models/TaxiCardListItem";
import Styles from "./TaxiCards.module.css";
import { formatIdentifier } from "Util/IdentifierHelper";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";
import { TableRowSelection } from "antd/lib/table/interface";
import PermanentCancellationTaxiCardModal from "./PermanentCancellationTaxiCardModal";

const { Option } = Select;


function TaxiCardList(props: ITaxiCardListCoreProps) {
    const selectedRowColor = 'rgb(37, 184, 100)';
    const store = useTaxiCardsStore();
    const navigate = useNavigate();

    const _paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    }

    let paginationConfig = _paginationConfig;

    const edit = (record: TaxiCardListItem | null) => {
        if (record) {
            paginationConfig = {};
            store.setRecordUnderEdit(record.id);
        } else {
            paginationConfig = _paginationConfig;
            store.setRecordUnderEdit(null);
        }
    };
    const rowSelection: TableRowSelection<TaxiCardListItem> | undefined = !props.isSummary ? {
        type: "checkbox",
        selections: true,
        selectedRowKeys: store.selectedTaxiCards.map(v => v.id),
        getCheckboxProps: record => ({
            disabled: record.permanentlyCancelled
        }),
        onChange: (selectedRowKeys: React.Key[], selectedRows: TaxiCardListItem[]) => {
            const filteredSelectedRows = selectedRows.filter(s => !s.permanentlyCancelled);
            store.selectTaxiCards(filteredSelectedRows);
        }
    } : undefined;

    const isUnderEdit = (record: TaxiCardListItem) => store.recordIdUnderEdit === record.id;

    const getTaxiCardColumns = (): ColumnsType<TaxiCardListItem> => {
        const res: ColumnsType<TaxiCardListItem> = [
            {
                title: "Név",
                dataIndex: "ownerName",
                key: "ownerName",
                sorter: store.recordIdUnderEdit === null ? true : false,
                render: (_, record: TaxiCardListItem) => {
                    const recordUnderEdit = isUnderEdit(record);
                    const style = recordUnderEdit ? { backgroundColor: selectedRowColor } : {};
                    return {
                        props: { style },
                        children: <NameColumn record={record} isUnderEdit={recordUnderEdit} />
                    }
                }
            },
            {
                title: "Sorszám",
                dataIndex: "preMadeCardIndex",
                key: "preMadeCardIndex",
                defaultSortOrder: "ascend",
                align: "right",
                sorter: store.recordIdUnderEdit === null ? true : false,
                render: (value, record: TaxiCardListItem) => {
                    const recordUnderEdit = isUnderEdit(record);
                    const style = recordUnderEdit ? { backgroundColor: selectedRowColor } : {};
                    return {
                        props: { style },
                        children: <span>{value}</span>
                    }
                },
            },
            {
                title: "Azonosító",
                dataIndex: "identifier",
                key: "identifier",
                sorter: store.recordIdUnderEdit === null ? true : false,
                render: (identifier, record: TaxiCardListItem) => {
                    const recordUnderEdit = isUnderEdit(record);
                    const style = recordUnderEdit ? { backgroundColor: selectedRowColor } : {};
                    return {
                        props: { style },
                        children: <span className={Styles.identifier}>{formatIdentifier(identifier)}</span>
                    }
                },
            },
            {
                title: "Költséghely",
                dataIndex: "costPlacementType",
                key: "costPlacementType",
                render: (_, record: TaxiCardListItem) => {
                    const recordUnderEdit = isUnderEdit(record);
                    const style = recordUnderEdit ? { backgroundColor: selectedRowColor } : {};
                    return {
                        props: { style },
                        children: (
                            <Observer>
                                {() => (
                                    <CostPlacementTypeColumn
                                        isUnderEdit={isUnderEdit(record)}
                                        costPlacement={record.costPlacement}
                                        costPlacementType={record.costPlacementType}
                                        setCostPlacement={record.setCostPlacement}
                                        setCostPlacementType={record.setCostPlacementType}
                                    />
                                )}
                            </Observer>
                        )
                    }
                },
                sorter: store.recordIdUnderEdit == null ? true : false,
            },
            {
                title: "Pin kód",
                dataIndex: "pin",
                key: "pin",
                render: (_, record: TaxiCardListItem) => {
                    const recordUnderEdit = isUnderEdit(record);
                    const style = recordUnderEdit ? { backgroundColor: selectedRowColor } : {};
                    return {
                        props: { style },
                        children:
                            <PinColumn
                                record={record}
                                isUnderEdit={recordUnderEdit}
                            />
                    }
                },
                sorter: store.recordIdUnderEdit === null ? true : false,
            },
            {
                title: "Aktív",
                dataIndex: "isActive",
                key: "isActive",
                sorter: store.recordIdUnderEdit === null ? true : false,
                render: (isActive: boolean, record: TaxiCardListItem) => {
                    const recordUnderEdit = isUnderEdit(record);
                    const style = recordUnderEdit ? { backgroundColor: selectedRowColor, color: "white" } : {}
                    return {
                        props: { style },
                        children: <span>{isActive ? "Igen" : "Nem"}</span>
                    }
                },
            },
            {
                title: "Rendelve",
                dataIndex: "orderedAt",
                key: "orderedAt",
                sorter: store.recordIdUnderEdit === null ? true : false,
                width: 150,
                render: (date: moment.Moment, record: TaxiCardListItem) => {
                    const recordUnderEdit = isUnderEdit(record);
                    const style = recordUnderEdit ? { backgroundColor: selectedRowColor } : {};
                    return {
                        props: { style },
                        children: <span>{date.format('L LT')}</span>
                    }
                },
            },
        ];

        if (store.showInactive) {
            res.push(
                {
                    title: "Tiltó neve",
                    dataIndex: "prohibitedBy",
                    key: "prohibitedBy",
                    sorter: store.recordIdUnderEdit === null ? true : false,

                },
                {
                    title: "Tiltás oka",
                    dataIndex: "prohibitionReason",
                    key: "prohibitionReason",
                    sorter: store.recordIdUnderEdit === null ? true : false,
                });
        }
        if (props.isSummary) {
            res.push({
                title: "Partner",
                dataIndex: "customerName",
                key: "customerName",
                render: (_: any, row: TaxiCardListItem) => <span>{row.customerName} ({row.customerBpid})</span>,
                sorter: store.recordIdUnderEdit === null ? true : false,
            }, {
                title: "",
                dataIndex: "action",
                key: "action",
                render: (_, record: TaxiCardListItem) => {
                    return (
                        <Space size="middle">
                            <Tooltip title="PIN kód megjelenítése / elrejtése">
                                < Button onClick={() => record.setIsPinVisible()} icon={<EyeOutlined />} />
                            </Tooltip>
                        </Space>
                    );
                }
            });
        }

        if (!props.isSummary) {
            res.push({
                title: "",
                dataIndex: "action",
                key: "action",
                render: (_, record: TaxiCardListItem) => {
                    if (isUnderEdit(record)) {
                        return {
                            props: { style: { backgroundColor: selectedRowColor } },
                            children:
                                <Space size="middle">
                                    <span>
                                        <Typography.Link
                                            onClick={store.saveRecordUnderEdit}
                                            style={{
                                                marginRight: 8,
                                                color: 'white'
                                            }}
                                        >
                                            Mentés
                                        </Typography.Link>
                                        <Popconfirm title="Biztosan elveti a módosításokat?" okText="Igen" cancelText="Nem" onConfirm={() => edit(null)}>
                                            <a style={{ color: 'white' }}>Mégsem</a>
                                        </Popconfirm>
                                    </span>
                                </Space>
                        }
                    }
                    return (
                        <Space size="middle">
                            <Tooltip title="PIN kód megjelenítése / elrejtése">
                                <Button onClick={() => record.setIsPinVisible()} icon={<EyeOutlined />} />
                            </Tooltip>
                            <Tooltip title="Szerkesztés">
                                <Button disabled={record.permanentlyCancelled} onClick={() => edit(record)} icon={<EditOutlined />} />
                            </Tooltip>
                            <Tooltip title="Letiltás / aktiválás">
                                <Button disabled={record.permanentlyCancelled} onClick={() => record.isActive ? store.setProhibitionModalIsVisible([record.id]) : store.toggleProhibitSingledTaxiCardAsync.fireAndForget(record.id)} icon={<StopOutlined />} />
                            </Tooltip>

                            <Tooltip title="Végleges letiltás">
                                <Button disabled={record.permanentlyCancelled} type="primary" danger icon={<DeleteOutlined />} onClick={() => store.openPermanentCancellationTaxiCardDialog(record)} />
                            </Tooltip>
                        </Space>
                    )
                }
            });
        }

        return res;
    }

    const downloadMenu = (
        <Menu>
            <div>
                <Button onClick={() => store.print(PrintOptions.PrintToPdf, false)} icon={<FilePdfOutlined />} style={{ width: "100%" }}>
                    Lista letöltése PDF-ben, PIN kódok nélkül
                </Button>
            </div>
            <div>
                <Button onClick={() => store.print(PrintOptions.PrintToExcel, false)} icon={<FileExcelOutlined />} style={{ width: "100%" }}>
                    Lista letöltése Excelben, PIN kódok nélkül
                </Button>
            </div>
            <div>
                <Button onClick={() => store.print(PrintOptions.PrintToPdf, true)} icon={<FileExcelOutlined />} style={{ width: "100%" }}>
                    Lista letöltése PDF-ben PIN kódokkal
                </Button>
            </div>
            <div>
                <Button onClick={() => store.print(PrintOptions.PrintToExcel, true)} icon={<FileExcelOutlined />} style={{ width: "100%" }}>
                    Lista letöltése Excel-ben PIN kódokkal
                </Button>
            </div>
        </Menu>
    );

    const navigateToCreate = useCallback(() => navigate('new'), [navigate]);

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    return (
        <>

            <PageHeader
                title={<span id="page_title">Taxikártyák</span>}
                extra={
                    !props.isSummary && <Button key="1" type="primary" onClick={navigateToCreate}>
                        Új taxikártya igénylés
                    </Button>
                }
            >
                <Row gutter={16}>
                    <Col xl={10} xs={12}>
                        <TextBox
                            label="Név:"
                            onChange={store.taxiCardFilter.setName}
                            value={store.taxiCardFilter.name}
                            onEnter={store.filterResult}
                        />
                    </Col>
                    <Col xl={10} xs={12}>
                        <TextBox
                            label="Kártyaszám:"
                            onChange={store.taxiCardFilter.setCardNumber}
                            value={store.taxiCardFilter.cardNumber}
                            onEnter={store.filterResult}
                        />
                    </Col>
                    {props.isSummary &&
                        <Col xl={10} xs={12}>
                            <TextBox
                                label="Partner (név vagy BPID)"
                                onChange={store.taxiCardFilter.setCustomer}
                                value={store.taxiCardFilter.customer}
                                onEnter={store.filterResult}
                            />
                        </Col>}
                </Row>
                <Row gutter={16}>
                    <Col xl={20} xs={24}>
                        <CostPlacementTypeSelector
                            showLabels
                            costPlacementType={store.taxiCardFilter.costPlacementType}
                            costPlacementValue={store.taxiCardFilter.costPlacement}
                            onCostPlacementTypeChange={store.taxiCardFilter.setCostPlacementType}
                            onCostplacementChange={store.taxiCardFilter.setCostPlacement}
                            onSearch={store.filterResult}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Checkbox
                            style={{ marginTop: "16px" }}
                            onChange={store.toggleShowInactive}
                            checked={store.showInactive}>
                            Letiltott kártyák megjelenítése
                        </Checkbox>
                    </Col>
                </Row>


                <Space style={{ marginTop: "16px", marginBottom: "16px" }}>
                    <Dropdown.Button style={{ width: "100%" }} onClick={() => store.print(PrintOptions.PrintToPdf, false)} icon={<DownOutlined />} overlay={downloadMenu}>

                        Lista letöltése
                        <DownloadOutlined />

                    </Dropdown.Button>

                    <Button style={{ width: "100%" }} type="primary" onClick={store.filterResult} icon={<FilterOutlined />}>
                        Szűrés
                    </Button>
                </Space>

                {store.selectedTaxiCards.length > 0 && !props.isSummary ? (
                    <Space style={{ marginTop: "-8px", marginBottom: "16px", display: "block" }}>
                        <Button onClick={() => store.isEverySelectedTaxiCardProhibited ? store.toggleProhibitSelectedTaxiCardsAsync.fireAndForget() : store.setProhibitionModalIsVisibleForSelectedTaxiCards()}>
                            {store.isEverySelectedTaxiCardProhibited ? "Kijelöltek aktíválása" : "Kijelöltek letiltása"}
                        </Button>
                    </Space>
                ) : (
                    <div style={{ height: 32 }} />
                )}
                <TableSummaryHeader items={summaryItems} />
                <Table
                    onChange={store.reloadList}
                    pagination={paginationConfig}
                    size="middle"
                    columns={getTaxiCardColumns()}
                    dataSource={store.taxiCards}
                    rowSelection={rowSelection}
                    rowKey="id" />
            </PageHeader>
            <PermanentCancellationTaxiCardModal
                isLoading={store.confirmIsLoading}
                isOpen={store.showPermanentCancellationTaxiCardDialog}
                onOk={() => store.permanentCancellationTaxiCardAsync.fireAndForget()}
                taxiCardIdentifier={store.selectedTaxiCardIdentifierForPermanantlyCancel}
                onCancel={store.cancelPermanentCancellationTaxiCardDialog}
                permanentCancellationFee={store.permanentCancellationFee}
                prohibitionInfo={store.prohibitionInfo!}
            />
            <ProhibitionInfoModal
                prohibitionInfo={store.prohibitionInfo!}
                title="Tiltás információ"
                visible={store.modalIsVisible}
                onOk={store.toggleProhibit}
                confirmLoading={store.confirmIsLoading}
                onCancel={store.cancelModal}
            />
        </>
    );
}

export default observer(TaxiCardList);

