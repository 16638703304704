import { Select, Typography } from "antd"
import { TargetAudience, TargetAudienceEntries } from "Models/TargetAudience";
const { Option } = Select;
const { Text } = Typography;

export interface TargetAudienceSelectBoxProps {
    onChange: (targetAudience: TargetAudience) => void;
    targetAudience: TargetAudience | null;
    style?: React.CSSProperties;
    disabled?: boolean;
    label: string;
}

export function TargetAudienceSelectBox(props: TargetAudienceSelectBoxProps) {
    return (
        <div style={{ width: "100%", ...props.style }}>

            <Text>{props.label}</Text>
            <Select
                disabled={props.disabled}
                style={{ width: "100%" }}
                onChange={props.onChange}
                value={props.targetAudience}
                allowClear
            >
                {TargetAudienceEntries.map(i =>
                    <Option key={i.enumValue} value={i.enumValue}>
                        {i.displayValue}
                    </Option>
                )}
            </Select>
        </div>
    )
} 