import { EnumHelper } from "Util/EnumHelper";

// Copy from Api.ts:
export enum VoucherType {
    None = "None",
    Voucher = "Voucher",
    Card = "Card",
    PhoneNumber = "PhoneNumber",
    AutoAccounted = "AutoAccounted"
}

function getVoucherTypeEntries() {
    return EnumHelper.getEnumEnumerableEntries<VoucherType>(VoucherType, getVoucherTypeDisplayValue);
}

export function getVoucherTypeDisplayValue(value: VoucherType) {

    switch (value) {
        case VoucherType.None: return "Meghiúsult fuvar";
        case VoucherType.Voucher: return "Azonosító szelvény";
        case VoucherType.Card: return "Taxikártya";
        case VoucherType.PhoneNumber: return "Telefonszám";
        case VoucherType.AutoAccounted: return "Központ fizet";
    }
}

export const VoucherTypeEntries = getVoucherTypeEntries();