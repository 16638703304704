
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import ITaxiCardApiAdapter from "Adapters/ITaxiCardApiAdapter";
import { Alert, Button, Col, PageHeader, Row, Select, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { CostPlacementType, CostPlacementTypeEntries } from "Models/CostPlacementType";
import CreatedTaxiCardListItem from "Models/CreatedTaxiCardListItem";
import TaxiCardListItem from "Models/TaxiCardListItem";
import AddressBox from "Pages/CommonControls/AddressBox";
import { CostplacementTypeSelectBox } from "Pages/CommonControls/CostPlacementTypeSelectBox";
import NumberBox from "Pages/CommonControls/NumberBox";
import PinInputBox from "Pages/CommonControls/PinInputBox";
import TextBox from "Pages/CommonControls/TextBox";
import { useTaxiCardsStore } from "Pages/Partner/TaxiCards/TaxiCardsStore";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ValidationStoreProvider } from "Stores/ValidationStore";
import CostPlacementTypeColumn from "../Controls/CostPlacementTypeColumn";
import { useTaxiCardCreationStore, withTaxiCardCreationStore } from "./TaxiCardCreationStore";
const { Title } = Typography;
const { Option } = Select;

export interface ITaxiCardCreationProps {
    taxiCardApiApadter: ITaxiCardApiAdapter;
}

export interface ITaxiCardCreationCoreProps extends ITaxiCardCreationProps {
    partnerId?: number;
}

function TaxiCardCreationCore(props: ITaxiCardCreationProps) {

    const listStore = useTaxiCardsStore();
    const store = useTaxiCardCreationStore();
    const navigate = useNavigate();

    const goBack = useCallback(() => {
        if (store.createdTaxiCards.length > 0) {
            listStore.vLoadAsync.fireAndForget();
        }
        navigate(-1);
    }, [store.createdTaxiCards.length]);

    const TaxiCardCreationColumns: ColumnsType<CreatedTaxiCardListItem> = [
        {
            title: "Név",
            dataIndex: "ownerName",
            key: "ownerName",
            sorter: true,
        },
        {
            title: "Kártyaszám",
            dataIndex: "identifier",
            key: "identifier",
        },
        {
            title: "Költséghely",
            dataIndex: "costPlacementType",
            key: "costPlacementType",
            render: (_, record: CreatedTaxiCardListItem) => {
                return {
                    children:
                        <CostPlacementTypeColumn
                            isUnderEdit={false}
                            costPlacement={record.costPlacement}
                            costPlacementType={record.costPlacementType} />
                }
            },
            sorter: true,
        },
        {
            title: "Pin kód",
            dataIndex: "pin",
            key: "pin",
            sorter: true,
        },
        {
            title: "Aktív",
            dataIndex: "isActive",
            key: "isActive",
            render: (isActive: boolean, record: CreatedTaxiCardListItem) => {
                return <span>{isActive ? "Igen" : "Nem"}</span>
            },
        },
    ];

    return (
        <>
            <PageHeader
                title="Új taxikártya igénylés"
                footer={
                    <Row gutter={16} >
                        <Col xxl={3} xl={4} lg={5} md={6} xs={8}>
                            <Button style={{ width: "100%" }} onClick={goBack}>
                                {store.createdTaxiCards.length === 0 ? "Mégsem" : "Vissza a listára"}
                            </Button>
                        </Col>
                        {store.createdTaxiCards.length === 0 &&
                            <Col xxl={3} xl={4} lg={5} md={6} xs={8}>
                                <Button style={{ width: "100%" }} key="submit" type="primary" loading={store.isLoading} onClick={store.create}>
                                    Igénylés
                                </Button>
                            </Col>
                        }
                    </Row>
                }
            >
                {store.createdTaxiCards.length === 0 && (
                    <ValidationStoreProvider value={store.validationStore}>
                        <Row gutter={16} style={{ marginBottom: "16px" }}>
                            <Col xl={10} xs={12}>
                                <NumberBox
                                    label="Darabszám:"
                                    onChange={store.setAmount}
                                    value={store.amount}
                                    minValue={1}
                                    selectOnFocus
                                />
                            </Col>
                            <Col xl={10} xs={12}>
                                <PinInputBox
                                    showLabel
                                    value={store.pin}
                                    onChange={store.setPin}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col xl={10} xs={12}>
                                <CostplacementTypeSelectBox showLabel costPlacementType={store.costPlacementType} onChange={store.setCostPlacementType} />
                            </Col>
                            <Col xl={10} xs={12}>
                                <TextBox
                                    label="Költséghely:"
                                    disabled={store.costPlacementType !== CostPlacementType.Fix}
                                    value={store.costPlacement}
                                    onChange={store.setCostPlacement}
                                    style={{ width: "100%" }}
                                    isRequired={store.isCostPlacementRequired}
                                    maxLength={128}
                                />
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col xl={20} xs={24}>
                                <Alert
                                    message="Információ"
                                    style={{ margin: "2rem" }}
                                    description={(
                                        <>
                                            Csoportos, nagyobb darabszámú kártya igénylés esetén kérjük kizárólag a darabszám meghatározását, a Költséghely, és PIN mező kitöltése nélkül!<br />
                                            A kártya igénylés gomb megnyomását követően a kártya menüpontban megjelennek az igényelt kártyák adatai, mely felületen a kártyák adataihoz igényük szerint egyenként rögzíthetőek a különböző nevek, költséghelyek, PIN kódok, vagy szükség esetén inaktívvá, aktívvá tehetőek a kártyák.<br />
                                            A kártya igények alapján a kiszállítást a minden héten kedden, és csütörtökön esedékes kiszállításunk alkalmával teljesítjük.
                                        </>
                                    )}
                                    type="info"
                                />
                            </Col>
                        </Row>


                        <Title style={{ marginBottom: "16px", marginTop: "16px" }} level={3}>Adminisztratív egyéb adatok:</Title>
                        <Row gutter={16} >

                            <Col xl={10} xs={12}>
                                <TextBox
                                    label="Igénylő személy neve:"
                                    onChange={store.setRequesterName}
                                    value={store.requesterName}
                                    style={{ width: "100%" }}
                                    maxLength={64}
                                    isRequired
                                    placeholder="Kérjük ne a cég nevét adja meg"
                                />
                            </Col>
                            <Col xl={10} xs={12}>
                                <TextBox
                                    label="Igénylő személy telefonszáma:"
                                    value={store.requesterPhoneNumber}
                                    onChange={store.setRequesterPhoneNumber}
                                    style={{ width: "100%" }}
                                    maxLength={64}
                                    isRequired
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xl={10} xs={12}>
                                <TextBox
                                    label="Egyéb információk, kérések:"
                                    onChange={store.setReferenceNumber}
                                    value={store.referenceNumber}
                                    style={{ width: "100%" }}
                                    maxLength={256}
                                    multiline
                                />
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col xl={20} xs={24}>
                                <Title style={{ marginBottom: "16px", marginTop: "16px" }} level={4}>Szállítási cím:</Title>
                                <AddressBox
                                    isRequired
                                    addressLine={store.addressStore.addressLine}
                                    onAddressLineChange={store.addressStore.setAddressLine}
                                    zipCode={store.addressStore.zipCode}
                                    onZipCodeChange={store.addressStore.setZipCode}
                                    onSettlementChange={store.addressStore.setSettlement}
                                    settlement={store.addressStore.settlement}
                                />
                            </Col>
                        </Row>

                        <Space style={{ marginTop: 8 }}>
                            <Button loading={store.isLoading} onClick={store.getPartnerAddress} >Partner székhely másolása</Button>
                        </Space>
                    </ValidationStoreProvider>
                )}
                {store.createdTaxiCards.length !== 0 && (
                    <>
                        <Title level={3}>Igényelt kártyák</Title>
                        <Table size="middle" columns={TaxiCardCreationColumns} dataSource={store.createdTaxiCards} rowKey="identifier" />
                    </>
                )}
            </PageHeader >
        </>
    );
}

const TaxiCardCoreWithStore = withTaxiCardCreationStore(TaxiCardCreationCore);

export default function TaxiCardCreation(props: ITaxiCardCreationProps) {
    const { partnerId } = useParams();
    return (
        <TaxiCardCoreWithStore {...props} partnerId={isNullOrUndefined(partnerId) ? undefined : parseInt(partnerId!)} />
    )
}