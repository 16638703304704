import { PageHeader, Table, TablePaginationConfig, Button, Dropdown, Menu, Popconfirm } from "antd";
import { ColumnType } from "antd/lib/table";
import { observer } from "mobx-react";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";
import { getFullScreenTableScroll } from "Util/TableHelpers";
import { ICommonFileListProps } from "Pages/Admin/CommonFile/CommonFiles";
import { useCommonFilesStore } from "Pages/Admin/CommonFile/CommonFilesStore";
import CommonFileListItem from "Models/CommonFileListItem";
import { DeleteOutlined, DownloadOutlined, MoreOutlined } from "@ant-design/icons";
import CommonFileDetailModal from "./CommonFileDetail/CommonFileDetailModal";
import { ValidationStoreProvider } from "Stores/ValidationStore";

function CommonFileList(props: ICommonFileListProps) {
    const store = useCommonFilesStore();

    const _paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    }

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    let paginationConfig = _paginationConfig;

    const commonFileColumns: Array<ColumnType<CommonFileListItem>> = [
        {
            title: "Név",
            dataIndex: "name",
            key: "name",
            sorter: true,
        },
        {
            title: "Kategória",
            dataIndex: "category",
            key: "category",
            sorter: true,
        },
        {
            title: "Feltöltő",
            dataIndex: "createdByName",
            key: "createdByName",
            sorter: true
        },
        {
            title: "Feltöltés ideje",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date: moment.Moment) => <span > {date!.format("YYYY.MM.DD HH:mm")}</span>,
            sorter: true
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            width: 100,
            render: (_, record: CommonFileListItem) => {
                return (
                    <Dropdown.Button
                        style={{ width: "100%" }}
                        icon={<MoreOutlined />}
                        overlay={() => dropDownMenu(record)}
                    />

                );
            }
        }
    ];

    const dropDownMenu = (record: CommonFileListItem) => (
        <Menu>
            <Menu.Item>
                <Button onClick={() => store.openDetailModal(false, record)} style={{ width: "100%" }}>
                    Frissítés
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button onClick={() => store.print(record.id, record.name)} icon={<DownloadOutlined />} style={{ width: "100%" }}>
                    Letöltés
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Popconfirm title={`Biztosan törli a ${record.name} dokumentumot?`} okText="Igen" cancelText="Nem" onConfirm={() => store.delete(record.id)}>
                    <Button style={{ width: "100%" }} icon={<DeleteOutlined />}>
                        Törlés
                    </Button>
                </Popconfirm>
            </Menu.Item>
        </Menu >
    );

    const tableScroll = getFullScreenTableScroll(commonFileColumns.reduce((sum, col) => sum + (col.width as number ?? 100), 0));

    return (
        <>
            <PageHeader
                title={<span id="page_title">Dokumentumtár</span>}
                extra={
                    <Button key="newPoll" type="primary" onClick={() => store.openDetailModal(true)}>
                        Új dokumentum
                    </Button>
                }
            >
                <TableSummaryHeader items={summaryItems} />
                <Table
                    onChange={store.reloadList}
                    pagination={paginationConfig}
                    size="middle"
                    columns={commonFileColumns}
                    dataSource={store.commonFiles}
                    scroll={tableScroll}
                    rowKey="id" />
                <ValidationStoreProvider value={store.validationStore}>
                    <CommonFileDetailModal
                        showOverewriteExistCommonFilePopconfirm={store.showOverwriteExistCommonFileConfirmation}
                        onCancelOverewriteExistCommonFilePopconfirm={store.cancelOverWriteExistPopConfirmation}
                        onOverWriteExistCommonFile={store.update}
                        commonFileDetailStore={store.commonFileDetailStore!}
                        isOpen={store.detailModalIsVisible}
                        onCancel={store.cancelDetailModal}
                        onOk={store.save}
                        isLoading={store.confirmIsLoading}
                        categories={store.categories}
                    />
                </ValidationStoreProvider>
            </PageHeader>
        </>
    );
}

export default observer(CommonFileList);

