import moment from "moment";
import InvoiceForPartnerListItem from "./InvoiceForPartnerListItem";

export default class InvoiceSummaryDetails {
    constructor(
        public readonly id: number,
        public readonly identifier: string,
        public readonly createdOn: moment.Moment,
        public readonly fromDate: moment.Moment,
        public readonly toDate: moment.Moment,
        public readonly invoices: InvoiceForPartnerListItem[]
    ) { }
}

