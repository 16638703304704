import { Col, Row } from "antd";
import ReadonlyInput from "Pages/CommonControls/ReadonlyInput";

interface IAddressProps {
    zipCode?: string;
    settlement?: string;
    addressLine?: string;
    showCountryCode?: boolean;
    countryCode?: string;
}

function ReadonlyAddress(props: IAddressProps) {
    return (
        <>
            <Row gutter={[16, 8]}>
                <>{props.showCountryCode && <Col span={6}><ReadonlyInput label="Országkód:" value={props.countryCode} /></Col>}</>
                <Col span={7}><ReadonlyInput label="Ir.Sz:" value={props?.zipCode} /></Col>
                <Col span={props.showCountryCode ? 11 : 17}><ReadonlyInput label="Település:" value={props?.settlement} /></Col>

                <Col span={24}> <ReadonlyInput label="Utca, házszám:" value={props?.addressLine} /></Col>
            </Row>
        </>
    );
}

export default ReadonlyAddress;
