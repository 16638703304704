import { StatementType } from "Models/StatementType";
import moment from "moment";

export class SavedConsent {
    constructor(
        public readonly consentId: number,
        public readonly statementType: StatementType,
        public readonly statementContentHtml: string,
        public readonly createdAt: moment.Moment,
        public readonly items: SavedConsentItem[]
    ) {}
}

export class SavedConsentItem  {
    constructor(
        public readonly statementItemId: number,
        public readonly statementItemDisplayValue: string,
        public readonly isAccepted: boolean,
        public readonly checkedAt: moment.Moment | null,
    ) {}
}

