import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { Select, Typography } from "antd"
import { CostPlacementType, CostPlacementTypeEntries } from "Models/CostPlacementType"

const { Option } = Select;
const { Text } = Typography;

export interface CostplacementTypeSelectBoxProps {
    onChange: (costPlacementType: CostPlacementType) => void;
    costPlacementType: CostPlacementType | null;
    style?: React.CSSProperties;
    allowEmpty?: boolean;
    showLabel?: boolean;
}

export function CostplacementTypeSelectBox(props: CostplacementTypeSelectBoxProps) {
    return (
        <div style={{ width: "100%" }}>
            {props.showLabel && <Text>Költséghely típus:</Text>}
            <Select
                style={{ width: "100%" }}
                onChange={props.onChange}
                value={props.costPlacementType}
                allowClear={props.allowEmpty}
            >
                {CostPlacementTypeEntries.map(i =>
                    <Option key={i.enumValue} value={i.enumValue}>
                        {i.displayValue}
                    </Option>
                )}
            </Select>
        </div>
    )
} 