import { useCallback } from "react";
import { AutoComplete, Typography } from 'antd';
import { BaseOptionType } from "antd/lib/select";

const { Text } = Typography;

export interface IAutoCompleteBoxProps {
    options: BaseOptionType[],
    value: string,
    setValue: (value: string) => void
    style?: React.CSSProperties;
    maxLength?: number;
    label?: string;
    isRequired?: boolean;
}

function AutoCompleteBox(props: IAutoCompleteBoxProps) {

    const filterOption = useCallback((inputValue, option) => {
        return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }, []);

    const onSelect = useCallback((inputValue, option) => {
        const value = option.value;
        props.setValue(value);
    }, [props.setValue]);

    const onChange = useCallback((inputValue: string, option: BaseOptionType | BaseOptionType[]) => {
        props.setValue(inputValue);
    }, [props.setValue])

    return (
        <div style={{ width: "100%" }}>
            <Text>{props.label}{props.isRequired ? "*" : ""}</Text>
            <AutoComplete
                style={props.style}
                searchValue={props.value}
                value={props.value}
                onSelect={onSelect}
                options={props.options}
                filterOption={filterOption}
                onChange={onChange}
                maxLength={props.maxLength}
            />
        </div>
    );
}

export default AutoCompleteBox;