import Address from "./Address";
import TaxNumber from "./TaxNumber";

export class Partner {
    constructor(
        public readonly id: number,
        public readonly bpid: string,
        public readonly bpName: string,
        public readonly taxNumber: TaxNumber | null,
        public readonly groupMemberTaxNumber: TaxNumber | null,
        public readonly address: Address,
        public readonly mailingAddress: Address | null,
        public readonly name: string | null,
        public readonly isPrivatePerson: boolean,
        public cardPermanentCancellationFee: number | null
    ) { }
}

export class PartnerWithEmailPreferences extends Partner {
    constructor(
        id: number,
        bpid: string,
        bpName: string,
        taxNumber: TaxNumber | null,
        groupMemberTaxNumber: TaxNumber | null,
        address: Address,
        mailingAddress: Address | null,
        public readonly invoiceSummaryEmail: string | null,
        public readonly eTaxiContactEmail: string | null,
        public readonly name: string | null,
        public readonly isPrivatePerson: boolean,
        public readonly includeRideDataInInvoiceSummary: boolean,
        public cardPermanentCancellationFee: number
    ) {
        super(
            id,
            bpid,
            bpName,
            taxNumber,
            groupMemberTaxNumber,
            address,
            mailingAddress,
            name,
            isPrivatePerson,
            cardPermanentCancellationFee
        );
    }
}