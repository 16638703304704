import { FilterOutlined } from "@ant-design/icons";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { Button, Checkbox, Col, PageHeader, Row, Table, TablePaginationConfig, Typography } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import Address from "Models/Address";
import TaxiDriver from "Models/TaxiDriver";
import TaxiDriverListItem from "Models/TaxiDriverListItem";
import TaxNumber from "Models/TaxNumber";
import AddressTextBox from "Pages/CommonControls/AddressTextBox";
import DateRangePicker from "Pages/CommonControls/DateRangePicker";
import BooleanFilterSelectBox from "Pages/CommonControls/BooleanFilterSelectBox";
import PageWrapper from "Pages/CommonControls/PageWrapper/PageWrapper";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";
import TextBox from "Pages/CommonControls/TextBox";
import { useMemo } from "react";
import { getFullScreenTableScroll } from "Util/TableHelpers";
import { useTaxiDriversStore, withTaxiDriversStore } from "./TaxiDriverStore";

const { Title } = Typography;

function TaxiDriverList() {
    const store = useTaxiDriversStore();

    const paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    }

    const columns: ColumnsType<TaxiDriverListItem> = [
        {
            title: "BPID",
            dataIndex: "bpid",
            key: "bpid",
            sorter: true,
            width: 100,
            fixed: "left"
        },
        {
            title: "Név",
            dataIndex: "driverName",
            sorter: true,
            key: "driverName",
            width: 200
        },
        {
            title: "Adószám",
            dataIndex: "orderAbleTaxNumber",
            key: "orderAbleTaxNumber",
            render: (value: string, record: TaxiDriverListItem) => <span>{record.taxNumber.toString()}</span>,
            sorter: true,
            width: 150
        },
        {
            title: "Nyilvántatási szám",
            dataIndex: "selfEmployedIdentifier",
            sorter: true,
            key: "selfEmployedIdentifier",
            width: 150
        },
        {
            title: "Számla név",
            dataIndex: "name",
            sorter: true,
            key: "name",
            width: 200
        },
        {
            title: "Székhely",
            dataIndex: "address",
            key: "address",
            render: (address: Address) => <span>{`${address.zipCode} ${address.settlement} ${address.addressLine}`}</span>,
            width: 350
        },
        {
            title: "Postázási cím",
            dataIndex: "mailingAddress",
            key: "mailingAddress",
            render: (address: Address) => address ? <span>{`${address.zipCode} ${address.settlement} ${address.addressLine}`}</span> : <span>Nincs</span>,
            width: 350
        },
        {
            title: "E-mail",
            dataIndex: "email",
            sorter: true,
            key: "email",
            width: 250
        },
        {
            title: "Belépés dátuma",
            dataIndex: "enteredOn",
            key: "enteredOn",
            render: (date: moment.Moment | null) => <span>{isNullOrUndefined(date) ? "" : date?.format('L')}</span>,
            sorter: true,
            width: 100
        },
        store.taxiDriverFilter.showLeavedDrivers ? {
            title: "Kilépés dátuma",
            dataIndex: "leavedOn",
            key: "leavedOn",
            render: (date: moment.Moment | null) => <span>{isNullOrUndefined(date) ? "" : date?.format('L')}</span>,
            sorter: true,
            width: 100
        } : null,
        {
            title: "Szöv.tag",
            dataIndex: "isCooperativeMember",
            key: "isCooperativeMember",
            render: (val: boolean | null) => <span>{isNullOrUndefined(val) ? "-" : (val ? "Igen" : "Nem")}</span>,
            sorter: true,
            width: 80
        },
    ].filter(Boolean) as Array<ColumnType<TaxiDriverListItem>>;

    const tableScroll = getFullScreenTableScroll(columns.reduce((sum, col) => sum + (col.width as number ?? 100), 0));

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    return (
        <>
            <PageHeader title={<span id="page_title">Fuvarozók</span>}>
                <Row gutter={16}>
                    <Col lg={5} md={10} xs={12}>
                        <TextBox
                            label="BPID:"
                            style={{ width: "100%" }}
                            onChange={store.taxiDriverFilter.setBpId}
                            value={store.taxiDriverFilter.bpId}
                            onEnter={store.filterResult}
                        />
                    </Col>
                    <Col lg={5} md={10} xs={12}>
                        <TextBox
                            label="Számla Név:"
                            style={{ width: "100%" }}
                            onChange={store.taxiDriverFilter.setName}
                            value={store.taxiDriverFilter.name}
                            onEnter={store.filterResult}
                        />
                    </Col>
                    <Col lg={5} md={10} xs={12}>
                        <TextBox
                            label="Adószám:"
                            style={{ width: "100%" }}
                            onChange={store.taxiDriverFilter.setTaxNumber}
                            value={store.taxiDriverFilter.taxNumber}
                            onEnter={store.filterResult}
                        />
                    </Col>
                    <Col lg={5} md={10} xs={12}>
                        <BooleanFilterSelectBox
                            label="Szöv. tag:"
                            style={{ width: "100%" }}
                            onChange={(v) => { store.taxiDriverFilter.setIsCooperativeMember(v); store.filterResult(); }}
                            value={store.taxiDriverFilter.isCooperativeMember}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={5} md={10} xs={12}>
                        <TextBox
                            label="Nyilvántatási szám:"
                            style={{ width: "100%" }}
                            onChange={store.taxiDriverFilter.setSelfEmployedIdentifier}
                            value={store.taxiDriverFilter.selfEmployedIdentifier}
                            onEnter={store.filterResult}
                        />
                    </Col>
                    <Col lg={5} md={10} xs={12}>
                        <DateRangePicker
                            label="Belépés dátuma:"
                            from={store.taxiDriverFilter.enteredOnFrom}
                            to={store.taxiDriverFilter.enteredOnTo}
                            onChange={store.taxiDriverFilter.setEnteredOnRange}
                            style={{ width: "100%" }}
                            onEnter={store.filterResult}
                            allowClear
                        />
                    </Col>
                    {store.taxiDriverFilter.showLeavedDrivers && (
                        <Col lg={5} md={10} xs={12}>
                            <DateRangePicker
                                label="Kilépés dátuma:"
                                from={store.taxiDriverFilter.leavedOnFrom}
                                to={store.taxiDriverFilter.leavedOnTo}
                                onChange={store.taxiDriverFilter.setLeavedOnRange}
                                style={{ width: "100%" }}
                                onEnter={store.filterResult}
                                allowClear
                            />
                        </Col>
                    )}
                </Row>
                <AddressTextBox
                    addressLineOnChange={store.taxiDriverFilter.setAddressLine}
                    addressLineValue={store.taxiDriverFilter.addressLine}
                    addressTypeOnChange={store.taxiDriverFilter.setAddressType}
                    addressTypeValue={store.taxiDriverFilter.addressType}
                    settlementOnChange={store.taxiDriverFilter.setSettlement}
                    settlementValue={store.taxiDriverFilter.settlement}
                    zipCodeOnChange={store.taxiDriverFilter.setZipCode}
                    zipCodeValue={store.taxiDriverFilter.zipCode}
                    onEnter={store.filterResult}
                />

                <Checkbox style={{ marginTop: "16px" }} onChange={store.setShowLeavedDrivers} checked={store.taxiDriverFilter.showLeavedDrivers}>Korábban kilépett fuvarozók megjelenítése is</Checkbox>

                <Row gutter={8} style={{ marginTop: "16px", marginBottom: "16px" }}>
                    <Col xxl={3} xl={4} lg={5} md={6} xs={8}>
                        <Button style={{ width: "100%" }} type="primary" onClick={store.filterResult} icon={<FilterOutlined />}>
                            Szűrés
                        </Button>
                    </Col>
                </Row>

                <TableSummaryHeader items={summaryItems} />
                <Table scroll={tableScroll} onChange={store.reloadList} pagination={paginationConfig} size="middle" columns={columns} dataSource={store.taxiDrivers} rowKey="id" />
            </PageHeader>
        </>
    );
}


export default withTaxiDriversStore(TaxiDriverList);