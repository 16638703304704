import React from "react";
import IInvoiceSummaryApiAdapter from "Adapters/IInvoiceSummaryApiAdapter";
import { Button, Col, PageHeader, Row, Space, Table } from "antd";
import { useInvoiceSummaryDetailStore, withInvoiceSummaryDetailStore } from "./InvoiceSummaryDetailStore";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { useParams } from "react-router-dom";
import { InvoiceColumns } from "./InvoiceColumns";
import ReadonlyInput from "Pages/CommonControls/ReadonlyInput";
import Title from "antd/lib/typography/Title";
import IInvoiceApiAdapter from "Adapters/IInvoiceApiAdapter";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";

export interface IInvoiceSummaryDetailProps {
    invoiceSummaryApiApadter: IInvoiceSummaryApiAdapter;
    invoiceApiApadter: IInvoiceApiAdapter;
}

export interface IInvoiceSummaryDetailCoreProps extends IInvoiceSummaryDetailProps {
    partnerId?: number;
    invoiceSummaryId: number;
}

function InvoiceSummaryDetailCore(props: IInvoiceSummaryDetailCoreProps) {

    const store = useInvoiceSummaryDetailStore();

    return store.details && (
        <PageHeader
            title={`Számlaösszesítő: ${store.details.identifier}`}
        >
            <Row>
                <Col xl={10} lg={16} xs={24}>
                    <Row gutter={[16, 8]}>
                        <Col sm={12} xs={24}><ReadonlyInput label="Összesítő azonosító:" value={store.details.identifier} /></Col>
                        <Col sm={12} xs={24}><ReadonlyInput label="Készült:" value={store.details.createdOn.format("L")} /></Col>

                        <Col sm={12} xs={24}><ReadonlyInput label="Időszak kezdete:" value={store.details.fromDate.format("L")} /></Col>
                        <Col sm={12} xs={24}><ReadonlyInput label="Időszak vége:" value={store.details.toDate.format("L")} /></Col>
                    </Row>
                </Col>
                <Col xl={10} lg={16} xs={24} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", paddingLeft: 16, paddingTop: 16 }}>
                    <Space size="middle">
                        <Button onClick={store.downloadPdfAsync.fireAndForget} icon={<FilePdfOutlined />}>Letöltés PDF-ben</Button>
                        <Button onClick={store.downloadExcelAsync.fireAndForget} icon={<FileExcelOutlined />}>Letöltés Excel-ben</Button>
                    </Space>
                </Col>
            </Row>

            <Title level={4} style={{ marginTop: 32 }}>Számlák:</Title>
            <Table pagination={false} size="middle" columns={InvoiceColumns} dataSource={store.details.invoices} rowKey="id" />
        </PageHeader>
    )
}

const InvoiceSummaryDetailCoreWithStore = withInvoiceSummaryDetailStore(InvoiceSummaryDetailCore);

export default function InvoiceSummaryDetail(props: IInvoiceSummaryDetailProps) {
    const { partnerId, invoiceSummaryId } = useParams();
    return (
        <InvoiceSummaryDetailCoreWithStore {...props} partnerId={isNullOrUndefined(partnerId) ? undefined : parseInt(partnerId!)} invoiceSummaryId={parseInt(invoiceSummaryId!)} />
    )
}