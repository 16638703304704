import { DocumentStatus } from "./DocumentStatus";

export default class TrafficLicenseListItem {
    constructor(
        public readonly id: number,
        public readonly bpId: string,
        public readonly name: string,
        public readonly identifier: string | null,
        public readonly validTo: moment.Moment | null,
        public readonly status: DocumentStatus,
        public readonly lastReviewedAt: moment.Moment | null,
        public readonly lastUpdatedAt: moment.Moment | null,
    ) { }
}