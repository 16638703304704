import { createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { action, computed, observable, runInAction } from "mobx";
import InvoiceSummaryFilter from "Models/Filters/InvoiceSummaryFilter";
import InvoiceSummaryForPartnerListItem from "Models/InvoiceSummaryForPartnerListItem";
import { downloadBase64Async } from "Util/FileDownloader";
import { IInvoiceSummaryListCoreProps } from "./InvoiceSummaryList";
import moment from "moment";

export default class InvoiceSummaryListStore extends StoreBase<IInvoiceSummaryListCoreProps> {
    public readonly dateFormat = "YYYY.MM.DD";

    @observable.ref public invoiceSummaries: InvoiceSummaryForPartnerListItem[] = [];
    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    public orderedBy = "";
    public orderDirection = "";

    public invoiceSummaryFilter = new InvoiceSummaryFilter();

    @computed private get filter() {
        return {
            Identifier: this.invoiceSummaryFilter.identifier,
            CreatedOn: this.invoiceSummaryFilter.createdOn,

        };
    }

    public readonly vLoadAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await this.props.invoiceSummaryApiApadter.getListAsync(paging, ordering, this.filter, this.props.partnerId);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.invoiceSummaries = result.invoiceSummaries;
        });
    });

    @action.bound
    public filterResult() {
        this.currentPage = 1;
        this.invoiceSummaryFilter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<InvoiceSummaryForPartnerListItem> | SorterResult<InvoiceSummaryForPartnerListItem>[], extra: TableCurrentDataSource<InvoiceSummaryForPartnerListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (Array.isArray(sorter)) {
            this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
            this.orderDirection = sorter[0].order!;
        } else {
            this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
            this.orderDirection = sorter.order!;
        }
        this.vLoadAsync.fireAndForget();
    }

    public readonly downloadPdfAsync = this.async(async (invoiceSummary: InvoiceSummaryForPartnerListItem) => {
        const result = await this.props.invoiceSummaryApiApadter.getPdfAsync(invoiceSummary.id, this.props.partnerId);
        await downloadBase64Async(result, "application/pdf", `eTaxi_szamlaosszesito__${invoiceSummary.identifier.replace(/\\\//gi, "_")}.pdf`);
    });

    public readonly downloadExcelAsync = this.async(async (invoiceSummary: InvoiceSummaryForPartnerListItem) => {
        const result = await this.props.invoiceSummaryApiApadter.getExcelAsync(invoiceSummary.id, this.props.partnerId);
        if (invoiceSummary.createdOn.isSameOrBefore(moment("2022-12-31"))) {
            await downloadBase64Async(result, "application/vnd.ms-excel", `eTaxi_szamlaosszesito__${invoiceSummary.identifier.replace(/\\\//gi, "_")}.xls`);
        }
        else {
            await downloadBase64Async(result, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `eTaxi_szamlaosszesito__${invoiceSummary.identifier.replace(/\\\//gi, "_")}.xlsx`);
        }
    });
}



const { ContextComponent: InvoiceSummaryListStoreContext, StoreProvider: InvoiceSummaryListStoreProvider, useStore: useInvoiceSummaryListStore, withStore: withInvoiceSummaryListStore } =
    createStoreProvider<IInvoiceSummaryListCoreProps, InvoiceSummaryListStore>(() => new InvoiceSummaryListStore());

export {
    InvoiceSummaryListStoreContext,
    InvoiceSummaryListStoreProvider,
    useInvoiceSummaryListStore,
    withInvoiceSummaryListStore
};