import { IQueryParameters, isNullOrUndefined } from "@eblsoft/react-toolkit";
import { IAddress, ICreateTaxiCardRequest, IPermanentCancellationTaxiCardRequest, ITaxiCard, IToggleTaxiCardProhibitionRequest, IUpdateTaxiCardRequest } from "Adapters/Api/Api.g";
import ITaxiCardApiAdapter from "Adapters/ITaxiCardApiAdapter";
import { mapAddressDtoToDomainModel, mapCreatedTaxiCardListItemDtoToDomainModel, mapTaxiCardListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import { CostPlacementType } from "Models/CostPlacementType";
import Ordering from "Adapters/QueryHelpers/Ordering";
import { PrintOptions } from "Models/PrintOptions";
import Paging from "Adapters/QueryHelpers/Paging";
import Address from "Models/Address";
import TaxiCardListItem from "Models/TaxiCardListItem";

class AdminPartnerTaxiCardAdapter extends ProtectedApiAdapterBase implements ITaxiCardApiAdapter {

    private readonly mainRoute = "api/web/admin/Partner";

    public getTaxiCardListAsync(paging: Paging, ordering: Ordering, filter: any, partnerId: number): Promise<{ totalCount: number, taxiCards: TaxiCardListItem[], permanentCancellationFee: number }> {

        const queryParameters: IQueryParameters = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            ...filter
        }
        return this
            .httpGet<{ totalCount: number, taxiCards: TaxiCardListItem[], permanentCancellationFee: number }>(this.mainRoute, [partnerId!.toString(), "TaxiCards"], queryParameters)
            .map(paginatedResult => {
                return {
                    totalCount: paginatedResult.totalCount,
                    taxiCards: paginatedResult.taxiCards.map((v: any) => {
                        return mapTaxiCardListItemDtoToDomainModel(v);
                    }),
                    permanentCancellationFee: paginatedResult.cardPermanentCancellationFee
                };
            }
            ).performOperationAsync();
    }

    public updateTaxiCardAsync(taxiCard: TaxiCardListItem, partnerId: number) {
        const body: IUpdateTaxiCardRequest = {
            id: taxiCard.id,
            ownerName: taxiCard.ownerName,
            pin: taxiCard.pin,
            costPlacementType: taxiCard.costPlacementType,
            costPlacement: taxiCard.costPlacement,
            isActive: taxiCard.isActive,
        };
        return this.httpPost<boolean>(
            this.mainRoute,
            [partnerId.toString(), "TaxiCards", "update"],
            body
        ).map((res) => {
            return true;
        }).performOperationAsync();
    }

    public createTaxiCardsAsync(
        amount: number,
        costPlacementType: CostPlacementType,
        costPlacement: string = "",
        pin: string,
        requesterName: string,
        requesterPhoneNumber: string,
        referenceNumber: string,
        deliveryAddress: IAddress,
        partnerId: number): Promise<{ totalCount: number, taxiCards: TaxiCardListItem[] }> {
        const body: ICreateTaxiCardRequest = {
            amount,
            costPlacementType,
            costPlacement,
            pin,
            requesterName,
            referenceNumber,
            requesterPhoneNumber,
            deliveryAddress
        };
        return this.httpPost<{ totalCount: number, taxiCards: TaxiCardListItem[] }>(this.mainRoute, [partnerId!.toString(), "TaxiCards", "create"], body).map(paginatedResult => {
            return {
                totalCount: paginatedResult.totalCount,
                taxiCards: paginatedResult.taxiCards.map((v: any) => {
                    return mapCreatedTaxiCardListItemDtoToDomainModel(v);
                })
            };
        }
        ).performOperationAsync();
    }

    public toggleProhibitTaxiCardAsync(ids: number[], prohibitionReason?: string, userName?: string, partnerId?: number): Promise<boolean> {
        const body: IToggleTaxiCardProhibitionRequest = {
            ids,
            prohibitionReason: prohibitionReason ?? "",
            userName: userName ?? ""
        };
        return this.httpPost<boolean>(this.mainRoute, [partnerId!.toString(), "TaxiCards", "toggleProhibition"], body).map(result => {
            return result;
        }).performOperationAsync();
    }

    public permanentCancellationTaxiCardAsync(taxiCardId: number, prohibitionReason?: string, userName?: string, partnerId?: number): Promise<boolean> {
        const body: IPermanentCancellationTaxiCardRequest = {
            taxiCardId,
            prohibitionReason: prohibitionReason ?? "",
            userName: userName ?? ""
        };
        return this.httpPost<boolean>(this.mainRoute, [partnerId!.toString(), "TaxiCards", "permanentCancellation"], body).map(result => {
            return result;
        }).performOperationAsync();
    }

    public printTaxiCardsAsync(ordering: Ordering, filter: any, printOption: PrintOptions, showPin: boolean, partnerId: number) {
        const queryParameters: IQueryParameters = {
            printOption,
            showPin,
            OrderBy: ordering.orderBy,
            OrderAsc: ordering.orderAsc,
            ...filter
        };
        return this.httpGet<string>(this.mainRoute, [partnerId!.toString(), "TaxiCards", "print"], queryParameters).map(result => {
            return result;
        }).performOperationAsync();
    }

    public getPartnerAddressAsync(partnerId: number): Promise<Address | null> {
        return this.httpGet<Address | null>(this.mainRoute, [partnerId!.toString(), "TaxiCards", "PartnerAddress"])
            .map(result => isNullOrUndefined(result.address) ? null : mapAddressDtoToDomainModel(result.address)).performOperationAsync();
    }
}

export default new AdminPartnerTaxiCardAdapter();