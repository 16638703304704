import { IQueryParameters } from "@eblsoft/react-toolkit";
import AdminPartnerRideAdapter from "Adapters/AdminAdapter/AdminPartnerRideAdapter";
import { IAccountedRideDto, IConnectOfflineRideManuallyRequest, IConnectOfflineRideToRideRequest, IGetAccountedRidesRequest, IGetAccountedRidesResponse, IGetFailedRidesResponse, IGetWaitingRidesListRequest, IFailedRideReviewDto, IRideDto, IUpdateAccountedRideRequest, PrintOptions, IAuditInfoDto } from "Adapters/Api/Api.g";
import IRideApiAdapter from "Adapters/IRideApiAdapter";
import { mapAccountedRideDetailToDomainModel, mapAccountedRideListItemToDomainModel, mapAddressDtoToDomainModel, mapFailedRideDetailsForReviewToDomainModel, mapFailedRideToDomainModel, mapRideListFilterToDto, mapRideListItemDtoToDomain, mapTaxNumberDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import AccountedRide from "Models/AccountedRide";
import AccountedRideDetails from "Models/AccountedRideDetails";
import Customer from "Models/Customer";
import FailedRide from "Models/FailedRide";
import FailedRideDetailsForReview from "Models/FailedRideDetailsForReview";
import RideFilter from "Models/Filters/RideFilter";
import Ride from "Models/Ride";
import RideLitsItem from "Models/RideListItem";
import moment from "moment";

class AdminRideAdapter extends ProtectedApiAdapterBase implements IRideApiAdapter {

    private readonly mainRoute = "api/web/admin/ride";


    public getListAsync(paging: Paging, ordering: Ordering, filter: any) {
        const request: IGetAccountedRidesRequest = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            ...filter
        }

        return this.httpPost<{ totalCount: number, rides: AccountedRide[] }>(this.mainRoute, undefined, request).map((paginatedResult: IGetAccountedRidesResponse) => {
            return {
                totalCount: paginatedResult.totalCount,
                rides: paginatedResult.accountedRides.map((i: any) => {
                    return mapAccountedRideListItemToDomainModel(i);
                })
            };
        }
        ).performOperationAsync();
    }

    public printReceiptAsync(accountedRideId: number) {
        return this.httpGet<string>(this.mainRoute, [accountedRideId.toString(), "printReceipt"]).map(result => {
            return result;
        }).performOperationAsync();
    }

    public printRidesAsync(ordering: Ordering, filter: any, printOption: PrintOptions) {
        const request = {
            printOption,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            ...filter
        };
        return this.httpGet<string>(this.mainRoute, ["print"], request).map(result => {
            return result;
        }).performOperationAsync();
    }

    public getOfflineListAsync(PageNumber: number, PageSize: number, OrderBy: string, OrderAsc: boolean) {
        const queryParameters: IQueryParameters = {
            PageNumber,
            PageSize,
            OrderBy,
            OrderAsc,
        }
        return this.httpGet<{ totalCount: number, rides: AccountedRide[] }>(this.mainRoute, ["offline"], queryParameters).map((paginatedResult: IGetAccountedRidesResponse) => {
            return {
                totalCount: paginatedResult.totalCount,
                rides: paginatedResult.accountedRides.map((i: any) => {
                    return mapAccountedRideListItemToDomainModel(i);
                })
            };
        }
        ).performOperationAsync();
    }

    public getFailedListAsync(PageNumber: number, PageSize: number, OrderBy: string, OrderAsc: boolean) {
        const queryParameters: IQueryParameters = {
            PageNumber,
            PageSize,
            OrderBy,
            OrderAsc,
        }
        return this.httpGet<{ totalCount: number, rides: FailedRide[] }>(this.mainRoute, ["failed"], queryParameters).map((paginatedResult: IGetFailedRidesResponse) => {
            return {
                totalCount: paginatedResult.totalCount,
                rides: paginatedResult.failedRides.map((i: any) => {
                    return mapFailedRideToDomainModel(i);
                })
            };
        }
        ).performOperationAsync();
    }

    public reviewFailedRideAsync(
        failedRideId: number,
        isApproved: boolean,
        partnerId: number | null,
        reviewerComment: string | null,
        grossPrice: number,
        costPlacement: string | null,
        reviewedBy: string,
        partnerAllowedBy: string | null
    ) {
        const req: IFailedRideReviewDto = {
            failedRideId,
            isApproved,
            partnerId,
            reviewerComment,
            grossPrice: grossPrice.toString(),
            costPlacement,
            partnerAllowedBy,
            reviewedBy
        };
        return this.httpPost(this.mainRoute, ["failed", "review"], req).performOperationAsync();
    }

    public getFailedRideDetailsForReviewAsync(id: number) {
        return this.httpGet<FailedRideDetailsForReview>(this.mainRoute, ["failed", id.toString(), "review"])
            .map(mapFailedRideDetailsForReviewToDomainModel)
            .performOperationAsync();
    }

    public getOfflineDetailAsync(id: number): Promise<AccountedRideDetails> {
        return this.httpGet<AccountedRideDetails>(this.mainRoute, ["offline", id.toString()]).map((response: IAccountedRideDto) => {
            return mapAccountedRideDetailToDomainModel(response);
        }
        ).performOperationAsync();
    }

    public getWaitingRidesAsync(driverId: number, paging: Paging, ordering: Ordering, filter: RideFilter): Promise<{ totalCount: number, rides: RideLitsItem[] }> {
        const request: IGetWaitingRidesListRequest = {
            taxiDriverId: driverId,
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapRideListFilterToDto(filter)
        }

        return this.httpPost<{ totalCount: number, rides: [] }>(this.mainRoute, ["waiting"], request).map(paginatedResult => {
            return {
                totalCount: paginatedResult.totalCount,
                rides: paginatedResult.rides.map((i: any) => {
                    return mapRideListItemDtoToDomain(i);
                })
            }
        }).performOperationAsync();
    }

    public connectOfflineRideToRideAsync(offlineRideId: number, rideId: number) {
        const body: IConnectOfflineRideToRideRequest = {
            rideToConnectId: rideId
        };
        return this.httpPost(this.mainRoute, ["offline", offlineRideId.toString(), "connectRide"], body).performOperationAsync();
    }

    public connectOfflineRideManualAsync(offlineRideId: number, rideExternalId: string, additionalInfo: string) {
        const body: IConnectOfflineRideManuallyRequest = {
            rideExternalId,
            additionalInfo
        };
        return this.httpPost(this.mainRoute, ["offline", offlineRideId.toString(), "connectManual"], body).performOperationAsync();
    }

    public deleteOfflineRideManualAsync(offlineRideId: number, modifierName: string, reason: string | null) {
        const body: IAuditInfoDto = {
            modifierName,
            reason
        };
        return this.httpDelete(this.mainRoute, ["offline", offlineRideId.toString()], body).performOperationAsync();
    }

    public updateAsync(id: number, additionalInfo: string, partnerId: number): Promise<boolean> {
        const body: IUpdateAccountedRideRequest = {
            id: id,
            additionalInfo: additionalInfo,
        }
        return this.httpPost<boolean>(
            this.mainRoute, ["update"], body
        ).map((res) => {
            return true;
        })
            .performOperationAsync();
    }
}


export default new AdminRideAdapter();