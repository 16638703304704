import { EnumHelper } from "Util/EnumHelper";

// Copy from Api.ts:
export enum CostPlacementType {
    None = "None",
    Fix = "Fix",
    FilledOnUse = "FilledOnUse"
}

function getCostPlacementTypeEntries() {
    return EnumHelper.getEnumEnumerableEntries<CostPlacementType>(CostPlacementType, getCostPlacementTypeDisplayValue);
}

export function getCostPlacementTypeDisplayValue(value: CostPlacementType) {

    switch(value) {
        case CostPlacementType.FilledOnUse: return "Utazáskor kötelező megadni";
        case CostPlacementType.Fix: return "Központilag megadva";
        case CostPlacementType.None: return "Nincs";
    }
}

export const CostPlacementTypeEntries = getCostPlacementTypeEntries();