import Config from "Config";
import { makeAutoObservable, observable, runInAction } from "mobx";
import NavigationStore from "Stores/NavigationStore";
import User from "Stores/User";
import { decodeBase64UrlToString, encodeBase64FromString, encodeBase64UrlFromBytes } from "Util/Base64Url";
import { getRandomString } from "Util/RandomString";
import { sha256Async } from "Util/Sha256";

class AuthStore {

    @observable.ref public user: User | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    public async startupAsync(): Promise<"redirecting-to-login" | "ok"> {

        if (window.location.pathname === "/signin") {

            const codeVerifier = sessionStorage.getItem("cv") ?? "";
            sessionStorage.removeItem("cv");

            const params = new URLSearchParams(window.location.search);

            const response = await fetch(`${Config.settings.oauth.issuerHost.replace(/\/$/gi, "")}/connect/token`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: new URLSearchParams({
                    "grant_type": "authorization_code",
                    "code": params.get("code")!,
                    "client_id": Config.settings.oauth.clientId,
                    "code_verifier": codeVerifier,
                    "redirect_uri": `${window.location.origin}/signin`
                })
            });

            const responseBody = await response.json();

            if (!responseBody.access_token) {
                throw new Error("Cannot get access_token.");
            }

            AccessTokenStore.instance.set(responseBody.access_token);

            if (Config.isDevelopment) {
                localStorage.setItem("DEV_access_token", responseBody.access_token);
            }

            const state = params.get("state");

            if (state) {
                const redirectTo = decodeBase64UrlToString(state);
                NavigationStore.navigateTo(redirectTo, true);
            } else {
                NavigationStore.navigateToHome(true);
            }

            const identityTokenPayload = JSON.parse(decodeBase64UrlToString(responseBody.id_token.split(".")[1]));

            runInAction(() => {
                this.user = new User(identityTokenPayload.name, Array.isArray(identityTokenPayload.role) ? identityTokenPayload.role : [identityTokenPayload.role]);
            });

            return "ok";

        } else {

            const codeVerifier = getRandomString(60);
            const codeVerifierSha256 = await sha256Async(codeVerifier);
            const codeVerifierSha256Base64url = encodeBase64UrlFromBytes(codeVerifierSha256);

            sessionStorage.setItem("cv", codeVerifier);
            const state = encodeBase64FromString(window.location.pathname + window.location.search);

            const data = new URLSearchParams({
                "response_type": "code",
                "client_id": Config.settings.oauth.clientId,
                state,
                "redirect_uri": `${window.location.origin}/signin`,
                "code_challenge": codeVerifierSha256Base64url,
                "code_challenge_method": "S256",
                "scope": [...Config.settings.oauth.scopes, "openid"].join(" ")
            });
            window.location.replace(`${Config.settings.oauth.issuerHost.replace(/\/$/gi, "")}/connect/authorize?${data.toString()}`);
            return "redirecting-to-login";
        }
    }

    public async getAccessTokenAsync() {
        return AccessTokenStore.instance.get();
    }
}

class AccessTokenStore {

    public static instance = new AccessTokenStore();
    public static factory = () => AccessTokenStore.instance;

    private cache: string | null = null;

    public set(token: string) {
        this.cache = token;
    }

    public clear() {
        this.cache = null;
    }

    public get(): string {
        return this.cache!;
    }
}

export default new AuthStore();