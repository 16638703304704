import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import Customer from "Models/Customer";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import RideLitsItem from "Models/RideListItem";
import Address from "Models/Address";

export const RideListColumns: ColumnsType<RideLitsItem> = [
    {
        title: "Rendelésszám",
        dataIndex: "externalId",
        key: "externalId",
        sorter: true,
        width: 60,
    },
    {
        title: "Időpont",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (date: moment.Moment) => <span>{date.format("L LT")}</span>,
        sorter: true,
        width: 100,
    },
    {
        title: "Név",
        dataIndex: "name",
        key: "name",
        sorter: true,
        width: 150,
    },
    {
        title: "Cím",
        dataIndex: "address",
        key: "address",
        render: (address: Address) => <span>{`${address.zipCode} ${address.settlement} ${address.addressLine}`}</span>,
        width: 200,
    },
    {
        title: "Központ fizet",
        dataIndex: "isAutoAccounted",
        key: "isAutoAccounted",
        render: (value: boolean) => <span>{value ? "Igen" : "Nem"}</span>,
        sorter: true,
        width: 80,
    },
    {
        title: "Telefonszám",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        sorter: true,
        width: 100,
    },
    {
        title: "Megjegyzés",
        dataIndex: "additionalInfo",
        key: "additionalInfo",
        sorter: true,
        width: 150,
    }
];