import { IDocumentAuthResponse, IGetAdminDocumentExcelRequest, IGetDocumentListRequest, IGetDocumentListResponse, IInitTfaResponse } from "Adapters/Api/Api.g";
import { mapAdminDocumentListFilterToDto, mapAdminDocumentListItemDtoToDomainModel, mapSimpleDocumentDtoToDomainModel, mapSimpleDocumentListFilterToDto as mapSimpleDocumentListFilterToDto, mapSimpleDocumentListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";

import SimpleDocumentFilter from "Models/Filters/SimpleDocumentFilter";
import SimpleDocument from "Models/SimpleDocument";

import ITfaInitData from "Models/ITfaInitData";
import { AdminDocumentListItem } from "Models/AdminDocumentListItem";
import AdminDocumentFilter from "Models/Filters/AdminDocumentFilter";
import { decodeBase64ToString } from "Util/Base64Url";


class AdminDocumentAdapter extends ProtectedApiAdapterBase {
    private readonly mainRoute = "api/web/admin/document";

    public getListAsync(paging: Paging, ordering: Ordering, filter: AdminDocumentFilter): Promise<{ totalCount: number, documents: AdminDocumentListItem[] }> {
        const request: IGetDocumentListRequest = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapAdminDocumentListFilterToDto(filter)
        }

        return this.httpPost<{ totalCount: number, documents: AdminDocumentListItem[] }>(this.mainRoute, undefined, request).map((paginatedResult: IGetDocumentListResponse) => {
            return {
                totalCount: paginatedResult.totalCount,
                documents: paginatedResult.items.map((i: any) => {
                    return mapAdminDocumentListItemDtoToDomainModel(i);
                }),
            };
        }).performOperationAsync();
    }

    public getExcelAsync(ordering: Ordering, filter: AdminDocumentFilter) {
        const request: IGetAdminDocumentExcelRequest = {
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapAdminDocumentListFilterToDto(filter),
        };
        return this.httpPost<string>(this.mainRoute, ["excel"], request).map(result => {
            return result;
        }).performOperationAsync();
    }

    public hasTfaAsync() {
        return this.httpGet<boolean>(`${this.mainRoute}/hasTfa`).map((res: boolean) => {
            return res;
        }).performOperationAsync();
    }

    public createTfaAsync() {
        return this.httpPost<ITfaInitData>(`${this.mainRoute}/createTfa`).map((res: IInitTfaResponse) => {
            return {
                manualCode: res.manualCode,
                qrUrl: res.qrUrl,
            };
        }).performOperationAsync();
    }

    public getTfaAsync(code: string) {
        return this.httpPost<ITfaInitData>(`${this.mainRoute}/getTfa`, undefined, { code }).map((res: IInitTfaResponse) => {
            return {
                manualCode: res.manualCode,
                qrUrl: res.qrUrl,
            };
        }).performOperationAsync();
    }

    public async authorizeAsync(code: string): Promise<boolean> {
        const token = await this.httpPost<string | null>(`${this.mainRoute}/authorize`, undefined, { code }).map((res: IDocumentAuthResponse) => {
            return res.token;
        }).performOperationAsync();

        if (token == null) {
            return false;
        }

        sessionStorage.setItem("docrevtoken", token);

        return true;
    }

    public getDocumentReviewToken(): string | null {
        const token = sessionStorage.getItem("docrevtoken") ?? null;
        if (token == null) return null;

        const parts = token.split(".");
        const payload = JSON.parse(decodeBase64ToString(parts[1]));
        if (Date.now() >= payload.exp * 1000) {
            sessionStorage.removeItem("docrevtoken");
            return null;
        }
        return token;
    }
}

export default new AdminDocumentAdapter();