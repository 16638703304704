import { observer } from "mobx-react";
import { getCostPlacementTypeDisplayValue } from "Models/CostPlacementType";
import PhoneNumber from "Models/PhoneNumber";
import { getTaxiCardOrderStatusDisplayValue, TaxiCardOrderStatus } from "Models/TaxiCardOrderStatus";
import { CostPlacementTypeSelector } from "Pages/CommonControls/CostPlacementTypeSelector";
import { useMemo } from "react";
import { TaxiCardOrderStatusSelectBox } from "./TaxiCardOrderStatusSelectBox";

export interface ITaxiCardOrderStatusColumnProps {
    status: TaxiCardOrderStatus,
    onChange: (status: TaxiCardOrderStatus) => void;
    isUnderEdit: boolean;
}

function TaxiCardOrderStatusColumn(props: ITaxiCardOrderStatusColumnProps) {

    const value = useMemo(() => {
        let res = getTaxiCardOrderStatusDisplayValue(props.status);
        return res;
    }, [props.onChange, props.status, props.isUnderEdit]);
    return (
        <>
            {props.isUnderEdit ?
                <TaxiCardOrderStatusSelectBox
                    taxiCardOrderStatus={props.status}
                    onChange={props.onChange}
                /> :
                <span>{value}</span>
            }
        </>
    )
}

export default observer(TaxiCardOrderStatusColumn);