import { emptyString, isNullOrUndefined } from "@eblsoft/react-toolkit";
import { Input, InputNumber, Typography } from "antd";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ValidationMessages } from "StaticResources/ValidationMessages";

const { Text } = Typography;

export interface INumberBoxProps {
    onChange: (value: number | null) => void;
    value: number | null;
    disabled?: boolean;
    style?: React.CSSProperties;
    maxValue?: number;
    minValue?: number;
    multiple?: boolean;
    inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
    addonAfter?: React.ReactNode;
    step?: number;
    onEnter?: () => void;
    onValidate?: (value: number | null) => (string | null);
    selectOnFocus?: boolean;
    label?: string;
    isReadOnly?: boolean;
    isHighlighted?: boolean;
    id?: string;
}

function NumberBox(props: INumberBoxProps) {
    const { value, onChange } = props;
    const [error, setError] = useState<string | null>(null);
    const ref = useRef();

    const setValue = useCallback((inputValue: number | null) => {
        onChange(inputValue);
        validate(inputValue);
    }, [onChange]);

    const validate = useCallback((value: number | null) => {

        if (!isNullOrUndefined(props.onValidate)) {
            setError(props.onValidate!(value));
        }
        else {
            setError(null);
        }

    }, [props.onValidate, props.disabled, value]);

    const keyPressed = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            props.onEnter?.();
        }
    }, [props.onEnter]);

    useEffect(() => {
        setError(null);
    }, [props.disabled]);

    const focus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        if (props.selectOnFocus) {
            e.target.select();
        }
    }, [props.selectOnFocus]);

    const color = useMemo(() => {
        if (props.isReadOnly) {
            return "#f3f3f3";
        }

        if (props.isHighlighted) {
            return "#fdb813";
        }

        return props.style?.backgroundColor ?? "#ffffff";
    }, [props.isReadOnly, props.isHighlighted, props.style?.backgroundColor]);

    return (
        <div style={{ width: "100%", ...props.style }}>
            <Text>{props.label}</Text>
            <InputNumber
                inputMode={props.inputMode}
                multiple={props.multiple}
                min={props.minValue ?? 0}
                max={props.maxValue}
                style={{ width: "100%", backgroundColor: color }}
                disabled={props.disabled}
                onChange={setValue}
                value={value ?? 0}
                addonAfter={props.addonAfter}
                step={props.step}
                onKeyPress={keyPressed}
                onFocus={focus}
                id={props.id}
            />
            {error && !props.disabled && <Text type="danger">{error}</Text>}
        </div>
    )
}

export default observer(NumberBox);