import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { Select, Typography } from "antd"
import { AddressType, AddressTypeEntries } from "Models/AddressType"
const { Option } = Select;

const { Text } = Typography;

export interface AddressTypeSelectBoxProps {
    onChange: (AddressType: AddressType) => void;
    AddressType: AddressType | null;
    style?: React.CSSProperties;
    allowEmpty?: boolean;
    label?: string;

}

export function AddressTypeSelectBox(props: AddressTypeSelectBoxProps) {
    return (
        <div style={{ width: "100%" }}>
            <Text>{props.label}</Text>
            <Select

                style={{ width: "100%" }}
                onChange={props.onChange}
                value={props.AddressType}
                allowClear={props.allowEmpty}
            >
                {AddressTypeEntries.map(i =>
                    <Option key={i.enumValue} value={i.enumValue}>
                        {i.displayValue}
                    </Option>
                )}
            </Select>
        </div>
    )
} 