import { createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import AdminRideAdapter from "Adapters/AdminAdapter/AdminRideAdapter";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { action, observable, runInAction } from "mobx";
import AccountedRide from "Models/AccountedRide";
import { IOfflineRideListProps } from "./OfflineRideList";

export default class OfflineRideListStore extends StoreBase<IOfflineRideListProps> {
    @observable.ref public rides: AccountedRide[] = [];
    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    public orderedBy = "";
    public orderDirection = "";

    public readonly vLoadAsync = this.async(async () => {
        const result = await AdminRideAdapter.getOfflineListAsync(this.currentPage, this.pageSize, this.orderedBy, this.orderDirection === "ascend");

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.rides = result.rides;
        });
    });

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<AccountedRide> | SorterResult<AccountedRide>[], extra: TableCurrentDataSource<AccountedRide>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (Array.isArray(sorter)) {
            this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
            this.orderDirection = sorter[0].order!;
        } else {
            this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
            this.orderDirection = sorter.order!;
        }
        this.vLoadAsync.fireAndForget();
    }
}

const { ContextComponent: OfflineRideListStoreContext, StoreProvider: OfflineRideListStoreProvider, useStore: useOfflineRideListStore, withStore: withOfflineRideListStore } = createStoreProvider(() => new OfflineRideListStore());

export {
    OfflineRideListStoreContext,
    OfflineRideListStoreProvider,
    useOfflineRideListStore,
    withOfflineRideListStore
};