import { createStoreProvider, isNullOrUndefined, StoreBase } from "@eblsoft/react-toolkit";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { action, computed, observable, runInAction } from "mobx";
import Address from "Models/Address";
import AddressStore from "Models/AddressStore";
import Answer from "Models/Answer";
import { CostPlacementType } from "Models/CostPlacementType"
import CreatedTaxiCardListItem from "Models/CreatedTaxiCardListItem";
import VoteFilter from "Models/Filters/VoteFilter";
import Poll from "Models/Poll";
import PollDistribution from "Models/PollDistribution";
import TaxiCardListItem from "Models/TaxiCardListItem";
import VoteListItem from "Models/VoteListItem";
import NotificationStore from "Stores/NotificationStore";
import { ValidationStore } from "Stores/ValidationStore";
import { IVotesCoreProps } from "./Votes";
import { PrintOptions } from "Models/PrintOptions";
import moment from "moment";
import { downloadBase64Async } from "Util/FileDownloader";

export default class VotesStore extends StoreBase<IVotesCoreProps> {
    @observable.ref public votes: VoteListItem[] = [];
    @observable.ref public poll: Poll | null = null;
    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    @observable.ref public pollDistribution: PollDistribution | null = null;
    public orderedBy = "bpId";
    public orderDirection = "ascend";

    public voteFilter = new VoteFilter();

    @action.bound
    public filterResult() {
        this.currentPage = 1;
        this.voteFilter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    public readonly vLoadAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await this.props.pollApiAdapter.getVoteListAsync(this.props.pollId!, paging, ordering, this.voteFilter);

        runInAction(() => {
            this.recordNum = result.votesTotalCount;
            this.votes = result.votes;
            this.poll = result.poll;
            this.pollDistribution = result.pollDistribution;
        });

    });

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<VoteListItem> | SorterResult<VoteListItem>[], extra: TableCurrentDataSource<VoteListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (!sorter) {
            this.orderedBy = "bpId";
        } else {
            if (Array.isArray(sorter)) {
                this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
                this.orderDirection = sorter[0].order!;
            } else {
                this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
                this.orderDirection = sorter.order!;
            }
        }
        this.vLoadAsync.fireAndForget();
    }

    public readonly printVotesAsync = this.async(async (option: PrintOptions) => {
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await this.props.pollApiAdapter.printVotesAsync(this.props.pollId!, ordering, this.voteFilter, option);
        const date = moment().format("YYYY_MM_DD");
        switch (option) {
            case PrintOptions.PrintToExcel:
                await downloadBase64Async(result, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `szavazatok_${date}.xlsx`);
                return;
            default:
                await downloadBase64Async(result, "application/pdf", `szavazatok_${date}.pdf`);
        }
    });

    public readonly printPollAsync = this.async(async () => {
        const result = await this.props.pollApiAdapter.printPollAsync(this.props.pollId!, PrintOptions.PrintToPdf);
        const date = moment().format("YYYY_MM_DD");
        await downloadBase64Async(result, "application/pdf", `szavazás_${date}.pdf`);
    });
}



const { ContextComponent: VotesStoreContext, StoreProvider: VotesStoreProvider, useStore: useVotesStore, withStore: withVotesStore } =
    createStoreProvider<IVotesCoreProps, VotesStore>(() => new VotesStore());

export {
    VotesStoreContext,
    VotesStoreProvider,
    useVotesStore,
    withVotesStore
};