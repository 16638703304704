import { IQueryParameters, isNullOrUndefined } from "@eblsoft/react-toolkit";
import { IAddress, ICreateTaxiCardRequest, IPermanentCancellationTaxiCardRequest, ITaxiCard, IToggleTaxiCardProhibitionRequest, IUpdateTaxiCardRequest } from "Adapters/Api/Api.g";
import ITaxiCardApiAdapter from "Adapters/ITaxiCardApiAdapter";
import { mapAddressDtoToDomainModel, mapCreatedTaxiCardListItemDtoToDomainModel, mapTaxiCardListItemDtoToDomainModel, mapTaxiCardOrderListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import Address from "Models/Address";
import { CostPlacementType } from "Models/CostPlacementType";
import CreatedTaxiCardListItem from "Models/CreatedTaxiCardListItem";
import { PrintOptions } from "Models/PrintOptions";
import TaxiCardListItem from "Models/TaxiCardListItem";

class PartnerTaxiCardAdapter extends ProtectedApiAdapterBase implements ITaxiCardApiAdapter {

  private readonly mainRoute = "api/web/partner/TaxiCard";

  public getTaxiCardListAsync(paging: Paging, ordering: Ordering, filter: any): Promise<{ totalCount: number, taxiCards: TaxiCardListItem[], permanentCancellationFee: number }> {
    const queryParameters: IQueryParameters = {
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize,
      orderBy: ordering.orderBy,
      orderAsc: ordering.orderAsc,
      ...filter
    }

    return this.httpGet<{ totalCount: number, taxiCards: TaxiCardListItem[], permanentCancellationFee: number }>(this.mainRoute, undefined, queryParameters).map(paginatedResult => {
      return {
        totalCount: paginatedResult.totalCount,
        taxiCards: paginatedResult.taxiCards.map((i: any) => {
          return mapTaxiCardListItemDtoToDomainModel(i);
        }),
        permanentCancellationFee: paginatedResult.cardPermanentCancellationFee
      };
    }).performOperationAsync();
  }

  public permanentCancellationTaxiCardAsync(taxiCardId: number, prohibitionReason?: string, userName?: string): Promise<boolean> {
    const body: IPermanentCancellationTaxiCardRequest = {
      taxiCardId,
      prohibitionReason: prohibitionReason ?? "",
      userName: userName ?? ""
    };
    return this.httpPost<boolean>(this.mainRoute, ["permanentCancellation"], body).map(result => {
      return result;
    }).performOperationAsync();
  }

  public updateTaxiCardAsync(taxiCard: TaxiCardListItem) {
    const body: IUpdateTaxiCardRequest = {
      id: taxiCard.id,
      ownerName: taxiCard.ownerName,
      pin: taxiCard.pin,
      costPlacementType: taxiCard.costPlacementType,
      costPlacement: taxiCard.costPlacement,
      isActive: taxiCard.isActive
    };
    return this.httpPost<boolean>(
      this.mainRoute, ["update"], body
    ).map((res) => {
      return true;
    })
      .performOperationAsync();
  }

  public deleteAsync(id: number) {
    return this.httpDelete<boolean>(
      this.mainRoute, ["delete", id.toString()]
    ).map((res) => {
      return true;
    })
      .performOperationAsync();
  }

  public createTaxiCardsAsync(
    amount: number,
    costPlacementType: CostPlacementType,
    costPlacement: string = "",
    pin: string,
    requesterName: string,
    requesterPhoneNumber: string,
    referenceNumber: string,
    deliveryAddress: IAddress): Promise<{ totalCount: number, taxiCards: CreatedTaxiCardListItem[] }> {
    const body: ICreateTaxiCardRequest = {
      amount,
      costPlacementType,
      costPlacement,
      pin,
      requesterName,
      referenceNumber,
      requesterPhoneNumber,
      deliveryAddress
    };
    return this.httpPost<{ totalCount: number, taxiCards: CreatedTaxiCardListItem[] }>(this.mainRoute, ["create"], body).map(paginatedResult => {
      return {
        totalCount: paginatedResult.totalCount,
        taxiCards: paginatedResult.taxiCards.map((v: any) => {
          return mapCreatedTaxiCardListItemDtoToDomainModel(v);
        })
      };
    }).performOperationAsync();
  }

  public toggleProhibitTaxiCardAsync(ids: number[], prohibitionReason?: string, userName?: string): Promise<boolean> {
    const body: IToggleTaxiCardProhibitionRequest = {
      ids,
      prohibitionReason: prohibitionReason ?? "",
      userName: userName ?? ""
    };
    return this.httpPost<boolean>(this.mainRoute, ["toggleProhibition"], body).map((res) => true).performOperationAsync();
  }

  public printTaxiCardsAsync(ordering: Ordering, filter: any, printOption: PrintOptions, showPin: boolean) {
    const queryParameters: IQueryParameters = {
      printOption,
      showPin,
      OrderBy: ordering.orderBy,
      OrderAsc: ordering.orderAsc,
      ...filter
    };
    return this.httpGet<string>(this.mainRoute, ["print"], queryParameters).map(result => {
      return result;
    }).performOperationAsync();
  }

  public getPartnerAddressAsync(): Promise<Address | null> {
    return this.httpGet<Address | null>(this.mainRoute, ["partnerAddress"])
      .map(result => isNullOrUndefined(result.address) ? null : mapAddressDtoToDomainModel(result.address)).performOperationAsync();
  }
}


export default new PartnerTaxiCardAdapter();