import moment from "moment";
import { AuditEventType } from "./AuditEventType";
import { SimpleDocumentType } from "./SimpleDocumentType";

export default class AuditEventListItem {
    constructor(
        public readonly user: string,
        public readonly type: AuditEventType,
        public readonly occuredAt: moment.Moment,
        public readonly technicalInfo: string | null,
        public readonly referencedSimpleDocumentType: SimpleDocumentType | null,
        public readonly referencedTaxiDriverBpid: string | null,
        public readonly referencedTaxiDriverName: string | null,
        public readonly referencedPartnerBpid: string | null,
        public readonly referencedPartnerName: string | null,
        public readonly referencedAccountedRideIdentifier: string | null,
        public readonly referencedInvoiceNumber: string | null,
    ) {}
}