import { Select, Typography } from "antd"
import { TaxiCardProhibitionEventStatus, TaxiCardProhibitionEventStatusEntries } from "Models/TaxiCardProhibitionEventStatus";
const { Option } = Select;
const { Text } = Typography;

export interface ITaxiCardProhibitionEventStatusSelectBoxProps {
    onChange: (taxiCardProhibitionEventStatus: TaxiCardProhibitionEventStatus) => void;
    taxiCardProhibitionEventStatus: TaxiCardProhibitionEventStatus | null;
    style?: React.CSSProperties;
    label?: string
    allowClear?: boolean;
}

export function TaxiCardProhibitionEventStatusSelectBox(props: ITaxiCardProhibitionEventStatusSelectBoxProps) {
    return (
        <div style={props.style}>
            {props.label && <Text>{props.label}</Text>}
            <Select
                style={{ width: "100%" }}
                onChange={props.onChange}
                value={props.taxiCardProhibitionEventStatus}
                allowClear={props.allowClear}
            >
                {TaxiCardProhibitionEventStatusEntries.map(i =>
                    <Option key={i.enumValue} value={i.enumValue}>
                        {i.displayValue}
                    </Option>
                )}
            </Select>
        </div>

    )
} 