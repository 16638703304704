import { IQueryParameters } from "@eblsoft/react-toolkit";
import { IDeletePhoneNumberRequest, ITogglePhoneNumberProhibitionRequest } from "Adapters/Api/Api.g";
import IPhoneNumberAdapter from "Adapters/IPhoneNumberAdapter";
import { mapPhoneNumberDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { IPhoneNumberEditData } from "Models/IPhoneNumberEditData";
import PhoneNumberListItem from "Models/PhoneNumberListItem";
import { PrintOptions } from "Models/PrintOptions";

class AdminPartnerPhoneNumberAdapter extends ProtectedApiAdapterBase implements IPhoneNumberAdapter {
  private readonly mainRoute = "api/web/admin/partner";

  public getListAsync(paging: Paging, ordering: Ordering, filter: any, partnerId?: number | undefined): Promise<{ totalCount: number; phoneNumbers: PhoneNumberListItem[]; }> {
    const queryParameters: IQueryParameters = {
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize,
      orderBy: ordering.orderBy,
      orderAsc: ordering.orderAsc,
      ...filter
    };
    return this.httpGet<{ totalCount: number; phoneNumbers: PhoneNumberListItem[] }>(
      this.mainRoute,
      [partnerId!.toString(), "phoneNumber"],
      queryParameters
    )
      .map((paginatedResult) => {
        return {
          totalCount: paginatedResult.totalCount,
          phoneNumbers: paginatedResult.phoneNumbers.map((p: any) => {
            return mapPhoneNumberDtoToDomainModel(p);
          }),
        };
      })
      .performOperationAsync();
  }

  public updateAsync(phoneNumber: IPhoneNumberEditData, partnerId?: number): Promise<boolean> {
    return this.httpPost<boolean>(
      this.mainRoute, [partnerId!.toString(), "phoneNumber", "update"], phoneNumber
    ).map((res) => {
      return true;
    })
      .performOperationAsync();
  }

  public toggleProhibitionAsync(id: number, prohibitionReason?: string, userName?: string, partnerId?: number): Promise<boolean> {
    const body: ITogglePhoneNumberProhibitionRequest = {
      id,
      prohibitionReason: prohibitionReason ?? "",
      userName: userName ?? ""
    };
    return this.httpPost<boolean>(this.mainRoute, [partnerId!.toString(), "phoneNumber", "toggleProhibition"], body).map(result => {
      return true;
    })
      .performOperationAsync();
  }

  public deleteAsync?(id: number, deletionReason?: string, userName?: string, partnerId?: number): Promise<boolean> {
    const body: IDeletePhoneNumberRequest = {
      id,
      deletionReason: deletionReason ?? "",
      userName: userName ?? ""
    };
    return this.httpDelete<boolean>(this.mainRoute, [partnerId!.toString(), "phoneNumber"], body).map(result => {
      return true;
    }).performOperationAsync();
  }

  public createAsync(newData: IPhoneNumberEditData, partnerId?: number): Promise<boolean> {
    return this.httpPost<boolean>(
      this.mainRoute,
      [partnerId!.toString(), "phoneNumber", "create"], newData).map((res) => {
        return true;
      })
      .performOperationAsync();
  }

  public printPhoneNumbersAsync(ordering: Ordering, filter: any, printOption: PrintOptions, showPin: boolean, partnerId: number) {
    const queryParameters: IQueryParameters = {
      printOption,
      showPin,
      OrderBy: ordering.orderBy,
      OrderAsc: ordering.orderAsc,
      ...filter
    };
    return this.httpGet<string>(this.mainRoute, [partnerId!.toString(), "phoneNumber", "print"], queryParameters).map(result => {
      return result;
    }).performOperationAsync();
  }
}

export default new AdminPartnerPhoneNumberAdapter();