import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import ITaxiCardApiAdapter from "Adapters/ITaxiCardApiAdapter";
import { Outlet, useParams } from "react-router-dom";
import { withTaxiCardsStore } from "Pages/Partner/TaxiCards/TaxiCardsStore";
import IPollApiAdapter from "Adapters/IPollApiAdapter";
import { withPollsStore } from "./PollsStore";

export interface IPollListProps {
    pollApiAdapter: IPollApiAdapter;
}

function PollsCore() {
    return (
        <Outlet />
    );
}


const PollsCoreWithStore = withPollsStore(PollsCore);

export default function Polls(props: IPollListProps) {
    return (
        <PollsCoreWithStore {...props}/>
    )
}

