import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { DatePicker, Typography } from "antd";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { ValidationMessages } from "StaticResources/ValidationMessages";
import { useValidationStore } from "Stores/ValidationStore";

const { RangePicker } = DatePicker;
const { Text } = Typography;

export interface IDateRangePickerProps {
    onChange: (from: moment.Moment | null, to: moment.Moment | null) => void;
    from: moment.Moment | null;
    to: moment.Moment | null;
    disabled?: boolean;
    style?: React.CSSProperties;
    onEnter?: () => void;
    label?: string;
    allowClear?: boolean;
    showTime?: boolean;
    defaultValues?: [moment.Moment, moment.Moment];
    format?: string;
    id?: string;
    isRequired?: boolean;
}

function DateRangePicker(props: IDateRangePickerProps) {
    const { from, to, onChange } = props;
    const dateFormat = isNullOrUndefined(props.format) ? "YYYY.MM.DD" : props.format;
    const defaultValue: [from: moment.Moment, to: moment.Moment] = isNullOrUndefined(props.defaultValues) ? [moment().subtract(30, 'days'), moment()] : props.defaultValues!
    const [error, setError] = useState<string | null>(null);

    const setValue = useCallback((dates: any, dateStrings: [string, string]) => {

        if (isNullOrUndefined(dates)) {
            onChange(null, null);
        }
        if (Array.isArray(dates)) {
            onChange(dates[0], dates[1]);
        }

        setError(validate(isNullOrUndefined(dates) ? null : dates[0], isNullOrUndefined(dates) ? null : dates[1], props.isRequired ?? false));


    }, [onChange]);

    function validate(from: moment.Moment | null, to: moment.Moment | null, isRequired: boolean): string | null {
        if (isRequired && ((isNullOrUndefined(from) || isNullOrUndefined(to)))) {
            return ValidationMessages.isRequired;
        }
        return null;
    }

    const validationStore = useValidationStore();

    useEffect(() => {
        return validationStore?.registerValidateCallbackWithDisposer(() => {
            const result = validate(from, to, props.isRequired ?? false);
            setError(result);
            return result === null;
        });
    });

    const keyDown = useCallback((e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            props.onEnter?.();
        }
    }, [props.onEnter]);

    return (
        <div style={{ width: "100%", ...props.style }}>
            <Text>{props.label}{props.isRequired ? "*" : ""}</Text>

            <RangePicker
                showTime={props.showTime}
                style={{ width: "100%" }}
                disabled={props.disabled}
                status={error ? "error" : undefined}
                onChange={setValue}
                value={[from, to]}
                defaultValue={defaultValue}
                format={dateFormat}
                onKeyDown={keyDown}
                allowClear={props.allowClear}
                id={props.id}
            />
            {error && !props.disabled && <Text type="danger">{error}</Text>}
        </div>
    )
}

export default DateRangePicker;