import { action, makeObservable, observable } from "mobx";
import { TaxiCardOrderStatus } from "Models/TaxiCardOrderStatus";
import Voucher from "Models/Voucher";
import { VoucherType } from "Models/VoucherType";
import moment from "moment";

class TaxiCardOrderFilter {
    @observable.ref public status: TaxiCardOrderStatus | null = null;
    @observable.ref public numberOfCards: number | null = null;
    @observable.ref public sellerName: string = "";
    @observable.ref public sellerBpid: string = "";
    @observable.ref public referenceNumber: string = "";
    @observable.ref public createdAtFrom: moment.Moment | null = null;
    @observable.ref public createdAtTo: moment.Moment | null = null;

    @observable public areFiltersDirty = false;

    public constructor(
    ) {
        makeObservable(this);
    }

    @action.bound
    public setStatus(status: TaxiCardOrderStatus) {
        this.status = status;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setReferenceNumber(referenceNumber: string) {
        this.referenceNumber = referenceNumber;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setNumberOfCards(numberOfCards: number | null) {
        this.numberOfCards = numberOfCards;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setSellerName(sellerName: string) {
        this.sellerName = sellerName;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setSellerBpid(sellerBpid: string) {
        this.sellerBpid = sellerBpid;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setCreatedAtFrom(from: moment.Moment) {
        this.createdAtFrom = from;
        this.areFiltersDirty = true;

    }

    @action.bound
    public setCreatedAtTo(to: moment.Moment) {
        this.createdAtTo = to;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setDateRangeFilter(from: moment.Moment|null, to: moment.Moment|null) {
        this.createdAtFrom = from?.clone().hour(0).minute(0).second(0).millisecond(0) ?? null;
        this.createdAtTo = to?.clone().hour(23).minute(59).second(59).millisecond(59) ?? null;
        this.areFiltersDirty = true;
    }
    
}
export default TaxiCardOrderFilter;