import { IUpdatePartnerEmailPreferencesRequest } from "Adapters/Api/Api.g";
import IPartnerApiAdapter from "Adapters/IPartnerApiAdapter";
import { mapPartnerWithEmailPreferencesDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import {PartnerWithEmailPreferences} from "Models/Partner";

class PartnerDataAdapter extends ProtectedApiAdapterBase implements IPartnerApiAdapter {
    private readonly mainRoute = "api/web/partner/Partner";

    public getAsync(): Promise<PartnerWithEmailPreferences> {
        return this
            .httpGet<PartnerWithEmailPreferences>(this.mainRoute)
            .map(result => mapPartnerWithEmailPreferencesDtoToDomainModel(result))
            .performOperationAsync();
    }

    public updateEmailPreferencesAsync(invoiceSummaryEmail: string, eTaxiContactEmail: string, includeRideDataInInvoiceSummary: boolean, partnerId?: number): Promise<PartnerWithEmailPreferences> {
        const request: IUpdatePartnerEmailPreferencesRequest = {
            eTaxiContactEmail,
            invoiceSummaryEmail,
            includeRideDataInInvoiceSummary
        };

        return this
            .httpPost<PartnerWithEmailPreferences>(this.mainRoute, ["email"], request)
            .map(result => mapPartnerWithEmailPreferencesDtoToDomainModel(result))
            .performOperationAsync();
    }
}

export default new PartnerDataAdapter();
