import { Col, Row } from "antd";
import { CostPlacementType } from "Models/CostPlacementType";
import { CostplacementTypeSelectBox } from "./CostPlacementTypeSelectBox";
import TextBox from "./TextBox";

export interface CostplacementTypeSelectBoxProps {
    onCostPlacementTypeChange: (costPlacementType: CostPlacementType) => void;
    onCostplacementChange: (value: string) => void;
    costPlacementValue: string;
    costPlacementType: CostPlacementType | null;
    onSearch?: () => void;
    showLabels?: boolean;
}

export function CostPlacementTypeSelector(props: CostplacementTypeSelectBoxProps) {
    const setCostPlacementType = (value: CostPlacementType) => {
        if (value !== CostPlacementType.Fix) {
            props.onCostplacementChange('');
        }

        props.onCostPlacementTypeChange(value);
        props.onSearch?.();
    }

    return (
        <Row gutter={16}>
            <Col xs={props.costPlacementType === CostPlacementType.Fix ? 12 : 24}>
                <CostplacementTypeSelectBox showLabel={props.showLabels} allowEmpty costPlacementType={props.costPlacementType} onChange={setCostPlacementType} />
            </Col>
            {props.costPlacementType === CostPlacementType.Fix && (
                <Col xs={12}>
                    <TextBox
                        label={props.showLabels ? "Költséghely:" : undefined}
                        onChange={props.onCostplacementChange}
                        value={props.costPlacementValue}
                        placeholder="Központi költséghely"
                        onEnter={props.onSearch}
                        maxLength={128}
                    />
                </Col>
            )}
        </Row>
    )
}