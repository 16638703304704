import { EnumHelper } from "Util/EnumHelper";


export enum AddressType {
    Headquarters = "Headquarters",
    Mailing = "Mailing",
    Any = "Any"
}

function getAddressTypeEntries() {
    return EnumHelper.getEnumEnumerableEntries<AddressType>(AddressType, getAddressTypeDisplayValue);
}

export function getAddressTypeDisplayValue(value: AddressType) {

    switch(value) {
        case AddressType.Headquarters: return "Székhely";
        case AddressType.Mailing: return "Postázási cím";
        case AddressType.Any: return "Bármilyen cím";
    }
}

export const AddressTypeEntries = getAddressTypeEntries();