import { IGetAuditEventsRequest, IGetAuditEventsResponse } from "Adapters/Api/Api.g";
import { mapAdminAuditEventListFilterToDto, mapAuditEventListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";


import AuditEventFilter from "Models/Filters/AuditEventFilter";
import AuditEventListItem from "Models/AuditEventListItem";


class AdminAuditEventApiAdapter extends ProtectedApiAdapterBase {
    private readonly mainRoute = "api/web/admin/AuditEvents";

    public getListAsync(paging: Paging, ordering: Ordering, filter: AuditEventFilter): Promise<{ totalCount: number, consents: AuditEventListItem[] }> {
        const request: IGetAuditEventsRequest = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapAdminAuditEventListFilterToDto(filter),
        }

        return this.httpPost<{ totalCount: number, consents: AuditEventListItem[] }>(this.mainRoute, undefined, request).map((paginatedResult: IGetAuditEventsResponse) => {
            return {
                totalCount: paginatedResult.totalCount,
                consents: paginatedResult.items.map((i: any) => {
                    return mapAuditEventListItemDtoToDomainModel(i);
                }),
            };
        }).performOperationAsync();
    }
}

export default new AdminAuditEventApiAdapter();
