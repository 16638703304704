import { useErrorDispatcher } from "@eblsoft/react-toolkit";
import AdminPartnerAdapter from "Adapters/AdminAdapter/AdminPartnerAdapter";
import { Tabs, Typography } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams, } from "react-router-dom";
import Styles from "./PartnerDetail.module.css";

const { Title } = Typography;

function PartnerDetailWrapper() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const dispatchError = useErrorDispatcher();
    const [name, setName] = useState("...");

    useEffect(() => {
        const partnerId = parseInt(params.partnerId!);

        AdminPartnerAdapter.getAsync(partnerId).then(partner => {
            setName(partner.bpName);
        }).catch(dispatchError);

    }, []);

    const setTab = useCallback((activeKey: string) => navigate(`${activeKey}`), [navigate]);

    const selectedTab = useMemo(() => {
        const parts = location.pathname.split("/");
        return parts[parts.length - 1];
    }, [location.pathname]);

    return (
        <>
            <Title level={3} className={Styles.partnerTitle}>Partner: {name}</Title>
            <Tabs onChange={setTab} activeKey={selectedTab} className="in-header">
                <Tabs.TabPane tab={<span id="partnerDetailMenu_baseData">Alapadatok</span>} key="partnerBaseData" />
                <Tabs.TabPane tab={<span id="partnerDetailMenu_vouchers">Azonosító szelvények</span>} key="vouchers" />
                <Tabs.TabPane tab={<span id="partnerDetailMenu_phoneNumbers">Telefonszámok</span>} key="phoneNumbers" />
                <Tabs.TabPane tab={<span id="partnerDetailMenu_taxiCards">Kártyák</span>} key="taxiCards" />
                <Tabs.TabPane tab={<span id="partnerDetailMenu_invoices">Számlák</span>} key="invoices" />
                <Tabs.TabPane tab={<span id="partnerDetailMenu_invoiceSummaries">Számlaösszesítők</span>} key="invoiceSummaries" />
            </Tabs>
            <Outlet />
        </>
    );
}

export default PartnerDetailWrapper;