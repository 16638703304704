import { createStoreProvider, isNullOrUndefined, StoreBase } from "@eblsoft/react-toolkit";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { action, computed, observable, runInAction } from "mobx";
import { CostPlacementType } from "Models/CostPlacementType";
import TaxiCardFilter from "Models/Filters/TaxiCardFilter";
import { PrintOptions } from "Models/PrintOptions";
import ProhibitionInfo from "Models/ProhibitionInfo";

import TaxiCardListItem from "Models/TaxiCardListItem";
import moment from "moment";
import { ITaxiCardListCoreProps } from "Pages/Partner/TaxiCards/TaxiCardCreation/TaxiCards";
import { downloadBase64Async } from "Util/FileDownloader";

export default class TaxiCardsStore extends StoreBase<ITaxiCardListCoreProps> {
    @observable.ref public taxiCards: TaxiCardListItem[] = [];
    @observable.ref public permanentCancellationFee: number = 0;
    @observable.ref public selectedTaxiCards: TaxiCardListItem[] = [];
    @observable.ref public recordIdUnderEdit: number | null = null;

    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    public orderedBy = "preMadeCardIndex";
    public orderDirection = "ascend";

    public taxiCardFilter = new TaxiCardFilter();
    @observable.ref public showPermanentCancellationTaxiCardDialog = false;
    @observable.ref public modalIsVisible: boolean = false;
    @observable.ref public confirmIsLoading: boolean = false;
    @observable.ref public prohibitionInfo: ProhibitionInfo = new ProhibitionInfo(null);
    @observable.ref public showInactive = false;
    @observable.ref public selectedTaxiCardIdentifierForPermanantlyCancel = "";

    @computed private get filter() {
        const filterResult = {
            CostPlacementType: this.taxiCardFilter.costPlacementType,
            OwnerName: this.taxiCardFilter.name,
            Identifier: this.taxiCardFilter.cardNumber,
            CostPlacement: this.taxiCardFilter.costPlacement,
            ShowInactive: this.showInactive
        }
        return this.props.isSummary ? { ...filterResult, Customer: this.taxiCardFilter.customer } : filterResult;
    }

    @computed
    public get isEverySelectedTaxiCardProhibited() {
        return this.selectedTaxiCards.every(v => !v.isActive);
    }


    public readonly vLoadAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");

        const result = await this.props.taxiCardApiAdapter.getTaxiCardListAsync(
            paging,
            ordering,
            this.filter,
            this.props.isSummary ? undefined : this.props.partnerId);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.taxiCards = result.taxiCards;

            if (!this.props.isSummary) {
                this.permanentCancellationFee = result.permanentCancellationFee!
            }

            if (!this.selectedTaxiCards.every(v => this.taxiCards.find(vv => vv.id === v.id))) {
                this.selectedTaxiCards = [];
            } else {
                this.selectTaxiCards(this.taxiCards.filter(v => this.selectedTaxiCards.some(sv => sv.id === v.id)));
            }
        });
    });

    private readonly updateAsync = this.async(async (newData: TaxiCardListItem) => {
        const result = await this.props.taxiCardApiAdapter.updateTaxiCardAsync!(newData, this.props.partnerId);
        if (result) {
            this.vLoadAsync.fireAndForget();
        }
    });

    public toggleProhibitTaxiCardAsync = this.async(async (ids: number[]) => {
        const result = await this.props.taxiCardApiAdapter.toggleProhibitTaxiCardAsync!(ids, this.prohibitionInfo?.prohibitionReason, this.prohibitionInfo?.userName, this.props.partnerId);
        if (result && this.modalIsVisible) {
            this.prohibitionInfo = new ProhibitionInfo(null);
            this.confirmIsLoading = false;
            this.modalIsVisible = false;
        }
        await this.vLoadAsync();

    });

    public readonly toggleProhibitSelectedTaxiCardsAsync = this.async(async () => {
        await this.toggleProhibitTaxiCardAsync(this.selectedTaxiCards.map(v => v.id));
    });

    public readonly toggleProhibitSingledTaxiCardAsync = this.async(async (id: number) => {
        await this.toggleProhibitTaxiCardAsync([id]);
    });

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<TaxiCardListItem> | SorterResult<TaxiCardListItem>[], extra: TableCurrentDataSource<TaxiCardListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (!sorter) {
            this.orderedBy = "identifier";
        } else {
            if (Array.isArray(sorter)) {
                this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
                this.orderDirection = sorter[0].order!;
            } else {
                this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
                this.orderDirection = sorter.order!;
            }
        }
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public toggleShowInactive() {
        this.showInactive = !this.showInactive;
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public setRecordUnderEdit(id: number | null) {
        this.recordIdUnderEdit = id;
    }

    @action.bound
    public selectTaxiCards(taxiCards: TaxiCardListItem[]) {
        this.selectedTaxiCards = taxiCards;
    }

    @action.bound
    public saveRecordUnderEdit() {
        const newData = this.taxiCards.find(p => p.id === this.recordIdUnderEdit)!;
        this.updateAsync.fireAndForget(newData);
        this.recordIdUnderEdit = null;
    }

    @action.bound
    public setProhibitionModalIsVisible(ids: number[]) {
        this.modalIsVisible = true;
        this.prohibitionInfo = new ProhibitionInfo(ids);
    }

    @action.bound
    public setProhibitionModalIsVisibleForSelectedTaxiCards() {
        this.modalIsVisible = true;
        this.prohibitionInfo = new ProhibitionInfo(this.selectedTaxiCards.map(v => v.id));
    }

    @action.bound
    public toggleProhibit() {
        this.confirmIsLoading = true;
        this.toggleProhibitTaxiCardAsync.fireAndForget(this.prohibitionInfo.prohibitionItemIds!);
    }

    @action.bound
    public cancelModal() {
        this.modalIsVisible = false;
    }

    @action.bound
    public filterResult() {
        this.currentPage = 1;
        this.taxiCardFilter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    public readonly printAsync = this.async(async (option: PrintOptions, showPin: boolean) => {
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const filter = this.selectedTaxiCards.length === 0 ? this.filter : { ...this.filter, ids: this.selectedTaxiCards.map(v => v.id) };
        const result = await this.props.taxiCardApiAdapter.printTaxiCardsAsync(ordering, filter, option, showPin, this.props.isSummary ? undefined : this.props.partnerId);
        const date = moment().format("YYYY_MM_DD");
        switch (option) {
            case PrintOptions.PrintToExcel:
                await downloadBase64Async(result, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `taxikártyák_${date}.xlsx`);
                return;
            default:
                await downloadBase64Async(result, "application/pdf", `taxikártyák_${date}.pdf`);
            // TODO other options
        }
    });

    @action.bound
    public print(option: PrintOptions, showPin: boolean) {
        this.printAsync.fireAndForget(option, showPin);
    }

    public permanentCancellationTaxiCardAsync = this.async(async () => {
        this.confirmIsLoading = true;
        const result = await this.props.taxiCardApiAdapter.permanentCancellationTaxiCardAsync!(this.prohibitionInfo.prohibitionItemIds![0], this.prohibitionInfo.prohibitionReason, this.prohibitionInfo.userName, this.props.partnerId);
        if (result) {
            this.selectedTaxiCards = this.selectedTaxiCards.filter(stc => stc.id !== this.prohibitionInfo.prohibitionItemIds![0]);
            this.confirmIsLoading = false;
            this.prohibitionInfo = new ProhibitionInfo(null);
            this.showPermanentCancellationTaxiCardDialog = false;
            this.selectedTaxiCardIdentifierForPermanantlyCancel = "";
        }
        await this.vLoadAsync();

    });

    @action.bound
    public openPermanentCancellationTaxiCardDialog(taxicard: TaxiCardListItem) {
        this.prohibitionInfo = new ProhibitionInfo([taxicard.id]);
        this.showPermanentCancellationTaxiCardDialog = true;
        this.selectedTaxiCardIdentifierForPermanantlyCancel = taxicard.identifier;
    }

    @action.bound
    public cancelPermanentCancellationTaxiCardDialog() {
        this.prohibitionInfo = new ProhibitionInfo(null);
        this.showPermanentCancellationTaxiCardDialog = false;
        this.selectedTaxiCardIdentifierForPermanantlyCancel = "";
    }
}

const { ContextComponent: TaxiCardsStoreContext, StoreProvider: TaxiCardsStoreProvider, useStore: useTaxiCardsStore, withStore: withTaxiCardsStore } = createStoreProvider<ITaxiCardListCoreProps, TaxiCardsStore>(() => new TaxiCardsStore());

export {
    TaxiCardsStoreContext,
    TaxiCardsStoreProvider,
    useTaxiCardsStore,
    withTaxiCardsStore
};