import { observer } from "mobx-react";
import TaxiCardListItem from "Models/TaxiCardListItem";
import PinInputBox from "Pages/CommonControls/PinInputBox";
import { useMemo } from "react";

export interface IPinColumnProps {
    record: TaxiCardListItem;
    isUnderEdit: boolean;
}

function PinColumn(props: IPinColumnProps) {

    const value = useMemo(() => {
        const pin = props.record.pin;
        if (pin === null || pin.length === 0) {
            return "Nincs";
        }

        if (props.record.isPinVisible) {
            return pin;
        } else {
            return "***";
        }
    }, [props.record, props.record.pin, props.record.isPinVisible, props.isUnderEdit]);

    return (
        <>
            {props.isUnderEdit ?
                <PinInputBox value={props.record.pin} onChange={props.record.setPin} /> :
                <span>{value}</span>
            }
        </>
    )
}

export default observer(PinColumn);