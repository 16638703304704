import { IQueryParameters } from "@eblsoft/react-toolkit";
import InvoiceForPartnerListItem from "Models/InvoiceForPartnerListItem";
import IInvoiceApiAdapter from "Adapters/IInvoiceApiAdapter";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import { mapInvoiceDetailForPartnerListItemDtoToDomainModel, mapInvoiceForPartnerListItemDtoToDomainModel, mapInvoiceListFilterToDto } from "Adapters/Mapping/Map";
import Ordering from "Adapters/QueryHelpers/Ordering";
import { PrintOptions } from "Models/PrintOptions";
import InvoiceFilter from "Models/Filters/InvoiceFilter";
import moment from "moment";
import Paging from "Adapters/QueryHelpers/Paging";
import { IDownloadInvoiceRequest, IGetInvoiceListRequest, IInvoiceListItemDetailDto, IInvoiceListItemDto } from "Adapters/Api/Api.g";
import InvoiceDetailForPartnerListItem from "Models/InvoiceDetailForPartnerListItem";

class PartnerInvoiceApiAdapter extends ProtectedApiAdapterBase implements IInvoiceApiAdapter {

    private readonly mainRoute = "api/web/partner/Invoice";

    public getListAsync(paging: Paging, ordering: Ordering, filter: InvoiceFilter): Promise<{ totalCount: number, invoices: InvoiceForPartnerListItem[], totalGrossPrice: number }> {

        const request: IGetInvoiceListRequest = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapInvoiceListFilterToDto(filter)
        }

        return this.httpPost<{ totalCount: number, invoices: [], totalGrossPrice: number }>(this.mainRoute, undefined, request).map(paginatedResult => {
            return {
                totalCount: paginatedResult.totalCount,
                invoices: paginatedResult.invoices.map((i: any) => {
                    return mapInvoiceForPartnerListItemDtoToDomainModel(i);
                }),
                totalGrossPrice: paginatedResult.stats.totalGrossPrice
            };
        }).performOperationAsync();
    }

    public getPdfAsync(invoiceId: number): Promise<string> {
        return this.httpGet<string>(this.mainRoute, [invoiceId.toString(), "pdf"]).map(result => {
            return result;
        }).performOperationAsync();
    }

    public getDetailsAsync(invoiceId: number): Promise<InvoiceDetailForPartnerListItem> {
        return this.httpGet<InvoiceDetailForPartnerListItem>(this.mainRoute, [invoiceId.toString()]).map((result: IInvoiceListItemDetailDto) => {
            return mapInvoiceDetailForPartnerListItemDtoToDomainModel(result);
        }).performOperationAsync();
    }

    public printInvoicesAsync(ordering: Ordering, filter: InvoiceFilter, printOption: PrintOptions, showExtendedColumns: boolean) {
        const request: IDownloadInvoiceRequest = {
            printOption,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapInvoiceListFilterToDto(filter),
            showExtendedColumns
        };
        
        return this.httpPost<string>(this.mainRoute, ["print"], request).map(result => {
            return result;
        }).performOperationAsync();
    }
}

export default new PartnerInvoiceApiAdapter();
