import { DocumentStatus, DocumentStatusEntries } from "Models/DocumentStatus";
import { Select, Typography } from "antd"
const { Option } = Select;
const { Text } = Typography;

export interface IDocumentStatusSelectBoxProps {
    onChange: (documentStatus: DocumentStatus) => void;
    documentStatus: DocumentStatus | null;
    style?: React.CSSProperties;
    label?: string
    allowClear?: boolean;
}

export function DocumentStatusSelectBox(props: IDocumentStatusSelectBoxProps) {
    return (
        <div style={props.style}>
            {props.label && <Text>{props.label}</Text>}
            <Select
                style={{ width: "100%" }}
                onChange={props.onChange}
                value={props.documentStatus}
                allowClear={props.allowClear}
            >
                {DocumentStatusEntries.map(i =>
                    <Option key={i.enumValue} value={i.enumValue}>
                        {i.displayValue}
                    </Option>
                )}
            </Select>
        </div>

    )
} 