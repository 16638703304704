import { ApiAdapterBase } from "@eblsoft/react-toolkit";
import Config from "Config";
import ISettings from "Models/ISettings";

class SettingsAdapter extends ApiAdapterBase {
    public getAsync(): Promise<ISettings> {
        return this
            .httpGet<ISettings>("api/web/Settings")
            .map(settings => settings)
            .performOperationAsync();
    }
}

export default new SettingsAdapter(Config.appServerAddress);
