import { EnumHelper } from "Util/EnumHelper";

export enum ConsentSummary {
    AllAccepted = "AllAccepted",
    Mixed = "Mixed",
    AllRejected = "AllRejected",
}

function getConsentSummaryEntries() {
    return EnumHelper.getEnumEnumerableEntries<ConsentSummary>(ConsentSummary, getConsentSummaryDisplayValue);
}

export function getConsentSummaryDisplayValue(value: ConsentSummary) {

    switch(value) {
        case ConsentSummary.AllAccepted: return "Elfogadva";
        case ConsentSummary.Mixed: return "Részlegesen elfogadva";
        case ConsentSummary.AllRejected: return "Elutasítva";
    }
}

export const ConsentSummaryEntries = getConsentSummaryEntries();