import { createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import AdminTaxiDriverAdapter from "Adapters/AdminAdapter/AdminTaxiDriverAdapter";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { FilterValue, SorterResult, TableCurrentDataSource, TablePaginationConfig } from "antd/lib/table/interface";
import { action, computed, observable, runInAction } from "mobx";
import TaxiDriverFilter from "Models/Filters/TaxiDriverFilter";
import TaxiDriver from "Models/TaxiDriver";
import TaxiDriverListItem from "Models/TaxiDriverListItem";

export default class TaxiDriversStore extends StoreBase {
    @observable.ref public taxiDrivers: TaxiDriverListItem[] = [];
    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    public orderedBy = "";
    public orderDirection = "";
    public taxiDriverFilter = new TaxiDriverFilter();

    public readonly vLoadAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");

        const result = await AdminTaxiDriverAdapter.getListAsync(paging, ordering, this.taxiDriverFilter);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.taxiDrivers = result.taxiDrivers;
        });
    });

    @action.bound
    public setShowLeavedDrivers() {
        this.taxiDriverFilter.setShowLeavedDrivers();
        this.taxiDriverFilter.areFiltersDirty = false;
        this.currentPage = 1;
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public filterResult() {
        this.currentPage = 1;
        this.taxiDriverFilter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<TaxiDriverListItem> | SorterResult<TaxiDriverListItem>[], extra: TableCurrentDataSource<TaxiDriverListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (Array.isArray(sorter)) {
            this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
            this.orderDirection = sorter[0].order!;
        } else {
            this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
            this.orderDirection = sorter.order!;
        }
        this.vLoadAsync.fireAndForget();
    }
}



const { ContextComponent: TaxiDriversStoreContext, StoreProvider: TaxiDriversStoreProvider, useStore: useTaxiDriversStore, withStore: withTaxiDriversStore } = createStoreProvider(() => new TaxiDriversStore());

export {
    TaxiDriversStoreContext,
    TaxiDriversStoreProvider,
    useTaxiDriversStore,
    withTaxiDriversStore
};