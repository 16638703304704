import { emptyString, isNotNullAndUndefined, isNullOrUndefined } from "@eblsoft/react-toolkit";
import { Input, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ValidationMessages } from "StaticResources/ValidationMessages";
import { useValidationStore } from "Stores/ValidationStore";

const { Text } = Typography;

export interface ITextBoxProps {
    onChange: (value: string) => void;
    value: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    addonAfter?: React.ReactNode;
    label?: string;
    multiline?: boolean | number;
    placeholder?: string;
    onEnter?: () => void;
    onValidate?: (value: string) => (string | null);
    isRequired?: boolean;
    maxLength?: number;
    minLength?: number;
    allowClear?: boolean;
    secondaryText?: string;
    isReadOnly?: boolean;
    isHighlighted?: boolean;
    id?: string;
}

function validate(value: string, isRequired: boolean, minLength?: number, onValidate?: (value: string) => (string | null)): string | null {
    if (isRequired && (isNullOrUndefined(value) || value === emptyString)) {
        return ValidationMessages.isRequired;
    }
    else if (value?.length > 0 && !isNullOrUndefined(minLength) && minLength! > value.length) {
        return ValidationMessages.minLength(minLength!);
    }
    else if (!isNullOrUndefined(onValidate)) {
        return onValidate!(value);
    }

    return null;
}

function TextBox(props: ITextBoxProps) {
    const { value, onChange, onEnter, isReadOnly, isHighlighted, style } = props;
    const [error, setError] = useState<string | null>(null);

    const setValue = useCallback((inputValue: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = inputValue.target.value;
        onChange(value);

        setError(validate(value, props.isRequired ?? false, props.minLength, props.onValidate));

    }, [onChange, props.isRequired, props.minLength, props.onValidate]);

    const keyPressed = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            onEnter?.();
        }
    }, [onEnter]);

    useEffect(() => {
        setError(null);
    }, [props.disabled, props.isRequired]);

    var validationStore = useValidationStore();
    useEffect(() => {
        return validationStore?.registerValidateCallbackWithDisposer(() => {
            const result = validate(value, props.isRequired ?? false, props.minLength, props.onValidate);
            setError(result);
            return result === null;
        });
    });

    const color = useMemo(() => {
        if (isReadOnly) {
            return "#f3f3f3";
        }

        if (isHighlighted) {
            return "#fdb813";
        }

        return style?.backgroundColor ?? "#ffffff";
    }, [isReadOnly, isHighlighted, style?.backgroundColor]);

    return (
        <div style={{ width: "100%" }}>
            {props.label && < Text > {props.label}{props.isRequired ? "*" : ""}</Text>}

            {
                props.multiline ? (
                    <TextArea
                        allowClear={props.allowClear}
                        disabled={props.disabled}
                        onChange={setValue}
                        value={value}
                        rows={typeof (props.multiline) === "number" ? props.multiline : 4}
                        style={{ width: "100%", backgroundColor: color, ...props.style }}
                        status={error ? "error" : undefined}
                        maxLength={props.maxLength}
                        showCount={isNotNullAndUndefined(props.maxLength)}
                        readOnly={props.isReadOnly}
                        id={props.id}
                    />
                ) : (
                    <Input
                        allowClear={props.allowClear}
                        disabled={props.disabled}
                        onChange={setValue}
                        value={value}
                        addonAfter={props.addonAfter}
                        style={{ width: "100%", backgroundColor: color, ...props.style }}
                        placeholder={props.placeholder}
                        onKeyPress={keyPressed}
                        status={error ? "error" : undefined}
                        maxLength={props.maxLength}
                        showCount={isNotNullAndUndefined(props.maxLength)}
                        readOnly={props.isReadOnly}
                        id={props.id}
                    />
                )
            }
            {props.secondaryText && <Text style={{ display: "flex" }} type="secondary">{props.secondaryText}</Text>}
            {error && !props.disabled && <Text type="danger">{error}</Text>}
        </div >

    )
}

export default TextBox;