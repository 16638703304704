import React from "react";
import Styles from "./TableSummaryHeader.module.css";

interface ITableSummaryHeaderProps {
    items: ITableSummaryHeaderItem[];
}

export interface ITableSummaryHeaderItem {
    title: string;
    value: string;
}

export default function TableSummaryHeader(props: ITableSummaryHeaderProps) {
    return (
        <div className={Styles.container}>
            {props.items.map(i => (
                <div key={i.title}>
                    <b>{i.title}</b> {i.value}
                </div>
            ))}
        </div>
    );
}