import { EnumHelper } from "Util/EnumHelper";

export enum SimpleDocumentType {
    DriverLicence = "DriverLicence",
    TransportingLicence = "TransportingLicence",
    TransportingLicenceSummary = "TransportingLicenceSummary",
    TaxiStationUsageAgreement = "TaxiStationUsageAgreement",
    TaxiDriverLicence = "TaxiDriverLicence",
    TaximeterCertificate = "TaximeterCertificate",
}

function getSimpleDocumentTypeEntries() {
    return EnumHelper.getEnumEnumerableEntries<SimpleDocumentType>(SimpleDocumentType, getSimpleDocumentTypeDisplayValue);
}

export function getSimpleDocumentTypeDisplayValue(value: SimpleDocumentType) {

    switch(value) {
        case SimpleDocumentType.DriverLicence: return "Jogosítvány";
        case SimpleDocumentType.TransportingLicence: return "Tevékenységi Engedély";
        case SimpleDocumentType.TransportingLicenceSummary: return "Tevékenységi Engedély kivonat";
        case SimpleDocumentType.TaxiStationUsageAgreement: return "Taxiállomás használati hozzájárulás";
        case SimpleDocumentType.TaxiDriverLicence: return "Taxi vezetői igazolvány";
        case SimpleDocumentType.TaximeterCertificate: return "Taxióra hitelesítési bizonyítvány";
    }
}

export const SimpleDocumentTypeEntries = getSimpleDocumentTypeEntries();