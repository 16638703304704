import { Col, Row, Typography } from "antd";
import { VoucherType } from "Models/VoucherType";
import { VoucherTypeSelectBox } from "./VoucherTypeSelectBox";
import TextBox from "./TextBox";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";

export interface VoucherTypeSelectBoxProps {
    onVoucherTypeChange: (VoucherType: VoucherType) => void;
    onVoucherInfoChange: (value: string) => void;
    VoucherInfo: string;
    VoucherType: VoucherType | null;
    onSearch?: () => void;
    idPrefix?: string;
}

export function VoucherTypeSelector(props: VoucherTypeSelectBoxProps) {
    const setVoucherType = (value: VoucherType) => {
        if (isNullOrUndefined(value) || props.VoucherType === VoucherType.None) {
            props.onVoucherInfoChange('');
        }

        props.onVoucherTypeChange(value);
        props.onSearch?.();
    }

    return (
        <Row gutter={16}>
            <Col xxl={6} xl={10} xs={12}>
                <Typography.Text>Elszámolás típusa:</Typography.Text>
                <VoucherTypeSelectBox VoucherType={props.VoucherType} onChange={setVoucherType} id={`${props.idPrefix}accountingType`} />
            </Col>
            <Col xxl={6} xl={10} xs={12}>
                {(props.VoucherType === VoucherType.Card || props.VoucherType === VoucherType.Voucher || props.VoucherType === VoucherType.PhoneNumber) && (
                    <TextBox
                        label={props.VoucherType === VoucherType.PhoneNumber ? "Telefonszám:" : "Azonosító:"}
                        onChange={props.onVoucherInfoChange}
                        value={props.VoucherInfo}
                        style={{ width: "100%" }}
                        onEnter={props.onSearch}
                        id={`${props.idPrefix}accountingInfo`}
                    />
                )}
            </Col>
        </Row>
    )
}