import React, { useMemo } from "react";
import { useAuditEventListStore, withAuditEventListStore } from "./AuditEventListStore";
import { Observer, observer } from "mobx-react";
import { Button, Col, PageHeader, Row, Select, Space, TablePaginationConfig, Typography } from "antd";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";
import Table, { ColumnType } from "antd/lib/table";
import AuditEventListItem from "Models/AuditEventListItem";
import { getStatementTypeDisplayValue, StatementType } from "Models/StatementType";
import { ConsentSummary, getConsentSummaryDisplayValue } from "Models/ConsentSummary";
import { getFullScreenTableScroll } from "Util/TableHelpers";
import DateRangePicker from "Pages/CommonControls/DateRangePicker";
import TextBox from "Pages/CommonControls/TextBox";
import { FilterOutlined } from "@ant-design/icons";
import { AuditEventType, AuditEventTypeEntries, getAuditEventTypeDisplayValue } from "Models/AuditEventType";
import { getSimpleDocumentTypeDisplayValue, SimpleDocumentType } from "Models/SimpleDocumentType";

const { Title, Text } = Typography;
const { Option } = Select;

function AuditEventList() {
    const store = useAuditEventListStore();

    const _paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    }

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    let paginationConfig = _paginationConfig;

    const cols: Array<ColumnType<AuditEventListItem>> = [
        {
            title: "Időpont",
            dataIndex: "occuredAt",
            key: "occuredAt",
            render: (value: moment.Moment) => <span>{value?.format("L LT") ?? ""}</span>,
            sorter: true
        },
        {
            title: "Felhasználó",
            dataIndex: "user",
            key: "user",
            sorter: true,
        },
        {
            title: "Esemény",
            dataIndex: "type",
            key: "type",
            sorter: true,
            render: (value: AuditEventType) => <span>{getAuditEventTypeDisplayValue(value)}</span>,
        },
        {
            title: "Egyéb információ",
            dataIndex: "technicalInfo",
            key: "technicalInfo",
            sorter: false
        },
        {
            title: "Hivatkozott egyszerű dokumentum típus",
            dataIndex: "referencedSimpleDocumentType",
            key: "referencedSimpleDocumentType",
            render: (value: SimpleDocumentType) => <span>{getSimpleDocumentTypeDisplayValue(value)}</span>,
            sorter: true
        },
        {
            title: "Hivatkozott kolléga BPID",
            dataIndex: "referencedTaxiDriverBpid",
            key: "referencedTaxiDriverBpid",
            sorter: true
        },
        {
            title: "Hivatkozott kolléga neve",
            dataIndex: "referencedTaxiDriverName",
            key: "referencedTaxiDriverName",
            sorter: true
        },
        {
            title: "Hivatkozott partner BPID",
            dataIndex: "referencedPartnerBpid",
            key: "referencedPartnerBpid",
            sorter: true
        },
        {
            title: "Hivatkozott partner neve",
            dataIndex: "referencedPartnerName",
            key: "referencedPartnerName",
            sorter: true
        },
        {
            title: "Hivatkozott elszámolás",
            dataIndex: "referencedAccountedRideIdentifier",
            key: "referencedAccountedRideIdentifier",
            sorter: true
        },
        {
            title: "Hivatkozott számlaszám",
            dataIndex: "referencedInvoiceNumber",
            key: "referencedInvoiceNumber",
            sorter: true
        },
    ];

    const tableScroll = getFullScreenTableScroll(cols.reduce((sum, col) => sum + (col.width as number ?? 100), 0));

    return (
        <PageHeader title="Tevékenységnapló" >
            <Observer>
                {() => (
                    <>
                        <Row gutter={16}>
                            <Col xxl={8} xl={10} xs={12}>
                                <DateRangePicker
                                    label="Esemény ideje:"
                                    from={store.filter.occuredAtFrom}
                                    to={store.filter.occuredAtTo}
                                    onChange={store.filter.setOccuredAtRange}
                                    style={{ width: "100%" }}
                                    onEnter={store.filterResult}
                                    allowClear
                                />
                            </Col>
                            <Col xxl={8} xl={10} xs={12}>
                                <TextBox
                                    label="Felhasználó:"
                                    onChange={store.filter.setUser}
                                    value={store.filter.user}
                                    onEnter={store.filterResult}
                                />
                            </Col>
                            <Col xxl={8} xl={10} xs={12}>
                                <div style={{ width: "100%" }}>
                                    <Text>Esemény típusa:</Text>
                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        onChange={store.filter.setEventTypes}
                                        value={store.filter.eventTypes}
                                        allowClear
                                    >
                                        {AuditEventTypeEntries.map(i =>
                                            <Option key={i.enumValue} value={i.enumValue}>
                                                {i.displayValue}
                                            </Option>
                                        )}
                                    </Select>
                                </div>
                            </Col>
                        </Row>
                        {<Space style={{ marginTop: "16px", marginBottom: "16px" }}>
                            <Button key="1" style={{ width: "100%" }} type="primary" onClick={store.filterResult} icon={<FilterOutlined />}>
                                Szűrés
                            </Button>
                        </Space>
                        }
                    </>
                )}
            </Observer>
            <TableSummaryHeader items={summaryItems} />
            <Table
                onChange={store.reloadList}
                pagination={paginationConfig}
                size="middle"
                columns={cols}
                dataSource={store.items}
                scroll={tableScroll}
                rowKey="id" />
        </PageHeader>
    );
}

export default withAuditEventListStore(AuditEventList);