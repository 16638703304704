import { DownloadOutlined, DownOutlined, FileExcelOutlined, FilePdfOutlined, PrinterOutlined } from "@ant-design/icons";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import ITaxiVoucherAdapter from "Adapters/ITaxiVoucherAdapter";
import { Button, Col, DatePicker, Dropdown, Input, Menu, PageHeader, Row, Select, Space, Table, Typography } from "antd";
import { CostPlacementType, CostPlacementTypeEntries } from "Models/CostPlacementType";
import { PrintOptions } from "Models/PrintOptions";
import { TaxiVoucherLimitEntries } from "Models/TaxiVoucherLimit";
import moment from "moment";
import { CostPlacementTypeSelector } from "Pages/CommonControls/CostPlacementTypeSelector";
import NumberBox from "Pages/CommonControls/NumberBox";
import { useVouchersStore } from "Pages/Partner/Vouchers/VouchersStore";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { VoucherCreationColumns } from "./VoucherCreationColumns";
import { useVoucherCreationStore, withVoucherCreationStore } from "./VoucherCreationStore";
const { Title } = Typography;
const { Option } = Select;


export interface IVoucherCreationProps {
    voucherApiApadter: ITaxiVoucherAdapter;
    isElevated: boolean;
}

export interface IVoucherCreationCoreProps extends IVoucherCreationProps {
    partnerId?: number;
}

function VoucherCreationCore(props: IVoucherCreationCoreProps) {

    const listStore = useVouchersStore();
    const store = useVoucherCreationStore();
    const navigate = useNavigate();

    const goBack = useCallback(() => {
        if (store.createdVouchers?.length > 0) {
            listStore.vLoadAsync.fireAndForget();
        }
        navigate(-1);
    }, [store.createdVouchers?.length]);

    const downloadMenu = (
        <Menu>
            <div>
                <Button onClick={() => store.printListAsync.fireAndForget(PrintOptions.PrintToPdf)} icon={<FilePdfOutlined />} >
                    Lista letöltése PDF-ben
                </Button>
            </div>
            <div>
                <Button onClick={() => store.printListAsync.fireAndForget(PrintOptions.PrintToExcel)} icon={<FileExcelOutlined />} >
                    Lista letöltése Excelben
                </Button>
            </div>
        </Menu>
    );

    return (
        <>
            <PageHeader
                title="Új azonosító igénylés"
                footer={
                    <Row gutter={[8, 8]} >
                        <Col xxl={3} xl={4} lg={5} md={6} xs={8}>
                            <Button style={{ width: "100%" }} onClick={goBack}>
                                {!!store.createdVouchers?.length ? "Rendben" : "Mégsem"}
                            </Button>
                        </Col>
                        {!store.createdVouchers?.length &&
                            <Col xxl={3} xl={4} lg={5} md={6} xs={8}>
                                <Button style={{ width: "100%" }} key="submit" type="primary" loading={store.isLoading} onClick={store.create}>
                                    Igénylés
                                </Button>
                            </Col>
                        }
                        {!!store.createdVouchers?.length &&
                            <Col xxl={3} xl={4} lg={5} md={6} xs={8}>
                                <Button style={{ width: "100%" }} key="submit" type="primary" loading={store.isLoading} onClick={store.empty}>
                                    Új Igénylés
                                </Button>
                            </Col>
                        }
                    </Row>
                }
            >
                {!store.createdVouchers?.length && (
                    <>
                        <Row gutter={16}>
                            <Col xl={5} lg={7} md={10} xs={12}>
                                <Typography.Text>Érvényességi idő:</Typography.Text>
                                <DatePicker
                                    style={{ width: "100%" }}
                                    value={store.validity}
                                    onChange={store.setValidity}
                                    format={store.dateFormat}
                                />
                            </Col>
                            <Col xl={5} lg={7} md={10} xs={12}>
                                <Typography.Text>Fix összeg:</Typography.Text>
                                <Select
                                    style={{ width: "100%" }}
                                    value={store.cost}
                                    onChange={store.setVoucherLimit}
                                >
                                    {TaxiVoucherLimitEntries.map(i =>
                                        <Option key={i.enumValue} value={i.enumValue}>
                                            {i.displayValue}
                                        </Option>
                                    )}
                                </Select>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xl={10} lg={14} md={20} xs={24}>
                                <CostPlacementTypeSelector
                                    showLabels
                                    costPlacementType={store.costPlacementType}
                                    costPlacementValue={store.costPlacement}
                                    onCostPlacementTypeChange={store.setCostPlacementType}
                                    onCostplacementChange={store.setCostPlacement} />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col lg={5} md={10} xs={12}>
                                <NumberBox
                                    label="Darabszám:"
                                    onChange={store.setCount}
                                    value={store.count}
                                    maxValue={props.isElevated ? 10000 : 50}
                                />
                            </Col>
                        </Row>
                    </>
                )}
                {
                    !!store.createdVouchers?.length && (
                        <>
                            <Row gutter={[8, 8]} style={{ marginBottom: "8px" }}>
                                <Col xxl={3} xl={4} lg={5} md={6} xs={8}>
                                    <Dropdown.Button style={{ width: "100%" }} onClick={() => store.printListAsync.fireAndForget(PrintOptions.PrintToPdf)} icon={<DownOutlined />} overlay={downloadMenu}>
                                        Lista letöltése
                                        <DownloadOutlined />
                                    </Dropdown.Button>
                                </Col>
                                <Col xxl={3} xl={4} lg={5} md={6} xs={8}>
                                    <Button style={{ width: "100%" }} icon={<PrinterOutlined />} onClick={store.printTicketsAsync.fireAndForget}>
                                        Szelvények nyomtatása
                                    </Button>
                                </Col>
                            </Row>
                            <Table size="middle" columns={VoucherCreationColumns} dataSource={store.createdVouchers} rowKey="identifier" />
                        </>
                    )
                }
            </PageHeader>
        </>
    );
}

const VoucherCreationCoreWithStore = withVoucherCreationStore(VoucherCreationCore);

export default function VoucherCreation(props: IVoucherCreationProps) {
    const { partnerId } = useParams();
    return (
        <VoucherCreationCoreWithStore {...props} partnerId={isNullOrUndefined(partnerId) ? undefined : parseInt(partnerId!)} />
    )
}