import { createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import Ordering from "Adapters/QueryHelpers/Ordering";
import { action, observable, runInAction } from "mobx";
import { CostPlacementType } from "Models/CostPlacementType";
import { PrintOptions } from "Models/PrintOptions";
import { TaxiVoucherLimit } from "Models/TaxiVoucherLimit";
import Voucher from "Models/Voucher";
import moment from "moment";
import { downloadBase64Async } from "Util/FileDownloader";
import { IVoucherCreationCoreProps } from "./VoucherCreation";

export default class VoucherCreationStore extends StoreBase<IVoucherCreationCoreProps> {
    public readonly dateFormat = "YYYY.MM.DD";

    @observable public count = 0;
    @observable.ref public cost: TaxiVoucherLimit = TaxiVoucherLimit.NoLimit;
    @observable.ref public validity = moment().add(395, "days");
    @observable public costPlacement = "";
    @observable.ref public costPlacementType = CostPlacementType.None;
    @observable.ref public createdVouchers: Voucher[] = [];

    private get filter() {
        return {
            ids: this.createdVouchers?.map(v => v.id) ?? []
        };

    }
    @action.bound
    setVoucherLimit(value: TaxiVoucherLimit) {
        this.cost = value;
    }

    @action.bound
    setValidity(momentValue: moment.Moment | null) {
        if (!!momentValue) {
            this.validity = momentValue;
        }
    }

    @action.bound
    setCostPlacement(value: string) {
        this.costPlacement = value;
    }

    @action.bound
    setCostPlacementType(value: CostPlacementType) {
        this.costPlacementType = value;
    }

    @action.bound
    setCount(value: number | null) {
        this.count = value ?? 1;
    }

    public create = this.async(async () => {
        const res = await this.props.voucherApiApadter.createVouchersAsync!(this.count, this.cost, this.validity, this.costPlacementType, this.costPlacement, this.props.partnerId);
        runInAction(() => {
            this.createdVouchers = res.vouchers;
        });
    });

    @action.bound
    public empty() {
        this.cost = TaxiVoucherLimit.NoLimit;
        this.costPlacement = "";
        this.costPlacementType = CostPlacementType.None;
        this.validity = moment().add(395, "days");
        this.createdVouchers = [];
    }

    public readonly printListAsync = this.async(async (option: PrintOptions) => {
        const ordering = new Ordering("identifier", true);
        const result = await this.props.voucherApiApadter.printVoucherListAsync(ordering, this.filter, option, this.props.partnerId);
        const date = moment().format("YYYY_MM_DD");
        switch (option) {
            case PrintOptions.PrintToExcel:
                await downloadBase64Async(result, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `Azonosító_szelvények_${date}.xlsx`);
                return;
            default:
                await downloadBase64Async(result, "application/pdf", `Azonosító_szelvények_${date}.pdf`);
            // TODO other options
        }
    });

    public readonly printTicketsAsync = this.async(async () => {
        const ordering = new Ordering("identifier", true);
        const result = await this.props.voucherApiApadter.printVoucherTicketsAsync!(ordering, this.filter, this.props.partnerId);
        await downloadBase64Async(result, "application/pdf", "Azonosító_jegyek.pdf");
    });
}

const { ContextComponent: VoucherCreationStoreContext, StoreProvider: VoucherCreationStoreProvider, useStore: useVoucherCreationStore, withStore: withVoucherCreationStore } =
    createStoreProvider<IVoucherCreationCoreProps, VoucherCreationStore>(() => new VoucherCreationStore());

export {
    VoucherCreationStoreContext,
    VoucherCreationStoreProvider,
    useVoucherCreationStore,
    withVoucherCreationStore
};