import { DownloadOutlined, FilterOutlined } from "@ant-design/icons";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import IInvoiceSummaryApiAdapter from "Adapters/IInvoiceSummaryApiAdapter";
import { Button, Col, DatePicker, Input, PageHeader, Row, Select, Table, TablePaginationConfig, Typography } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";
import TextBox from "Pages/CommonControls/TextBox";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { InvoiceSummaryColumns } from "./Common/InvoiceSummaryColumns";
import { useInvoiceSummaryListStore, withInvoiceSummaryListStore } from "./InvoiceSummaryListStore";

const { Title } = Typography;

export interface IInvoiceSummaryListProps {
    invoiceSummaryApiApadter: IInvoiceSummaryApiAdapter;
}

export interface IInvoiceSummaryListCoreProps extends IInvoiceSummaryListProps {
    partnerId?: number;
}

function InvoiceSummaryListCore(props: IInvoiceSummaryListCoreProps) {
    const store = useInvoiceSummaryListStore();

    const paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    }

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    return (
        <>
            <PageHeader title={<span id="page_title">Számlaösszesítők</span>}>
                <Row gutter={16}>
                    <Col span={4}>
                        <Title level={5}>Időszak:</Title>
                        <DatePicker
                            style={{ width: "100%" }}
                            value={store.invoiceSummaryFilter.createdOn}
                            onChange={store.invoiceSummaryFilter.setCreatedOn}
                            format={store.dateFormat}
                        />
                    </Col>
                    <Col span={4}>
                        <Title level={5}>Azonosító:</Title>
                        <TextBox
                            style={{ width: "100%" }}
                            onChange={store.invoiceSummaryFilter.setIdentifier}
                            value={store.invoiceSummaryFilter.identifier}
                            onEnter={store.filterResult}
                        />
                    </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: "16px", marginBottom: "16px" }}>
                    {/* <div >
                        <Button key="2" style={{ marginRight: "1rem", width: "9.5rem" }} icon={<DownloadOutlined />} >
                            Lista letöltése
                        </Button>
                    </div> */}
                    <Col xxl={3} xl={2} lg={5} md={6} xs={8}>
                        <Button key="4" style={{ width: "100%" }} onClick={store.filterResult} type="primary" icon={<FilterOutlined />}>
                            Szűrés
                        </Button>
                    </Col>
                </Row>
                <TableSummaryHeader items={summaryItems} />
                <Table onChange={store.reloadList} pagination={paginationConfig} size="middle" columns={InvoiceSummaryColumns} dataSource={store.invoiceSummaries} rowKey="id" />
            </PageHeader>
        </>
    );
}

const InvoiceSummaryListCoreWithStore = withInvoiceSummaryListStore(InvoiceSummaryListCore);

export default function InvoiceList(props: IInvoiceSummaryListProps) {
    const { partnerId } = useParams();
    return (
        <InvoiceSummaryListCoreWithStore {...props} partnerId={isNullOrUndefined(partnerId) ? undefined : parseInt(partnerId!)} />
    )
}