import { IGetConsentsRequest, IGetConsentsResponse, ISavedConsentDto } from "Adapters/Api/Api.g";
import { mapAdminConsentListFilterToDto, mapConsentListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import ConsentFilter from "Models/Filters/ConsentFilter";
import ConsentListItem from "Models/ConsentListItem";
import { SavedConsent, SavedConsentItem } from "Models/SavedConsent";
import moment from "moment";


class AdminConsentApiAdapter extends ProtectedApiAdapterBase {
    private readonly mainRoute = "api/web/admin/Consents";

    public getListAsync(paging: Paging, ordering: Ordering, filter: ConsentFilter): Promise<{ totalCount: number, consents: ConsentListItem[] }> {
        const request: IGetConsentsRequest = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapAdminConsentListFilterToDto(filter),
        }

        return this.httpPost<{ totalCount: number, consents: ConsentListItem[] }>(this.mainRoute, undefined, request).map((paginatedResult: IGetConsentsResponse) => {
            return {
                totalCount: paginatedResult.totalCount,
                consents: paginatedResult.items.map((i: any) => {
                    return mapConsentListItemDtoToDomainModel(i);
                }),
            };
        }).performOperationAsync();
    }

    public getAsync(id: number): Promise<SavedConsent> {
        return this.httpGet<SavedConsent>(this.mainRoute, [id.toString()]).map((response: ISavedConsentDto) => {
            return new SavedConsent(
                response.consentId,
                response.statementType,
                response.statementContentHtml,
                moment(response.createdAt),
                response.items.map(i => new SavedConsentItem(i.statementItemId, i.statementItemDisplayValue, i.isAccepted, i.checkedAt == null ? null : moment(i.checkedAt)))
            );
        }).performOperationAsync();
    }
}

export default new AdminConsentApiAdapter();
