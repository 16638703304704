import { bound, createStoreProvider, isNullOrUndefined, StoreBase } from "@eblsoft/react-toolkit";
import { IInvoiceListFilterDto } from "Adapters/Api/Api.g";
import { applyInvoiceListFilterDtoTo, mapInvoiceListFilterToDto } from "Adapters/Mapping/Map";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { action, computed, observable, runInAction } from "mobx";
import InvoiceFilter from "Models/Filters/InvoiceFilter";
import InvoiceForPartnerListItem from "Models/InvoiceForPartnerListItem";
import { PrintOptions } from "Models/PrintOptions";
import moment from "moment";
import { downloadBase64Async } from "Util/FileDownloader";
import { IInvoiceListCoreProps } from "./InvoiceList";

interface IFilterStateStorage {
    filter: IInvoiceListFilterDto;
    orderedBy: string;
    orderDirection: string;
}

export default class InvoiceListStore extends StoreBase<IInvoiceListCoreProps> {
    @observable.ref public invoices: InvoiceForPartnerListItem[] = [];
    @observable.ref public totalGrossPrice = 0;
    @observable.ref public recordNum = 100;
    @observable.ref public pageSize = 50;
    @observable.ref public currentPage = 1;
    @observable.ref public showExtendedColumns = false;

    public orderedBy = "invoiceNumber";
    public orderDirection = "ascend";
    public invoiceFilter = new InvoiceFilter();

    private getFilterSessionKey() { return `invoice_list_filter_v1_${!isNullOrUndefined(this.props.partnerId) ? "partner" : "admin"}`; }

    public readonly vLoadAsync = this.async(async () => {
        await this.restoreState();
        await this.loadListAsync();
    });

    private readonly loadListAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await this.props.invoiceApiApadter.getListAsync(paging, ordering, this.invoiceFilter, this.props.isSummary ? undefined : this.props.partnerId);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.invoices = result.invoices;
            this.totalGrossPrice = result.totalGrossPrice;
        });
    });

    private restoreState() {
        try {
            const saved = sessionStorage.getItem(this.getFilterSessionKey());
            if (saved) {
                const stateStorage = JSON.parse(saved);
                runInAction(() => {
                    applyInvoiceListFilterDtoTo(stateStorage.filter, this.invoiceFilter);
                    this.orderedBy = stateStorage.orderedBy;
                    this.orderDirection = stateStorage.orderDirection;
                });
            }
        } catch { }
    }

    private saveState() {
        try {
            const filterStorage = mapInvoiceListFilterToDto(this.invoiceFilter);
            sessionStorage.setItem(this.getFilterSessionKey(), JSON.stringify({
                filter: filterStorage,
                orderedBy: this.orderedBy,
                orderDirection: this.orderDirection
            }));
        }
        catch { }
    }

    @bound
    public clearState() {
        this.invoiceFilter.clear();
        sessionStorage.removeItem(this.getFilterSessionKey());
        this.filterResult();
    }

    @action.bound
    public filterResult() {
        this.saveState();
        this.currentPage = 1;
        this.invoiceFilter.areFiltersDirty = false;
        this.loadListAsync.fireAndForget();
    }

    public readonly printAsync = this.async(async (option: PrintOptions) => {
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await this.props.invoiceApiApadter.printInvoicesAsync(ordering, this.invoiceFilter, option, this.showExtendedColumns, this.props.isSummary ? undefined : this.props.partnerId);
        const date = moment().format("YYYY_MM_DD");
        switch (option) {
            case PrintOptions.PrintToExcel:
                await downloadBase64Async(result, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `számlák_${date}.xlsx`);
                return;
            default:
                await downloadBase64Async(result, "application/pdf", `számlák_${date}.pdf`);
        }
    });

    @action.bound
    public print(option: PrintOptions) {
        this.printAsync.fireAndForget(option);
    }

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<InvoiceForPartnerListItem> | SorterResult<InvoiceForPartnerListItem>[], extra: TableCurrentDataSource<InvoiceForPartnerListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (Array.isArray(sorter)) {
            this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
            this.orderDirection = sorter[0].order!;
        } else {
            this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
            this.orderDirection = sorter.order!;
        }
        this.saveState();
        this.loadListAsync.fireAndForget();
    }

    public readonly downloadPdfAsync = this.async(async (invoice: InvoiceForPartnerListItem) => {
        const result = await this.props.invoiceApiApadter.getPdfAsync!(invoice.id, this.props.partnerId);
        await downloadBase64Async(result, "application/pdf", `eTaxi_szla__${invoice.invoiceNumber.replace(/\\\//gi, "_")}.pdf`);
    });

    @action.bound
    public setShowExtendedColumns(value: boolean) {
        this.showExtendedColumns = value;
    }

}



const { ContextComponent: InvoiceListStoreContext, StoreProvider: InvoiceListStoreProvider, useStore: useInvoiceListStore, withStore: withInvoiceListStore } =
    createStoreProvider<IInvoiceListCoreProps, InvoiceListStore>(() => new InvoiceListStore());

export {
    InvoiceListStoreContext,
    InvoiceListStoreProvider,
    useInvoiceListStore,
    withInvoiceListStore
};