import { Col, Modal, Row } from "antd";
import ProhibitionInfo from "Models/ProhibitionInfo";
import TextBox from "./TextBox";
import { observer } from "mobx-react";
import AutoCompleteBox from "./AutoCompleteBox";
import { ProhibitReasonOption } from "Models/ProhibitReasonOption";

export interface IProhibitionInfoBoxProps {
    prohibitionInfo: ProhibitionInfo
}

function ProhibitionInfoBox(props: IProhibitionInfoBoxProps) {

    return (
        <>
            <Row>
                <Col span={24}>
                    <TextBox label="Felhasználó neve:" onChange={props.prohibitionInfo.setUseName} value={props.prohibitionInfo.userName} isRequired maxLength={256} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <AutoCompleteBox label="Indoklás:" style={{ display: "flex" }} setValue={props.prohibitionInfo.setProhibitionReason} value={props.prohibitionInfo.prohibitionReason} options={ProhibitReasonOption} maxLength={512} />
                </Col>
            </Row>
        </>
    );
}

export default observer(ProhibitionInfoBox);