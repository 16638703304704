import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import ITaxiCardApiAdapter from "Adapters/ITaxiCardApiAdapter";
import { Outlet, useParams } from "react-router-dom";
import { withTaxiCardsStore } from "Pages/Partner/TaxiCards/TaxiCardsStore";

export interface ITaxiCardListProps {
    taxiCardApiAdapter: ITaxiCardApiAdapter;
    isSummary: boolean;
}

export interface ITaxiCardListCoreProps extends ITaxiCardListProps {
    partnerId?: number;
    isSummary: boolean;
}

function TaxiCardsCore() {
    return (
        <Outlet />
    );
}


const TaxiCardsCoreWithStore = withTaxiCardsStore(TaxiCardsCore);

export default function TaxiCards(props: ITaxiCardListProps) {
    const { partnerId } = useParams();
    return (
        <TaxiCardsCoreWithStore {...props} partnerId={isNullOrUndefined(partnerId) ? undefined : parseInt(partnerId!)} />
    )
}

