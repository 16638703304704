import { EnumHelper } from "Util/EnumHelper";

export enum DocumentStatus {
    NoData = "NoData",
    PendingConfirmation = "PendingConfirmation",
    CannotConfirm = "CannotConfirm",
    Confirmed = "Confirmed",
    WillBeExpired = "WillBeExpired",
    Expired = "Expired",
}

function getDocumentStatusEntries() {
    return EnumHelper.getEnumEnumerableEntries<DocumentStatus>(DocumentStatus, getDocumentStatusDisplayValue);
}

export function getDocumentStatusDisplayValue(value: DocumentStatus) {

    switch(value) {
        case DocumentStatus.NoData: return "Nincs feltöltött adat";
        case DocumentStatus.PendingConfirmation: return "Ellenőrzésre vár";
        case DocumentStatus.CannotConfirm: return "Sikertelen ellenőrzés";
        case DocumentStatus.Confirmed: return "Rendben";
        case DocumentStatus.WillBeExpired: return "Hamarosan lejár";
        case DocumentStatus.Expired: return "LEJÁRT";
    }
}

export function getDocumentStatusDisplayValueWithExpirationDate(value: DocumentStatus, updatedAt: moment.Moment | null, validTo: moment.Moment | null) {

    switch(value) {
        case DocumentStatus.NoData: return "Nincs feltöltött adat";
        case DocumentStatus.PendingConfirmation: return `Ellenőrzésre vár: ${updatedAt?.format("L LT") ?? "?"} óta`;
        case DocumentStatus.CannotConfirm: return "Sikertelen ellenőrzés";
        case DocumentStatus.Confirmed: return `Rendben, lejár: ${validTo?.format("L") ?? "?"}`;
        case DocumentStatus.WillBeExpired: return `Hamarosan lejár: ${validTo?.format("L") ?? "?"}`;
        case DocumentStatus.Expired: return `LEJÁRT: ${validTo?.format("L") ?? "?"}`;
    }
}

export const DocumentStatusEntries = getDocumentStatusEntries();