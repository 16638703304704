import { Button, CheckboxOptionType, Col, DatePicker, PageHeader, Radio, Row, Space, Table, Tabs } from "antd";
import Title from "antd/lib/typography/Title";
import AccountedRideDetails from "Models/AccountedRideDetails";
import { getVoucherTypeDisplayValue } from "Models/VoucherType";
import PartnerView from "Pages/CommonControls/PartnerView";
import ReadonlyInput from "Pages/CommonControls/ReadonlyInput";
import React from "react";
import { useParams } from "react-router-dom";
import { useOfflineRideDetailStore, withOfflineRideDetailStore } from "./OfflineRideDetailStore";
import { formatHuf } from "Util/PriceFormatter";
import { LinkOutlined, DeleteOutlined } from "@ant-design/icons";
import TextBox from "Pages/CommonControls/TextBox";
import TaxiDriver from "Models/TaxiDriver";
import { RideSearcherMode } from "Models/RideSearcherMode";
import RideSearcher from "Pages/CommonControls/RideSearcher/RideSearcher";
import DeleteOfflineRideConfirmationDialog from "./DeleteOfflineRideConfirmationDialog";

const { RangePicker } = DatePicker;

export interface IOfflineRideDetailCoreProps {
    offlineRideId: number;
}

export interface IOfflineRideDetailProps {

}

function OfflineRideDetailCore(props: IOfflineRideDetailCoreProps) {

    const store = useOfflineRideDetailStore();

    return (
        <PageHeader title="Fuvar nélküli elszámolás">
            {store.rideDetails && (
                <>
                    <Row gutter={32}>
                        <Col span={12}>
                            <Title level={4}>Partner:</Title>
                            <PartnerView partner={store.rideDetails.partner} />
                        </Col>
                        <Col span={12}>
                            <Title level={4}>Sofőr:</Title>
                            <DriverView driver={store.rideDetails.taxiDriver} />

                            <Title level={4} style={{ marginTop: 16 }}>Fuvar nélküli elszámolás:</Title>
                            <OfflineRideView ride={store.rideDetails} />
                        </Col>
                    </Row>

                    <Title level={4} style={{ marginTop: 32 }}>Fuvarhoz rendelés:</Title>
                    <RideSearcher
                        defaultRidesTo={store.ridesTo}
                        defaultRidesFrom={store.ridesFrom}
                        selectedRide={store.selectedRide}
                        taxiDriverId={store.rideDetails.taxiDriver.id}
                        rideSearcherMode={store.rideSearcherMode}
                        manualRideStore={store.manualRideStore}
                        callbacks={store.callbacks}
                    />

                    <Space style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: 24 }}>

                        <Button type="primary" icon={<LinkOutlined />} onClick={store.saveConnectedToRideOrManuallyAsync.fireAndForget}>
                            Mentés
                        </Button>
                        <Button type="primary" danger icon={<DeleteOutlined />} onClick={store.showDeleteConfirmation}>
                            Törlés
                        </Button>
                        <Button type="default" onClick={store.cancel}>
                            Mégsem
                        </Button>
                    </Space>
                </>
            )}
            <DeleteOfflineRideConfirmationDialog 
                isLoading={store.isLoading}
                isOpen={store.isDeleteConfirmationModalOpen}
                onCancel={store.cancelDeleteConfirmation}
                onOk={store.deleteAsync.fireAndForget}
                modifierName={store.modifierName}
                modificationReason={store.modificationReason}
                onModifierNameChange={store.setModifierName}
                onModificationReasonChange={store.setModificationReason}
            />
        </PageHeader >
    );
}

function OfflineRideView({ ride }: { ride: AccountedRideDetails }) {
    return (
        <>
            <Row gutter={[16, 8]}>
                <Col span={24}><ReadonlyInput label="Elszámolás azonosító:" value={ride.publicIdentifier} /></Col>

                <Col span={10}><ReadonlyInput label="Elszámolás ideje:" value={ride.createdAt.format("L LTS")} /></Col>
                <Col span={14}><ReadonlyInput label="Azonosító:" value={`${ride.voucherInfo} (${getVoucherTypeDisplayValue(ride.voucherType)})`} /></Col>

                <Col span={10}><ReadonlyInput label="Összeg:" value={formatHuf(ride.grossPrice)} /></Col>
                <Col span={14}><ReadonlyInput label="Költséghely:" value={ride.costPlacement ?? "Nincs"} /></Col>
            </Row>
        </>
    );
}

function DriverView({ driver }: { driver: TaxiDriver }) {
    return (
        <>
            <Row gutter={[16, 8]}>
                <Col span={8}><ReadonlyInput label="BPID:" value={driver.bpid} /></Col>
                <Col span={16}><ReadonlyInput label="Név:" value={driver.name} /></Col>
            </Row>
        </>
    );
}

const OfflineRideDetailWithStore = withOfflineRideDetailStore(OfflineRideDetailCore);

export default function OfflineRideDetail(props: IOfflineRideDetailProps) {
    const { offlineRideId } = useParams();
    return (
        <OfflineRideDetailWithStore {...props} offlineRideId={parseInt(offlineRideId!)} />
    )
}