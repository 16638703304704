import { PrinterOutlined } from "@ant-design/icons";
import { Button, Space, Tooltip } from "antd";
import { useCallback } from "react";
import InvoiceForPartnerListItem from "Models/InvoiceForPartnerListItem";
import { useInvoiceSummaryDetailStore } from "Pages/Partner/InvoiceSummaries/Detail/InvoiceSummaryDetailStore";

export default function InvoiceActions({ invoice }: { invoice: InvoiceForPartnerListItem }) {

    const store = useInvoiceSummaryDetailStore();
    const print = useCallback(() => {
        store.downloadInvoicePdfAsync.fireAndForget(invoice);
    }, [invoice]);

    return (
        <Space size="middle">
            <Tooltip title="Számla letöltése PDF-ben">
                <Button onClick={print} icon={<PrinterOutlined />} />
            </Tooltip>
        </Space>
    );
}