import { createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import AdminTaxiCardProhibitionEventAdapter from "Adapters/AdminAdapter/AdminTaxiCardProhibitionEventAdapter";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { FilterValue, SorterResult, TableCurrentDataSource, TablePaginationConfig } from "antd/lib/table/interface";
import { action, computed, observable, runInAction } from "mobx";
import TaxiCardProhibitionEventFilter from "Models/Filters/TaxiCardProhibitionEventFilter";
import { PrintOptions } from "Models/PrintOptions";
import TaxiCardProhibitionEventListItem from "Models/TaxiCardProhibitionEventListItem";
import moment from "moment";
import { downloadBase64Async } from "Util/FileDownloader";

export default class TaxiCardProhibitionEventStore extends StoreBase {
    @observable.ref public taxiCardProhibitionEvents: TaxiCardProhibitionEventListItem[] = [];
    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    public orderedBy = "identifier";
    public orderDirection = "ascend";
    public taxiCardProhibitionEventFilter = new TaxiCardProhibitionEventFilter();
    @observable.ref public recordIdUnderEdit: number | null = null;

    public readonly vLoadAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await AdminTaxiCardProhibitionEventAdapter.getListAsync(paging, ordering, this.taxiCardProhibitionEventFilter);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.taxiCardProhibitionEvents = result.taxiCardProhibitionEvents;
        });
    });

    @action.bound
    public filterResult() {
        this.currentPage = 1;
        this.taxiCardProhibitionEventFilter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    private readonly updateAsync = this.async(async (newData: TaxiCardProhibitionEventListItem) => {
        const result = await AdminTaxiCardProhibitionEventAdapter.updateAsync(newData.id, newData.status);
        if (result) {
            this.vLoadAsync.fireAndForget();
        }
    });

    @action.bound
    public setRecordUnderEdit(id: number | null) {
        this.recordIdUnderEdit = id;
    }

    @action.bound
    public saveRecordUnderEdit() {
        const newData = this.taxiCardProhibitionEvents.find(p => p.id === this.recordIdUnderEdit)!;
        this.updateAsync.fireAndForget(newData);
        this.recordIdUnderEdit = null;
    }

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<TaxiCardProhibitionEventListItem> | SorterResult<TaxiCardProhibitionEventListItem>[], extra: TableCurrentDataSource<TaxiCardProhibitionEventListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (Array.isArray(sorter)) {
            this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
            this.orderDirection = sorter[0].order!;
        } else {
            this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
            this.orderDirection = sorter.order!;
        }
        this.vLoadAsync.fireAndForget();
    }

    public readonly printAsync = this.async(async (option: PrintOptions) => {
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await AdminTaxiCardProhibitionEventAdapter.printAsync(ordering, this.taxiCardProhibitionEventFilter, option);
        const date = moment().format("YYYY_MM_DD");
        switch (option) {
            case PrintOptions.PrintToExcel:
                await downloadBase64Async(result, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `taxikártya_letiltások_${date}.xlsx`);
                return;
            default:
                await downloadBase64Async(result, "application/pdf", `taxikártya_letiltások_${date}.pdf`);
            // TODO other options
        }
    });

    @action.bound
    public print(option: PrintOptions) {
        this.printAsync.fireAndForget(option);
    }
}

const { ContextComponent: taxiCardProhibitionEventStoreContext, StoreProvider: taxiCardProhibitionEventStoreProvider, useStore: useTaxiCardProhibitionEventStore, withStore: withTaxiCardProhibitionEventStore } = createStoreProvider(() => new TaxiCardProhibitionEventStore());

export {
    taxiCardProhibitionEventStoreContext,
    taxiCardProhibitionEventStoreProvider,
    useTaxiCardProhibitionEventStore,
    withTaxiCardProhibitionEventStore
};