import Styles from "./App.module.css";
import { Layout, Spin } from "antd";
import AppMenu from "./AppMenu";
import { Outlet, Route, Routes } from "react-router-dom";
import Vouchers from "Pages/Partner/Vouchers/Vouchers";
import VoucherList from "Pages/Partner/Vouchers/VoucherList";
import { useEffect, useState } from "react";
import AuthStore from "Stores/AuthStore";
import { LoadingStateStore } from "@eblsoft/react-toolkit";
import { observer } from "mobx-react-lite";
import Config from "Config";
import SettingsAdapter from "Adapters/SettingsAdapter/SettingsAdapter";
import Home from "Pages/Home/Home";
import VoucherCreation from "Pages/Partner/Vouchers/Creation/VoucherCreation";
import PartnerData from "Pages/Partner/PartnerData/PartnerData";
import PhoneNumbers from "Pages/Partner/PhoneNumbers/PhoneNumbers";
import PhoneNumberList from "Pages/Partner/PhoneNumbers/PhoneNumberList";
import Partners from "Pages/Admin/Partners/Partners";
import PartnerList from "Pages/Admin/Partners/PartnerList";
import PartnerDataAdapter from "Adapters/PartnerAdapters/PartnerDataAdapter";
import AdminPartnerAdapter from "Adapters/AdminAdapter/AdminPartnerAdapter";
import TaxiCardList from "Pages/Partner/TaxiCards/TaxiCardList";
import PartnerVoucherAdapter from "Adapters/PartnerAdapters/PartnerVoucherAdapter";
import AdminPartnerTaxiVoucherAdapter from "Adapters/AdminAdapter/AdminPartnerTaxiVoucherAdapter";
import TaxiDriverList from "Pages/Admin/TaxiDrivers/TaxiDriverList";
import PartnerPhoneNumberAdapter from "Adapters/PartnerAdapters/PartnerPhoneNumberAdapter";
import AdminPartnerPhoneNumberAdapter from "Adapters/AdminAdapter/AdminPartnerPhoneNumberAdapter";
import TaxiCardCreation from "Pages/Partner/TaxiCards/TaxiCardCreation/TaxiCardCreation";
import TaxiCards from "Pages/Partner/TaxiCards/TaxiCardCreation/TaxiCards";
import logo from "./city-taxi-logo.png";
import PartnerDetailWrapper from "Pages/Admin/PartnerDetail/PartnerDetailWrapper";
import PartnerTaxiCardAdapter from "Adapters/PartnerAdapters/PartnerTaxiCardAdapter";
import AdminPartnerTaxiCardAdapter from "Adapters/AdminAdapter/AdminPartnerTaxiCardAdapter";
import OfflineRideList from "Pages/Admin/OfflineRideList/OfflineRideList";
import FailedRideList from "Pages/Admin/FailedRideList/FailedRideList";
import FailedRideDetail from "Pages/Admin/FailedRideDetail/FailedRideDetail";
import OfflineRideDetail from "Pages/Admin/OfflineRideDetail/OfflineRideDetail";
import PartnerInvoiceApiAdapter from "Adapters/PartnerAdapters/PartnerInvoiceApiAdapter";
import AdminPartnerInvoiceApiAdapter from "Adapters/AdminAdapter/AdminPartnerInvoiceApiAdapter";
import InvoiceList from "Pages/Partner/Invoices/InvoiceList";
import InvoiceDetail from "Pages/Partner/Invoices/Detail/InvoiceDetail";
import PartnerInvoiceSummaryApiAdapter from "Adapters/PartnerAdapters/PartnerInvoiceSummaryApiAdapter";
import AdminInvoiceSummaryApiAdapter from "Adapters/AdminAdapter/AdminInvoiceSummaryApiAdapter";
import InvoiceSummaryList from "Pages/Partner/InvoiceSummaries/InvoiceSummaryList";
import InvoiceSummaryDetail from "Pages/Partner/InvoiceSummaries/Detail/InvoiceSummaryDetail";
import UnhandledErrorHandler from "./UnhandledErrorHandler";
import TaxiCardOrderList from "Pages/Admin/TaxiCardOrders/TaxiCardOrderList";
import AdminInvoiceApiAdapter from "Adapters/AdminAdapter/AdminInvoiceApiAdapter";
import AdminTaxiVoucherAdapter from "Adapters/AdminAdapter/AdminTaxiVoucherAdapter";
import AdminPhoneNumberAdapter from "Adapters/AdminAdapter/AdminPhoneNumberAdapter";
import AdminTaxiCardAdapter from "Adapters/AdminAdapter/AdminTaxiCardAdapter";
import Settings from "Pages/Admin/Settings/Settings";
import AdminPollAdapter from "Adapters/AdminAdapter/AdminPollAdapter";
import Polls from "Pages/Admin/Poll/Polls";
import PollList from "Pages/Admin/Poll/PollList";
import PollDetail from "Pages/Admin/Poll/PollDetail/PollDetail";
import Votes from "Pages/Admin/Poll/Votes/Votes";
import TaxiCardProhibitionEventList from "Pages/Admin/TaxiCardProhibitionEvent/TaxiCardProhibitionEventList";
import DocumentListWrapper from "Pages/Admin/Documents/DocumentListWrapper";
import SimpleDocumentDetail from "Pages/Admin/Documents/SimpleDocument/SimpleDocumentDetail/SimpleDocumnetDetail";
import { SimpleDocumentType } from "Models/SimpleDocumentType";
import Documents from "Pages/Admin/Documents/SimpleDocument/SimpleDocuments";
import SimpleDocumentsList from "Pages/Admin/Documents/SimpleDocument/SimpleDocumentList";
import TrafficLicenses from "Pages/Admin/Documents/TrafficLicense/TrafficLicenses";
import TrafficLicenseList from "Pages/Admin/Documents/TrafficLicense/TrafficLicenseList";
import TrafficLicenseDetail from "Pages/Admin/Documents/TrafficLicense/TrafficLicenseDetail/TrafficLicenseDetail";
import VehicleConditions from "Pages/Admin/VehicleCondition/VehicleConditions";
import VehicleConditionList from "Pages/Admin/VehicleCondition/VehicleConditionList";
import VehicleConditionDetail from "Pages/Admin/VehicleCondition/VehicleConditionDetail/VehicleConditionDetail";
import AdminDocumentList from "Pages/Admin/Documents/AdminDocumentList/AdminDocumentList";
import ConsentList from "Pages/Admin/Consents/List/ConsentList";
import ConsentDetail from "Pages/Admin/Consents/Detail/ConsentDetail";
import AuditEventList from "Pages/Admin/AuditEvent/List/AuditEventList";
import CommonFiles from "Pages/Admin/CommonFile/CommonFiles";
import AdminCommonFileApiAdapter from "Adapters/AdminAdapter/AdminCommonFileApiAdapter";
import CommonFileList from "Pages/Admin/CommonFile/CommonFileList";

const { Content, Footer, Sider } = Layout;

async function StartupAsync() {
    Config.settings = await SettingsAdapter.getAsync();
}

const sidebarWidth = 250;
(window as any)["globalLoadingState"] = LoadingStateStore.globalInstance;

function App() {

    const [startupState, setStartupState] = useState<string | null>(null);
    const [startupError, setStartupError] = useState<Error | null>(null);

    useEffect(() => {
        StartupAsync().then(() => {
            AuthStore.startupAsync().then((state) => {
                setStartupState(state);
            });
        }).catch(setStartupError);
    }, [setStartupState, setStartupError]);

    if (!!startupError) {
        return <>
            Startup error: {startupError.name}<br />
            <pre>{startupError.message}</pre><br />
            <pre>{startupError.stack}</pre>
        </>;
    }

    if (!startupState || startupState === "redirecting-to-login") {
        return <>Loading...</>;
    }

    return (
        <UnhandledErrorHandler>
            <Layout hasSider>
                <Sider
                    className={Styles.sider}
                    width={sidebarWidth}
                >
                    <div className={Styles.logo} />
                    <h5 style={{ color: "white", textAlign: "center" }}>{AuthStore.user?.identifier}</h5>
                    <AppMenu />
                    <div style={{ flex: "1" }} />
                    <img src={logo} alt="City Taxi logo" className={Styles.cityTaxiLogo} />
                    <div style={{ flex: "0 0 20px", color: "white", marginBottom: "2rem", fontSize: 12 }}>eTaxi v{Config.settings.version}</div>
                </Sider>
                <Layout className="site-layout" style={{ marginLeft: sidebarWidth }}>
                    <Spin spinning={LoadingStateStore.globalInstance.isLoading}>
                        <Content style={{ padding: "0 16px", overflow: "initial" }}>
                            <Routes>
                                <Route path="partner">
                                    <Route path="partnerData" element={<PartnerData isCityAdmin={false} partnerApiApadter={PartnerDataAdapter} />} />
                                    <Route path="vouchers" element={<Vouchers isSummary={false} voucherApiApadter={PartnerVoucherAdapter} />}>
                                        <Route index element={<VoucherList isSummary={false} voucherApiApadter={PartnerVoucherAdapter} />} />
                                        <Route path="new" element={<VoucherCreation isElevated={false} voucherApiApadter={PartnerVoucherAdapter} />} />
                                    </Route>
                                    <Route path="phoneNumbers" element={<PhoneNumbers isSummary={false} phoneNumberApiAdapter={PartnerPhoneNumberAdapter} />}>
                                        <Route index element={<PhoneNumberList isSummary={false} phoneNumberApiAdapter={PartnerPhoneNumberAdapter} />} />
                                    </Route>
                                    <Route path="taxiCards" element={<TaxiCards isSummary={false} taxiCardApiAdapter={PartnerTaxiCardAdapter} />} >
                                        <Route index element={<TaxiCardList isSummary={false} taxiCardApiAdapter={PartnerTaxiCardAdapter} />} />
                                        <Route path="new" element={<TaxiCardCreation taxiCardApiApadter={PartnerTaxiCardAdapter} />} />
                                    </Route>
                                    <Route path="invoices">
                                        <Route index element={<InvoiceList invoiceApiApadter={PartnerInvoiceApiAdapter} isSummary={false} isAdmin={false} />} />
                                        <Route path=":invoiceId" element={<InvoiceDetail isSummary={false} invoiceApiApadter={PartnerInvoiceApiAdapter} isAdmin={false} />} />
                                    </Route>
                                    <Route path="invoiceSummaries">
                                        <Route index element={<InvoiceSummaryList invoiceSummaryApiApadter={PartnerInvoiceSummaryApiAdapter} />} />
                                        <Route path=":invoiceSummaryId" element={<InvoiceSummaryDetail invoiceApiApadter={PartnerInvoiceApiAdapter} invoiceSummaryApiApadter={PartnerInvoiceSummaryApiAdapter} />} />
                                    </Route>

                                </Route>
                                <Route path="admin/">
                                    <Route path="partners" element={<Partners />}>
                                        <Route index element={<PartnerList />} />
                                        <Route path=":partnerId" element={<PartnerDetailWrapper />} >
                                            <Route path="PartnerBaseData" element={<PartnerData isCityAdmin partnerApiApadter={AdminPartnerAdapter} />} />
                                            <Route path="phoneNumbers" element={<PhoneNumbers isSummary={false} phoneNumberApiAdapter={AdminPartnerPhoneNumberAdapter} />}>
                                                <Route index element={<PhoneNumberList isSummary={false} phoneNumberApiAdapter={AdminPartnerPhoneNumberAdapter} />} />
                                            </Route>
                                            <Route path="vouchers" element={<Vouchers isSummary={false} voucherApiApadter={AdminPartnerTaxiVoucherAdapter} />}>
                                                <Route index element={<VoucherList isSummary={false} voucherApiApadter={AdminPartnerTaxiVoucherAdapter} />} />
                                                <Route path="new" element={<VoucherCreation isElevated={true} voucherApiApadter={AdminPartnerTaxiVoucherAdapter} />} />
                                            </Route>
                                            <Route path="taxiCards" element={<TaxiCards isSummary={false} taxiCardApiAdapter={AdminPartnerTaxiCardAdapter} />} >
                                                <Route index element={<TaxiCardList isSummary={false} taxiCardApiAdapter={AdminPartnerTaxiCardAdapter} />} />
                                                <Route path="new" element={<TaxiCardCreation taxiCardApiApadter={AdminPartnerTaxiCardAdapter} />} />
                                            </Route>
                                            <Route path="invoices">
                                                <Route index element={<InvoiceList invoiceApiApadter={AdminPartnerInvoiceApiAdapter} isSummary={false} isAdmin={true} />} />
                                                <Route path=":invoiceId" element={<InvoiceDetail isSummary={false} invoiceApiApadter={AdminPartnerInvoiceApiAdapter} isAdmin={true} />} />
                                            </Route>
                                            <Route path="invoiceSummaries">
                                                <Route index element={<InvoiceSummaryList invoiceSummaryApiApadter={AdminInvoiceSummaryApiAdapter} />} />
                                                <Route path=":invoiceSummaryId" element={<InvoiceSummaryDetail invoiceApiApadter={AdminPartnerInvoiceApiAdapter} invoiceSummaryApiApadter={AdminInvoiceSummaryApiAdapter} />} />
                                            </Route>
                                        </Route>
                                    </Route>
                                    <Route path="invoices">
                                        <Route index element={<InvoiceList invoiceApiApadter={AdminInvoiceApiAdapter} isSummary={true} isAdmin={true} />} />
                                        <Route path=":invoiceId" element={<InvoiceDetail isSummary={true} invoiceApiApadter={AdminInvoiceApiAdapter} isAdmin={true} />} />
                                    </Route>
                                    <Route path="vouchers" element={<Vouchers isSummary={true} voucherApiApadter={AdminTaxiVoucherAdapter} />}>
                                        <Route index element={<VoucherList voucherApiApadter={AdminTaxiVoucherAdapter} isSummary={true} />} />
                                    </Route>
                                    <Route path="phoneNumbers" element={<PhoneNumbers isSummary={true} phoneNumberApiAdapter={AdminPhoneNumberAdapter} />}>
                                        <Route index element={<PhoneNumberList isSummary={true} phoneNumberApiAdapter={AdminPhoneNumberAdapter} />} />
                                    </Route>
                                    <Route path="taxiCards" element={<TaxiCards isSummary={true} taxiCardApiAdapter={AdminTaxiCardAdapter} />} >
                                        <Route index element={<TaxiCardList isSummary={true} taxiCardApiAdapter={AdminTaxiCardAdapter} />} />
                                    </Route>
                                    <Route path="polls" element={<Polls pollApiAdapter={AdminPollAdapter} />} >
                                        <Route index element={<PollList pollApiAdapter={AdminPollAdapter} />} />
                                        <Route path="new" element={<PollDetail pollApiAdapter={AdminPollAdapter} />} />
                                        <Route path=":pollId" element={<Outlet />} >
                                            <Route index element={<PollDetail pollApiAdapter={AdminPollAdapter} />} />
                                            <Route path="votes" element={<Votes pollApiAdapter={AdminPollAdapter} />} />
                                        </Route>
                                    </Route>

                                    <Route path="vehicleConditions" element={<VehicleConditions />} >
                                        <Route index element={<VehicleConditionList />} />
                                        <Route path=":vehicleConditionId" element={<Outlet />} >
                                            <Route index element={<VehicleConditionDetail />} />
                                        </Route>
                                    </Route>
                                    {/* <Route path="documents" element={<DocumentListWrapper />}>
                                        <Route path=":simpleDocumentType" element={<Documents />} >
                                            <Route index element={<SimpleDocumentsList />} />
                                            <Route path=":simpleDocumentId" element={<Outlet />} >
                                                <Route index element={<SimpleDocumentDetail />} />
                                            </Route>
                                        </Route>
                                        <Route path="TrafficLicense" element={<TrafficLicenses />} >
                                            <Route index element={<TrafficLicenseList />} />
                                            <Route path=":trafficLicenseId" element={<Outlet />} >
                                                <Route index element={<TrafficLicenseDetail />} />
                                            </Route>
                                        </Route>
                                    </Route> */}
                                    <Route path="commonFiles" element={<CommonFiles commonFileApiAdapter={AdminCommonFileApiAdapter} />} >
                                        <Route index element={<CommonFileList commonFileApiAdapter={AdminCommonFileApiAdapter} />} />
                                    </Route>
                                    <Route path="documents" element={<AdminDocumentList />} />
                                    <Route path="documents/:simpleDocumentType/:simpleDocumentId" element={<SimpleDocumentDetail />} />
                                    <Route path="documents/trafficLicense/:trafficLicenseId" element={<TrafficLicenseDetail />} />
                                    <Route path="taxiDrivers" element={<TaxiDriverList />} />
                                    <Route path="taxiCardOrders" element={<TaxiCardOrderList />} />
                                    <Route path="taxiCardProhibitionEvents" element={<TaxiCardProhibitionEventList />} />
                                    <Route path="offlineRides" element={<OfflineRideList />} />
                                    <Route path="offlineRides/:offlineRideId" element={<OfflineRideDetail />} />
                                    <Route path="failedRides" element={<FailedRideList />} />
                                    <Route path="failedRides/:failedRideId" element={<FailedRideDetail />} />
                                    <Route path="consents" element={<ConsentList />} />
                                    <Route path="consents/:consentId" element={<ConsentDetail />} />
                                    <Route path="auditEvents" element={<AuditEventList />} />
                                    <Route path="settings" element={<Settings />} />
                                </Route>
                                <Route
                                    index
                                    element={<Home />}
                                />
                            </Routes>
                        </Content>
                    </Spin>
                    <Footer style={{ textAlign: "center" }}>City Taxi - eTaxi 2 ©{new Date().getFullYear()}</Footer>
                </Layout>
            </Layout>
        </UnhandledErrorHandler >
    );
}

export default observer(App);
