import base64 from 'base64-js';

function stringToByteArray(str: string): Uint8Array {
    var arr = new Uint8Array(str.length);
    for (var a = 0; a < str.length; a++) {
        arr[a] = str.charCodeAt(a);
    }
    return arr;
}

function byteArrayToString(array: Uint8Array) {
    var result = '';
    for (var i = 0; i < array.length; i++) {
        result += String.fromCharCode(array[i]);
    }
    return result;
}

export function encodeBase64FromString(str: string): string {
    return base64.fromByteArray(stringToByteArray(str));
}

export function encodeBase64FromBytes(bytes: Uint8Array): string {
    return base64.fromByteArray(bytes);
}

export function decodeBase64ToString(str: string): string {
    return byteArrayToString(base64.toByteArray(str));
}

export function decodeBase64ToBytes(str: string): Uint8Array {
    return base64.toByteArray(str);
}

export function encodeBase64UrlFromString(str: string): string {
    return normalToUrl(encodeBase64FromString(str));
}

export function encodeBase64UrlFromBytes(bytes: Uint8Array): string {
    return normalToUrl(encodeBase64FromBytes(bytes));
}

export function decodeBase64UrlToString(str: string): string {
    return decodeBase64ToString(urlToNormal(str));
}

export function decodeBase64UrlToBytes(str: string): Uint8Array {
    return decodeBase64ToBytes(urlToNormal(str));
}

function urlToNormal(str: string) {
    var mod = str.length % 4;
    var pad = 4 - mod;

    if (mod === 0) {
        return str;
    }

    return (str + new Array(1 + pad).join('=')).replace(/-/g, '+').replace(/_/g, '/');
}

function normalToUrl(str: string) {
    return str.replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/gi, "");
}