import { action, makeObservable } from "mobx";
import moment from "moment";
import Address from "./Address";
import { TaxiCardProhibitionEventStatus } from "./TaxiCardProhibitionEventStatus";

export default class TaxiCardProhibitionEventListItem {

    constructor(
        public readonly id: number,
        public status: TaxiCardProhibitionEventStatus,
        public readonly identifier: string,
        public readonly partnerName: string,
        public readonly partnerBpid: string,
        public readonly occuredAt: moment.Moment,
        public readonly prohibitedByUserName: string,
        public readonly prohibitedByName: string | null,
        public readonly prohibitionReason: string | null,
    ) {
        makeObservable(this);
    }

    @action.bound
    public setStatus(status: TaxiCardProhibitionEventStatus) {
        this.status = status;
    }
}