import moment from "moment";

export default class InvoiceSummaryForPartnerListItem {
    constructor(
        public readonly id: number,
        public readonly identifier: string,
        public readonly createdOn: moment.Moment,
        public readonly fromDate: moment.Moment,
        public readonly toDate: moment.Moment,
    ) { }
}

