import { EnumHelper } from "Util/EnumHelper";

// Copy from Api.ts:
export enum TaxiVoucherLimit {
    NoLimit = "NoLimit",
    Limit1000 = "Limit1000",
    Limit1500 = "Limit1500",
    Limit2000 = "Limit2000",
    Limit2500 = "Limit2500",
    Limit3000 = "Limit3000",
    Limit4000 = "Limit4000",
    Limit5000 = "Limit5000",
}

function getTaxiVoucherLimitEntries() {
    return EnumHelper.getEnumEnumerableEntries<TaxiVoucherLimit>(TaxiVoucherLimit, getTaxiVoucherLimitDisplayValue);
}

export function getTaxiVoucherLimitDisplayValue(value: TaxiVoucherLimit) {
    if (value === TaxiVoucherLimit.NoLimit) {
        return "Nem fix";
    }

    return TaxiVoucherLimit[value].replace("Limit", "") + " Ft."
}

export const TaxiVoucherLimitEntries = getTaxiVoucherLimitEntries();