import { IInvoiceDetailCoreProps } from "./InvoiceDetail";
import { bound, createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import { action, computed, observable, runInAction } from "mobx";
import { downloadBase64Async } from "Util/FileDownloader";
import InvoiceDetailForPartnerListItem from "Models/InvoiceDetailForPartnerListItem";
import NotificationStore from "Stores/NotificationStore";
import moment from "moment";
import { Partner } from "Models/Partner";
import Address from "Models/Address";
import { IAccountedRideModificationDto } from "Adapters/Api/Api.g";
import Guid from "Util/Guid";
import AdminInvoiceApiAdapter from "Adapters/AdminAdapter/AdminInvoiceApiAdapter";
import NavigationStore from "Stores/NavigationStore";
import AddressStore from "Models/AddressStore";

export default class InvoiceDetailStore extends StoreBase<IInvoiceDetailCoreProps> {

    @observable.ref public details: InvoiceDetailForPartnerListItem | null = null;

    @observable.ref public storno = false;
    @observable.ref public editingInvoiceData = false;
    @observable.ref public partnerId: number | null = null;
    @observable.ref public grossPrice: number | null = null;

    @observable.ref public manuallyEditingRideData = false;
    @observable.ref public rideCostCenter = "";
    @observable.ref public rideCustomerName = "";
    @observable.ref public rideStartZipCode = "";
    @observable.ref public rideStartSettlement = "";
    @observable.ref public rideStartAddressLine = "";
    @observable.ref public rideFinalZipCode = "";
    @observable.ref public rideFinalSettlement = "";
    @observable.ref public rideFinalAddressLine = "";
    @observable.ref public rideAdditionalInfo = "";
    @observable.ref public rideIntermediateAddresses: AddressStore[] = [];
    @observable.ref public intermediateAddressesToDelete: AddressStore[] = [];
    @observable.ref public finalAddressIsAttachedToRide = false;
    @observable.ref public intermediateAddressesAreAttachedToRide = false;

    @observable.ref public modificationReason = "";
    @observable.ref public modifierName = "";

    @observable.ref public isModificationConfirmationModalOpen = false;
    @computed public get actionsToPerform(): string[] {

        return [
            (this.editingInvoiceData || this.storno) && `Meglévő, '${this.details?.invoiceNumber}' számú számla sztornózása`,
            this.manuallyEditingRideData && "Fuvar adatok módosítása kézi bevitel alapján",
            this.editingInvoiceData && !this.storno && "Új számla kiállítása módosított adatokkal",
        ].filter(Boolean) as string[];
    }

    public readonly vLoadAsync = this.async(async () => {
        const result = await this.props.invoiceApiApadter.getDetailsAsync!(this.props.invoiceId, this.props.isSummary ? undefined : this.props.partnerId);

        runInAction(() => {
            this.editingInvoiceData = this.manuallyEditingRideData = this.storno = this.isModificationConfirmationModalOpen = false;

            this.details = result;
            this.partnerId = this.details.customer.id;
            this.grossPrice = this.details.grossPrice;

            this.setRideDetailsFromInvoice();
        });
    });

    public vGetReloadTriggerProps(props?: IInvoiceDetailCoreProps) {
        return [props!.invoiceId];
    }

    public readonly saveAsync = this.async(async () => {

        if (!this.modifierName || this.modifierName.length == 0) {
            NotificationStore.error("Kérjük adja meg a módosító nevét!");
            return;
        }

        const data: IAccountedRideModificationDto = {
            stornoInvoice: this.storno,
            manuallyModifyRideData: this.manuallyEditingRideData,
            modifyRideById: false,
            modifyInvoice: this.editingInvoiceData,
            rideCostCenter: this.rideCostCenter,
            invoiceGrossPrice: this.grossPrice?.toString() ?? null,
            invoicePartnerId: this.partnerId,
            rideAdditionalInfo: this.rideAdditionalInfo,
            rideCustomerName: this.rideCustomerName,
            rideId: null,
            startAddress: {
                zipCode: this.rideStartZipCode,
                settlement: this.rideStartSettlement,
                addressLine: this.rideStartAddressLine,
                countryCode: this.details!.startAddress!.countryCode
            },
            finalAddress: {
                zipCode: this.rideFinalZipCode,
                settlement: this.rideFinalSettlement,
                addressLine: this.rideFinalAddressLine,
                countryCode: this.details!.finalAddress?.countryCode ?? "HU"
            },
            modificationReason: this.modificationReason,
            modifierName: this.modifierName,
            intermediateAddresses: this.rideIntermediateAddresses.filter(a => !this.intermediateAddressesToDelete.includes(a)).map(a => a.address),
            intermediateAddressesAreAttachedToRide: this.intermediateAddressesAreAttachedToRide,
            finalAddressIsAttachedToRide: this.finalAddressIsAttachedToRide
        };

        if (this.props.isAdmin) {
            try {
                const createdInvoiceData = await AdminInvoiceApiAdapter.compositeModifyAsync(this.props.invoiceId, data);
                NotificationStore.successfullySaved();
                this.cancelModificationConfirmation();

                if (createdInvoiceData.invoiceId != null) {
                    NavigationStore.navigateTo(this.props.isSummary ? `/admin/invoices/${createdInvoiceData.invoiceId}` : `/admin/partners/${this.props.partnerId}invoices/${createdInvoiceData.invoiceId}`);
                } else {
                    this.vLoadAsync.fireAndForget();
                }
            } catch (e) {
                console.log(e);
            }
        }
    });

    @action
    private setRideDetailsFromInvoice() {
        this.rideCostCenter = this.details?.costCenter ?? "";
        this.rideCustomerName = this.details?.rideCustomerName ?? "";
        this.rideStartZipCode = this.details?.startAddress?.zipCode ?? "";
        this.rideStartSettlement = this.details?.startAddress?.settlement ?? "";
        this.rideStartAddressLine = this.details?.startAddress?.addressLine ?? "";
        this.rideFinalZipCode = this.details?.finalAddress?.zipCode ?? "";
        this.rideFinalSettlement = this.details?.finalAddress?.settlement ?? "";
        this.rideFinalAddressLine = this.details?.finalAddress?.addressLine ?? "";
        this.rideAdditionalInfo = this.details?.rideAdditionalInfo ?? "";
        this.rideIntermediateAddresses = this.details?.intermediateAddresses?.map(a => AddressStore.fromAddress(a)) ?? [];
        this.finalAddressIsAttachedToRide = this.details?.shouldUpdateFinalAddress ?? true;
        this.intermediateAddressesAreAttachedToRide = this.details?.shouldUpdateIntermediateAddresses ?? true;
        this.intermediateAddressesToDelete = [];
    }

    public readonly downloadInvoicePdfAsync = this.async(async () => {
        const result = await this.props.invoiceApiApadter.getPdfAsync!(this.details!.id, this.props.isSummary ? undefined : this.props.partnerId);
        await downloadBase64Async(result, "application/pdf", `eTaxi_szla__${this.details!.invoiceNumber.replace(/\\\//gi, "_")}.pdf`);
    });

    @action.bound
    public toggleEditingInvoiceData() {
        this.editingInvoiceData = !this.editingInvoiceData;
    }

    @action.bound
    public setCostCenter(value: string) {
        this.rideCostCenter = value;
    }

    @bound
    public notSupported() {
        NotificationStore.error("A funkció még nem elérhető.");
    }

    @action.bound
    public toggleManuallyEditingRideData() {
        this.manuallyEditingRideData = !this.manuallyEditingRideData;

        if (!this.manuallyEditingRideData) {
            this.setRideDetailsFromInvoice();
        }
    }

    @action.bound setRideCustomerName(value: string) { this.rideCustomerName = value; }
    @action.bound setRideStartZipCode(value: string) { this.rideStartZipCode = value; }
    @action.bound setRideStartSettlement(value: string) { this.rideStartSettlement = value; }
    @action.bound setRideStartAddressLine(value: string) { this.rideStartAddressLine = value; }
    @action.bound setRideFinalZipCode(value: string) { this.rideFinalZipCode = value; }
    @action.bound setRideFinalSettlement(value: string) { this.rideFinalSettlement = value; }
    @action.bound setRideFinalAddressLine(value: string) { this.rideFinalAddressLine = value; }
    @action.bound setRideAdditionalInfo(value: string) { this.rideAdditionalInfo = value; }
    @action.bound setPartnerId(value: number | null) { this.partnerId = value; }
    @action.bound setGrossPrice(value: number | null) { this.grossPrice = value; }

    @action.bound setModificationReason(value: string | null) { this.modificationReason = value ?? ""; }
    @action.bound setModifierName(value: string | null) { this.modifierName = value ?? ""; }

    @action.bound showModificationConfirmation() {
        this.isModificationConfirmationModalOpen = true;
    }

    @action.bound showStornoConfirmation() {
        this.storno = true;
        this.isModificationConfirmationModalOpen = true;
    }

    @action.bound cancelModificationConfirmation() {
        this.storno = false;
        this.isModificationConfirmationModalOpen = false;
    }

    @action.bound setShouldDetachFinalAddress(value: boolean) {
        this.finalAddressIsAttachedToRide = value;
    }

    @action.bound setShouldDetachIntermediateAddresses(value: boolean) {
        this.intermediateAddressesAreAttachedToRide = value;
    }

    @action.bound addIntermediateAddress() {
        this.rideIntermediateAddresses = [...this.rideIntermediateAddresses, new AddressStore()];
    }

    @action.bound toggleDeleteIntermediateAddress(address: AddressStore) {
        if (this.intermediateAddressesToDelete.includes(address)) {
            this.intermediateAddressesToDelete = [...this.intermediateAddressesToDelete.filter(a => a !== address)];
        } else {
            this.intermediateAddressesToDelete = [...this.intermediateAddressesToDelete, address];
        }
    }
}

const { ContextComponent: InvoiceDetailStoreContext, StoreProvider: InvoiceDetailStoreProvider, useStore: useInvoiceDetailStore, withStore: withInvoiceDetailStore } =
    createStoreProvider<IInvoiceDetailCoreProps, InvoiceDetailStore>(() => new InvoiceDetailStore());

export {
    InvoiceDetailStoreContext,
    InvoiceDetailStoreProvider,
    useInvoiceDetailStore,
    withInvoiceDetailStore
};