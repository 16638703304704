import { createStoreProvider, isNullOrUndefined, StoreBase } from "@eblsoft/react-toolkit";
import { runInAction } from "mobx";
import NotificationStore from "Stores/NotificationStore";
import { IVehicleConditionDetailCoreProps } from "./VehicleConditionDetail";
import VehicleCondition from "Models/VehicleCondition";
import AdminVehicleConditionAdapter from "Adapters/AdminAdapter/AdminVehicleConditionAdapter";

export default class VehicleConditionDetailStore extends StoreBase<IVehicleConditionDetailCoreProps> {
    public vehicleCondition: VehicleCondition | null = null;

    public readonly vLoadAsync = this.async(async () => {
        if (!isNullOrUndefined(this.props.vehicleConditionId)) {
            const result = await AdminVehicleConditionAdapter.getDetailsAsync(this.props.vehicleConditionId!);
            runInAction(() => {
                this.vehicleCondition = result ?? null
            });
        }
    });

    public saveAsync = this.async(async () => {

        if (this.vehicleCondition?.status === null) {
            NotificationStore.error("Hibás vagy kitöltetlen adatok");
            return false;
        }

        const result = await AdminVehicleConditionAdapter.updateVehicleConditionAsync(
            this.vehicleCondition!.id,
            this.vehicleCondition!.status!);

        runInAction(() => {
            this.vehicleCondition!.status = result?.status ?? null;
        });

        NotificationStore.success("Sikeres mentés");
        return true;
    });
}



const { ContextComponent: VehicleConditionDetailStoreContext, StoreProvider: VehicleConditionDetailStoreProvider, useStore: useVehicleConditionDetailStore, withStore: withVehicleConditionDetailStore } =
    createStoreProvider<IVehicleConditionDetailCoreProps, VehicleConditionDetailStore>(() => new VehicleConditionDetailStore());

export {
    VehicleConditionDetailStoreContext,
    VehicleConditionDetailStoreProvider,
    useVehicleConditionDetailStore,
    withVehicleConditionDetailStore
};