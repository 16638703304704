import { EnumHelper } from "Util/EnumHelper";

export enum VehicleConditionStatus {
    Published = "Published",
    Verified = "Verified"
}

function getVehicleConditionStatusEntries() {
    return EnumHelper.getEnumEnumerableEntries<VehicleConditionStatus>(VehicleConditionStatus, getVehicleConditionStatusDisplayValue);
}

export function getVehicleConditionStatusDisplayValue(value: VehicleConditionStatus) {

    switch(value) {
        case VehicleConditionStatus.Published: return "Bejelentve";
        case VehicleConditionStatus.Verified: return "Ellenőrizve";
    }
}

export const VehicleConditionStatusEntries = getVehicleConditionStatusEntries();