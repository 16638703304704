import moment from "moment";
import FailedRide from "./FailedRide";

export default class FailedRideDetails extends FailedRide {
    constructor(
        id: number,
        publicIdentifier: string,
        rideIdentifier: string,
        driverName: string,
        driverBpid: string,
        taxiDriverName: string,
        grossPrice: number,
        createdAt: moment.Moment,
        deletedAt: moment.Moment | null,
        isApproved: boolean | null,
        reviewedAt: moment.Moment | null,

        public readonly rideAdditionalInfo: string | null,
        public readonly driverComment: string | null,
        public readonly reviewerComment: string | null,
    ) {
        super(
            id,
            publicIdentifier,
            rideIdentifier,
            driverName,
            driverBpid,
            taxiDriverName,
            grossPrice,
            createdAt,
            deletedAt,
            isApproved,
            reviewedAt,
        );
    }
}