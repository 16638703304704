import { EnumHelper } from "Util/EnumHelper";

export enum InvoiceType {
    Normal = "Normal",
    Storno = "Storno"
}

function getInvoiceTypeEntries() {
    return EnumHelper.getEnumEnumerableEntries<InvoiceType>(InvoiceType, getInvoiceTypeDisplayValue);
}

export function getInvoiceTypeDisplayValue(value: InvoiceType) {

    switch(value) {
        case InvoiceType.Normal: return "Normál";
        case InvoiceType.Storno: return "Sztornó";
    }
}

export const InvoiceTypeEntries = getInvoiceTypeEntries();