import { action, makeObservable, observable } from "mobx";
import { AddressType } from "Models/AddressType";
import moment from "moment";

class TaxiDriverFilter {
    @observable.ref public bpId: string = "";
    @observable.ref public name: string = "";
    @observable.ref public taxNumber: string = "";
    @observable public areFiltersDirty = false;
    @observable.ref public showLeavedDrivers: boolean = false;
    @observable.ref public enteredOnFrom: moment.Moment | null = null;
    @observable.ref public enteredOnTo: moment.Moment | null = null;
    @observable.ref public leavedOnFrom: moment.Moment | null = null;
    @observable.ref public leavedOnTo: moment.Moment | null = null;
    @observable.ref public driverName: string = "";
    @observable.ref public addressType: AddressType = AddressType.Any;
    @observable.ref public settlement: string = "";
    @observable.ref public addressLine: string = "";
    @observable.ref public zipCode: string = "";
    @observable.ref public selfEmployedIdentifier: string = "";
    @observable.ref public isCooperativeMember: boolean | null = null;

    public constructor(
    ) {
        makeObservable(this);
    }

    @action.bound
    public setShowLeavedDrivers() {
        this.showLeavedDrivers = !this.showLeavedDrivers;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setBpId(bpId: string) {
        this.bpId = bpId;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setName(name: string) {
        this.name = name;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setTaxNumber(taxNumber: string) {
        this.taxNumber = taxNumber;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setSelfEmployedIdentifier(selfEmployedIdentifier: string) {
        this.selfEmployedIdentifier = selfEmployedIdentifier;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setEnteredOnRange(from: moment.Moment | null, to: moment.Moment | null) {
        this.enteredOnFrom = from?.clone().hour(0).minute(0).second(0).millisecond(0) ?? null;
        this.enteredOnTo = to?.clone().hour(23).minute(59).second(59).millisecond(59) ?? null;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setLeavedOnRange(from: moment.Moment | null, to: moment.Moment | null) {
        this.leavedOnFrom = from?.clone().hour(0).minute(0).second(0).millisecond(0) ?? null;
        this.leavedOnTo = to?.clone().hour(23).minute(59).second(59).millisecond(59) ?? null;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setDriverName(name: string) {
        this.driverName = name;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setAddressType(addressType: AddressType) {
        this.addressType = addressType;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setSettlement(settlement: string) {
        this.settlement = settlement;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setAddressLine(addressLine: string) {
        this.addressLine = addressLine;
        this.areFiltersDirty = true;
    }
    
    @action.bound
    public setZipCode(zipCode: string) {
        this.zipCode = zipCode;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setIsCooperativeMember(value: boolean | null) {
        this.isCooperativeMember = value;
        this.areFiltersDirty = true;
    }
}
export default TaxiDriverFilter;