import { Outlet } from "react-router-dom";
import ICommonFileApiAdapter from "Adapters/ICommonFileApiAdapter";
import { withCommonFilesStore } from "./CommonFilesStore";

export interface ICommonFileListProps {
    commonFileApiAdapter: ICommonFileApiAdapter;
}

function CommonFilesCore() {
    return (
        <Outlet />
    );
}


const CommonFilesCoreWithStore = withCommonFilesStore(CommonFilesCore);

export default function CommonFiles(props: ICommonFileListProps) {
    return (
        <CommonFilesCoreWithStore {...props}/>
    )
}
