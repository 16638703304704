import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { Select, Typography } from "antd"
import React from "react";
const { Option } = Select;

const { Text } = Typography;

export interface IBooleanFilterSelectBoxProps {
    onChange: (value: boolean | null) => void;
    value: boolean | null;
    style?: React.CSSProperties;
    label?: string;
}

export default function BooleanFilterSelectBox(props: IBooleanFilterSelectBoxProps) {
    return (
        <div style={{ width: "100%" }}>
            <Text>{props.label}</Text>
            <Select
                style={{ width: "100%" }}
                onChange={props.onChange}
                value={props.value}
                allowClear={true}
                defaultValue={null}
            >
                <Option value={true}>
                    Igen
                </Option>
                <Option value={false}>
                    Nem
                </Option>
            </Select>
        </div>
    )
} 