import { isNotNullAndUndefined } from "@eblsoft/react-toolkit";
import { ColumnsType, ColumnType } from "antd/lib/table";
import Address from "Models/Address";
import InvoiceForPartnerListItem from "Models/InvoiceForPartnerListItem";
import { getInvoiceStatusDisplayValue, InvoiceStatus } from "Models/InvoiceStatus";
import { getInvoiceTypeDisplayValue, InvoiceType } from "Models/InvoiceType";
import { getVoucherTypeDisplayValue } from "Models/VoucherType";
import moment from "moment";
import { Link } from "react-router-dom";
import AuthStore from "Stores/AuthStore";
import { formatHuf } from "Util/PriceFormatter";
import InvoiceActions from "./InvoiceActions";

export function getInvoiceColumns(isAdmin: boolean, isSummary: boolean, showExtendedColumns: boolean): ColumnsType<InvoiceForPartnerListItem> {

    const res: Array<ColumnType<InvoiceForPartnerListItem> | null | undefined | false> = [
        {
            title: "Számlaszám",
            dataIndex: "invoiceNumber",
            key: "invoiceNumber",
            sorter: true,
            width: 230
        },
        {
            title: "Típus",
            dataIndex: "invoiceType",
            key: "invoiceType",
            render: (value: InvoiceType, row: InvoiceForPartnerListItem) => <><span>{getInvoiceTypeDisplayValue(value)}</span>{value === InvoiceType.Storno ? <><br/><small>{row.referencedInvoiceNumber}</small></> : ""}</>,
            sorter: true,
            width: 100
        },
        {
            title: "Utazás azon.",
            dataIndex: "accountedRidePublicIdentifier",
            key: "accountedRidePublicIdentifier",
            sorter: true,
            width: 120
        },
        {
            title: "Rendelésszám",
            dataIndex: "rideExternalIdentifier",
            key: "rideExternalIdentifier",
            sorter: true,
            width: 120
        },
        showExtendedColumns && {
            title: "Megrendelő",
            dataIndex: "rideCustomerName",
            key: "rideCustomerName",
            sorter: true,
            width: 150
        },
        {
            title: "Szállító (BPID)",
            dataIndex: "sellerName",
            key: "sellerName",
            render: (_: any, row: InvoiceForPartnerListItem) => <span>{row.sellerName} ({row.sellerBpid})</span>,
            sorter: true,
            width: 200
        },
        {
            title: "Fuvar ideje",
            dataIndex: "accountedAt",
            key: "accountedAt",
            render: (date: moment.Moment) => <span>{date.format('L LT')}</span>,
            sorter: true,
            width: 150
        },
        {
            title: "Számla dátuma",
            dataIndex: "createdOn",
            key: "createdOn",
            render: (date: moment.Moment) => <span>{date.format('L')}</span>,
            sorter: true,
            width: 100
        },
        showExtendedColumns && {
            title: "Teljesítés",
            dataIndex: "fulfilledOn",
            key: "fulfilledOn",
            render: (date: moment.Moment) => <span>{date.format('L')}</span>,
            sorter: true,
            width: 100
        },
        {
            title: "Költséghely",
            dataIndex: "costCenter",
            key: "costCenter",
            sorter: true,
            width: 150
        },
        showExtendedColumns && {
            title: "Nettó összeg",
            dataIndex: "netPrice",
            key: "netPrice",
            sorter: true,
            render: (value: number) => <span>{formatHuf(value)}</span>,
            align: "right",
            width: 100
        },
        {
            title: "Bruttó összeg",
            dataIndex: "grossPrice",
            key: "grossPrice",
            sorter: true,
            render: (value: number) => <span>{formatHuf(value)}</span>,
            align: "right",
            width: 100
        },
        showExtendedColumns && {
            title: "Partner szla. össz.",
            dataIndex: "partnerInvoiceSummaryIdentifier",
            key: "partnerInvoiceSummaryIdentifier",
            sorter: true,
            width: 150
        },
        {
            title: "Elszámolás típusa",
            dataIndex: "voucherInfo",
            key: "voucherInfo",
            sorter: true,
            render: (_: any, row: InvoiceForPartnerListItem) => <span>{getVoucherTypeDisplayValue(row.voucherType)} {row.voucherInfo}</span>,
            width: 200
        },
        showExtendedColumns && {
            title: "Kiállás",
            dataIndex: "startAddress",
            key: "startAddress",
            sorter: false,
            render: (address: Address) => <span>{`${address.zipCode} ${address.settlement} ${address.addressLine}`}</span>,
            width: 250
        },
        showExtendedColumns && {
            title: "Köztes cím(ek)",
            dataIndex: "intermediateAddresses",
            key: "intermediateAddresses",
            sorter: false,
            render: (addresses: Address[] | null) => addresses?.map((a, idx) => (
                <div key={idx}>{`${a.zipCode} ${a.settlement} ${a.addressLine}`}</div>
            )),
            width: 250
        },
        showExtendedColumns && {
            title: "Végcél",
            dataIndex: "finalAddress",
            key: "finalAddress",
            sorter: false,
            render: (address: Address | null) => address && <span>{`${address.zipCode} ${address.settlement} ${address.addressLine}`}</span>,
            width: 250
        },
        showExtendedColumns && {
            title: "Megjegyzés",
            dataIndex: "rideAdditionalInfo",
            key: "rideAdditionalInfo",
            sorter: false,
            width: 200
        },
        isSummary && {
            title: "Partner",
            dataIndex: "customerName",
            key: "customerName",
            render: (_: any, row: InvoiceForPartnerListItem) => <span>{row.customerName} ({row.customerBpid})</span>,
            sorter: true,
            width: 200
        },
        isAdmin && {
            title: "Állapot",
            dataIndex: "status",
            key: "status",
            sorter: true,
            render: (status: InvoiceStatus) => <span>{getInvoiceStatusDisplayValue(status)}</span>,
            width: 100
        },
        (AuthStore.user?.hasRole("Admin") || AuthStore.user?.hasRole("PartnerAdmin")) && {
            title: "",
            render: (_, invoice) => <InvoiceActions invoice={invoice} />,
            width: isAdmin ? 100 : 50,
            fixed: "right"
        }
    ];

    return res.filter(c => c !== null && c !== undefined && c !== false) as Array<ColumnType<InvoiceForPartnerListItem>>;
}