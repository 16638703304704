import { VehicleConditionStatus } from "Models/VehicleConditionStatus";
import { VehicleConditionType } from "Models/VehicleConditionType";
import { action, makeObservable, observable } from "mobx";

class VehicleConditionFilter {

    @observable.ref public partnerName: string = "";
    @observable.ref public partnerBpid: string = "";
    @observable.ref public licensePlateNumber: string = "";
    @observable.ref public status: VehicleConditionStatus | null = null;
    @observable.ref public type: VehicleConditionType | null = null;
    @observable.ref public createdAtFrom: moment.Moment | null = null;
    @observable.ref public createdAtTo: moment.Moment | null = null;

    @observable public areFiltersDirty = false;

    public constructor(
    ) {
        makeObservable(this);
    }

    @action.bound
    public setStatus(status: VehicleConditionStatus) {
        this.status = status;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setType(type: VehicleConditionType) {
        this.type = type;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setPartnerName(partnerName: string) {
        this.partnerName = partnerName;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setPartnerBpid(partnerBpid: string) {
        this.partnerBpid = partnerBpid;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setCreatedAtRange(from: moment.Moment | null, to: moment.Moment | null) {
        this.createdAtFrom = from?.clone() ?? null;
        this.createdAtTo = to?.clone() ?? null;
        this.areFiltersDirty = true;
    }
    
}
export default VehicleConditionFilter;