import { observer } from "mobx-react";
import PhoneNumber from "Models/PhoneNumber";
import PhoneNumberTextBox from "Pages/CommonControls/PhoneNumberTextBox";

export interface IPhoneNumberColumnProps {
    record: PhoneNumber;
    isUnderEdit: boolean;
}

function PhoneNumberColumn(props: IPhoneNumberColumnProps) {
    
    return (
        <>
            {props.isUnderEdit ?
                <PhoneNumberTextBox value={props.record.number} onChange={props.record.setNumber}/> :
                <span>{props.record.number}</span>
            }
        </>
    )
}

export default observer(PhoneNumberColumn);