import { FilterOutlined } from "@ant-design/icons";
import { PageHeader, Checkbox, Col, Row, Table, TablePaginationConfig, Typography, Button, Modal } from "antd";
import { observer } from "mobx-react";
import AddressTextBox from "Pages/CommonControls/AddressTextBox";
import PageWrapper from "Pages/CommonControls/PageWrapper/PageWrapper";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";
import TextBox from "Pages/CommonControls/TextBox";
import { useMemo } from "react";
import { getFullScreenTableScroll } from "Util/TableHelpers";
import { usePartnersColumns } from "./Common/PartnersColumns";
import { usePartnersStore } from "./PartnersStore";
const { Title } = Typography;


function PartnerList() {
    const store = usePartnersStore();

    const paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    };

    const columns = usePartnersColumns();
    const tableScroll = getFullScreenTableScroll(columns.reduce((sum, col) => sum + (col.width as number ?? 100), 0));

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    return (
        <>
            <PageHeader title={<span id="page_title">Partnerek</span>}>
                <Row gutter={16}>
                    <Col lg={5} md={10} xs={12}>
                        <TextBox
                            label="BPID:"
                            style={{ width: "100%" }}
                            onChange={store.partnerFilter.setBpId}
                            value={store.partnerFilter.bpId}
                            onEnter={store.filterResult}
                        />
                    </Col>
                    <Col lg={5} md={10} xs={12}>
                        <TextBox
                            label="Számlázási név:"
                            style={{ width: "100%" }}
                            onChange={store.partnerFilter.setBpName}
                            value={store.partnerFilter.bpName}
                            onEnter={store.filterResult}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={5} md={10} xs={12}>
                        <TextBox
                            label="Adószám:"
                            style={{ width: "100%" }}
                            onChange={store.partnerFilter.setTaxNumber}
                            value={store.partnerFilter.taxNumber}
                            onEnter={store.filterResult}
                        />
                    </Col>
                    <Col lg={5} md={10} xs={12}>
                        <TextBox
                            label="Név:"
                            style={{ width: "100%" }}
                            onChange={store.partnerFilter.setName}
                            value={store.partnerFilter.name}
                            onEnter={store.filterResult}
                        />
                    </Col>
                </Row>
                <AddressTextBox
                    addressLineOnChange={store.partnerFilter.setAddressLine}
                    addressLineValue={store.partnerFilter.addressLine}
                    addressTypeOnChange={store.partnerFilter.setAddressType}
                    addressTypeValue={store.partnerFilter.addressType}
                    settlementOnChange={store.partnerFilter.setSettlement}
                    settlementValue={store.partnerFilter.settlement}
                    zipCodeOnChange={store.partnerFilter.setZipCode}
                    zipCodeValue={store.partnerFilter.zipCode} />

                <Row gutter={16} style={{ marginTop: "16px", marginBottom: "16px" }}>
                    <Col span={24}>
                        <Button type="primary" onClick={store.filterResult} icon={<FilterOutlined />}>
                            Szűrés
                        </Button>
                    </Col>
                </Row>

                <TableSummaryHeader items={summaryItems} />
                <Table id="partnerList" onChange={store.reloadList} pagination={paginationConfig} size="middle" columns={columns} dataSource={store.Partners} rowKey="id" scroll={tableScroll} />
            </PageHeader>
        </>
    );
}


export default observer(PartnerList);