import { IGetVehicleConditionListRequest, IGetVehicleConditionResponse,  IUpdateVehicleConditionRequest, IUpdateVehicleConditionResponse } from "Adapters/Api/Api.g";
import {  mapVehicleConditionDtoToDomainModel, mapVehicleConditionListFilterToDto, mapVehicleConditionListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import VehicleConditionFilter from "Models/Filters/VehicleConditionFilter";
import VehicleConditionListItem from "Models/VehicleConditionListItem";
import { VehicleConditionStatus } from "Models/VehicleConditionStatus";
import VehicleCondition from "Models/VehicleCondition";


class AdminVehicleConditionAdapter extends ProtectedApiAdapterBase {
    private readonly mainRoute = "api/web/admin/vehicleCondition";

    public getListAsync(paging: Paging, ordering: Ordering, filter: VehicleConditionFilter): Promise<{ totalCount: number, vehicleConditions: VehicleConditionListItem[] }> {
        const request: IGetVehicleConditionListRequest = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapVehicleConditionListFilterToDto(filter)
        }

        return this.httpPost<{ totalCount: number, vehicleConditions: VehicleConditionListItem[] }>(this.mainRoute, undefined, request).map(paginatedResult => {
            return {
                totalCount: paginatedResult.totalCount,
                vehicleConditions: paginatedResult.vehicleConditions.map((i: any) => {
                    return mapVehicleConditionListItemDtoToDomainModel(i);
                }),
            };
        }).performOperationAsync();
    }

    public updateVehicleConditionAsync(
        id: number,
        status: VehicleConditionStatus,
    ): Promise<VehicleCondition> {
        const body: IUpdateVehicleConditionRequest = {    
            status,
            id
        };
        return this.httpPut<VehicleCondition>(this.mainRoute, ["update"], body).map((result: IUpdateVehicleConditionResponse) => {
            return mapVehicleConditionDtoToDomainModel(result.vehicleCondition);
        }).performOperationAsync();
    }

    public getDetailsAsync(vehicleConditionId: number): Promise<VehicleCondition> {
        return this.httpGet<VehicleCondition>(this.mainRoute, [vehicleConditionId.toString()]).map((result: IGetVehicleConditionResponse) => {
            return mapVehicleConditionDtoToDomainModel(result.vehicleCondition);
        }).performOperationAsync();
    }

}

export default new AdminVehicleConditionAdapter();