import React, { useCallback } from "react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface ICheckBoxProps {
    label?: React.ReactNode;
    onChange?: (value: boolean) => void;
    value?: boolean;
    style?: React.CSSProperties;
    className?: string;
    id?: string;
    isReadOnly?: boolean;
}

export default function CheckBox(props: ICheckBoxProps) {

    const change = useCallback((e: CheckboxChangeEvent) => {
        props.onChange?.(e.target.checked);
    }, [props.onChange]);

    return (
        <Checkbox
            onChange={change}
            checked={props.value}
            style={props.style}
            className={props.className}
            id={props.id}
            disabled={props.isReadOnly}
        >
            {props.label}
        </Checkbox>
    );
}