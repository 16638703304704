import { DownloadOutlined, DownOutlined, FileExcelOutlined, FilePdfOutlined, FilterOutlined, UndoOutlined } from "@ant-design/icons";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import IInvoiceApiAdapter from "Adapters/IInvoiceApiAdapter";
import { Button, Col, DatePicker, Dropdown, Input, InputNumber, Menu, PageHeader, Row, Select, Space, Table, TablePaginationConfig, Typography } from "antd";
import DropdownButton from "antd/lib/dropdown/dropdown-button";
import { Observer, observer } from "mobx-react";
import { PrintOptions } from "Models/PrintOptions";
import moment from "moment";
import DateRangePicker from "Pages/CommonControls/DateRangePicker";
import NumberBox from "Pages/CommonControls/NumberBox";
import TextBox from "Pages/CommonControls/TextBox";
import { VoucherTypeSelector } from "Pages/CommonControls/VoucherTypeSelector";
import React, { useMemo } from "react";
import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { fullScreenTableScroll, getFullScreenTableScroll } from "Util/TableHelpers";
import { getInvoiceColumns } from "./Common/InvoiceColumns";
import { useInvoiceListStore, withInvoiceListStore } from "./InvoiceListStore";
import CheckBox from "Pages/CommonControls/CheckBox";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";
import { formatHuf } from "Util/PriceFormatter";
import { InvoiceTypeEntries } from "Models/InvoiceType";
import { InvoiceStatusEntries } from "Models/InvoiceStatus";

const { Title, Text } = Typography;
const { Option } = Select;

export interface IInvoiceListProps {
    invoiceApiApadter: IInvoiceApiAdapter;
    isAdmin: boolean;
    isSummary: boolean;
}

export interface IInvoiceListCoreProps extends IInvoiceListProps {
    partnerId?: number;
}

function InvoiceListCore(props: IInvoiceListCoreProps) {
    const store = useInvoiceListStore();

    const paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    }

    const downloadMenu = (
        <Menu>
            <div>
                <Button onClick={() => store.print(PrintOptions.PrintToPdf)} icon={<FilePdfOutlined />} style={{ width: "100%" }}>
                    Lista letöltése PDF-ben
                </Button>
            </div>
            <div>
                <Button onClick={() => store.print(PrintOptions.PrintToExcel)} icon={<FileExcelOutlined />} style={{ width: "100%" }}>
                    Lista letöltése Excel-ben
                </Button>
            </div>
        </Menu>
    );

    const columns = getInvoiceColumns(props.isAdmin, props.isSummary, store.showExtendedColumns);
    const tableScroll = getFullScreenTableScroll(columns.reduce((sum, col) => sum + (col.width as number ?? 100), 0));

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [
        {
            title: "Bruttó összeg:",
            value: `${formatHuf(store.totalGrossPrice)}.`
        },
        {
            title: "Mennyiség:",
            value: `${store.recordNum} db`
        },
    ], [store.recordNum]);

    return (
        <>
            <PageHeader title={<span id="page_title">Számlák</span>}>
                <Observer>
                    {() => (
                        <>
                            <Row gutter={16}>
                                <Col xl={6} lg={8} md={10} xs={12}>
                                    <DateRangePicker
                                        label="Számla dátuma:"
                                        from={store.invoiceFilter.createdOnFrom}
                                        to={store.invoiceFilter.createdOnTo}
                                        onChange={store.invoiceFilter.setCreatedOnRange}
                                        style={{ width: "100%" }}
                                        onEnter={store.filterResult}
                                        allowClear
                                        id="filter_invoice_createdOn"
                                    />
                                </Col>
                                <Col xl={6} lg={8} md={10} xs={12}>
                                    <DateRangePicker
                                        label="Utazás dátuma:"
                                        from={store.invoiceFilter.accountedOnFrom}
                                        to={store.invoiceFilter.accountedOnTo}
                                        onChange={store.invoiceFilter.setAccountedOnRange}
                                        style={{ width: "100%" }}
                                        onEnter={store.filterResult}
                                        allowClear
                                        id="filter_invoice_accountedOn"
                                    />
                                </Col>
                                {store.showExtendedColumns && <Col xl={6} lg={8} md={10} xs={12}>
                                    <DateRangePicker
                                        label="Teljesítés dátuma:"
                                        from={store.invoiceFilter.fulfilledOnFrom}
                                        to={store.invoiceFilter.fulfilledOnTo}
                                        onChange={store.invoiceFilter.setfulfilledOnRange}
                                        style={{ width: "100%" }}
                                        onEnter={store.filterResult}
                                        allowClear
                                        id="filter_invoice_fulfilledOn"
                                    />
                                </Col>}
                                <Col xl={6} lg={8} md={10} xs={12}>
                                    <TextBox
                                        label="Szállító (név vagy BPID):"
                                        onChange={store.invoiceFilter.setSeller}
                                        value={store.invoiceFilter.seller}
                                        onEnter={store.filterResult}
                                        id="filter_invoice_seller"
                                    />
                                </Col>

                                {props.isSummary &&
                                    <Col xl={6} lg={8} md={10} xs={12}>
                                        <TextBox
                                            label="Partner (név vagy BPID):"
                                            onChange={store.invoiceFilter.setCustomer}
                                            value={store.invoiceFilter.customer}
                                            onEnter={store.filterResult}
                                            id="filter_invoice_customer"
                                        />
                                    </Col>}
                            </Row>
                            <Row gutter={16}>
                                <Col xl={6} lg={8} md={10} xs={12}>
                                    <TextBox
                                        label="Számlaszám:"
                                        onChange={store.invoiceFilter.setInvoiceNumber}
                                        value={store.invoiceFilter.invoiceNumber}
                                        onEnter={store.filterResult}
                                        id="filter_invoice_invoiceNumber"
                                    />
                                </Col>
                                <Col xl={6} lg={8} md={10} xs={12}>
                                    <TextBox
                                        label="Utazás azon.:"
                                        onChange={store.invoiceFilter.setAccountedRidePublicIdentifier}
                                        value={store.invoiceFilter.accountedRidePublicIdentifier}
                                        onEnter={store.filterResult}
                                        id="filter_invoice_rideIdentifier"
                                    />
                                </Col>
                                <Col xl={6} lg={8} md={10} xs={12}>
                                    <TextBox
                                        label="Rendelésszám:"
                                        onChange={store.invoiceFilter.setRideExternalIdentifier}
                                        value={store.invoiceFilter.rideExternalIdentifier}
                                        onEnter={store.filterResult}
                                        id="filter_invoice_orderId"
                                    />
                                </Col>
                                <Col xl={6} lg={8} md={10} xs={12}>
                                    <TextBox
                                        label="Költséghely:"
                                        onChange={store.invoiceFilter.setCostCenter}
                                        value={store.invoiceFilter.costCenter}
                                        onEnter={store.filterResult}
                                        id="filter_invoice_costCenter"
                                    />
                                </Col>

                                {store.showExtendedColumns && <Col xl={6} lg={8} md={10} xs={12}>
                                    <TextBox
                                        label="Partner szla. össz.:"
                                        onChange={store.invoiceFilter.setPartnerInvoiceSummaryIdentifier}
                                        value={store.invoiceFilter.partnerInvoiceSummaryIdentifier}
                                        onEnter={store.filterResult}
                                        id="filter_invoice_partnerInvoiceSummaryIdentifier"
                                    />
                                </Col>}
                            </Row>
                            <Row gutter={16}>
                                <Col xl={6} lg={8} md={10} xs={12}>
                                    <NumberBox
                                        label="Minimum bruttó számlaérték:"
                                        onChange={store.invoiceFilter.setGrossCostFrom}
                                        value={store.invoiceFilter.grossCostFrom}
                                        onEnter={store.filterResult}
                                        id="filter_invoice_minGrossPrice"
                                    />
                                </Col>
                                <Col xl={6} lg={8} md={10} xs={12}>
                                    <NumberBox
                                        label="Maximum bruttó számlaérték:"
                                        onChange={store.invoiceFilter.setGrossCostTo}
                                        value={store.invoiceFilter.grossCostTo}
                                        onEnter={store.filterResult}
                                        id="filter_invoice_maxGrossPrice"
                                    />
                                </Col>

                                {store.showExtendedColumns &&
                                    <Col xl={6} lg={8} md={10} xs={12}>
                                        <TextBox
                                            label="Megrendelő:"
                                            onChange={store.invoiceFilter.setRideCustomerName}
                                            value={store.invoiceFilter.rideCustomerName}
                                            onEnter={store.filterResult}
                                            id="filter_invoice_orderedByName"
                                        />
                                    </Col>}

                                {props.isAdmin && (
                                    <Col xl={6} lg={8} md={10} xs={12}>
                                        <div style={{ width: "100%" }}>
                                            <Text>Számla állapota:</Text>
                                            <Select
                                                mode="multiple"
                                                style={{ width: "100%" }}
                                                onChange={store.invoiceFilter.setStatuses}
                                                value={store.invoiceFilter.statuses}
                                                allowClear
                                            >
                                                {InvoiceStatusEntries.map(i =>
                                                    <Option key={i.enumValue} value={i.enumValue}>
                                                        {i.displayValue}
                                                    </Option>
                                                )}
                                            </Select>
                                        </div>
                                    </Col>
                                )}

                            </Row>
                            {store.showExtendedColumns && <>
                                <Row gutter={16}>
                                    <Col xl={3} lg={3} md={5} xs={6}>
                                        <TextBox label="Kiállás ir.Sz:" value={store.invoiceFilter.startZipCode} onChange={store.invoiceFilter.setStartZipCode} onEnter={store.filterResult} id="filter_invoice_pickupAddressZipCode" />
                                    </Col>
                                    <Col xl={5} lg={7} md={9} xs={10}>
                                        <TextBox label="Kiállás település:" value={store.invoiceFilter.startSettlement} onChange={store.invoiceFilter.setStartSettlement} onEnter={store.filterResult} id="filter_invoice_pickupAddressSettlement" />
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col xl={8} lg={10} md={14} xs={16}>
                                        <TextBox label="Kiállás utca, házszám:" value={store.invoiceFilter.startAddressLine} onChange={store.invoiceFilter.setStartAddressLine} onEnter={store.filterResult} id="filter_invoice_pickupAddressLine" />
                                    </Col>
                                </Row>
                            </>}

                            <VoucherTypeSelector
                                VoucherInfo={store.invoiceFilter.voucherInfo}
                                VoucherType={store.invoiceFilter.voucherType}
                                onVoucherInfoChange={store.invoiceFilter.setVoucherInfo}
                                onVoucherTypeChange={store.invoiceFilter.setVoucherType}
                                onSearch={store.filterResult}
                                idPrefix="filter_invoice_"
                            />

                            <Space style={{ marginTop: "16px", marginBottom: "16px" }}>
                                <CheckBox label="Összes oszlop megjelenítése" value={store.showExtendedColumns} onChange={store.setShowExtendedColumns} id="filter_invoice_showExtendedColumns" />
                                <Dropdown.Button
                                    style={{ width: "100%" }}
                                    disabled={store.invoiceFilter.areFiltersDirty}
                                    onClick={() => store.print(PrintOptions.PrintToExcel)}
                                    icon={<DownOutlined />}
                                    overlay={downloadMenu}
                                >
                                    Lista letöltése
                                    <DownloadOutlined />
                                </Dropdown.Button>
                                <Button style={{ width: "100%" }} type="primary" onClick={store.filterResult} icon={<FilterOutlined />} id="filter_invoice_list">
                                    Szűrés
                                </Button>
                                {!store.invoiceFilter.isEmpty && (
                                    <Button style={{ width: "100%" }} onClick={store.clearState} icon={<UndoOutlined />} id="clear_filter_invoice_list">
                                        Szűrőfeltételek törlése
                                    </Button>
                                )}
                            </Space>
                        </>
                    )}
                </Observer>

                <TableSummaryHeader items={summaryItems} />
                <Table
                    onChange={store.reloadList}
                    pagination={paginationConfig}
                    size="middle"
                    columns={columns}
                    dataSource={store.invoices}
                    rowKey="id"
                    scroll={tableScroll}
                    id="invoice_list"
                />
            </PageHeader>
        </>
    );
}

const InvoiceListCoreWithStore = withInvoiceListStore(InvoiceListCore);

export default function InvoiceList(props: IInvoiceListProps) {
    const { partnerId } = useParams();
    return (
        <InvoiceListCoreWithStore {...props} isSummary={props.isSummary} partnerId={isNullOrUndefined(partnerId) ? undefined : parseInt(partnerId!)} />
    )
}