import { action, makeObservable, observable } from "mobx";
import { CostPlacementType } from "Models/CostPlacementType";

class PhoneNumberFilter {
    @observable.ref public name: string = "";
    @observable.ref public phoneNumber: string = "";
    @observable.ref public costPlacementType: CostPlacementType | null = null;
    @observable.ref public costPlacement: string = "";
    @observable.ref public customer: string = "";
    @observable public areFiltersDirty = false;

    public constructor(
    ) {
        makeObservable(this);
    }

    @action.bound
    public setName(value: string) {
        this.areFiltersDirty = true;
        this.name = value;
    }

    @action.bound
    public setPhoneNumber(value: string) {
        this.areFiltersDirty = true;
        this.phoneNumber = value;
    }

    @action.bound
    public setCostPlacement(value: string) {
        this.areFiltersDirty = true;
        this.costPlacement = value;
    }

    @action.bound
    public setCostPlacementType(value: CostPlacementType) {
        this.areFiltersDirty = true;
        this.costPlacementType = value;
    }
    
    @action.bound
    public setCustomer(customer: string) {
        this.customer = customer;
        this.areFiltersDirty = true;

    }
}
export default PhoneNumberFilter;