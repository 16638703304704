import NotificationStore from "Stores/NotificationStore";
import { RcFile } from "antd/lib/upload";
import { action, makeObservable, observable } from "mobx";

export default class CommonFileDetailStore {
    @observable.ref public file: RcFile | null = null;
    @observable.ref public isNew: boolean = false;
    @observable.ref public commonFileName: string = "";
    @observable.ref public category: string = "";

    public constructor(
    ) {
        makeObservable(this);
    }

    @action.bound
    public beforeFileUpload = (file: RcFile) => {
        const isPDF = file.type === 'application/pdf';
        const isTooLarge = file.size > 100 * 1024 * 1024;

        if (!isPDF) {
            NotificationStore.error("Kérjük csak pdf dokumentumot töltsön fel.");
        } if (isTooLarge) {
            NotificationStore.error("A fájl túl nagy (>100MB).");
        } else {
            this.file = file;
            if (this.commonFileName === "") {
                this.commonFileName = this.file.name.substring(0, this.file.name.lastIndexOf('.'));
            }
        }

        return false; //return false to prevent to upload immediately the file to the current route.
    }

    @action.bound
    public handleFileRemove = () => {
        this.file = null;
    };

    @action.bound
    public handleCommonFileNameChange = (name: string) => {
        this.commonFileName = name;
    }

    @action.bound
    public setCategory = (cat: string) => {
        this.category = cat;
    }
}