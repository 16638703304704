import { IDownloadTaxiCardProhibitionEventsRequest, IGetTaxiCardOrdersRequest, IGetTaxiCardProhibitionEventsRequest, IUpdateTaxiCardOrderRequest, IUpdateTaxiCardProhibitionEventRequest } from "Adapters/Api/Api.g";
import { mapTaxiCardOrderListItemDtoToDomainModel, mapTaxiCardProhibitionEventListFilterToDto, mapTaxiCardProhibitionEventListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import TaxiCardProhibitionEventFilter from "Models/Filters/TaxiCardProhibitionEventFilter";
import { PrintOptions } from "Models/PrintOptions";
import TaxiCardOrderListItem from "Models/TaxiCardOrderListItem";
import { TaxiCardOrderStatus } from "Models/TaxiCardOrderStatus";
import TaxiCardProhibitionEventListItem from "Models/TaxiCardProhibitionEventListItem";
import TaxiCardProhibitionEventListItemDto from "Models/TaxiCardProhibitionEventListItem";
import { TaxiCardProhibitionEventStatus } from "Models/TaxiCardProhibitionEventStatus";

class AdminTaxiCardProhibitionEventAdapter extends ProtectedApiAdapterBase {
    private readonly mainRoute = "api/web/admin/TaxiCardProhibitionEvent";

    public getListAsync(paging: Paging, ordering: Ordering, filter: TaxiCardProhibitionEventFilter): Promise<{ totalCount: number, taxiCardProhibitionEvents: TaxiCardProhibitionEventListItem[] }> {
        const body: IGetTaxiCardProhibitionEventsRequest = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapTaxiCardProhibitionEventListFilterToDto(filter)
        }

        return this.httpPost<{ totalCount: number, taxiCardProhibitionEvents: TaxiCardProhibitionEventListItem[] }>(this.mainRoute, undefined, body).map(paginatedResult => {
            return {
                totalCount: paginatedResult.totalCount,
                taxiCardProhibitionEvents: paginatedResult.taxiCardProhibitionEvents.map((p: any) => {
                    return mapTaxiCardProhibitionEventListItemDtoToDomainModel(p);
                })
            };
        }
        ).performOperationAsync();
    }

    public printAsync(ordering: Ordering, filter: TaxiCardProhibitionEventFilter, printOption: PrintOptions) {
        const body: IDownloadTaxiCardProhibitionEventsRequest = {
            printOption,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapTaxiCardProhibitionEventListFilterToDto(filter)
        };
        return this.httpPost<string>(this.mainRoute, ["print"], body).map(result => {
            return result;
        }).performOperationAsync();
    }

    public updateAsync(id: number, status: TaxiCardProhibitionEventStatus) {
        const body: IUpdateTaxiCardProhibitionEventRequest = {
            id,
            taxiCardProhibitionEventStatus: status
        }

        return this.httpPost<boolean>(this.mainRoute, ["update"], body).map((res) => {
            return true;
        }).performOperationAsync();
    }
}

export default new AdminTaxiCardProhibitionEventAdapter();