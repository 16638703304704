import React from "react";
import IInvoiceApiAdapter from "Adapters/IInvoiceApiAdapter";
import { Alert, Button, Col, Divider, PageHeader, Row, Space, Table, Tooltip } from "antd";
import { useInvoiceDetailStore, withInvoiceDetailStore } from "./InvoiceDetailStore";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import ReadonlyInput from "Pages/CommonControls/ReadonlyInput";
import Title from "antd/lib/typography/Title";
import { DeleteOutlined, EditOutlined, FileExcelOutlined, FilePdfOutlined, MoreOutlined, PlusOutlined, SaveOutlined, StopOutlined, SyncOutlined, UndoOutlined } from "@ant-design/icons";
import { formatHuf } from "Util/PriceFormatter";
import { getVoucherTypeDisplayValue } from "Models/VoucherType";
import ReadonlyAddress from "Pages/Partner/PartnerData/ReadonlyAddress";
import PartnerView from "Pages/CommonControls/PartnerView";
import TextBox from "Pages/CommonControls/TextBox";
import moment from "moment";
import AddressBox from "Pages/CommonControls/AddressBox";
import InvoiceCorrectionSummaryModal from "./InvoiceCorrectionSummaryModal";
import PartnerSelector from "Pages/CommonControls/PartnerSelector/PartnerSelector";
import { getInvoiceTypeDisplayValue, InvoiceType } from "Models/InvoiceType";
import NumberBox from "Pages/CommonControls/NumberBox";
import CheckBox from "Pages/CommonControls/CheckBox";

export interface IInvoiceDetailProps {
    invoiceApiApadter: IInvoiceApiAdapter;
    isAdmin: boolean;
    isSummary: boolean;
}

export interface IInvoiceDetailCoreProps extends IInvoiceDetailProps {
    partnerId?: number;
    invoiceId: number;
}

function InvoiceDetailCore(props: IInvoiceDetailCoreProps) {

    const store = useInvoiceDetailStore();
    const navigate = useNavigate();

    return store.details && (
        <PageHeader
            title={`Számla: ${store.details.invoiceNumber}`}
        >

            <Row gutter={[16, 8]}>
                <Col xxl={12} xl={12} lg={24} xs={24} sm={24}>
                    <Title style={{ marginBottom: "16px", marginTop: "16px" }} level={3}>Fuvar adatok</Title>
                    {props.isAdmin && isNullOrUndefined(store.details.latestModifierInvoiceId) && (
                        <Alert
                            message="Javítási lehetőségek:"
                            style={{ margin: "2rem 1rem" }}
                            description={(
                                <Space size="middle">
                                    {!store.manuallyEditingRideData && (
                                        <Button icon={<SyncOutlined />} onClick={store.notSupported}>Fuvar cseréje</Button>
                                    )}
                                    <Button icon={<EditOutlined />} onClick={store.toggleManuallyEditingRideData}>
                                        {store.manuallyEditingRideData ? "Mégsem módosítom kézzel a fuvar adatokat" : "Fuvar adatok kézi módosítása"}
                                    </Button>
                                </Space>
                            )}
                            type="info"
                        />
                    )}
                    <Row gutter={[16, 8]}>
                        <Col span={12}><ReadonlyInput label="Rendelésszám:" value={store.details.rideExternalIdentifier} /></Col>
                        <Col span={12}><ReadonlyInput label="Elszámolás azonosító:" value={store.details.accountedRidePublicIdentifier} /></Col>
                        <Col span={12}>
                            <TextBox
                                label="Megrendelő neve:"
                                value={store.rideCustomerName}
                                onChange={store.setRideCustomerName}
                                isReadOnly={!store.manuallyEditingRideData}
                                isHighlighted={store.manuallyEditingRideData}
                            />
                        </Col>
                        <Col span={12}>
                            <ReadonlyInput
                                label="Elszámolás ideje:"
                                value={store.details.accountedAt.format("L LT")}
                            />
                        </Col>
                        <Col span={12}>
                            <ReadonlyInput label="Elszámolás típusa:" value={`${getVoucherTypeDisplayValue(store.details.voucherType)} ${isNullOrUndefined(store.details.voucherInfo) ? "" : store.details.voucherInfo}`} />
                        </Col>
                        <Col span={12}>
                            <TextBox
                                label="Költséghely:"
                                value={store.rideCostCenter}
                                isReadOnly={!store.manuallyEditingRideData}
                                onChange={store.setCostCenter}
                                isHighlighted={store.manuallyEditingRideData}
                            />
                        </Col>
                    </Row>
                    <Divider orientation="left">Kiállás</Divider>
                    <AddressBox
                        addressLine={store.rideStartAddressLine}
                        settlement={store.rideStartSettlement}
                        zipCode={store.rideStartZipCode}
                        onAddressLineChange={store.setRideStartAddressLine}
                        onSettlementChange={store.setRideStartSettlement}
                        onZipCodeChange={store.setRideStartZipCode}
                        isReadOnly={!store.manuallyEditingRideData}
                        isHighlighted={store.manuallyEditingRideData}
                    />
                    <Divider orientation="left">Köztes címek</Divider>
                    <CheckBox
                        label="A köztes címek frissüljenek automatikusan amikor a diszpécser rendszer küldi"
                        value={store.intermediateAddressesAreAttachedToRide}
                        onChange={store.setShouldDetachIntermediateAddresses}
                        isReadOnly={!store.manuallyEditingRideData}
                        style={{ marginBottom: ".5rem" }}
                    />
                    {store.rideIntermediateAddresses?.map((a, idx) => {
                        const isDeleted = store.intermediateAddressesToDelete.includes(a);
                        return (
                            <div key={idx} style={{ display: "flex", flexDirection: "row", alignItems: "end", gap: "1rem", marginBottom: "1rem" }}>
                                <div style={{ textDecoration: isDeleted ? "line-through" : undefined }}>
                                    <AddressBox
                                        addressLine={a.addressLine}
                                        settlement={a.settlement}
                                        zipCode={a.zipCode}
                                        onAddressLineChange={a.setAddressLine}
                                        onSettlementChange={a.setSettlement}
                                        onZipCodeChange={a.setZipCode}
                                        isReadOnly={isDeleted || !store.manuallyEditingRideData || store.intermediateAddressesAreAttachedToRide}
                                        isHighlighted={store.manuallyEditingRideData} />
                                </div>
                                <div>
                                    <Tooltip title={isDeleted ? "Törlés visszavonása" : "Törlés"}>
                                        <Button
                                            icon={isDeleted ? <UndoOutlined /> : <DeleteOutlined />}
                                            onClick={() => store.toggleDeleteIntermediateAddress(a)}
                                            disabled={!store.manuallyEditingRideData || store.intermediateAddressesAreAttachedToRide} />
                                    </Tooltip>
                                </div>
                            </div>
                        );
                    })}
                    <Button
                        icon={<PlusOutlined />}
                        onClick={store.addIntermediateAddress}
                        disabled={!store.manuallyEditingRideData || store.intermediateAddressesAreAttachedToRide}
                    >
                        Új köztes cím
                    </Button>
                    <Divider orientation="left">Végcél</Divider>
                    <CheckBox
                        label="A végcél frissüljön automatikusan amikor a diszpécser rendszer küldi"
                        value={store.finalAddressIsAttachedToRide}
                        onChange={store.setShouldDetachFinalAddress}
                        isReadOnly={!store.manuallyEditingRideData}
                        style={{ marginBottom: ".5rem" }}
                    />
                    <AddressBox
                        addressLine={store.rideFinalAddressLine}
                        settlement={store.rideFinalSettlement}
                        zipCode={store.rideFinalZipCode}
                        onAddressLineChange={store.setRideFinalAddressLine}
                        onSettlementChange={store.setRideFinalSettlement}
                        onZipCodeChange={store.setRideFinalZipCode}
                        isReadOnly={!store.manuallyEditingRideData || store.finalAddressIsAttachedToRide}
                        isHighlighted={store.manuallyEditingRideData}
                    />
                    <Row gutter={[16, 8]} style={{ marginTop: "2rem" }}>
                        <Col span={24}>
                            <TextBox
                                label="Megjegyzés:"
                                value={store.rideAdditionalInfo}
                                onChange={store.setRideAdditionalInfo}
                                isReadOnly={!store.manuallyEditingRideData}
                                isHighlighted={store.manuallyEditingRideData}
                                multiline
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xxl={12} xl={12} lg={24} xs={24} sm={24}>
                    <Title style={{ marginBottom: "16px", marginTop: "16px", display: "flex", gap: 16 }} level={3}>
                        Számla adatok
                        {props.isAdmin && store.details.type === InvoiceType.Normal && isNullOrUndefined(store.details.latestModifierInvoiceId) && (
                            <Button
                                icon={store.editingInvoiceData ? <StopOutlined /> : <EditOutlined />}
                                onClick={store.toggleEditingInvoiceData}
                            >
                                {store.editingInvoiceData ? "Mégsem" : "Módosítás"}
                            </Button>
                        )}</Title>

                    {!isNullOrUndefined(store.details.latestModifierInvoiceId) && (
                        <Alert
                            message="Már javított számla"
                            style={{ margin: "2rem 1rem" }}
                            description={(
                                <p>Ez a számla már módosítva lett egy sztornó és/vagy javító számla által. A legfrissebb számla ehhez a fuvarhoz:
                                    <Link to={`/admin/invoices/${store.details.latestModifierInvoiceId}`} style={{ color: "black" }}>
                                        <Button type="primary">{store.details.latestModifierInvoiceNumber}</Button>
                                    </Link></p>
                            )}
                            type="info"
                        />
                    )}
                    <Row gutter={[16, 8]}>
                        <Col span={8}><ReadonlyInput label="Típus:" value={getInvoiceTypeDisplayValue(store.details.type)} /></Col>
                    </Row>
                    <Divider orientation="left">Szállító</Divider>
                    <Row gutter={[16, 8]}>
                        <Col span={8}><ReadonlyInput label="BPID:" value={store.details.sellerBpid} /></Col>
                        <Col span={16}><ReadonlyInput label="Név:" value={store.details.sellerName} /></Col>
                        <Col span={12}><ReadonlyInput label="Adószám:" value={isNullOrUndefined(store.details.sellerTaxNumber) ? "" : store.details.sellerTaxNumber?.toString()} /></Col>
                    </Row>
                    <ReadonlyAddress addressLine={store.details.sellerAddress?.addressLine} settlement={store.details.sellerAddress?.settlement} zipCode={store.details.sellerAddress?.zipCode} />
                    <Divider orientation="left">Partner</Divider>
                    {store.editingInvoiceData
                        ? (
                            <Row gutter={[16, 8]}>
                                <Col span={24}>
                                    <PartnerSelector label="BPID:" partnerId={store.partnerId} onChange={store.setPartnerId} isHighlighted />
                                </Col>
                            </Row>
                        )
                        : (
                            <PartnerView
                                showMailingaddress={false}
                                partner={store.details.customer}
                            />
                        )
                    }
                    <Divider orientation="left">Számviteli adatok</Divider>
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <ReadonlyInput
                                label="Kiállítás dátuma:"
                                value={store.editingInvoiceData ? moment().format("L") : store.details.createdOn.format("L")}
                            />
                        </Col>
                        <Col span={12}><ReadonlyInput label="Teljesítés dátuma:" value={store.details.fulfilledOn.format("L")} /></Col>
                        <Col span={12}>
                            {!store.editingInvoiceData && (
                                <ReadonlyInput
                                    label="Nettó számlaérték:"
                                    value={store.details.netPrice}
                                />
                            )}
                        </Col>
                        <Col span={12}>
                            <NumberBox
                                label="Bruttó számlaérték:"
                                value={store.grossPrice}
                                onChange={store.setGrossPrice}
                                isHighlighted={store.editingInvoiceData}
                                isReadOnly={!store.editingInvoiceData}
                            />
                        </Col>
                    </Row>
                    <br />
                </Col>


            </Row>
            <Row>
                <Col xl={10} lg={16} xs={24} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", paddingTop: 16 }}>
                    <Space size="middle">
                        <Button onClick={() => navigate(-1)}>Vissza a listára</Button>
                        {props.isAdmin && (
                            <>
                                {(store.editingInvoiceData || store.manuallyEditingRideData) ? (
                                    <Button icon={<SaveOutlined />} type="primary" onClick={store.showModificationConfirmation}>Módosítások mentése</Button>
                                ) : store.details.type === InvoiceType.Normal && (
                                    <Button onClick={store.showStornoConfirmation} danger type="primary">Sztornó</Button>
                                )}
                            </>
                        )}
                        <Button onClick={store.downloadInvoicePdfAsync.fireAndForget} icon={<FilePdfOutlined />}>Letöltés PDF-ben</Button>
                    </Space>
                </Col>
            </Row>

            <InvoiceCorrectionSummaryModal
                actions={store.actionsToPerform}
                isLoading={store.isLoading}
                isOpen={store.isModificationConfirmationModalOpen}
                onCancel={store.cancelModificationConfirmation}
                onOk={store.saveAsync.fireAndForget}
                modifierName={store.modifierName}
                modificationReason={store.modificationReason}
                onModifierNameChange={store.setModifierName}
                onModificationReasonChange={store.setModificationReason}
            />

        </PageHeader>
    )
}

const InvoiceDetailCoreWithStore = withInvoiceDetailStore(InvoiceDetailCore);

export default function InvoiceDetail(props: IInvoiceDetailProps) {
    const { partnerId, invoiceId } = useParams();
    return (
        <InvoiceDetailCoreWithStore {...props} partnerId={isNullOrUndefined(partnerId) ? undefined : parseInt(partnerId!)} invoiceId={parseInt(invoiceId!)} />
    )
}