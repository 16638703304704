import { observer } from "mobx-react";
import PhoneNumber from "Models/PhoneNumber";
import TextBox from "Pages/CommonControls/TextBox";

export interface INameColumnProps {
    record: PhoneNumber;
    isUnderEdit: boolean;
}

function NameColumn(props: INameColumnProps) {

    return (
        <>
            {props.isUnderEdit ?
                <TextBox value={props.record.name} onChange={props.record.setName} maxLength={64} /> :
                <span>{props.record.name}</span>
            }
        </>
    )
}

export default observer(NameColumn);