import { IQueryParameters } from "@eblsoft/react-toolkit";
import Voucher from "Models/Voucher";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import { TaxiVoucherLimit } from "Models/TaxiVoucherLimit";
import { CostPlacementType } from "Models/CostPlacementType";
import { mapVoucherDtoToDomainModel, mapVoucherListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { PrintOptions } from "Models/PrintOptions";
import ITaxiVoucherAdapter from "Adapters/ITaxiVoucherAdapter";
import { ICreateTaxiVouchersRequest, IDownloadTaxiVoucherListRequest, IDownloadTaxiVoucherTicketsRequest, IGetTaxiVouchersRequest } from "Adapters/Api/Api.g";
import { count } from "console";
import moment from "moment";
import TaxiVoucherFilter from "Models/Filters/TaxiVoucherFilter";
import VoucherListItem from "Models/VoucherListItem";

class PartnerVoucherAdapter extends ProtectedApiAdapterBase implements ITaxiVoucherAdapter {
    private readonly mainRoute = "api/web/partner/TaxiVoucher";

    public getListAsync(paging: Paging, ordering: Ordering, filter: any): Promise<{ totalCount: number, vouchers: VoucherListItem[] }> {

        const request: IGetTaxiVouchersRequest = {
                pageNumber: paging.pageNumber,
                pageSize: paging.pageSize,
                orderBy: ordering.orderBy,
                orderAsc: ordering.orderAsc,
                ...filter
            }

        return this
            .httpPost<{ totalCount: number, vouchers: VoucherListItem[] }>(this.mainRoute, undefined, request)
            .map(paginatedResult => {
                return {
                    totalCount: paginatedResult.totalCount,
                    vouchers: paginatedResult.vouchers.map((v: any) => {
                        return mapVoucherListItemDtoToDomainModel(v);
                    })
                };
            }
            ).performOperationAsync();
    }

    public createVouchersAsync(count: number, cost: TaxiVoucherLimit, validity: moment.Moment, costPlacementType: CostPlacementType, costPlacement: string = "") {
        const body:ICreateTaxiVouchersRequest = {
            count: count,
            cost: cost,
            validity: validity.toJSON(),
            costPlacementType: costPlacementType,
            costPlacement: costPlacement
        };
        return this.httpPost<{ totalCount: number, vouchers: VoucherListItem[] }>(this.mainRoute + "/new", undefined, body).map(paginatedResult => {
            return {
                totalCount: paginatedResult.totalCount,
                vouchers: paginatedResult.vouchers.map((v: any) => {
                    return mapVoucherListItemDtoToDomainModel(v);
                })
            };
        }
        ).performOperationAsync();
    }

    public printVoucherListAsync(ordering: Ordering, filter: any, printOption: PrintOptions, partnerId: number | undefined) {
        const request: IDownloadTaxiVoucherListRequest = {
            printOption,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            ...filter
        };
        
        return this.httpPost<string>(this.mainRoute, ["printList"], request).map(result => {
            return result;
        }).performOperationAsync();
    }

    public printVoucherTicketsAsync(ordering: Ordering, filter: any, partnerId: number | undefined): Promise<string> {
        const queryParameters: IDownloadTaxiVoucherTicketsRequest = {
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            ...filter
        };

        return this.httpPost<string>(this.mainRoute, ["printTickets"], queryParameters).map(result => {
            return result;
        }).performOperationAsync();
    }

    public toggleProhibitVouchersAsync(ids: number[], prohibitionReason?: string, userName?: string, partnerId?: number): Promise<boolean> {
        const body = {
            Ids: ids,
            ProhibitionReason: prohibitionReason,
            UserName: userName
        };
        return this.httpPut<boolean>(this.mainRoute, ["toggleProhibit"], body).map((res) => true).performOperationAsync();
    }
}

export default new PartnerVoucherAdapter();
