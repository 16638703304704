
import { Outlet } from "react-router-dom";
import { withVehicleConditionListStore } from "./VehicleConditionListStore";


function VehicleConditionsCore() {
    return (
        <Outlet />
    );
}


const VehicleConditionsCoreWithStore = withVehicleConditionListStore(VehicleConditionsCore);

export default function VehicleConditions() {
    return (
        <VehicleConditionsCoreWithStore />
    )
}


