import { IGetSimpleDocumentListRequest, IGetSimpleDocumentResponse, IUpdateSimpleDocumentRequest, IUpdateSimpleDocumentResponse } from "Adapters/Api/Api.g";
import { mapSimpleDocumentDtoToDomainModel, mapSimpleDocumentListFilterToDto, mapSimpleDocumentListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";

import SimpleDocumentFilter from "Models/Filters/SimpleDocumentFilter";
import SimpleDocument from "Models/SimpleDocument";

import SimpleDocumentListItem from "Models/SimpleDocumentListItem";
import { DocumentStatus } from "Models/DocumentStatus";
import { SimpleDocumentType } from "Models/SimpleDocumentType";


class AdminSimpleDocumentAdapter extends ProtectedApiAdapterBase {
    private readonly mainRoute = "api/web/admin/simpleDocument";

    public getListAsync(type: SimpleDocumentType, paging: Paging, ordering: Ordering, filter: SimpleDocumentFilter): Promise<{ totalCount: number, simpleDocuments: SimpleDocumentListItem[] }> {
        const request: IGetSimpleDocumentListRequest = {
            type: type,
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapSimpleDocumentListFilterToDto(filter)
        }

        return this.httpPost<{ totalCount: number, simpleDocuments: SimpleDocumentListItem[] }>(this.mainRoute, undefined, request).map(paginatedResult => {
            return {
                totalCount: paginatedResult.totalCount,
                simpleDocuments: paginatedResult.simpleDocuments.map((i: any) => {
                    return mapSimpleDocumentListItemDtoToDomainModel(i);
                }),
            };
        }).performOperationAsync();
    }

    public updateSimpleDocumentAsync(
        token: string,
        id: number,
        identifier: string | null,
        validTo: moment.Moment | null,
        status: DocumentStatus): Promise<SimpleDocument> {
        const body: IUpdateSimpleDocumentRequest = {
            token,
            identifier,
            validTo: validTo?.format("YYYY-MM-DD") ?? null,
            status,
            id
        };
        return this.httpPut<SimpleDocument>(this.mainRoute, ["update"], body).map((result: IUpdateSimpleDocumentResponse) => {
            return mapSimpleDocumentDtoToDomainModel(result.simpleDocument);
        }).performOperationAsync();
    }

    public getDetailsAsync(token: string, simpleDocumentId: number): Promise<SimpleDocument> {
        return this.httpGet<SimpleDocument>(this.mainRoute, [simpleDocumentId.toString()], { token }).map((result: IGetSimpleDocumentResponse) => {
            return mapSimpleDocumentDtoToDomainModel(result.simpleDocument);
        }).performOperationAsync();
    }

}

export default new AdminSimpleDocumentAdapter();