import { createStoreProvider, isNullOrUndefined, StoreBase } from "@eblsoft/react-toolkit";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { action, computed, observable, runInAction } from "mobx";
import { CostPlacementType } from "Models/CostPlacementType";
import TaxiVoucherFilter from "Models/Filters/TaxiVoucherFilter";
import { PrintOptions } from "Models/PrintOptions";
import ProhibitionInfo from "Models/ProhibitionInfo";
import { TaxiVoucherLimit } from "Models/TaxiVoucherLimit";
import Voucher from "Models/Voucher";
import VoucherListItem from "Models/VoucherListItem";
import moment from "moment";
import { IVoucherListCoreProps } from "Pages/Partner/Vouchers/Vouchers";
import { downloadBase64Async } from "Util/FileDownloader";

export default class VouchersStore extends StoreBase<IVoucherListCoreProps> {
    @observable.ref public vouchers: VoucherListItem[] = [];
    @observable.ref public selectedVouchers: VoucherListItem[] = [];
    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    public taxiVoucherFilter = new TaxiVoucherFilter();

    public orderedBy = "";
    public orderDirection = "";

    @observable.ref public modalIsVisible: boolean = false;
    @observable.ref public confirmIsLoading: boolean = false;
    @observable.ref public prohibitionInfo: ProhibitionInfo = new ProhibitionInfo(null);

    @computed private get filter() {
        const filterResult = {
            ShowProhibited: this.taxiVoucherFilter.showProhibited,
            ShowUsed: this.taxiVoucherFilter.showUsed,
            CostPlacementType: this.taxiVoucherFilter.costPlacementType,
            CostPlacement: this.taxiVoucherFilter.costPlacement,
            Identifier: this.taxiVoucherFilter.identifier,
            VoucherLimit: this.taxiVoucherFilter.voucherLimit,
            ValidFrom: this.taxiVoucherFilter.validFrom,
            ValidTo: this.taxiVoucherFilter.validTo,
        };
        return this.props.isSummary ? { ...filterResult, Customer: this.taxiVoucherFilter.customer } : filterResult;
    }

    @action.bound
    public setShowProhibited() {
        this.taxiVoucherFilter.setShowProhibited();
        this.currentPage = 1;
        this.taxiVoucherFilter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public setShowUsed() {
        this.taxiVoucherFilter.setShowUsed();
        this.currentPage = 1;
        this.taxiVoucherFilter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    @computed
    public get isEverySelectedVoucherProhibited() {
        return this.selectedVouchers.every(v => !isNullOrUndefined(v.prohibitedAt));
    }

    public readonly vLoadAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");

        const result = await this.props.voucherApiApadter.getListAsync(paging, ordering, this.filter, this.props.isSummary ? undefined : this.props.partnerId);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.vouchers = result.vouchers;

            if (!this.selectedVouchers.every(v => this.vouchers.find(vv => vv.id === v.id))) {
                this.selectedVouchers = [];
            } else {
                this.selectVouchers(this.vouchers.filter(v => this.selectedVouchers.some(sv => sv.id === v.id)));
            }
        });
    });

    public readonly printListAsync = this.async(async (option: PrintOptions) => {
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const filter = this.selectedVouchers.length === 0 ? this.filter : { ...this.filter, ids: this.selectedVouchers.map(v => v.id) };
        const result = await this.props.voucherApiApadter.printVoucherListAsync(ordering, filter, option, this.props.isSummary ? undefined : this.props.partnerId);
        const date = moment().format("YYYY_MM_DD");
        switch (option) {
            case PrintOptions.PrintToExcel:
                await downloadBase64Async(result, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `Azonosító_szelvények_${date}.xlsx`);
                return;
            default:
                await downloadBase64Async(result, "application/pdf", `Azonosító_szelvények_${date}.pdf`);
            // TODO other options
        }
    });

    public readonly toggleProhibitVouchersAsync = this.async(async (ids: number[]) => {
        const result = await this.props.voucherApiApadter.toggleProhibitVouchersAsync!(ids, this.prohibitionInfo?.prohibitionReason, this.prohibitionInfo?.userName, this.props.partnerId);
        if (result && this.modalIsVisible) {
            this.prohibitionInfo = new ProhibitionInfo(null);
            this.confirmIsLoading = false;
            this.modalIsVisible = false;
        }
        await this.vLoadAsync();

    });

    public readonly printTicketsAsync = this.async(async () => {
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const filter = this.selectedVouchers.length === 0 ? this.filter : { ...this.filter, ids: this.selectedVouchers.map(v => v.id) };
        const result = await this.props.voucherApiApadter.printVoucherTicketsAsync!(ordering, filter, this.props.partnerId);
        await downloadBase64Async(result, "application/pdf", "Azonosító_jegyek.pdf");
    });

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<VoucherListItem> | SorterResult<VoucherListItem>[], extra: TableCurrentDataSource<VoucherListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (Array.isArray(sorter)) {
            this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
            this.orderDirection = sorter[0].order!;
        } else {
            this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
            this.orderDirection = sorter.order!;
        }
        this.vLoadAsync.fireAndForget();
    }


    @action.bound
    public filterResult() {
        this.currentPage = 1;
        this.taxiVoucherFilter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public print(option: PrintOptions) {
        this.printListAsync.fireAndForget(option);
    }

    public readonly toggleProhibitSingleVoucherAsync = this.async(async (id: number) => {
        await this.toggleProhibitVouchersAsync([id]);
    });

    @action.bound
    public selectVouchers(vouchers: VoucherListItem[]) {
        this.selectedVouchers = vouchers;
    }

    public readonly toggleProhibitSelectedVouchersAsync = this.async(async () => {
        await this.toggleProhibitVouchersAsync(this.selectedVouchers.map(v => v.id));
    });

    @action.bound
    public toggleProhibit() {
        this.confirmIsLoading = true;
        this.toggleProhibitVouchersAsync.fireAndForget(this.prohibitionInfo.prohibitionItemIds!);
    }

    @action.bound
    public cancelModal() {
        this.modalIsVisible = false;
    }

    @action.bound
    public setProhibitionModalIsVisible(ids: number[]) {
        this.modalIsVisible = true;
        this.prohibitionInfo = new ProhibitionInfo(ids);
    }

    @action.bound
    public setProhibitionModalIsVisibleForSelectedVouchers() {
        this.modalIsVisible = true;
        this.prohibitionInfo = new ProhibitionInfo(this.selectedVouchers.map(v => v.id));
    }
}



const { ContextComponent: VouchersStoreContext, StoreProvider: VouchersStoreProvider, useStore: useVouchersStore, withStore: withVouchersStore } =
    createStoreProvider<IVoucherListCoreProps, VouchersStore>(() => new VouchersStore());

export {
    VouchersStoreContext,
    VouchersStoreProvider,
    useVouchersStore,
    withVouchersStore
};
