import { LinkOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Space, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import Address from "Models/Address";
import PartnerListItem from "Models/PartnerListItem";
import { usePartnersStore } from "Pages/Admin/Partners/PartnersStore";
import { Link } from "react-router-dom";

export function usePartnersColumns(): ColumnsType<PartnerListItem> {

    const store = usePartnersStore();

    return [
        {
            title: "BPID",
            dataIndex: "bpid",
            key: "bpid",
            sorter: true,
            width: 80
        },
        {
            title: "Számlázási név",
            dataIndex: "bpName",
            key: "bpName",
            sorter: true,
            width: 200
        },
        {
            title: "Név",
            dataIndex: "name",
            key: "name",
            sorter: true,
            width: 200
        },
        {
            title: "Adószám",
            dataIndex: "orderAbleTaxNumber",
            key: "orderAbleTaxNumber",
            render: (value: string, record: PartnerListItem) => <span>{record.isPrivatePerson ? "Magánszemély" : record.taxNumber?.toString()}</span>,
            sorter: true,
            width: 120
        },
        {
            title: "Székhely",
            dataIndex: "address",
            key: "address",
            render: (address: Address) => <span>{`${address.zipCode} ${address.settlement} ${address.addressLine}`}</span>,
            width: 200
        },
        {
            title: "Levelezési cím",
            dataIndex: "mailingAddress",
            key: "mailingAddress",
            render: (address: Address) => address ? <span>{`${address.zipCode} ${address.settlement} ${address.addressLine}`}</span> : <span>Nincs</span>,
            width: 200
        },
        {
            title: '',
            key: 'action',
            width: 80,
            render: (text, record) => (
                <Space>
                    <Tooltip title="Részletek">
                        <Link to={`/admin/partners/${record.id}/partnerBaseData`}>
                            <Button id={"action_details"} icon={<MoreOutlined />} type="primary" />
                        </Link>
                    </Tooltip>
                    <Tooltip title="Meghívás">
                        <Button icon={<LinkOutlined />} onClick={() => store.loadPartnerInvitationAsync.fireAndForget(record.id)} />
                    </Tooltip>
                </Space>
            ),
        },
    ];
}