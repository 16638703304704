import { action, makeObservable } from "mobx";
import moment from "moment";
import Address from "./Address";
import { TaxiCardOrderStatus } from "./TaxiCardOrderStatus";

export default class TaxiCardOrderListItem {

    constructor(
        public readonly id: number,
        public readonly createdAt: moment.Moment,
        public status: TaxiCardOrderStatus,
        public readonly numberOfCards: number,
        public readonly sellerName: string,
        public readonly sellerBpid: string,
        public readonly deliveryAddres: Address | null,
        public readonly requesterName: string | null,
        public readonly requesterPhoneNumber: string | null,
        public readonly referenceNumber: string | null,
        public readonly preMadeCardFrom: string | null,
        public readonly preMadeCardTo: string | null,
    ) {
        makeObservable(this);
    }

    @action.bound
    public setStatus(status: TaxiCardOrderStatus) {
        this.status = status;
    }
}