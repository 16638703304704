import { ColumnsType } from "antd/lib/table";
import InvoiceSummaryForPartnerListItem from "Models/InvoiceSummaryForPartnerListItem";
import moment from "moment";
import InvoiceSummaryActions from "./InvoiceSummaryActions";

export const InvoiceSummaryColumns: ColumnsType<InvoiceSummaryForPartnerListItem> = [
    {
        title: "Azonosító",
        dataIndex: "identifier",
        sorter: true,
        key: "identifier",
    },
    {
        title: "Készült",
        dataIndex: "createdOn",
        key: "createdOn",
        sorter: true,
        render: (date: moment.Moment) => <span>{date.format('L')}</span>,
    },
    {
        title: "Időszak",
        dataIndex: "fromDate",
        key: "fromDate",
        render: (_: any, row: InvoiceSummaryForPartnerListItem) => <span>{row.fromDate.format('L')} - {row.toDate.format("L")}</span>,
    },
    {
        title: "",
        render: (_, invoice) => <InvoiceSummaryActions invoiceSummary={invoice} />
    }
];