import { Select } from "antd"
import { VoucherType, VoucherTypeEntries } from "Models/VoucherType"
const { Option } = Select;

export interface VoucherTypeSelectBoxProps {
    onChange: (VoucherType: VoucherType) => void;
    VoucherType: VoucherType | null;
    style?: React.CSSProperties;
    id?: string;
}

export function VoucherTypeSelectBox(props: VoucherTypeSelectBoxProps) {
    return (
        <Select
            style={{ width: "100%" }}
            onChange={props.onChange}
            value={props.VoucherType}
            allowClear
            id={props.id}
        >
            {VoucherTypeEntries.map(i =>
                <Option key={i.enumValue} value={i.enumValue}>
                    {i.displayValue}
                </Option>
            )}
        </Select>
    )
} 