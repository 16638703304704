import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { action, makeObservable, observable } from "mobx";
import Voucher from "Models/Voucher";
import { VoucherType } from "Models/VoucherType";
import moment from "moment";

class RideFilter {
    @observable.ref public rideFrom: moment.Moment | null = null;
    @observable.ref public rideTo: moment.Moment | null = null;
    @observable.ref public externalId: string = "";
    @observable public name: string = "";
    @observable.ref public settlement: string = "";
    @observable.ref public addressLine: string = "";
    @observable.ref public zipCode: string = "";
    @observable.ref public phoneNumber: string = "";

    @observable public areFiltersDirty = false;

    public constructor() {
        makeObservable(this);
    }

    @action.bound
    public setDateRangeFilter(from: moment.Moment | null, to: moment.Moment | null) {
        this.rideFrom = from?.clone() ?? null;
        this.rideTo = to?.clone() ?? null;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setSettlement(settlement: string) {
        this.settlement = settlement;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setAddressLine(addressLine: string) {
        this.addressLine = addressLine;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setZipCode(zipCode: string) {
        this.zipCode = zipCode;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setName(name: string) {
        this.name = name;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setPhoneNumber(phoneNumber: string) {
        this.phoneNumber = phoneNumber;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setExternalId(externalId: string) {
        this.externalId = externalId;
        this.areFiltersDirty = true;
    }
}
export default RideFilter;