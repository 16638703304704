import { ICreatePollRequest, ICreatePollResponse, IDownloadPollRequest, IDownloadVotesRequest, IGetPollListRequest, IGetPollResponse, IGetVoteListRequest, IStartPollRequest, IStartPollResponse, IUpdatePollRequest, IUpdatePollResponse, PrintOptions } from "Adapters/Api/Api.g";
import IPollApiAdapter from "Adapters/IPollApiAdapter";
import { mapPoll, mapPollDistributions, mapPollListFilterToDto, mapPollListtItemDtoToDomainModel, mapVoteListFilterToDto, mapvoteListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import Answer from "Models/Answer";
import PollFilter from "Models/Filters/PollFilter";
import VoteFilter from "Models/Filters/VoteFilter";
import Poll from "Models/Poll";
import PollDistribution from "Models/PollDistribution";
import PollListItem from "Models/PollListItem";
import { TargetAudience } from "Models/TargetAudience";

import VoteListItem from "Models/VoteListItem";

class AdminPollAdapter extends ProtectedApiAdapterBase implements IPollApiAdapter {
    private readonly mainRoute = "api/web/admin/poll";

    public createPollAsync(
        question: string,
        name: string,
        answers: Answer[],
        targetAudience: TargetAudience,
        allowCustomAnswer: boolean): Promise<Poll> {
        const body: ICreatePollRequest = {
            name: name,
            question: question,
            answers: answers,
            targetAudience: targetAudience,
            isCustomAnswerEnabled: allowCustomAnswer
        };
        return this.httpPost<Poll>(this.mainRoute, ["create"], body).map((result: ICreatePollResponse) => {
            return mapPoll(result.poll);
        }).performOperationAsync();
    }

    public updatePollAsync(
        id: number,
        question: string,
        name: string,
        answers: Answer[],
        targetAudience: TargetAudience,
        allowCustomAnswer: boolean): Promise<Poll> {
        const body: IUpdatePollRequest = {
            answers: answers,
            id: id,
            name: name,
            question: question,
            targetAudience: targetAudience,
            isCustomAnswerEnabled: allowCustomAnswer
        };
        return this.httpPut<Poll>(this.mainRoute, ["update"], body).map((result: IUpdatePollResponse) => {
            return mapPoll(result.poll);
        }).performOperationAsync();
    }

    public getListAsync(paging: Paging, ordering: Ordering, filter: PollFilter): Promise<{ totalCount: number, polls: PollListItem[] }> {
        const request: IGetPollListRequest = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapPollListFilterToDto(filter)
        }

        return this.httpPost<{ totalCount: number, polls: PollListItem[] }>(this.mainRoute, undefined, request).map(paginatedResult => {
            return {
                totalCount: paginatedResult.totalCount,
                polls: paginatedResult.polls.map((i: any) => {
                    return mapPollListtItemDtoToDomainModel(i);
                }),
            };
        }).performOperationAsync();
    }

    public getDetailsAsync(pollId: number): Promise<Poll> {
        return this.httpGet<Poll>(this.mainRoute, [pollId.toString()]).map((result: IGetPollResponse) => {
            return mapPoll(result.poll);
        }).performOperationAsync();
    }

    public startPollAsync(id: number | null,
        question: string,
        name: string,
        endOfVoteDate: moment.Moment,
        answers: Answer[],
        targetAudience: TargetAudience,
        allowCustomAnswer: boolean): Promise<number | null> {
        const body: IStartPollRequest = {
            answers: answers,
            id: id,
            name: name,
            question: question,
            endOfVoteDate: endOfVoteDate.toISOString(),
            targetAudience: targetAudience,
            isCustomAnswerEnabled: allowCustomAnswer
        };
        return this.httpPost<number | null>(this.mainRoute, ["start"], body).map((result: IStartPollResponse) => {
            return result.id;
        }).performOperationAsync();
    }

    public getVoteListAsync(pollId: number, paging: Paging, ordering: Ordering, voteFilter: VoteFilter): Promise<{ votesTotalCount: number, votes: VoteListItem[], poll: Poll, pollDistribution: PollDistribution }> {
        const request: IGetVoteListRequest = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapVoteListFilterToDto(voteFilter)
        }

        return this.httpPost<{ votesTotalCount: number, votes: VoteListItem[], poll: Poll, pollDistribution: PollDistribution }>(this.mainRoute, [pollId.toString(), "votes"], request).map(paginatedResult => {
            return {
                votesTotalCount: paginatedResult.votesCount,
                poll: mapPoll(paginatedResult.poll),
                pollDistribution: mapPollDistributions(paginatedResult.pollDistributions),
                votes: paginatedResult.votes.map((i: any) => {
                    return mapvoteListItemDtoToDomainModel(i);
                })
            };
        }).performOperationAsync();
    }

    public printPollAsync(pollId: number, printOption: PrintOptions): Promise<string> {
        const request: IDownloadPollRequest = {
            printOption,
        };
        return this.httpPost<string>(this.mainRoute, [pollId.toString(), "print"], request).map(result => {
            return result;
        }).performOperationAsync();
    }

    public printVotesAsync(pollId: number, ordering: Ordering, voteFilter: VoteFilter, printOption: PrintOptions) {
        const request: IDownloadVotesRequest = {
            printOption,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapVoteListFilterToDto(voteFilter)
        };
        return this.httpPost<string>(this.mainRoute, [pollId.toString(), "votes", "print"], request).map(result => {
            return result;
        }).performOperationAsync();
    }


}

export default new AdminPollAdapter();