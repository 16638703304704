import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, PageHeader, Row, Space } from "antd";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { Image } from 'antd';

import { useVehicleConditionDetailStore, withVehicleConditionDetailStore } from "./VehicleConditionDetailStore";
import { VehicleConditionStatusSelectBox } from "Pages/CommonControls/VehicleConditionStatusSelectBox";
import ReadonlyInput from "Pages/CommonControls/ReadonlyInput";
import { getVehicleConditionTypeDisplayValue } from "Models/VehicleConditionType";
import { useVehicleConditionListStore } from "../VehicleConditionListStore";
import { useCallback } from "react";

export interface IVehicleConditionDetailCoreProps {
    vehicleConditionId?: number;
}

function VehicleConditionDetailCore(props: IVehicleConditionDetailCoreProps) {
    const store = useVehicleConditionDetailStore();
    const vehicleConditionList = useVehicleConditionListStore();
    const navigate = useNavigate();

    const saveVehicleConditionAsync = async () => {
        const success = await store.saveAsync();
        if (success) {
            vehicleConditionList.vLoadAsync.fireAndForget();
            navigate(-1);
        }
    }

    const navigateBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <>
            <PageHeader
                title={"Jármű állapot szerkesztése"}
            >
                <Row gutter={[16, 8]}>
                    <Col xxl={11} xl={11} lg={11} xs={14} sm={14}>
                        {store.vehicleCondition?.images.map((data) => <Image style={{ marginBottom: "10px" }} alt={"Jármű állapot képe"} src={`data:image/jpeg;base64,${data}`} />)}
                    </Col>
                    <Col xxl={13} xl={13} lg={13} xs={10} sm={10}>
                        <Row gutter={16}>
                            <Col xl={12} xs={12}>
                                <VehicleConditionStatusSelectBox
                                    style={{ width: "100%" }}
                                    label="Állapot:"
                                    onChange={store.vehicleCondition?.setStatus}
                                    vehicleConditionStatus={store.vehicleCondition?.status}
                                    allowClear
                                />
                            </Col>
                            <Col xl={12} xs={12}>
                                <ReadonlyInput label="Bejelentés típusa:" value={isNullOrUndefined(store.vehicleCondition?.type) ? "" : getVehicleConditionTypeDisplayValue(store.vehicleCondition!.type)} />
                            </Col>
                            <Col xl={12} xs={12}>
                                <ReadonlyInput label="Bejelentés ideje:" value={store.vehicleCondition?.createdAt?.format("YYYY.MM.DD HH:mm") ?? ""} />
                            </Col>
                            <Col xl={12} xs={12}>
                                <ReadonlyInput label="Leírás:" multiline value={store.vehicleCondition?.description} />
                            </Col>
                        </Row>

                        <Space size="middle" style={{ marginTop: "24px" }}>
                            <Button type="primary" loading={store.isLoading} onClick={() => saveVehicleConditionAsync()}>
                                Mentés
                            </Button>
                            <Button loading={store.isLoading} onClick={() => navigateBack()}>Mégsem</Button>
                        </Space>
                    </Col>
                </Row>
            </PageHeader>
        </>
    );
}

const VehicleConditionDetailCoreWithStore = withVehicleConditionDetailStore(VehicleConditionDetailCore);

export default function VehicleConditionDetail() {
    const { vehicleConditionId } = useParams();
    return (
        <VehicleConditionDetailCoreWithStore vehicleConditionId={isNullOrUndefined(vehicleConditionId) ? undefined : parseInt(vehicleConditionId!)} />
    )
}