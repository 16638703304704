import { createStoreProvider, isNullOrUndefined, StoreBase } from "@eblsoft/react-toolkit";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { action, observable, runInAction } from "mobx";
import { downloadBase64Async } from "Util/FileDownloader";
import CommonFileListItem from "Models/CommonFileListItem";
import { ICommonFileListProps } from "./CommonFiles";
import CommonFileDetailStore from "./CommonFileDetail/CommonFileDetailStore";
import NotificationStore from "Stores/NotificationStore";
import { ValidationStore } from "Stores/ValidationStore";

export default class CommonFilesStore extends StoreBase<ICommonFileListProps> {
    @observable.ref public commonFiles: CommonFileListItem[] = [];

    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    @observable.ref public detailModalIsVisible: boolean = false;
    @observable.ref public confirmIsLoading: boolean = false;
    @observable.ref public showOverwriteExistCommonFileConfirmation = false;
    @observable.ref public categories: string[] = [];

    public commonFileDetailStore: CommonFileDetailStore = new CommonFileDetailStore();
    public readonly validationStore = new ValidationStore();

    public orderedBy = "createdAt";
    public orderDirection = "ascend";

    public readonly vLoadAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await this.props.commonFileApiAdapter.getListAsync(paging, ordering);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.commonFiles = result.commonFiles;
            this.categories = result.categories;
            this.commonFileDetailStore.category = result.categories[0];
        });
    });

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<CommonFileListItem> | SorterResult<CommonFileListItem>[], extra: TableCurrentDataSource<CommonFileListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (!sorter) {
            this.orderedBy = "name";
        } else {
            if (Array.isArray(sorter)) {
                this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
                this.orderDirection = sorter[0].order!;
            } else {
                this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
                this.orderDirection = sorter.order!;
            }
        }
        this.vLoadAsync.fireAndForget();
    }

    public readonly printAsync = this.async(async (commonFileId: number, name: string) => {
        const result = await this.props.commonFileApiAdapter.printAsync(commonFileId);
        await downloadBase64Async(result, "application/pdf", name);
    });

    public readonly deleteAsync = this.async(async (id: number) => {
        const result = await this.props.commonFileApiAdapter.deleteCommonFileAsync(id);
        if (result) {
            runInAction(() => {
                this.confirmIsLoading = false;
            });
            NotificationStore.success("Sikeres törlés");
        }
        await this.vLoadAsync();
    });

    public readonly createAsync = this.async(async (name: string, category: string, file: File) => {
        const result = await this.props.commonFileApiAdapter.createCommonFileAsync(name, category, file);
        if (result) {
            runInAction(() => {
                this.commonFileDetailStore.isNew = false;
            });
            NotificationStore.success("Sikeres mentés");
        }
        await this.vLoadAsync();
    });

    public readonly updateAsync = this.async(async (name: string, category: string, file?: File) => {
        const result = await this.props.commonFileApiAdapter.updateCommonFileAsync(name, category, file);
        if (result) {
            runInAction(() => {
                this.commonFileDetailStore.isNew = false;
            });
            NotificationStore.success("Sikeres mentés");
        }
        await this.vLoadAsync();
    });

    @action.bound
    public update() {
        this.updateAsync.fireAndForget(this.commonFileDetailStore.commonFileName, this.commonFileDetailStore.category, this.commonFileDetailStore.file!);
        this.showOverwriteExistCommonFileConfirmation = false;
    }

    public readonly checkExistenceAsync = this.async(async (name: string) => {
        const result = await this.props.commonFileApiAdapter.existsAsync(name);
        return result;
    });

    public readonly saveAsync = this.async(async (name: string, category: string, file?: File) => {
        if (!this.validationStore.isValid()) {
            runInAction(() => {
                this.confirmIsLoading = false;
            });
            NotificationStore.error("Hibás vagy kitöltetlen adatok");
            return;
        } else if (isNullOrUndefined(file) && this.commonFileDetailStore.isNew) {
            runInAction(() => {
                this.confirmIsLoading = false;
            });
            NotificationStore.error("Kérjük töltsön fel dokumentumot.");
            return;
        } else if (this.commonFileDetailStore.isNew) {
            const isExists = await this.checkExistenceAsync(name);
            if (isExists) {
                runInAction(() => {
                    this.showOverwriteExistCommonFileConfirmation = true;
                })
                return;
            } else {
                await this.createAsync(name, category, file!);
            }
        } else {
            await this.updateAsync(name, category, file);
        }
        runInAction(() => {
            this.confirmIsLoading = false;
            this.detailModalIsVisible = false;
            this.cancelDetailModal();
        });
    });

    @action.bound
    public save() {
        this.confirmIsLoading = true;
        this.saveAsync.fireAndForget(this.commonFileDetailStore.commonFileName, this.commonFileDetailStore.category, this.commonFileDetailStore.file ?? undefined);
    }

    @action.bound
    public print(commonFileId: number, name: string) {
        this.printAsync.fireAndForget(commonFileId, name);
    }

    @action.bound
    public delete(id: number) {
        this.deleteAsync.fireAndForget(id);
    }

    @action.bound
    public cancelDetailModal() {
        this.detailModalIsVisible = false;
        this.commonFileDetailStore.file = null;
        this.commonFileDetailStore.commonFileName = "";
        this.commonFileDetailStore.category = this.categories[0];
        this.showOverwriteExistCommonFileConfirmation = false;
    }

    @action.bound
    public openDetailModal(isNew: boolean, record: CommonFileListItem | null = null) {
        this.detailModalIsVisible = true;
        this.commonFileDetailStore.isNew = isNew;
        this.commonFileDetailStore.commonFileName = record?.name ?? "";
        this.commonFileDetailStore.category = record?.category ?? this.categories[0];
    }

    @action.bound
    public cancelOverWriteExistPopConfirmation() {
        this.showOverwriteExistCommonFileConfirmation = false;
    }
}

const { ContextComponent: CommonFilesStoreContext, StoreProvider: CommonFilesStoreProvider, useStore: useCommonFilesStore, withStore: withCommonFilesStore } = createStoreProvider<ICommonFileListProps, CommonFilesStore>(() => new CommonFilesStore());

export {
    CommonFilesStoreContext,
    CommonFilesStoreProvider,
    useCommonFilesStore,
    withCommonFilesStore
};