import { action, computed, makeObservable, observable } from "mobx";
import { InvoiceStatus } from "Models/InvoiceStatus";
import { VoucherType } from "Models/VoucherType";
import moment from "moment";

class InvoiceFilter {
    @observable.ref public accountedRidePublicIdentifier: string = "";
    @observable.ref public invoiceNumber: string = "";
    @observable.ref public rideExternalIdentifier: string = "";
    @observable.ref public seller: string = "";
    @observable.ref public customer: string = "";
    @observable.ref public createdOnFrom: moment.Moment | null = null;
    @observable.ref public createdOnTo: moment.Moment | null = null;
    @observable.ref public accountedOnFrom: moment.Moment | null = null;
    @observable.ref public accountedOnTo: moment.Moment | null = null;
    @observable.ref public costCenter: string = "";
    @observable.ref public grossCostFrom: number | null = null;
    @observable.ref public grossCostTo: number | null = null;
    @observable.ref public voucherType: VoucherType | null = null;
    @observable.ref public voucherInfo: string = "";
    @observable.ref public startSettlement: string = "";
    @observable.ref public startZipCode: string = "";
    @observable.ref public startAddressLine: string = "";
    @observable.ref public rideCustomerName: string = "";
    @observable.ref public fulfilledOnFrom: moment.Moment | null = null;
    @observable.ref public fulfilledOnTo: moment.Moment | null = null;
    @observable.ref public partnerInvoiceSummaryIdentifier: string = "";
    @observable.ref public statuses: InvoiceStatus[] = [];
    @observable public areFiltersDirty = false;

    @computed public get isEmpty() {
        return this.accountedRidePublicIdentifier === "" &&
            this.invoiceNumber === "" &&
            this.rideExternalIdentifier === "" &&
            this.seller === "" &&
            this.customer === "" &&
            this.createdOnFrom === null &&
            this.createdOnTo === null &&
            this.accountedOnFrom === null &&
            this.accountedOnTo === null &&
            this.costCenter === "" &&
            this.grossCostFrom === null &&
            this.grossCostTo === null &&
            this.voucherType === null &&
            this.voucherInfo === "" &&
            this.startSettlement === "" &&
            this.startZipCode === "" &&
            this.startAddressLine === "" &&
            this.rideCustomerName === "" &&
            this.fulfilledOnFrom === null &&
            this.fulfilledOnTo === null &&
            this.partnerInvoiceSummaryIdentifier === "" &&
            this.statuses.length === 0;
    }

    public constructor(
    ) {
        makeObservable(this);
    }

    @action.bound
    public clear() {
        this.accountedRidePublicIdentifier = "";
        this.invoiceNumber = "";
        this.rideExternalIdentifier = "";
        this.seller = "";
        this.customer = "";
        this.createdOnFrom = null;
        this.createdOnTo = null;
        this.accountedOnFrom = null;
        this.accountedOnTo = null;
        this.costCenter = "";
        this.grossCostFrom = null;
        this.grossCostTo = null;
        this.voucherType = null;
        this.voucherInfo = "";
        this.startSettlement = "";
        this.startZipCode = "";
        this.startAddressLine = "";
        this.rideCustomerName = "";
        this.fulfilledOnFrom = null;
        this.fulfilledOnTo = null;
        this.partnerInvoiceSummaryIdentifier = "";
        this.statuses = [];
        this.areFiltersDirty = true;
    }

    @action.bound
    public setAccountedRidePublicIdentifier(accountedRidePublicIdentifier: string) {
        this.accountedRidePublicIdentifier = accountedRidePublicIdentifier;
        this.areFiltersDirty = true;

    }

    @action.bound
    public setRideExternalIdentifier(rideExternalIdentifier: string) {
        this.rideExternalIdentifier = rideExternalIdentifier;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setPartnerInvoiceSummaryIdentifier(partnerInvoiceSummaryIdentifier: string) {
        this.rideExternalIdentifier = partnerInvoiceSummaryIdentifier;
        this.areFiltersDirty = true;
    }


    @action.bound
    public setInvoiceNumber(invoiceNumber: string) {
        this.invoiceNumber = invoiceNumber;
        this.areFiltersDirty = true;

    }

    @action.bound
    public setSeller(seller: string) {
        this.seller = seller;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setRideCustomerName(rideCustomerName: string) {
        this.rideCustomerName = rideCustomerName;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setStartSettlement(settlement: string) {
        this.startSettlement = settlement;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setStartAddressLine(addressLine: string) {
        this.startAddressLine = addressLine;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setStartZipCode(zipCode: string) {
        this.startZipCode = zipCode;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setCustomer(customer: string) {
        this.customer = customer;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setCostCenter(costCenter: string) {
        this.costCenter = costCenter;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setGrossCostFrom(grossCostFrom: number | null) {
        this.grossCostFrom = grossCostFrom;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setGrossCostTo(grossCostTo: number | null) {
        this.grossCostTo = grossCostTo;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setfulfilledOnRange(from: moment.Moment | null, to: moment.Moment | null) {
        this.fulfilledOnFrom = from?.clone().hour(0).minute(0).second(0).millisecond(0) ?? null;
        this.fulfilledOnTo = to?.clone().hour(23).minute(59).second(59).millisecond(999) ?? null;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setCreatedOnRange(from: moment.Moment | null, to: moment.Moment | null) {
        this.createdOnFrom = from?.clone().hour(0).minute(0).second(0).millisecond(0) ?? null;
        this.createdOnTo = to?.clone().hour(23).minute(59).second(59).millisecond(999) ?? null;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setAccountedOnRange(from: moment.Moment | null, to: moment.Moment | null) {
        this.accountedOnFrom = from?.clone().hour(0).minute(0).second(0).millisecond(0) ?? null;
        this.accountedOnTo = to?.clone().hour(23).minute(59).second(59).millisecond(999) ?? null;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setVoucherInfo(voucherInfo: string) {
        this.areFiltersDirty = true;
        this.voucherInfo = voucherInfo;
    }

    @action.bound
    public setVoucherType(value: VoucherType) {
        this.areFiltersDirty = true;
        this.voucherType = value;
    }

    @action.bound
    public setStatuses(values: InvoiceStatus[]) {
        this.areFiltersDirty = true;
        this.statuses = values;
    }
}

const empty = new InvoiceFilter();

export default InvoiceFilter;