import { IQueryParameters } from "@eblsoft/react-toolkit";
import { IGetTaxiDriversRequest } from "Adapters/Api/Api.g";
import { mapTaxiDriverDtoToDomainModel, mapTaxiDriverListFilterToDto, mapTaxiDriverListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import TaxiDriverFilter from "Models/Filters/TaxiDriverFilter";
import TaxiDriver from "Models/TaxiDriver";
import TaxiDriverListItem from "Models/TaxiDriverListItem";

class AdminTaxiDriverAdapter extends ProtectedApiAdapterBase {
    private readonly mainRoute = "api/web/admin/TaxiDriver";

    public getListAsync(paging: Paging, ordering: Ordering, filter: TaxiDriverFilter): Promise<{ totalCount: number, taxiDrivers: TaxiDriverListItem[] }> {
        const request: IGetTaxiDriversRequest = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapTaxiDriverListFilterToDto(filter)
        }
        return this.httpPost<{ totalCount: number, taxiDrivers: TaxiDriverListItem[] }>(this.mainRoute, undefined, request).map(paginatedResult => {
            return {
                totalCount: paginatedResult.totalCount,
                taxiDrivers: paginatedResult.taxiDrivers.map((p: any) => {
                    return mapTaxiDriverListItemDtoToDomainModel(p);
                })
            };
        }
        ).performOperationAsync();
    }
}

export default new AdminTaxiDriverAdapter();