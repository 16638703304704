import { createStoreProvider, isNullOrUndefined, StoreBase } from "@eblsoft/react-toolkit";
import { action, computed, observable, runInAction } from "mobx";
import Address from "Models/Address";
import AddressStore from "Models/AddressStore";
import { CostPlacementType } from "Models/CostPlacementType"
import CreatedTaxiCardListItem from "Models/CreatedTaxiCardListItem";
import TaxiCardListItem from "Models/TaxiCardListItem";
import NotificationStore from "Stores/NotificationStore";
import { ValidationStore } from "Stores/ValidationStore";
import { ITaxiCardCreationCoreProps } from "./TaxiCardCreation";

export default class TaxiCardCreationStore extends StoreBase<ITaxiCardCreationCoreProps> {
    @observable public costPlacement = "";
    @observable.ref public costPlacementType = CostPlacementType.None;
    @observable.ref public amount = 1;
    @observable.ref public pin = "";
    @observable.ref public requesterName = "";
    @observable.ref public requesterPhoneNumber = "";
    @observable.ref public referenceNumber = "";
    @observable.ref public addressStore: AddressStore = new AddressStore();
    @observable.ref public isPhysical = false;
    @observable.ref public createdTaxiCards: CreatedTaxiCardListItem[] = [];

    public readonly validationStore = new ValidationStore();

    @action.bound
    public setPin(value: string) {
        this.pin = value;
    }

    @action.bound
    public setRequesterName(value: string) {
        this.requesterName = value;
    }

    @computed public get isCostPlacementRequired() { return this.costPlacementType === CostPlacementType.Fix };

    @action.bound
    public setRequesterPhoneNumber(value: string) {
        this.requesterPhoneNumber = value;
    }

    @action.bound
    public setReferenceNumber(value: string) {
        this.referenceNumber = value;
    }

    @action.bound
    public setCostPlacement(value: string) {
        this.costPlacement = value;
    }

    @action.bound
    public setCostPlacementType(value: CostPlacementType) {
        this.costPlacementType = value;
    }

    @action.bound
    public setAmount(value: number | null) {
        this.amount = value ?? 1;
    }

    @action.bound
    public setIsPhysical() {
        this.isPhysical = !this.isPhysical;
    }

    private isNullOrEmptyString(val: string | null | undefined) {
        return val === null || val === undefined || val.length === 0;
    }

    public create = this.async(async () => {

        // if (this.isNullOrEmptyString(this.requesterName) ||
        //     this.isNullOrEmptyString(this.requesterPhoneNumber) ||
        //     this.isNullOrEmptyString(this.addressStore.addressLine)) {
        //     NotificationStore.error("Hibás vagy kitöltetlen adatok");
        //     return;
        // }

        if (!this.validationStore.isValid()) {
            NotificationStore.error("Hibás vagy kitöltetlen adatok");
            return;
        }

        const res = await this.props.taxiCardApiApadter.createTaxiCardsAsync!(
            this.amount,
            this.costPlacementType,
            this.costPlacement,
            this.pin,
            this.requesterName,
            this.requesterPhoneNumber,
            this.referenceNumber,
            this.addressStore.address,
            this.props.partnerId);

        NotificationStore.success("Sikeres igénylés");

        runInAction(() => {
            this.createdTaxiCards = res.taxiCards;
        });
    });

    public getPartnerAddress = this.async(async () => {
        const res = await this.props.taxiCardApiApadter.getPartnerAddressAsync!(this.props.partnerId);
        runInAction(() => {
            this.addressStore.setAddressLine(res?.addressLine ?? "");
            this.addressStore.setRegion(res?.region ?? "");
            this.addressStore.setSettlement(res?.settlement ?? "");
            this.addressStore.setZipCode(res?.zipCode ?? "");
            this.addressStore.setCountryCode(res?.countryCode ?? "");
        });
    });

    @action.bound
    empty() {
        this.amount = 0;
        this.costPlacement = "";
        this.costPlacementType = CostPlacementType.Fix;
        this.isPhysical = false;
        this.createdTaxiCards = [];
    }
}

const { ContextComponent: TaxiCardCreationStoreContext, StoreProvider: TaxiCardCreationStoreProvider, useStore: useTaxiCardCreationStore, withStore: withTaxiCardCreationStore } =
    createStoreProvider<ITaxiCardCreationCoreProps, TaxiCardCreationStore>(() => new TaxiCardCreationStore());

export {
    TaxiCardCreationStoreContext,
    TaxiCardCreationStoreProvider,
    useTaxiCardCreationStore,
    withTaxiCardCreationStore
};