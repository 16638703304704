import { bound, createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { action, observable, runInAction } from "mobx";
import AuditEventListItem from "Models/AuditEventListItem";
import AdminAuditEventApiAdapter from "Adapters/AdminAdapter/AdminAuditEventApiAdapter";
import AuditEventFilter from "Models/Filters/AuditEventFilter";

class AuditEventListStore extends StoreBase {

    @observable.ref public items: AuditEventListItem[] = [];

    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    public orderedBy = "occuredAt";
    public orderDirection = "descend";

    public filter = new AuditEventFilter();

    public readonly vLoadAsync = this.async(async () => {
        // await this.restoreState();
        await this._loadListAsync();
    });

    private readonly _loadListAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await AdminAuditEventApiAdapter.getListAsync(paging, ordering, this.filter);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.items = result.consents;
        });
    });

    @action.bound
    public filterResult() {
        // this.saveState();
        this.currentPage = 1;
        this.filter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<AuditEventListItem> | SorterResult<AuditEventListItem>[], extra: TableCurrentDataSource<AuditEventListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (!sorter) {
            this.orderedBy = "occuredAt";
        } else {
            if (Array.isArray(sorter)) {
                this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
                this.orderDirection = sorter[0].order!;
            } else {
                this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
                this.orderDirection = sorter.order!;
            }
        }
        // this.saveState();
        this.vLoadAsync.fireAndForget();
    }
}

const { ContextComponent: AuditEventListStoreContext, StoreProvider: AuditEventListStoreProvider, useStore: useAuditEventListStore, withStore: withAuditEventListStore } = createStoreProvider(() => new AuditEventListStore());

export {
    AuditEventListStoreContext,
    AuditEventListStoreProvider,
    useAuditEventListStore,
    withAuditEventListStore
};
