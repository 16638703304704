import { bound, createStoreProvider, isNullOrUndefined, StoreBase } from "@eblsoft/react-toolkit";
import { action, observable } from "mobx";
import { PartnerWithEmailPreferences } from "Models/Partner";
import { IPartnerDataCoreProps } from "./PartnerData";
import { isValidEmail } from "Util/EmailValidator";
import { ValidationMessages } from "StaticResources/ValidationMessages";
import NotificationStore from "Stores/NotificationStore";

export default class PartnerDataStore extends StoreBase<IPartnerDataCoreProps> {
    @observable.ref public partner!: PartnerWithEmailPreferences;

    @observable.ref public invoiceSummaryEmail: string = "";
    @observable.ref public eTaxiContactEmail: string = "";
    @observable.ref public includeRideDataInInvoiceSummary: boolean = true;
    @observable.ref public cardPermanentCancellationFee: number | null = 0;
    @observable.ref public emailPreferencesChanged = false;
    @observable.ref public baseDataChanged = false;

    public readonly vLoadAsync = this.async(async () => {
        const partner = await this.props.partnerApiApadter.getAsync(this.props.partnerId);
        this.setLoadedState(partner);
    });

    @action.bound
    public setInvoiceSummaryEmail(value: string) { this.invoiceSummaryEmail = value; this.emailPreferencesChanged = true; }

    @action.bound
    public setETaxiContactEmail(value: string) { this.eTaxiContactEmail = value; this.emailPreferencesChanged = true; }

    @action.bound
    public setIncludeRideDataInInvoiceSummary(value: boolean) { this.includeRideDataInInvoiceSummary = value; this.emailPreferencesChanged = true; }

    @action.bound
    public setCardPermanentCancellationFee(value: number | null) { this.cardPermanentCancellationFee = value; this.baseDataChanged = true; }

    public readonly saveEmailPreferencesAsync = this.async(async () => {
        if (!this.areEmailPreferencesValid()) {
            NotificationStore.error("Hibás adatok");
            return;
        }

        const partner = await this.props.partnerApiApadter.updateEmailPreferencesAsync(this.invoiceSummaryEmail, this.eTaxiContactEmail, this.includeRideDataInInvoiceSummary, this.props.partnerId);
        NotificationStore.success("Sikeres mentés");
        this.setLoadedState(partner);
    });

    public readonly saveBaseDataAsync = this.async(async () => {

        if (!isNullOrUndefined(this.props.partnerApiApadter.updateBaseDataAsync)) {
            const partner = await this.props.partnerApiApadter.updateBaseDataAsync!(this.cardPermanentCancellationFee!, this.props.partnerId);
            NotificationStore.success("Sikeres mentés");
            this.setLoadedState(partner);
        }
    });

    @bound
    private areEmailPreferencesValid(): boolean {
        if (this.invoiceSummaryEmail && !this.validatedMultiEmail(this.invoiceSummaryEmail.split(","))) {
            return false;
        }

        if (this.eTaxiContactEmail && !isValidEmail(this.eTaxiContactEmail)) {
            return false;
        }

        return true;
    }

    @bound
    public validateInvoicSummaryEmail(value: string | null): string | null {
        if (isNullOrUndefined(value)) {
            return null;
        }

        return this.validatedMultiEmail(value!.split(",")) ? null : ValidationMessages.invalidEmailMessage;
    }

    private validatedMultiEmail(emails: string[]) {
        return emails.every(email => isValidEmail(email.trim()));
    }

    @bound
    public validateEmailAddress(value: string | null): string | null {
        if (value && !isValidEmail(value)) {
            return ValidationMessages.invalidEmailMessage;
        } else {
            return null;
        }
    }

    @action
    private setLoadedState(partner: PartnerWithEmailPreferences) {
        this.partner = partner;
        this.invoiceSummaryEmail = partner.invoiceSummaryEmail ?? "";
        this.eTaxiContactEmail = partner.eTaxiContactEmail ?? "";
        this.includeRideDataInInvoiceSummary = partner.includeRideDataInInvoiceSummary;
        this.cardPermanentCancellationFee = partner.cardPermanentCancellationFee;

        this.emailPreferencesChanged = false;
        this.baseDataChanged = false;
    }
}

const { ContextComponent: storeContextPartnerData, StoreProvider: providePartnerDataStore, useStore: usePartnerDataStore, withStore: withPartnerDataStore } = createStoreProvider<IPartnerDataCoreProps, PartnerDataStore>(() => new PartnerDataStore());

export {
    storeContextPartnerData,
    providePartnerDataStore,
    usePartnerDataStore,
    withPartnerDataStore
};
