import moment from "moment";

export default class FailedRide {
    constructor(
        public readonly id: number,
        public readonly publicIdentifier: string,
        public readonly rideIdentifier: string,
        public readonly driverName: string,
        public readonly driverBpid: string,
        public readonly taxiDriverName: string,
        public readonly grossPrice: number,
        public readonly createdAt: moment.Moment,
        public readonly deletedAt: moment.Moment | null,
        public readonly isApproved: boolean | null,
        public readonly reviewedAt: moment.Moment | null,
    ) {}
}