import React from "react";
import { Button, Col, Divider, PageHeader, Row, Space } from "antd";
import Config from "Config";
import AuthStore from "Stores/AuthStore";
import { LockOutlined, SaveOutlined } from "@ant-design/icons";
import { useSettingsStore, withSettingsStore } from "./SettingsStore";
import NumberBox from "Pages/CommonControls/NumberBox";

interface ISettingsProps {

}

function Settings(props: ISettingsProps) {

    const store = useSettingsStore();

    return (
        <PageHeader
            title={<span id="page_title">Beállítások</span>}
        >
            <Divider orientation="left">Biztonság</Divider>
            <Space>
                <a href={`${Config.settings.oauth.changePasswordUrl}?identifier=${AuthStore.user?.identifier}`}>
                    <Button icon={<LockOutlined />}>
                        Jelszóváltoztatás
                    </Button>
                </a>
            </Space>

            {AuthStore.user?.hasRole("Admin") && (
                <>
                    <Divider orientation="left">Kártya letiltás</Divider>
                    <div style={{ marginTop: "16px" }}>
                        <Row gutter={[16, 8]}>
                            <Col span={12}>
                                <NumberBox addonAfter="Ft." label="Alapértelmezett kártyaletiltási költség (nettó):" value={store.defaultCardPermanentCancellationFee} onChange={store.setDefaultCardPermanentCancellationFee} />
                            </Col>
                        </Row>

                        <Space style={{ marginTop: "16px", marginBottom: "16px", justifyContent: "right", width: "100%" }}>
                            <Button type="primary" icon={<SaveOutlined />} onClick={store.saveDefaultCardPermanentCancellationFeeAsync.fireAndForget} >
                                Mentés
                            </Button>
                        </Space>

                    </div>
                </>
            )}

            {AuthStore.user?.hasRole("DocRev") && (
                <>
                    <Divider orientation="left">Iratkezelés biztonság</Divider>

                    {store.hasTfa ? (
                        <p>A kétfaktoros hitelesítés beállítva. <small>(Ha elveszítette a kódot, keresse fel a rendszerüzemeltetőt!)</small></p>
                    ) : (
                        <>
                            {store.tfaInitData ? (
                                <div>
                                    <h3>Google Authenticator</h3>

                                    <ol>
                                        <li>Töltse le a Google Authenticator applikációt a telefonjára (<a target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=hu">Link</a>)</li>
                                        <li>Az applikációt megnyitva a jobb alsó sarokban kattintson a "+" gombra.</li>
                                        <li>Válassza a QR kód beolvasása lehetőséget és olvassa be az alábbi kódot.</li>
                                        <li>Ezután a Google Authenticator applikációban bármikor elérhető a hitelesítő kód.</li>
                                    </ol>

                                    <img src={store.tfaInitData.qrUrl} />
                                </div>
                            ) : (
                                <Button onClick={store.createTfaAsync.fireAndForget}>Kétfaktoros hitelesítés beállítása</Button>
                            )}
                        </>
                    )}


                </>
            )}
        </PageHeader>
    );
}

export default withSettingsStore(Settings);