import { action, makeObservable, observable } from "mobx";
import moment from "moment";

class InvoiceSummaryFilter {
    @observable.ref public identifier: string = "";
    @observable.ref public createdOn: moment.Moment | null = null;
    @observable public areFiltersDirty = false;

    public constructor(
    ) {
        makeObservable(this);
    }

    @action.bound
    public setIdentifier(identifier: string) {
        this.identifier = identifier;
        this.areFiltersDirty = true;

    }


    @action.bound
    public setCreatedOn(createdOn: moment.Moment | null) {
        this.createdOn = createdOn;
        this.areFiltersDirty = true;

    }

}
export default InvoiceSummaryFilter;