import { action, makeObservable, observable } from "mobx";
import Answer from "./Answer";
import { TargetAudience } from "./TargetAudience";

export default class Poll {
    constructor(
        public readonly id: number,
        public readonly question: string,
        public readonly name: string,
        public readonly endOfVoteDate: moment.Moment | null,
        public readonly answers: Answer[],
        public readonly startedAt: moment.Moment | null,
        public readonly targetAudience: TargetAudience,
        public readonly allowCustomAnswer: boolean,
    ) { }
}