import Address from "./Address";
import TaxNumber from "./TaxNumber";

export default class PartnerListItem {
    constructor(
        public readonly id: number,
        public readonly bpid: string,
        public readonly bpName: string,
        public readonly taxNumber: TaxNumber | null,
        public readonly orderAbleTaxNumber: string,
        public readonly groupMemberTaxNumber: TaxNumber | null,
        public readonly address: Address,
        public readonly mailingAddress: Address | null,
        public readonly name: string,
        public readonly isPrivatePerson: boolean,
    ) { }
}