import IPollApiAdapter from "Adapters/IPollApiAdapter";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import ITaxiCardApiAdapter from "Adapters/ITaxiCardApiAdapter";
import { Alert, Button, Col, PageHeader, Popconfirm, Row, Select, Space, Table, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { CostPlacementType, CostPlacementTypeEntries } from "Models/CostPlacementType";
import CreatedTaxiCardListItem from "Models/CreatedTaxiCardListItem";
import TaxiCardListItem from "Models/TaxiCardListItem";
import AddressBox from "Pages/CommonControls/AddressBox";
import { CostplacementTypeSelectBox } from "Pages/CommonControls/CostPlacementTypeSelectBox";
import NumberBox from "Pages/CommonControls/NumberBox";
import PinInputBox from "Pages/CommonControls/PinInputBox";
import TextBox from "Pages/CommonControls/TextBox";
import { useNavigate, useParams } from "react-router-dom";
import { ValidationStoreProvider } from "Stores/ValidationStore";
import { usePollDetailStore, withPollDetailStore } from "./PollDetailStore";
import DateRangePicker from "Pages/CommonControls/DateRangePicker";
import { EditOutlined, StopOutlined } from "@ant-design/icons";
import Answer from "Models/Answer";
import { Observer, observer } from "mobx-react";
import { usePollsStore } from "Pages/Admin/Poll/PollsStore";
import StartPollModal from "./StartPollModal";
import { TargetAudienceSelectBox } from "Pages/CommonControls/TargetAudienceSelectBox";
import CheckBox from "Pages/CommonControls/CheckBox";
const { Title } = Typography;
const { Option } = Select;


export interface IPollDetailProps {
    pollApiAdapter: IPollApiAdapter;
}
export interface IPollDetailCoreProps extends IPollDetailProps {
    pollId?: number;
}

function PollDetailCore(props: IPollDetailCoreProps) {
    const store = usePollDetailStore();
    const pollStore = usePollsStore();
    const navigate = useNavigate();

    const startPollAsync = async () => {
        const success = await store.startPollAsync();
        if (success) {
            pollStore.vLoadAsync.fireAndForget();
            if (!isNullOrUndefined(store.id)) {
                navigate(`/admin/polls/${store.id}/votes`, { replace: true });
            }
        }
    }

    const savePollAsync = async () => {
        const success = await store.saveAsync();
        if (success) {
            pollStore.vLoadAsync.fireAndForget();
        }
    }

    const AnswerColumns: ColumnsType<Answer> = [

        {
            title: "Szám",
            dataIndex: "number",
            key: "number",
            width: 75
        },
        {
            title: "Név",
            dataIndex: "name",
            key: "name",
            render: (_, record: Answer) => <Observer>{() => isNullOrUndefined(store.startedAt) ? <TextBox value={record.text} onChange={record.setText} isRequired maxLength={128} /> : <span>{record.text}</span>}</Observer>
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (_, record: Answer) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Törlés">
                            <Button onClick={() => store.removeAnswer(record.number)} disabled={!isNullOrUndefined(store.startedAt)} type="primary" icon={<StopOutlined />} />
                        </Tooltip>
                    </Space>
                );
            }
        }

    ];

    return (
        <>
            <PageHeader
                title={isNullOrUndefined(props?.pollId) ? "Új szavazás" : "Szavazás szerkeztés"}
                footer={
                    <Row gutter={16} >
                        <Col xxl={3} xl={4} lg={5} md={6} xs={8}>
                            <Button style={{ width: "100%", marginTop: "24px" }} key="submit" type="primary" disabled={!isNullOrUndefined(store.startedAt)} loading={store.isLoading} onClick={() => savePollAsync()}>
                                Mentés
                            </Button>
                        </Col>

                        <Col xxl={3} xl={4} lg={5} md={6} xs={8}>
                            <Button loading={store.isLoading} disabled={!isNullOrUndefined(store.startedAt)} onClick={() => store.showPollStartModal()} style={{ marginTop: "24px" }}>Indítás</Button>
                        </Col>
                    </Row>
                }
            >
                <ValidationStoreProvider value={store.validationStore}>
                    <Row gutter={16} style={{ marginBottom: "16px" }}>
                        <Col xl={12} xs={12}>
                            <TextBox
                                disabled={!isNullOrUndefined(store.startedAt)}
                                label="Szavazás címe:"
                                onChange={store.setName}
                                value={store.name}
                                isRequired
                                maxLength={256}
                            />
                        </Col>
                        <Col xl={12} xs={12}>
                            <TargetAudienceSelectBox
                                disabled={!isNullOrUndefined(store.startedAt)}
                                label="Célcsoport:"
                                onChange={store.setTargetAudience}
                                targetAudience={store.targetAudience}
                            />
                        </Col>
                        <Col xl={12} xs={12}>
                            <TextBox
                                disabled={!isNullOrUndefined(store.startedAt)}
                                value={store.question}
                                onChange={store.setQuestion}
                                style={{ width: "100%" }}
                                isRequired
                                multiline
                                label="Kérdés:"
                            />
                        </Col>
                    </Row>

                    <Title level={5}>Válaszlehetőségek:</Title>

                    <Row gutter={8} style={{ marginBottom: 16 }}>
                        <Col xs={24}>
                            <CheckBox
                                label="Egyéni válasz engedélyezése"
                                style={{ marginTop: "16px" }}
                                onChange={store.setAllowCustomAnswer}
                                value={store.allowCustomAnswer} />
                        </Col>
                    </Row>

                    <Row gutter={16} >
                        <Col xxl={3} xl={4} lg={5} md={6} xs={8}>
                            <Button style={{ width: "100%", marginBottom: 16 }} type="primary" disabled={!isNullOrUndefined(store.startedAt)} onClick={store.createNewAnswer}>
                                Hozzáadás
                            </Button>
                        </Col>
                    </Row>
                    <Table size="middle" columns={AnswerColumns} dataSource={store.answers} rowKey="number" />

                    <StartPollModal
                        isLoading={store.isLoading}
                        isOpen={store.isStartPollModal}
                        onCancel={store.cancelPollStart}
                        onOk={startPollAsync}
                        endOfVoteDate={store.endOfVoteDate}
                        setEndOfVoteDate={store.setEndOfVoteDate}
                    />
                </ValidationStoreProvider>
            </PageHeader>
        </>
    );
}

const PollDetailCoreWithStore = withPollDetailStore(PollDetailCore);

export default function PollDetail(props: IPollDetailProps) {
    const { pollId } = useParams();
    return (
        <PollDetailCoreWithStore {...props} pollId={isNullOrUndefined(pollId) ? undefined : parseInt(pollId!)} />
    )
}