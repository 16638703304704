import { createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { action, computed, observable, runInAction } from "mobx";
import { CostPlacementType } from "Models/CostPlacementType";
import PollFilter from "Models/Filters/PollFilter";
import TaxiCardFilter from "Models/Filters/TaxiCardFilter";
import Poll from "Models/Poll";
import PollListItem from "Models/PollListItem";
import { PrintOptions } from "Models/PrintOptions";
import ProhibitionInfo from "Models/ProhibitionInfo";

import TaxiCardListItem from "Models/TaxiCardListItem";
import moment from "moment";
import { downloadBase64Async } from "Util/FileDownloader";
import { IPollListProps } from "./Polls";

export default class PollsStore extends StoreBase<IPollListProps> {
    @observable.ref public polls: PollListItem[] = [];

    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    public orderedBy = "name";
    public orderDirection = "ascend";

    public pollFilter = new PollFilter();

    public readonly vLoadAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await this.props.pollApiAdapter.getListAsync(paging, ordering, this.pollFilter);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.polls = result.polls;
        });
    });

    @action.bound
    public filterResult() {
        this.currentPage = 1;
        this.pollFilter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<PollListItem> | SorterResult<PollListItem>[], extra: TableCurrentDataSource<PollListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (!sorter) {
            this.orderedBy = "name";
        } else {
            if (Array.isArray(sorter)) {
                this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
                this.orderDirection = sorter[0].order!;
            } else {
                this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
                this.orderDirection = sorter.order!;
            }
        }
        this.vLoadAsync.fireAndForget();
    }

}

const { ContextComponent: PollsStoreContext, StoreProvider: PollsStoreProvider, useStore: usePollsStore, withStore: withPollsStore } = createStoreProvider<IPollListProps, PollsStore>(() => new PollsStore());

export {
    PollsStoreContext,
    PollsStoreProvider,
    usePollsStore,
    withPollsStore
};