import moment from "moment";
import { DocumentStatus } from "./DocumentStatus";

export class AdminDocumentListItem {

    constructor(
        public readonly bpid: string,
        public readonly name: string,
        public readonly driverName: string,
        public readonly licensePlateNumber: string,
        public readonly documents: IAdminDocumentListItemDocuments,
    ) { }

    [key: string]: any;
}

export interface IAdminDocumentListItemDocuments {
    [key: string]: IDocumentDataInit;
    driverLicence: IDocumentDataInit;
    transportingLicence: IDocumentDataInit;
    transportingLicenceSummary: IDocumentDataInit;
    taxiStationUsageAgreement: IDocumentDataInit;
    taxiDriverLicence: IDocumentDataInit;
    taximeterCertificate: IDocumentDataInit;
    vehicleCertificate: IDocumentDataInit;
    trafficLicense: IDocumentDataInit;
}

export interface IDocumentDataInit {
    documentId: number | null;
    status: DocumentStatus | null;
    validTo: moment.Moment | null;
    updatedAt: moment.Moment | null;
}
