import { DeleteOutlined, DownloadOutlined, DownOutlined, EditOutlined, EyeOutlined, FileExcelOutlined, FilePdfOutlined, FilterOutlined, PropertySafetyFilled, StopOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Dropdown, Menu, PageHeader, Popconfirm, Row, Space, Table, TablePaginationConfig, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { observer } from "mobx-react";
import { PrintOptions } from "Models/PrintOptions";
import ProhibitionInfoModal from "Pages/CommonControls/ProhibitionInfoModal";
import TextBox from "Pages/CommonControls/TextBox";
import { usePhoneNumberStore } from "./PhoneNumbersStore";
import CostPlacementTypeColumn from "./Controls/CostPlacementTypeColumn";
import NameColumn from "./Controls/NameColumn";
import PhoneNumberColumn from "./Controls/PhoneNumberColumn";
import PinColumn from "./Controls/PinColumn";
import { CostPlacementTypeSelector } from "Pages/CommonControls/CostPlacementTypeSelector";
import PhoneNumberListItem from "Models/PhoneNumberListItem";
import { IPhoneNumberListCoreProps } from "./PhoneNumbers";
import PhoneNumberTextBox from "Pages/CommonControls/PhoneNumberTextBox";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";
import { useMemo } from "react";

function PhoneNumberList(props: IPhoneNumberListCoreProps) {
    const selectedRowColor = 'rgb(37, 184, 100)';
    const store = usePhoneNumberStore();

    const _paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum,
    }

    let paginationConfig = _paginationConfig;

    const edit = (record: PhoneNumberListItem | null) => {
        if (record) {
            paginationConfig = {};
            store.setRecordUnderEdit(record.id);
        } else {
            paginationConfig = _paginationConfig;
            store.setRecordUnderEdit(null);
        }
    };

    const isUnderEdit = (record: PhoneNumberListItem) => store.recordIdUnderEdit === record.id;

    const downloadMenu = (
        <Menu>
            <div>
                <Button onClick={() => store.print(PrintOptions.PrintToPdf, false)} icon={<FilePdfOutlined />} style={{ width: "100%" }}>
                    Lista letöltése PDF-ben, PIN kódok nélkül
                </Button>
            </div>
            <div>
                <Button onClick={() => store.print(PrintOptions.PrintToExcel, false)} icon={<FileExcelOutlined />} style={{ width: "100%" }}>
                    Lista letöltése Excelben, PIN kódok nélkül
                </Button>
            </div>
            <div>
                <Button onClick={() => store.print(PrintOptions.PrintToPdf, true)} icon={<FileExcelOutlined />} style={{ width: "100%" }}>
                    Lista letöltése PDF-ben PIN kódokkal
                </Button>
            </div>
            <div>
                <Button onClick={() => store.print(PrintOptions.PrintToExcel, true)} icon={<FileExcelOutlined />} style={{ width: "100%" }}>
                    Lista letöltése Excel-ben PIN kódokkal
                </Button>
            </div>
        </Menu>
    );

    const getPhoneNumberColumns = (): ColumnsType<PhoneNumberListItem> => {
        const res: ColumnsType<PhoneNumberListItem> = [
            {
                title: "Telefonszám",
                dataIndex: "number",
                key: "number",
                sorter: store.recordIdUnderEdit === null ? true : false,
                render: (_, record: PhoneNumberListItem) => {
                    const recordUnderEdit = isUnderEdit(record);
                    const style = recordUnderEdit ? { backgroundColor: selectedRowColor } : {};
                    return {
                        props: { style },
                        children: <PhoneNumberColumn record={record} isUnderEdit={recordUnderEdit} />
                    }
                }
            },
            {
                title: "Név",
                dataIndex: "name",
                key: "name",
                sorter: store.recordIdUnderEdit === null ? true : false,
                render: (_, record: PhoneNumberListItem) => {
                    const recordUnderEdit = isUnderEdit(record);
                    const style = recordUnderEdit ? { backgroundColor: selectedRowColor } : {};
                    return {
                        props: { style },
                        children: <NameColumn record={record} isUnderEdit={isUnderEdit(record)} />
                    }
                }
            },
            {
                title: "PIN",
                dataIndex: "pin",
                key: "pin",
                render: (_, record: PhoneNumberListItem) => {
                    const recordUnderEdit = isUnderEdit(record);
                    const style = recordUnderEdit ? { backgroundColor: selectedRowColor } : {};
                    return {
                        props: { style },
                        children:
                            <PinColumn
                                isPinVisible={store.visiblePhoneNumberPinId === record.id}
                                record={record}
                                isUnderEdit={isUnderEdit(record)}
                            />
                    }
                },
                sorter: store.recordIdUnderEdit === null ? true : false,
            },
            {
                title: "Költséghely",
                dataIndex: "costPlacementType",
                key: "costPlacementType",
                render: (_, record: PhoneNumberListItem) => {
                    const recordUnderEdit = isUnderEdit(record);
                    const style = recordUnderEdit ? { backgroundColor: selectedRowColor } : {};
                    return {
                        props: { style },
                        children: <CostPlacementTypeColumn isUnderEdit={isUnderEdit(record)} record={record} />
                    }
                },
                sorter: store.recordIdUnderEdit === null ? true : false,
            },
            {
                title: "Aktív",
                dataIndex: "isActive",
                key: "isActive",
                render: (isActive: boolean, record: PhoneNumberListItem) => {
                    const recordUnderEdit = isUnderEdit(record);
                    const style = recordUnderEdit ? { backgroundColor: selectedRowColor, color: "white" } : {}
                    return {
                        props: { style },
                        children: <span>{isActive ? "Igen" : "Nem"}</span>
                    }
                },
                sorter: store.recordIdUnderEdit === null ? true : false,
            },


        ];

        if (props.isSummary) {
            res.push();
            res.push({
                title: "Partner",
                dataIndex: "customerName",
                key: "customerName",
                render: (_: any, row: PhoneNumberListItem) => <span>{row.customerName} ({row.customerBpid})</span>,
                sorter: store.recordIdUnderEdit == null ? true : false,
            }, {
                title: "",
                dataIndex: "action",
                key: "action",
                render: (_, record: PhoneNumberListItem) => {
                    return (
                        <Space size="middle">
                            <Tooltip title="PIN kód megjelenítése / elrejtése">
                                <Button onClick={() => store.setPinVisible(record.id)} type="primary" icon={<EyeOutlined />} />
                            </Tooltip>
                        </Space>
                    );
                }
            });
        }

        if (!props.isSummary) {
            res.push({
                title: "",
                dataIndex: "action",
                key: "action",
                render: (_, record: PhoneNumberListItem) => {
                    if (store.recordIdUnderEdit === record.id) {
                        return {
                            props: { style: { backgroundColor: selectedRowColor } },
                            children:
                                <Space size="middle">
                                    <span>
                                        <Typography.Link
                                            onClick={store.saveRecordUnderEdit}
                                            style={{
                                                marginRight: 8,
                                                color: 'white'
                                            }}
                                        >
                                            Mentés
                                        </Typography.Link>
                                        <Popconfirm title="Biztosan elveti a módosításokat?" okText="Igen" cancelText="Nem" onConfirm={() => edit(null)}>
                                            <a style={{ color: 'white' }}>Mégsem</a>
                                        </Popconfirm>
                                    </span>
                                </Space>
                        }
                    }
                    return (
                        <Space size="middle">
                            <Tooltip title="PIN kód megjelenítése / elrejtése">
                                <Button onClick={() => store.setPinVisible(record.id)} type="primary" icon={<EyeOutlined />} />
                            </Tooltip>

                            <Tooltip title="Szerkesztés">
                                <Button onClick={() => edit(record)} type="primary" icon={<EditOutlined />} />
                            </Tooltip>

                            <Tooltip title="Letiltás / aktiválás">
                                <Button onClick={() => record.isActive ? store.setProhibitionModalIsVisible(record.id) : store.toggleProhibitionAsync.fireAndForget(record.id)} type="primary" icon={<StopOutlined />} />
                            </Tooltip>

                            <Tooltip title="Végleges törlés">
                                <Button onClick={() => store.setDeletionModalIsVisible(record.id)} type="primary" danger icon={<DeleteOutlined />} />
                            </Tooltip>
                        </Space>
                    );
                }
            });
        }

        return res;
    }

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    return (
        <>
            <ProhibitionInfoModal
                prohibitionInfo={store.prohibitionInfo!}
                title="Tiltás információ"
                visible={store.prohibitionModalIsVisible}
                onOk={store.toggleProhibit}
                confirmLoading={store.confirmIsLoading}
                onCancel={store.cancelModal}
            />
            <ProhibitionInfoModal
                prohibitionInfo={store.deletionInfo!}
                title="Törlés információ"
                visible={store.deletionModalIsVisible}
                onOk={store.delete}
                confirmLoading={store.confirmIsLoading}
                onCancel={store.cancelModal}
            />
            <PageHeader
                title={<span id="page_title">Telefonszámok</span>}
                extra={
                    !props.isSummary && <Button key="1" type="primary" onClick={store.createPhoneNumberRow} disabled={!!store.recordIdUnderEdit}>
                        Új telefonszám rögzítése
                    </Button>
                }
            >
                <Row gutter={16}>
                    <Col xl={10} xs={12}>
                        <TextBox
                            label="Név:"
                            onChange={store.phoneNumberFilter.setName}
                            value={store.phoneNumberFilter.name}
                            style={{ width: "100%" }}
                            onEnter={store.filterResult}
                        />
                    </Col>
                    <Col xl={10} xs={12}>
                        <PhoneNumberTextBox
                            label="Telefonszám:"
                            onChange={store.phoneNumberFilter.setPhoneNumber}
                            value={store.phoneNumberFilter.phoneNumber}
                            onEnter={store.filterResult}
                        />
                    </Col>
                    {props.isSummary &&
                        <Col xl={10} xs={12}>
                            <TextBox
                                label="Partner (név vagy BPID):"
                                onChange={store.phoneNumberFilter.setCustomer}
                                value={store.phoneNumberFilter.customer}
                                onEnter={store.filterResult}
                            />
                        </Col>}
                </Row>
                <Row gutter={16}>
                    <Col xl={20} xs={24}>
                        <CostPlacementTypeSelector
                            showLabels
                            costPlacementType={store.phoneNumberFilter.costPlacementType}
                            costPlacementValue={store.phoneNumberFilter.costPlacement}
                            onCostPlacementTypeChange={store.phoneNumberFilter.setCostPlacementType}
                            onCostplacementChange={store.phoneNumberFilter.setCostPlacement}
                            onSearch={store.filterResult}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Checkbox
                            style={{ marginTop: "16px" }}
                            onChange={store.toggleShowInactive}
                            checked={store.showInactive}>
                            Letiltott telefonszámok megjelenítése
                        </Checkbox>
                    </Col>
                </Row>

                <Space style={{ marginTop: "16px", marginBottom: "16px" }}>
                    <Dropdown.Button style={{ width: "100%" }} onClick={() => store.print(PrintOptions.PrintToPdf, false)} icon={<DownOutlined />} overlay={downloadMenu}>

                        Lista letöltése
                        <DownloadOutlined />

                    </Dropdown.Button>

                    <Button style={{ width: "100%" }} type="primary" onClick={store.filterResult} icon={<FilterOutlined />}>
                        Szűrés
                    </Button>
                </Space>

                <TableSummaryHeader items={summaryItems} />
                <Table
                    onChange={store.reloadList}
                    pagination={paginationConfig}
                    size="middle"
                    columns={getPhoneNumberColumns()}
                    dataSource={store.phoneNumbers}
                    rowKey="id"
                />
            </PageHeader>
        </>
    );
}

export default observer(PhoneNumberList);
