import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { Select, Typography } from "antd"
import { AddressType, AddressTypeEntries } from "Models/AddressType"
const { Option } = Select;

const { Text } = Typography;

export interface ITextSelectBox {
    options: string[];
    onChange: (val: string) => void;
    value: string | null;
    style?: React.CSSProperties;
    allowEmpty?: boolean;
    label?: string;
}

export function TextSelectBox(props: ITextSelectBox) {
    return (
        <div style={{ width: "100%" }}>
            <Text>{props.label}</Text>
            <Select
                style={{ width: "100%" }}
                onChange={props.onChange}
                value={props.value}
                allowClear={props.allowEmpty}
            >
                {props.options.map(i =>
                    <Option key={i} value={i}>
                        {i}
                    </Option>
                )}
            </Select>
        </div>
    )
} 