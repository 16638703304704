import { action, makeObservable, observable } from "mobx";
import Answer from "./Answer";
import Distribution from "./Distribution";

export default class PollDistributions {
    constructor(
        public readonly pollDistribution: Distribution[],
        public readonly answerDistribution: Distribution[],
        public readonly cooperativeMemberAnswerDistribution: Distribution[] | null,
        public readonly pollCooperativeMemberDistribution: Distribution[] | null) { }
}