
import Address from "./Address";
import TaxNumber from "./TaxNumber";

export default class TaxiDriverListItem {
    constructor(
        public readonly id: number,
        public readonly bpid: string,
        public readonly driverName: string | null,
        public readonly taxNumber: TaxNumber,
        public readonly orderAbleTaxNumber: string,
        public readonly selfEmployedIdentifier: string | null,
        public readonly name: string,
        public readonly address: Address,
        public readonly mailingAddress: Address | null,
        public readonly enteredOn: moment.Moment | null,
        public readonly leavedOn: moment.Moment | null,
        public readonly email: string | null,
        public readonly isCooperativeMember: boolean | null,
    ) { }
}