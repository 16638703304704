import { bound, createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { action, observable, runInAction } from "mobx";
import ConsentListItem from "Models/ConsentListItem";
import ConsentFilter from "Models/Filters/ConsentFilter";
import AdminConsentAdapter from "Adapters/AdminAdapter/AdminConsentApiAdapter";

class ConsentListStore extends StoreBase {

    @observable.ref public consents: ConsentListItem[] = [];

    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    public orderedBy = "driverBpid";
    public orderDirection = "ascend";

    public filter = new ConsentFilter();

    public readonly vLoadAsync = this.async(async () => {
        // await this.restoreState();
        await this._loadListAsync();
    });

    private readonly _loadListAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await AdminConsentAdapter.getListAsync(paging, ordering, this.filter);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.consents = result.consents;
        });
    });

    @action.bound
    public filterResult() {
        // this.saveState();
        this.currentPage = 1;
        this.filter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<ConsentListItem> | SorterResult<ConsentListItem>[], extra: TableCurrentDataSource<ConsentListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (!sorter) {
            this.orderedBy = "driverBpid";
        } else {
            if (Array.isArray(sorter)) {
                this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
                this.orderDirection = sorter[0].order!;
            } else {
                this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
                this.orderDirection = sorter.order!;
            }
        }
        // this.saveState();
        this.vLoadAsync.fireAndForget();
    }
}

const { ContextComponent: ConsentListStoreContext, StoreProvider: ConsentListStoreProvider, useStore: useConsentListStore, withStore: withConsentListStore } = createStoreProvider(() => new ConsentListStore());

export {
    ConsentListStoreContext,
    ConsentListStoreProvider,
    useConsentListStore,
    withConsentListStore
};
