import { createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import AdminPartnerAdapter from "Adapters/AdminAdapter/AdminPartnerAdapter";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";

import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { action, computed, observable, runInAction } from "mobx";
import PartnerFilter from "Models/Filters/PartnerFilter";
import PartnerListItem from "Models/PartnerListItem";
import UserInvitationUrl from "Models/UserInvitationUrl";
import { showUserInvitationModal } from "./UserInvitationModal";

export default class PartnersStore extends StoreBase {
    @observable.ref public Partners: PartnerListItem[] = [];
    @observable.ref public recordNum = 100;
    @observable.ref public pageSize = 50;
    @observable.ref public currentPage = 1;
    public orderedBy = "";
    public orderDirection = "";
    public partnerFilter = new PartnerFilter();

    @observable.ref public invitation: UserInvitationUrl | null = null;
    private invitationPartnerId: number | null = null;

    @computed private get filter() {
        return {
            BpId: this.partnerFilter.bpId,
            Name: this.partnerFilter.name,
            TaxNumber: this.partnerFilter.taxNumber,
            Settlement: this.partnerFilter.settlement,
            ZipCode: this.partnerFilter.zipCode,
            AddressLine: this.partnerFilter.addressLine,
            AddressType: this.partnerFilter.addressType,
            BpName: this.partnerFilter.bpName
        };
    }
    public readonly vLoadAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");

        const result = await AdminPartnerAdapter.getListAsync(paging, ordering, this.filter);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.Partners = result.partners;
        });
    });

    @action.bound
    public filterResult() {
        this.currentPage = 1;
        this.partnerFilter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<PartnerListItem> | SorterResult<PartnerListItem>[], extra: TableCurrentDataSource<PartnerListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (Array.isArray(sorter)) {
            this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
            this.orderDirection = sorter[0].order!;
        } else {
            this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
            this.orderDirection = sorter.order!;
        }
        this.vLoadAsync.fireAndForget();
    }

    public readonly loadPartnerInvitationAsync = this.async(async (partnerId: number) => {
        const invitation = await AdminPartnerAdapter.getOrCreateInvitationAsync(partnerId);

        runInAction(() => {
            this.invitation = invitation;
            this.invitationPartnerId = partnerId;
        });

        showUserInvitationModal(this);
    });

    public readonly resetPartnerInvitationAsync = this.async(async () => {
        const invitation = await AdminPartnerAdapter.resetInvitationExpirationAsync(this.invitationPartnerId!);

        runInAction(() => {
            this.invitation = invitation;
        });
    });
}

const { ContextComponent: PartnersStoreContext, StoreProvider: PartnersStoreProvider, useStore: usePartnersStore, withStore: withPartnersStore } = createStoreProvider(() => new PartnersStore());

export {
    PartnersStoreContext,
    PartnersStoreProvider,
    usePartnersStore,
    withPartnersStore
};