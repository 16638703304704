import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";
import { StatementType } from "../StatementType";
import { ConsentSummary } from "../ConsentSummary";

class ConsentFilter {

    @observable.ref public createdAtFrom: moment.Moment | null = null;
    @observable.ref public createdAtTo: moment.Moment | null = null;
    @observable.ref public driverNameOrBpid: string = "";
    @observable.ref public statementType: StatementType | null = null;
    @observable.ref public consentSummary: ConsentSummary | null = null;

    @observable public areFiltersDirty = false;

    public constructor(
    ) {
        makeObservable(this);
    }

    @computed public get isEmpty() {
        return this.driverNameOrBpid === "" &&
            this.createdAtFrom === null &&
            this.createdAtTo === null &&
            this.statementType === null &&
            this.consentSummary === null;
    }

    @action.bound
    public clear() {
        this.driverNameOrBpid = "";
        this.createdAtFrom = null;
        this.createdAtTo = null;
        this.statementType = null;
        this.consentSummary = null;
    }

    @action.bound
    public setDriverNameOrBpid(value: string) {
        this.driverNameOrBpid = value;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setStatementType(value: StatementType | null) {
        this.statementType = value;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setConsentSummary(value: ConsentSummary | null) {
        this.consentSummary = value;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setCreatedAtRange(from: moment.Moment | null, to: moment.Moment | null) {
        this.createdAtFrom = from?.clone().hour(0).minute(0).second(0).millisecond(0) ?? null;
        this.createdAtTo = to?.clone().hour(23).minute(59).second(59).millisecond(999) ?? null;
        this.areFiltersDirty = true;
    }
}
export default ConsentFilter;