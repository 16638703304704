import { action, makeObservable, observable } from "mobx";
import { CostPlacementType } from "Models/CostPlacementType";
import { TaxiVoucherLimit } from "Models/TaxiVoucherLimit";
import moment from "moment";

class TaxiVoucherFilter {
    @observable.ref public showUsed: boolean = false;
    @observable.ref public showProhibited: boolean = false;
    @observable.ref public identifier: string = "";
    @observable.ref public costPlacementType: CostPlacementType | null = null;
    @observable.ref public costPlacement: string  = "";
    @observable.ref public voucherLimit: TaxiVoucherLimit | null = null;
    @observable.ref public validFrom: moment.Moment | null = null;
    @observable.ref public validTo: moment.Moment | null = null;
    @observable.ref public customer: string = "";
    @observable public areFiltersDirty = false;
    
    public constructor(
    ) {
        makeObservable(this);
    }

    @action.bound
    public setShowUsed() {
        this.showUsed = !this.showUsed;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setShowProhibited() {
        this.showProhibited = !this.showProhibited;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setIdentifier(identifier: string) {
        this.identifier = identifier;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setVoucherLimit(limit: TaxiVoucherLimit) {
        this.voucherLimit = limit;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setValidFrom(validFrom: moment.Moment) {
        this.validFrom = validFrom;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setvValidTo(validTo: moment.Moment) {
        this.validTo = validTo;
        this.areFiltersDirty = true;
    }
    
    @action.bound
    public setCustomer(customer: string) {
        this.customer = customer;
        this.areFiltersDirty = true;

    }

    @action.bound
    public setCostPlacementType(costPlacementType: CostPlacementType) {
        this.costPlacementType = costPlacementType;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setCostPlacement(costPlacement: string) {
        this.costPlacement = costPlacement;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setDateRangeFilter(from: moment.Moment | null, to: moment.Moment | null) {
        this.validFrom = from?.clone().hour(0).minute(0).second(0).millisecond(0) ?? null;
        this.validTo = to?.clone().hour(23).minute(59).second(59).millisecond(59) ?? null;
        this.areFiltersDirty = true;
    }
}
export default TaxiVoucherFilter;