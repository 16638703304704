import { action, computed, makeObservable, observable } from "mobx";
import Address from "./Address";

class AddressStore {

    @observable.ref public countryCode: string = "HU";
    @observable.ref public region: string | null = null;
    @observable.ref public zipCode: string = "";
    @observable.ref public settlement: string = "";
    @observable.ref public addressLine: string = "";

    public constructor() {
        makeObservable(this);
    }

    public static fromAddress(address: Address) {
        const res = new AddressStore();
        res.countryCode = address.countryCode;
        res.region = address.region;
        res.zipCode = address.zipCode;
        res.settlement = address.settlement;
        res.addressLine = address.addressLine;
        return res;
    }

    @action.bound
    public setCountryCode(countryCode: string) {
        this.countryCode = countryCode;
    }

    @action.bound
    public setRegion(region: string | null) {
        this.region = region
    }

    @action.bound
    public setZipCode(zipCode: string) {
        this.zipCode = zipCode;
    }

    @action.bound
    public setSettlement(settlement: string) {
        this.settlement = settlement;
    }

    @action.bound
    public setAddressLine(addressLine: string) {
        this.addressLine = addressLine;
    }

    @computed public get address(): Address {
        return new Address(
            this.countryCode,
            this.region as any, // proxy gen
            this.zipCode,
            this.settlement,
            this.addressLine);

    }


}

export default AddressStore;