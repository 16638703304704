import React, { useCallback, useState } from "react";
import {
  Input,
  Select,
  Typography,
} from "antd";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";

const { Option } = Select;
const { Text } = Typography;

interface IPhoneNumberTextBoxProps {
  onChange: (value: string) => void;
  value: string;
  disabled?: boolean;
  label?: string;
  onEnter?: () => void;
}

function getCountryCode(value: string) {
  if (isNullOrUndefined(value) || value.length === 0) {
    return "36";
  }

  if (value.length < 2) {
    return value;
  }

  return value.substring(0, 2);
}

function getNumber(value: string) {
  if (isNullOrUndefined(value) || value.length < 2) {
    return "";
  }

  return value.substring(2);
}

export default function PhoneNumberTextBox(props: IPhoneNumberTextBoxProps) {

  const [countryCode, _setCountryCode] = useState(getCountryCode(props.value));
  const [number, _setNumber] = useState(getNumber(props.value));

  const setCountryCode = (cc: string) => {
    _setCountryCode(cc);
    props.onChange(isNullOrUndefined(number) || number.length === 0 ? "" : `${cc}${number}`);
  };

  const setNumber = (inputValue: React.ChangeEvent<HTMLInputElement>) => {
    const n = inputValue.target.value;
    if (/^\d*$/gi.test(n)) {
      _setNumber(n);
      props.onChange(isNullOrUndefined(n) || n.length === 0 ? "" : `${countryCode}${n}`);
    }
  };

  const keyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      props.onEnter?.();
    }
  }, [props.onEnter]);

  return (
    <div style={{ width: "100%" }}>
      {props.label && <Text>{props.label}</Text>}
      <Input.Group compact style={{ display: "flex", flexDirection: "row" }}>
        <Select style={{ flex: "0 0 70px" }} showSearch value={countryCode} onSelect={setCountryCode}>
          {countryCodes.map(cc => <Option key={cc} value={cc}>{cc}</Option>)}
        </Select>
        <Input style={{ flex: "1" }} placeholder="Telefonszám" value={number} onChange={setNumber} onKeyPress={keyPress} allowClear />
      </Input.Group>
    </div>
  );
}

const countryCodes = [
  "01",
  "07",
  "20",
  "27",
  "30",
  "31",
  "32",
  "33",
  "34",
  "36",
  "39",
  "40",
  "41",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "81",
  "82",
  "84",
  "86",
  "90",
  "91",
  "92",
  "93",
  "94",
  "95",
  "98",
  "211",
  "212",
  "213",
  "216",
  "218",
  "220",
  "221",
  "222",
  "223",
  "224",
  "225",
  "226",
  "227",
  "228",
  "229",
  "230",
  "231",
  "232",
  "233",
  "234",
  "235",
  "236",
  "237",
  "238",
  "239",
  "240",
  "241",
  "242",
  "243",
  "244",
  "245",
  "246",
  "248",
  "249",
  "250",
  "251",
  "252",
  "253",
  "254",
  "255",
  "256",
  "257",
  "258",
  "260",
  "261",
  "262",
  "263",
  "264",
  "265",
  "266",
  "267",
  "268",
  "269",
  "290",
  "291",
  "297",
  "298",
  "299",
  "350",
  "351",
  "352",
  "353",
  "354",
  "355",
  "356",
  "357",
  "358",
  "359",
  "370",
  "371",
  "372",
  "373",
  "374",
  "375",
  "376",
  "377",
  "378",
  "379",
  "380",
  "381",
  "382",
  "383",
  "385",
  "386",
  "387",
  "389",
  "420",
  "421",
  "423",
  "500",
  "501",
  "502",
  "503",
  "504",
  "505",
  "506",
  "507",
  "508",
  "509",
  "590",
  "591",
  "592",
  "593",
  "595",
  "597",
  "598",
  "599",
  "670",
  "672",
  "673",
  "674",
  "675",
  "676",
  "677",
  "678",
  "679",
  "680",
  "681",
  "682",
  "683",
  "685",
  "686",
  "687",
  "688",
  "689",
  "690",
  "691",
  "692",
  "850",
  "852",
  "853",
  "855",
  "856",
  "880",
  "886",
  "960",
  "961",
  "962",
  "963",
  "964",
  "965",
  "966",
  "967",
  "968",
  "970",
  "971",
  "972",
  "973",
  "974",
  "975",
  "976",
  "977",
  "992",
  "993",
  "994",
  "995",
  "996",
  "998",
]
  ;