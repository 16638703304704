import { ApiAdapterBase } from "@eblsoft/react-toolkit";
import Config from "Config";
import AuthStore from "Stores/AuthStore";
import CommonApiErrorHandler from "./CommonApiErrorHandler";

export default abstract class ProtectedApiAdapterBase extends ApiAdapterBase {
    constructor() {
        super(Config.appServerAddress, async () => ({
            "Accept": "application/json",
            "Authorization": `Bearer ${await AuthStore.getAccessTokenAsync()}`
        }), CommonApiErrorHandler)
    }
}