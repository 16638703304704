import { createStoreProvider, isNullOrUndefined, StoreBase } from "@eblsoft/react-toolkit";
import { action, observable, runInAction } from "mobx";
import Answer from "Models/Answer";
import Poll from "Models/Poll";
import { TargetAudience } from "Models/TargetAudience";
import NotificationStore from "Stores/NotificationStore";
import { ValidationStore } from "Stores/ValidationStore";
import { IPollDetailCoreProps } from "./PollDetail";

export default class PollDetailStore extends StoreBase<IPollDetailCoreProps> {
    public id: number | null = null;
    @observable.ref public question: string = "";
    @observable.ref public name: string = "";
    @observable.ref public startedAt: moment.Moment | null = null;
    @observable.ref public endOfVoteDate: moment.Moment | null = null;
    @observable.ref public answers: Answer[] = [];
    @observable.ref public isStartPollModal = false;
    @observable.ref public targetAudience = TargetAudience.AllEmployee;
    @observable.ref public allowCustomAnswer = false;

    public readonly validationStore = new ValidationStore();

    @action.bound
    public setAllowCustomAnswer(value: boolean) {
        this.allowCustomAnswer = value;
    }

    @action.bound
    public setQuestion(value: string) {
        this.question = value;
    }

    @action.bound
    public setName(value: string) {
        this.name = value;
    }

    @action.bound
    public setTargetAudience(value: TargetAudience) {
        this.targetAudience = value;
    }

    @action.bound
    public setEndOfVoteDate(value: moment.Moment | null) {
        this.endOfVoteDate = value;
    }

    @action.bound showPollStartModal() {
        this.isStartPollModal = true;
    }

    @action.bound cancelPollStart() {
        this.isStartPollModal = false;
    }

    @action.bound
    public createNewAnswer() {
        const answer = new Answer(this.answers.length + 1, "");
        this.answers = [...this.answers, answer];
    }

    @action.bound
    public removeAnswer(number: number) {
        this.answers = this.answers.filter(ans => ans.number !== number);
    }

    public readonly vLoadAsync = this.async(async () => {
        if (!isNullOrUndefined(this.props.pollId)) {
            const result = await this.props.pollApiAdapter.getDetailsAsync(this.props.pollId!);
            runInAction(() => {
                this.id = this.props.pollId ?? null
                this.name = result?.name ?? "";
                this.question = result?.question ?? "";
                this.answers = result?.answers ?? [];
                this.startedAt = result.startedAt ?? null;
                this.targetAudience = result?.targetAudience ?? TargetAudience.AllEmployee;
                this.allowCustomAnswer = result?.allowCustomAnswer ?? false;
            });
        }
    });

    public saveAsync = this.async(async () => {

        if (!this.validationStore.isValid()) {
            NotificationStore.error("Hibás vagy kitöltetlen adatok");
            return false;
        }
        let result: Poll | null = null;
        if (isNullOrUndefined(this.id)) {
            result = await this.props.pollApiAdapter.createPollAsync!(
                this.question,
                this.name,
                this.answers,
                this.targetAudience,
                this.allowCustomAnswer);
        } else {
            result = await this.props.pollApiAdapter.updatePollAsync(
                this.id!,
                this.question,
                this.name,
                this.answers,
                this.targetAudience,
                this.allowCustomAnswer);
        }

        runInAction(() => {
            this.id = result!.id!
            this.name = result!.name;
            this.question = result!.question;
            this.answers = result!.answers;
            this.targetAudience = result!.targetAudience;
        });

        NotificationStore.success("Sikeres mentés");
        return true;
    });

    public startPollAsync = async () => {

        if (!this.validationStore.isValid()) {
            NotificationStore.error("Hibás vagy kitöltetlen adatok");
            return false;
        }

        if (this.answers.length === 0 && this.allowCustomAnswer === false) {
            NotificationStore.error("Nem adott meg válasz lehetőséget");
            return false;
        }

        const pollId = await this.props.pollApiAdapter.startPollAsync(
            this.id,
            this.question,
            this.name,
            this.endOfVoteDate!,
            this.answers,
            this.targetAudience,
            this.allowCustomAnswer);

        this.id = pollId;
        if (!isNullOrUndefined(pollId)) {
            NotificationStore.success("Sikeresen elindította a szavazást");
        }
        return true;
    }
}



const { ContextComponent: PollDetailStoreContext, StoreProvider: PollDetailStoreProvider, useStore: usePollDetailStore, withStore: withPollDetailStore } =
    createStoreProvider<IPollDetailCoreProps, PollDetailStore>(() => new PollDetailStore());

export {
    PollDetailStoreContext,
    PollDetailStoreProvider,
    usePollDetailStore,
    withPollDetailStore
};