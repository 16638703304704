import Address from "Models/Address";
import { VoucherType } from "Models/VoucherType";
import moment from "moment";
import { InvoiceStatus } from "./InvoiceStatus";
import { Partner } from "./Partner";
import TaxNumber from "./TaxNumber";
import { InvoiceType } from "Models/InvoiceType";

export default class InvoiceDetailForPartnerListItem {
    constructor(
        public readonly id: number,
        public readonly type: InvoiceType,
        public readonly accountedRideId: number,
        public readonly accountedRidePublicIdentifier: string,
        public readonly rideExternalIdentifier: string,
        public readonly invoiceNumber: string,
        public readonly sellerName: string,
        public readonly sellerBpid: string,
        public readonly sellerAddress: Address | null,
        public readonly sellerTaxNumber: TaxNumber | null,
        public readonly customer: Partner,
        public readonly netPrice: number,
        public readonly grossPrice: number,
        public readonly createdOn: moment.Moment,
        public readonly fulfilledOn: moment.Moment,
        public readonly costCenter: string | null,

        public readonly voucherType: VoucherType,
        public readonly voucherInfo: string | null,
        public readonly accountedAt: moment.Moment,
        public readonly startAddress: Address | null,
        public readonly finalAddress: Address | null,
        public readonly shouldUpdateFinalAddress: boolean,
        public readonly intermediateAddresses: Address[] | null,
        public readonly shouldUpdateIntermediateAddresses: boolean,
        public readonly rideAdditionalInfo: string | null,
        public readonly rideCustomerName: string | null,

        public readonly latestModifierInvoiceId: number | null,
        public readonly latestModifierInvoiceNumber: string | null,
    ) { }
}

