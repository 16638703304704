import { action, makeObservable, observable } from "mobx";
import { InvoiceStatus } from "Models/InvoiceStatus";
import { VoucherType } from "Models/VoucherType";
import moment from "moment";

class VoteFilter {
    @observable.ref public name: string = "";
    @observable.ref public answerNumber: number | null = null;
    @observable.ref public anseweredAtFrom: moment.Moment | null = null;
    @observable.ref public anseweredAtTo: moment.Moment | null = null;
    @observable.ref public bpId: string = "";
    @observable.ref public onlyShowCooperativeMember: boolean = false;
    @observable public areFiltersDirty = false;

    public constructor(
    ) {
        makeObservable(this);
    }

    @action.bound
    public setName(name: string) {
        this.name = name;
        this.areFiltersDirty = true;
    }


    @action.bound
    public setBpId(bpId: string) {
        this.bpId = bpId;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setOnlyShowCooperativeMember(value: boolean) {
        this.onlyShowCooperativeMember = value;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setAnswerNumber(answerNumber: number | null) {
        this.answerNumber = answerNumber;
        this.areFiltersDirty = true;
    }


    @action.bound
    public setAnseweredAtFilterRange(from: moment.Moment | null, to: moment.Moment | null) {
        this.anseweredAtFrom = from?.clone().hour(0).minute(0).second(0).millisecond(0) ?? null;
        this.anseweredAtTo = to?.clone().hour(23).minute(59).second(59).millisecond(999) ?? null;
        this.areFiltersDirty = true;
    }
}
export default VoteFilter;