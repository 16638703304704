import { BodyType, ICommonApiErrorHandler, IQueryParameters, Mapper, isNotNullAndUndefined } from "@eblsoft/react-toolkit";
import { IUpdateCommonFileResponse, IGetCommonFileIsExistsResponse, ICreateCommonFileResponse, IGetCommonFilesResponse } from "Adapters/Api/Api.g";
import ICommonFileApiAdapter from "Adapters/ICommonFileApiAdapter";
import { mapCommonFileListtItemDtoToDomainModel } from "Adapters/Mapping/Map";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import Config from "Config";
import CommonFileListItem from "Models/CommonFileListItem";
import AuthStore from "Stores/AuthStore";

class AdminCommonFileApiAdapter implements ICommonFileApiAdapter {
    private readonly mainRoute = "api/web/admin/commonfile";
    private readonly baseUrl: string = Config.appServerAddress;

    constructor(
        private readonly getHeadersAsync: () => Promise<object> = async () => ({
            "Accept": "application/json",
            "Authorization": `Bearer ${await AuthStore.getAccessTokenAsync()}`
        }),
        private readonly commonApiErrorHandler?: ICommonApiErrorHandler
    ) { }

    private httpGet<TModel>(url: string, routeParameters?: string[], queryParams?: IQueryParameters): Mapper<TModel> {
        return new Mapper(
            () => {
                return this.httpActionAsync("GET", url, routeParameters, undefined, undefined, queryParams);
            },
            this.commonApiErrorHandler
        );
    }

    private httpPost<TModel>(url: string, routeParameters?: string[], body?: any, bodyType?: BodyType | "multipart/form-data", queryParams?: IQueryParameters): Mapper<TModel> {
        return new Mapper(
            () => {
                return this.httpActionAsync("POST", url, routeParameters, body, bodyType, queryParams);
            },
            this.commonApiErrorHandler
        );
    }

    private httpDelete<TModel>(url: string, routeParameters?: string[], body?: any, bodyType?: BodyType, queryParams?: IQueryParameters): Mapper<TModel> {
        return new Mapper(
            () => {
                return this.httpActionAsync("DELETE", url, routeParameters, body, bodyType, queryParams);
            },
            this.commonApiErrorHandler
        );
    }

    private httpPut<TModel>(url: string, routeParameters?: string[], body?: any, bodyType?: BodyType | "multipart/form-data", queryParams?: IQueryParameters): Mapper<TModel> {
        return new Mapper(
            () => {
                return this.httpActionAsync("PUT", url, routeParameters, body, bodyType, queryParams);
            },
            this.commonApiErrorHandler
        );
    }

    private async httpActionAsync(
        method: "POST" | "PUT" | "GET" | "DELETE",
        url: string,
        routeParameters?: string[],
        body?: any,
        bodyType?: BodyType | "multipart/form-data",
        queryParams?: IQueryParameters
    ) {
        const options = {
            method,
            headers: {
                ...await this.getHeadersAsync()
            },
        } as RequestInit;

        if (body) {
            switch (bodyType) {
                case "application/x-www-form-urlencoded":
                    options.body = new URLSearchParams(body);
                    (options.headers as any)["Content-Type"] = bodyType;
                    break;
                case "multipart/form-data":
                    options.body = body;
                    break;
                case "application/json":
                default:
                    options.body = JSON.stringify(body);
                    (options.headers as any)["Content-Type"] = bodyType;
                    break;
            }
        }

        const baseUrl = this.baseUrl.endsWith("/") ? this.baseUrl : `${this.baseUrl}/`;

        let urlWithParameters = routeParameters && routeParameters.length > 0 ?
            `${baseUrl}${url}/${routeParameters.map(p => encodeURIComponent(p)).join("/")}` :
            (baseUrl + url);

        if (queryParams) {
            urlWithParameters += `?${Object.getOwnPropertyNames(queryParams).filter(name => isNotNullAndUndefined(queryParams[name])).map(name => `${name}=${encodeURIComponent(`${queryParams[name]}`)}`).join("&")}`;
        }

        return await fetch(urlWithParameters, options);
    }

    public async createCommonFileAsync(
        name: string,
        category: string,
        file: File,
    ): Promise<boolean> {
        const formData = new FormData();
        formData.append("Name", name);
        formData.append("Category", category);
        formData.append("File", file);

        return this.httpPost<boolean>(this.mainRoute, ["create"], formData, "multipart/form-data").map((result: ICreateCommonFileResponse) => {
            return true;
        }).performOperationAsync();
    }

    public updateCommonFileAsync(
        name: string,
        category: string,
        file?: File): Promise<boolean> {

        const formData = new FormData();
        formData.append("Name", name);
        formData.append("Category", category);
        if (file) formData.append("File", file);

        return this.httpPut<boolean>(this.mainRoute, ["update"], formData, "multipart/form-data").map((result: IUpdateCommonFileResponse) => {
            return true
        }).performOperationAsync();
    }

    public printAsync(
        commonFileId: number): Promise<string> {
        return this.httpPost<string>(this.mainRoute, [commonFileId.toString(), "print"]).map(result => {
            return result;
        }).performOperationAsync();
    };

    public deleteCommonFileAsync(
        commonFileId: number): Promise<boolean> {
        return this.httpDelete<boolean>(this.mainRoute, [commonFileId.toString()]).performOperationAsync();
    }

    public getListAsync(paging: Paging, ordering: Ordering): Promise<{ totalCount: number, commonFiles: CommonFileListItem[], categories: string[] }> {
        const request = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc
        }

        return this.httpGet<{ totalCount: number, commonFiles: CommonFileListItem[], categories: string[] }>(this.mainRoute, undefined, request).map((paginatedResult: IGetCommonFilesResponse) => {
            return {
                categories: paginatedResult.categories,
                totalCount: paginatedResult.totalCount,
                commonFiles: paginatedResult.commonFiles.map((i: any) => {
                    return mapCommonFileListtItemDtoToDomainModel(i);
                }),
            };
        }).performOperationAsync();
    }

    public existsAsync(name: string): Promise<boolean> {
        const request = {
            name: name
        };
        return this.httpGet<boolean>(this.mainRoute, ["exists"], request).map((result: IGetCommonFileIsExistsResponse) => {
            return result.isExists;
        }).performOperationAsync();
    }
}

export default new AdminCommonFileApiAdapter();