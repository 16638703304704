import { Col, Modal, Row } from "antd";
import ProhibitionInfo from "Models/ProhibitionInfo";
import TextBox from "./TextBox";
import { observer } from "mobx-react";
import AutoCompleteBox from "./AutoCompleteBox";
import { ProhibitReasonOption } from "Models/ProhibitReasonOption";
import ProhibitionInfoBox from "./ProhibitionInfoBox";

export interface IProhibitionInfoModalProps {
    title: React.ReactNode,
    visible: boolean | undefined,
    onOk: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined,
    confirmLoading: boolean | undefined,
    onCancel: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined,
    prohibitionInfo: ProhibitionInfo
}

function ProhibitionInfoModal(props: IProhibitionInfoModalProps) {

    return (
        <Modal
            title={props.title}
            open={props.visible}
            onOk={props.onOk}
            confirmLoading={props.confirmLoading}
            onCancel={props.onCancel}
        >
            <ProhibitionInfoBox prohibitionInfo={props.prohibitionInfo} />
        </Modal >
    );
}

export default observer(ProhibitionInfoModal);