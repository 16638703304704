import { action, makeObservable, observable } from "mobx";

export default class Answer {
    @observable.ref public text: string = "";

    constructor(
        public readonly number: number,
        text: string,
    ) {
        this.text = text;
        makeObservable(this);

    }

    @action.bound
    public setText(value: string) {
        this.text = value;
    }
}