import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { ColumnsType } from "antd/lib/table"
import { CostPlacementType, getCostPlacementTypeDisplayValue } from "Models/CostPlacementType";
import { getTaxiVoucherLimitDisplayValue, TaxiVoucherLimit } from "Models/TaxiVoucherLimit";
import Voucher from "Models/Voucher"
import moment from "moment";

export const VoucherCreationColumns: ColumnsType<Voucher> = [
    {
        title: "Csekk azonosító",
        dataIndex: "identifier",
        key: "identifier",
        sorter: false,
    },
    {
        title: "Érvényesség",
        dataIndex: "validity",
        key: "validity",
        render: (date: Date) => <span>{moment(date).format('YYYY.MM.DD.')}</span>,
        sorter: false,
    },
    {
        title: "Költséghely",
        dataIndex: "costPlacementType",
        key: "costPlacementType",
        render: (type: CostPlacementType, column: Voucher) => {
            let res = getCostPlacementTypeDisplayValue(type);
            if (column.costPlacement?.length) {
                res += ` (${column.costPlacement})`;
            }
            return <span>{res}</span>
        },
        sorter: false,
    },
    {
        title: "Fix összeg",
        dataIndex: "cost",
        key: "cost",
        render: (cost: TaxiVoucherLimit) => <span>{getTaxiVoucherLimitDisplayValue(cost)}</span>,
        sorter: false,
    }
];