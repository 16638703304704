import { BaseOptionType } from "antd/lib/select";

export const ProhibitReasonOption: BaseOptionType[] = [
    {
        value: "kilépett a cégtől",
    },
    {
        value: "megszűnt a jogosultsága",
    },
    {
        value: "téves rögzítés",
    },
    {
        value: "elveszett",
    },
    {
        value: "ellopták",
    }
];