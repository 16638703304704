import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.variable.min.css";
import "index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import NavigationStore from "Stores/NavigationStore";
import moment from "moment";
import "moment/locale/hu";
import { ToastContainer } from "react-toastify";

const history = createBrowserHistory();
NavigationStore.init(history);
moment.locale("hu");

ConfigProvider.config({
    theme: {
        primaryColor: '#FDB813',
    },
});

ReactDOM.render(
    <React.StrictMode>
        <HistoryRouter history={history}>
            <App />
        </HistoryRouter>
        <ToastContainer />
    </React.StrictMode>,
    document.getElementById("root")
);

reportWebVitals();
