import { EnumHelper } from "Util/EnumHelper";


export enum TargetAudience {
    AllEmployee = "AllEmployee",
    OnlyCooperativeMember = "OnlyCooperativeMember"
}

function getTargetAudienceEntries() {
    return EnumHelper.getEnumEnumerableEntries<TargetAudience>(TargetAudience, getTargetAudienceDisplayValue);
}

export function getTargetAudienceDisplayValue(value: TargetAudience) {

    switch(value) {
        case TargetAudience.AllEmployee: return "Minden aktív kolléga";
        case TargetAudience.OnlyCooperativeMember: return "Csak szövetkezeti tagok";
    }
}

export const TargetAudienceEntries = getTargetAudienceEntries();