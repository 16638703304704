import { useNavigate, useParams } from "react-router-dom";
import { useSimpleDocumentDetailStore, withSimpleDocumentDetailStore } from "./SimpleDocumentDetailStore";
import NavigationStore from "Stores/NavigationStore";
import { Alert, Button, Col, Divider, PageHeader, Row, Space } from "antd";
import { ValidationStoreProvider } from "Stores/ValidationStore";
import TextBox from "Pages/CommonControls/TextBox";
import DatePickerBox from "Pages/CommonControls/DatePickerBox";
import { DocumentStatusSelectBox } from "Pages/CommonControls/DocumentStatusSelectBox";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { useCallback } from "react";
import { SimpleDocumentType, getSimpleDocumentTypeDisplayValue } from "Models/SimpleDocumentType";
import { Image } from 'antd';
import { useSimpleDocumentsStore } from "../SimpleDocumentListStore";
import { KeyOutlined } from "@ant-design/icons";

export interface ISimpleDocumentDetailCoreProps {
    simpleDocumentId?: number;
    simpleDocumentType: SimpleDocumentType

}

function SimpleDocumentDetailCore(props: ISimpleDocumentDetailCoreProps) {
    const store = useSimpleDocumentDetailStore();
    const simpleDocuments = useSimpleDocumentsStore();
    const navigate = useNavigate();

    const saveSimpleDocumentAsync = async () => {
        const success = await store.saveAsync();
        if (success) {
            simpleDocuments?.vLoadAsync.fireAndForget();
            navigateBack();
        }
    }

    const navigateBack = useCallback(() => navigate(-1), [navigate]);

    return (
        <>
            <PageHeader
                title={`${getSimpleDocumentTypeDisplayValue(props.simpleDocumentType)} szerkesztése`}
            >
                {store.isAuthorized ? (
                    <ValidationStoreProvider value={store.validationStore}>
                        <Row gutter={[16, 8]}>
                            <Col xxl={11} xl={11} lg={11} xs={14} sm={14}>
                                {store.images.map((data) => <Image style={{ marginBottom: "10px" }} alt={`${getSimpleDocumentTypeDisplayValue(props.simpleDocumentType)} kép`} src={`data:image/jpeg;base64,${data}`} />)}
                            </Col>
                            <Col xxl={13} xl={13} lg={13} xs={10} sm={10}>
                                <Row gutter={16}>
                                    <Col xl={24} xs={24}>
                                        <TextBox
                                            label="Azonosító:"
                                            onChange={store.setIdentifier}
                                            value={store.identifier}
                                            isRequired
                                            maxLength={128}
                                        />
                                    </Col>
                                    <Col xl={12} xs={12}>
                                        <DatePickerBox
                                            label="Érvényesség:"
                                            isRequired
                                            onChange={store.setValidTo}
                                            date={store.validTo}
                                        />
                                    </Col>
                                    <Col xl={12} xs={12}>
                                        <DatePickerBox
                                            label="Utoljára ellenőrzött érvényesség:"
                                            onChange={() => {}}
                                            disabled
                                            date={store.lastConfirmedValidTo}
                                        />
                                    </Col>
                                    <Col xl={12} xs={12}>
                                        <DocumentStatusSelectBox
                                            documentStatus={store.status}
                                            onChange={store.setSimpleDocumentStatus}
                                            style={{ width: "100%" }}
                                            label="Státusz:"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16} >
                                    <Col xxl={3} xl={4} lg={5} md={6} xs={8}>
                                        <Button style={{ width: "100%", marginTop: "24px" }} key="submit" type="primary" loading={store.isLoading} onClick={() => saveSimpleDocumentAsync()}>
                                            Mentés
                                        </Button>
                                    </Col>

                                    <Col xxl={3} xl={4} lg={5} md={6} xs={8}>
                                        <Button loading={store.isLoading} onClick={() => navigateBack()} style={{ marginTop: "24px" }}>Mégse</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ValidationStoreProvider>
                ) : (
                    <>
                        <Alert
                            message="Hitelesítés szükséges"
                            style={{ margin: "2rem 1rem" }}
                            description={(
                                <p>A dokumentumok ellenőrzéséhez kétfaktoros hitelesítés szükséges. A kód megadása után egy órán keresztül hozzáfér az adatokhoz.<br/><small>A hitelesítéshez szükséges beállításokat a "Beállítások" menüben találja.</small></p>
                            )}
                            type="info"
                        />
                        <Row gutter={16} justify="center">
                            <Col xs={6}>
                                <Space direction="vertical" style={{ width: "100%" }}>
                                    <TextBox label="Hitelesítő kód:" value={store.tfaCode} onChange={store.setTfaCode} onEnter={store.authenticateAsync.fireAndForget} />
                                    <Button icon={<KeyOutlined />} onClick={store.authenticateAsync.fireAndForget} style={{ width: "100%" }}>Hitelesítés</Button>
                                </Space>
                            </Col>
                        </Row>

                    </>
                )}
            </PageHeader>
        </>
    );
}

const SimpleDocumentDetailCoreWithStore = withSimpleDocumentDetailStore(SimpleDocumentDetailCore);

export default function SimpleDocumentDetail() {
    const { simpleDocumentType, simpleDocumentId } = useParams();
    return (
        <SimpleDocumentDetailCoreWithStore simpleDocumentType={SimpleDocumentType[simpleDocumentType as keyof typeof SimpleDocumentType]} simpleDocumentId={isNullOrUndefined(simpleDocumentId) ? undefined : parseInt(simpleDocumentId!)} />
    )
}