import { CostPlacementType } from "Models/CostPlacementType";

export default class CreatedTaxiCardListItem {
    public constructor(
        public readonly id: number,
        public readonly partnerId: number,
        public readonly identifier: string,
        public readonly pin: string,
        public readonly isPhysical: boolean,
        public readonly isActive: boolean,
        public readonly costPlacementType: CostPlacementType,
        public readonly costPlacement: string,
        public readonly ownerName: string | null,
    ) {}
}
