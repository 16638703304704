import { useErrorDispatcher } from "@eblsoft/react-toolkit";
import { useEffect } from "react";
import AuthStore from "Stores/AuthStore";
import NavigationStore from "Stores/NavigationStore";

export default function Home() {

    const errorDispatcher = useErrorDispatcher();

    useEffect(() => {
        if (!AuthStore.user) {
            AuthStore.getAccessTokenAsync().catch(errorDispatcher);
        } else {
            if (AuthStore.user.hasRole("PartnerAdmin")) {
                NavigationStore.navigateTo("partner/partnerData");
            }
        }
    }, []);

    if (!AuthStore.user) {
        return <p>Bejelentkezés...</p>;
    }

    return (
        <>
            <br />
            <h3>Üdvözöljük a City Taxi eTaxi 2.0 rendszerében.</h3>
            <p>Kérjük válasszon a baloldali menüpontok közül.</p>
        </>
    );
}