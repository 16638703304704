import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import IPartnerApiAdapter from "Adapters/IPartnerApiAdapter";
import { Button, Col, Divider, PageHeader, Row, Space } from "antd";
import { useParams } from "react-router-dom";
import { usePartnerDataStore, withPartnerDataStore } from "./PartnerDataStore";
import PartnerView from "Pages/CommonControls/PartnerView";
import { LockOutlined, SaveOutlined } from "@ant-design/icons";
import TextBox from "Pages/CommonControls/TextBox";
import Config from "Config";
import AuthStore from "Stores/AuthStore";
import CheckBox from "Pages/CommonControls/CheckBox";
import NumberBox from "Pages/CommonControls/NumberBox";

export interface IPartnerDataProps {
    partnerApiApadter: IPartnerApiAdapter;
    isCityAdmin: boolean;
}

export interface IPartnerDataCoreProps extends IPartnerDataProps {
    partnerId?: number;
}

function PartnerDataCore(props: IPartnerDataCoreProps) {

    const store = usePartnerDataStore();
    return (
        <PageHeader
            title={<span id="page_title">Partner adatok</span>}
        >
            {store.partner && (
                <Row>
                    <Col xs={24} xl={20}>
                        <Divider orientation="left">Alapadatok</Divider>
                        <PartnerView showMailingaddress showPartnerName={props.isCityAdmin} partner={store.partner} />

                        <div style={{ marginTop: "16px" }}>
                            <Row gutter={[16, 8]}>
                                <Col span={12}>
                                    <NumberBox isReadOnly={!props.isCityAdmin} addonAfter="Ft." label="Kártya végleges letiltási költség:" value={store.cardPermanentCancellationFee} onChange={store.setCardPermanentCancellationFee} />
                                </Col>
                            </Row>
                            {props.isCityAdmin && (
                                <Space style={{ marginTop: "16px", marginBottom: "16px", justifyContent: "right", width: "100%" }}>
                                    <Button type="primary" icon={<SaveOutlined />} onClick={store.saveBaseDataAsync.fireAndForget} disabled={!store.baseDataChanged}>
                                        Mentés
                                    </Button>
                                </Space>
                            )}
                        </div>
                        <Divider orientation="left">E-mail beállítások</Divider>
                        <Row gutter={[16, 8]}>
                            <Col span={12}>
                                <TextBox
                                    label="Számlaösszesítő Excel kiküldése e-mailben:"
                                    value={store.invoiceSummaryEmail ?? ""}
                                    secondaryText={"Több e-mail címet is megadhat vesszővel elválasztva."}
                                    onChange={store.setInvoiceSummaryEmail}
                                    maxLength={256}
                                    onValidate={store.validateInvoicSummaryEmail}
                                />
                                <CheckBox
                                    style={{ marginTop: "1rem" }}
                                    label="Fuvaradatok is szerepeljenek a kiállított számlaösszesítőkön, nyomtatott számlákon!"
                                    value={store.includeRideDataInInvoiceSummary}
                                    onChange={store.setIncludeRideDataInInvoiceSummary}
                                />
                                <br />
                                Amennyiben nem igényli a fuvar adatok megjelenítését a nyomtatott számlákon, kérjük vegye ki a pipát. Ez esetben a fuvaradatok kizárólag a „számlák” menüpontban lesznek elérhetőek, megtekinthetőek.
                            </Col>
                            <Col span={12}>
                                <TextBox
                                    label="eTaxi kapcsolattartó e-mail:"
                                    value={store.eTaxiContactEmail ?? ""}
                                    onChange={store.setETaxiContactEmail}
                                    maxLength={64}
                                    onValidate={store.validateEmailAddress}
                                />
                            </Col>
                        </Row>
                        <Space style={{ marginTop: "16px", marginBottom: "16px", justifyContent: "right", width: "100%" }}>
                            <Button type="primary" icon={<SaveOutlined />} onClick={store.saveEmailPreferencesAsync.fireAndForget} disabled={!store.emailPreferencesChanged}>
                                Mentés
                            </Button>
                        </Space>

                        {!props.isCityAdmin && (
                            <>
                                <Divider orientation="left">Biztonság</Divider>
                                <Space>
                                    <a href={`${Config.settings.oauth.changePasswordUrl}?identifier=${AuthStore.user?.identifier}`}>
                                        <Button icon={<LockOutlined />}>
                                            Jelszóváltoztatás
                                        </Button>
                                    </a>
                                </Space>
                            </>
                        )}
                    </Col>
                </Row>
            )}
        </PageHeader>
    );
}

const PartnerDataCoreWithStore = withPartnerDataStore(PartnerDataCore);

export default function PartnerData(props: IPartnerDataProps) {
    const { partnerId } = useParams();
    return (
        <PartnerDataCoreWithStore {...props} partnerId={isNullOrUndefined(partnerId) ? undefined : parseInt(partnerId!)} />
    )
}
