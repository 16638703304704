import { EnumHelper } from "Util/EnumHelper";

export enum StatementType {
    DocumentPhotoHandling = "DocumentPhotoHandling",
}

function getStatementTypeEntries() {
    return EnumHelper.getEnumEnumerableEntries<StatementType>(StatementType, getStatementTypeDisplayValue);
}

export function getStatementTypeDisplayValue(value: StatementType) {

    switch(value) {
        case StatementType.DocumentPhotoHandling: return "Igazolványfotók kezelése";
    }
}

export const StatementTypeEntries = getStatementTypeEntries();