import { createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import { IFailedRideDetailCoreProps } from "./FailedRideDetail";
import NavigationStore from "Stores/NavigationStore";
import { action, observable, runInAction } from "mobx";
import FailedRideDetailsForReview from "Models/FailedRideDetailsForReview";
import AdminRideAdapter from "Adapters/AdminAdapter/AdminRideAdapter";
import NotificationStore from "Stores/NotificationStore";
import { ValidationStore } from "Stores/ValidationStore";

export default class FailedRideDetailStore extends StoreBase<IFailedRideDetailCoreProps> {

    @observable.ref public details: FailedRideDetailsForReview | null = null;

    @observable.ref public partnerId: number | null = null;
    @observable.ref public reviewerComment: string | null = null;
    @observable.ref public grossPrice: number | null = null;
    @observable.ref public costPlacement: string = "";
    @observable.ref public reviewedBy: string = "";
    @observable.ref public partnerAllowedBy: string = "";

    public readonly validationStore = new ValidationStore();

    public readonly vLoadAsync = this.async(async () => {
        const result = await AdminRideAdapter.getFailedRideDetailsForReviewAsync(this.props.failedRideId);

        runInAction(() => {
            this.details = result;
            this.partnerId = this.details.partnerIdFromRide ?? null;
            this.reviewerComment = this.details.reviewerComment ?? null;
            this.grossPrice = this.details.grossPrice;
            this.partnerAllowedBy = this.reviewedBy = "";
        });
    });

    public readonly approveAsync = this.async(async () => {

        if (!this.details) {
            return;
        }

        if (this.partnerId === null) {
            NotificationStore.error("Kérjük válassza ki a partnert.");
            return;
        }

        if (!this.validationStore.isValid()) {
            NotificationStore.error("Hibás vagy kitöltetlen adatok");
            return;
        }

        await AdminRideAdapter.reviewFailedRideAsync(
            this.details.id,
            true,
            this.partnerId,
            this.reviewerComment,
            this.grossPrice ?? this.details.grossPrice,
            this.costPlacement,
            this.reviewedBy,
            this.partnerAllowedBy
        );
        NavigationStore.navigateBack();
        NotificationStore.successfullySaved();
    });

    public readonly declineAsync = this.async(async () => {

        if (!this.details) {
            return;
        }

        if (!this.validationStore.isValid()) {
            NotificationStore.error("Hibás vagy kitöltetlen adatok");
            return;
        }

        await AdminRideAdapter.reviewFailedRideAsync(
            this.details.id,
            false,
            null,
            this.reviewerComment,
            this.grossPrice ?? this.details.grossPrice,
            this.costPlacement,
            this.reviewedBy,
            this.partnerAllowedBy
        );
        NavigationStore.navigateBack();
        NotificationStore.successfullySaved();
    });

    public cancel() {
        NavigationStore.navigateBack();
    }

    @action.bound public setPartnerId(newValue: number | null) { this.partnerId = newValue; }
    @action.bound public setReviewerComment(newValue: string | null) { this.reviewerComment = newValue; }
    @action.bound public setGrossPrice(newValue: number | null) { this.grossPrice = newValue; }
    @action.bound public setCostPlacement(newValue: string | null) { this.costPlacement = newValue ?? ""; }
    @action.bound public setReviewedBy(newValue: string | null) { this.reviewedBy = newValue ?? ""; }
    @action.bound public setPartnerAllowedBy(newValue: string | null) { this.partnerAllowedBy = newValue ?? ""; }
}

const { ContextComponent: FailedRideDetailStoreContext, StoreProvider: FailedRideDetailStoreProvider, useStore: useFailedRideDetailStore, withStore: withFailedRideDetailStore } =
    createStoreProvider<IFailedRideDetailCoreProps, FailedRideDetailStore>(() => new FailedRideDetailStore());

export {
    FailedRideDetailStoreContext,
    FailedRideDetailStoreProvider,
    useFailedRideDetailStore,
    withFailedRideDetailStore
};