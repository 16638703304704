import { action, makeObservable, observable } from "mobx";
import { InvoiceStatus } from "Models/InvoiceStatus";
import { TaxiCardProhibitionEventStatus } from "Models/TaxiCardProhibitionEventStatus";
import { VoucherType } from "Models/VoucherType";
import moment from "moment";

class TaxiCardProhibitionEventFilter {
 
    @observable.ref public partnerName: string = "";
    @observable.ref public partnerBpid: string = "";
    @observable.ref public identifier: string = "";
    @observable.ref public prohibitionOccuredAtFrom: moment.Moment | null = null;
    @observable.ref public prohibitionOccuredAtTo: moment.Moment | null = null;
    @observable.ref public prohibitedByUserName: string = "";
    @observable.ref public prohibitedByName: string = "";
    @observable.ref public prohibitionReason: string = "";
    @observable.ref public status: TaxiCardProhibitionEventStatus | null = null;

    @observable public areFiltersDirty = false;

    public constructor(
    ) {
        makeObservable(this);
    }

    @action.bound
    public setStatus(status: TaxiCardProhibitionEventStatus) {
        this.status = status;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setIdentifier(identifier: string) {
        this.identifier = identifier;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setPartnerName(partnerName: string) {
        this.partnerName = partnerName;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setPartnerBpid(partnerBpid: string) {
        this.partnerBpid = partnerBpid;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setProhibitedByUserName(prohibitedByUserName: string) {
        this.prohibitedByUserName = prohibitedByUserName;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setProhibitedByName(prohibitedByName: string) {
        this.prohibitedByName = prohibitedByName;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setProhibitionReason(prohibitionReason: string) {
        this.prohibitionReason = prohibitionReason;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setProhibitionOccuredAtRange(from: moment.Moment | null, to: moment.Moment | null) {
        this.prohibitionOccuredAtFrom = from?.clone().hour(0).minute(0).second(0).millisecond(0) ?? null;
        this.prohibitionOccuredAtTo = to?.clone().hour(23).minute(59).second(59).millisecond(999) ?? null;
        this.areFiltersDirty = true;
    }
}
export default TaxiCardProhibitionEventFilter;