import React, { useMemo } from "react";
import { Menu, MenuProps } from "antd";
import { Link, useLocation } from "react-router-dom";
import IMenuItemType from "App/IMenuItemType";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";

export interface IMenuItemDescriptor {
    path?: string;
    isAbsolutePath?: boolean;
    icon?: React.ReactNode;
    label: string;
    id: string;
    children?: IMenuItemDescriptor[];
}

function mapMenuItem(mi: IMenuItemDescriptor): IMenuItemType {
    if (mi.isAbsolutePath) {
        return {
            key: mi.path ?? mi.id,
            icon: mi.icon,
            label: (
                <a href={mi.path} id={mi.id}>
                    {mi.label}
                </a>
            )
        };
    }

    return {
        key: mi.path ?? mi.id,
        icon: mi.icon,
        label: isNullOrUndefined(mi.path) ? mi.label : (
            <Link to={mi.path!} id={mi.id}>
                {mi.label}
            </Link>
        ),
        children: mi.children?.map(mapMenuItem)
    };
}

function findMenuItemByPathRecursive(items: IMenuItemDescriptor[], pathname: string, path: IMenuItemDescriptor[] = []): IMenuItemDescriptor[] | null {
    for (const item of items) {
        if (!isNullOrUndefined(item.path) && pathname.startsWith(item.path!)) {
            return [...path, item];
        }

        if (item.children) {
            const found = findMenuItemByPathRecursive(item.children, pathname, [...path, item]);
            if (found) return found;
        }
    }

    return null;
}

export function useMenuController(menuItemsFactory: () => IMenuItemDescriptor[]) {
    const menuItems = useMemo(menuItemsFactory, []);
    const location = useLocation();
    const [ openedKeys, selectedKeys ] = useMemo(() => {
        const itemWithPath = findMenuItemByPathRecursive(menuItems, location.pathname);
        return [
            (itemWithPath && itemWithPath.length > 0) ? itemWithPath.slice(0, itemWithPath.length - 1).map(i => i.path ?? i.id) : [],
            (itemWithPath && itemWithPath.length > 0) ? [itemWithPath[itemWithPath.length - 1].path!] : []
        ];
    }, [location]);

    const antMenuItems: IMenuItemType[] = useMemo(() => {
        return menuItems.map<IMenuItemType>(mapMenuItem);
    }, [menuItems]);

    return {
        ControlledMenu: (props: MenuProps) => (
            <Menu {...props} selectedKeys={selectedKeys} items={antMenuItems} defaultOpenKeys={openedKeys} />
        )
    };
}