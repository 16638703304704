import { createStoreProvider, isNullOrUndefined, StoreBase } from "@eblsoft/react-toolkit";
import AdminRideAdapter from "Adapters/AdminAdapter/AdminRideAdapter";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { action, computed, observable, runInAction } from "mobx";
import RideFilter from "Models/Filters/RideFilter";
import Ride from "Models/Ride";
import RideLitsItem from "Models/RideListItem";
import moment from "moment";
import { IRideSearcherProps } from "./RideSearcher";

export default class RideSearcherStore extends StoreBase<IRideSearcherProps> {
    @observable.ref public rides: RideLitsItem[] = [];
    @observable.ref public recordNum = 100;
    @observable.ref public pageSize = 50;
    @observable.ref public currentPage = 1;
    public rideFilter = new RideFilter();

    public orderedBy = "createdAt";
    public orderDirection = "ascend";

    public readonly vLoadAsync = this.async(async () => {
        runInAction(() => {
            if (this.props.defaultRidesFrom) {
                this.rideFilter.rideFrom = this.props.defaultRidesFrom;
            }
            if (this.props.defaultRidesTo) {
                this.rideFilter.rideTo = this.props.defaultRidesTo;
            }
        });
        await this.loadRidesAsync();
    });

    @action.bound
    public filterResult() {
        this.currentPage = 1;
        this.rideFilter.areFiltersDirty = false;
        this.loadRidesAsync.fireAndForget();
    }

    @action.bound
    public setRidesDateTimeFilter(from: moment.Moment | null, to: moment.Moment | null) {
        this.rideFilter.setDateRangeFilter(from, to);

        this.loadRidesAsync.fireAndForget();
    }

    private readonly loadRidesAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await AdminRideAdapter.getWaitingRidesAsync(this.props.taxiDriverId, paging, ordering, this.rideFilter);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.rides = result.rides;
        });
    });

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<RideLitsItem> | SorterResult<RideLitsItem>[], extra: TableCurrentDataSource<RideLitsItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (Array.isArray(sorter)) {
            this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
            this.orderDirection = sorter[0].order!;
        } else {
            this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
            this.orderDirection = sorter.order!;
        }
        this.loadRidesAsync.fireAndForget();
    }
}



const { ContextComponent: RideSearcherStoreContext, StoreProvider: RideSearcherStoreProvider, useStore: useRideSearcherStore, withStore: withRideSearcherStore }
    = createStoreProvider<IRideSearcherProps, RideSearcherStore>(() => new RideSearcherStore());

export {
    RideSearcherStoreContext,
    RideSearcherStoreProvider,
    useRideSearcherStore,
    withRideSearcherStore
};