import { bound, createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import AdminDocumentApiAdapter from "Adapters/AdminAdapter/AdminDocumentApiAdapter";
import { applyAdminDocumentListFilterDtoTo, mapAdminDocumentListFilterToDto } from "Adapters/Mapping/Map";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import moment from "antd/node_modules/moment";
import { observable, runInAction, action } from "mobx";
import { AdminDocumentListItem } from "Models/AdminDocumentListItem";
import AdminDocumentFilter from "Models/Filters/AdminDocumentFilter";
import { downloadBase64Async } from "Util/FileDownloader";

const filterSessionKey = "adminDocument_list_filter_v1";

class AdminDocumentListStore extends StoreBase {
    @observable.ref public documents: AdminDocumentListItem[] = [];

    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    public orderedBy = "bpId";
    public orderDirection = "ascend";

    public documentFilter = new AdminDocumentFilter();

    public readonly vLoadAsync = this.async(async () => {
        await this.restoreState();
        await this.loadListAsync();
    });

    private readonly loadListAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await AdminDocumentApiAdapter.getListAsync(paging, ordering, this.documentFilter);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.documents = result.documents;
        });
    });

    private restoreState() {
        try {
            const saved = sessionStorage.getItem(filterSessionKey);
            if (saved) {
                const stateStorage = JSON.parse(saved);
                runInAction(() => {
                    applyAdminDocumentListFilterDtoTo(stateStorage.filter, this.documentFilter);
                    this.orderedBy = stateStorage.orderedBy;
                    this.orderDirection = stateStorage.orderDirection;
                });
            }
        } catch { }
    }

    private saveState() {
        try {
            const filterStorage = mapAdminDocumentListFilterToDto(this.documentFilter);
            sessionStorage.setItem(filterSessionKey, JSON.stringify({
                filter: filterStorage,
                orderedBy: this.orderedBy,
                orderDirection: this.orderDirection
            }));
        }
        catch { }
    }

    @bound
    public clearState() {
        this.documentFilter.clear();
        sessionStorage.removeItem(filterSessionKey);
        this.filterResult();
    }

    @action.bound
    public filterResult() {
        this.saveState();
        this.currentPage = 1;
        this.documentFilter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<AdminDocumentListItem> | SorterResult<AdminDocumentListItem>[], extra: TableCurrentDataSource<AdminDocumentListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (!sorter) {
            this.orderedBy = "bpid";
        } else {
            if (Array.isArray(sorter)) {
                this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
                this.orderDirection = sorter[0].order!;
            } else {
                this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
                this.orderDirection = sorter.order!;
            }
        }
        this.saveState();
        this.vLoadAsync.fireAndForget();
    }

    public readonly downloadExcelAsync = this.async(async () => {
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await AdminDocumentApiAdapter.getExcelAsync(ordering, this.documentFilter);
        const date = moment().format("YYYY_MM_DD__HHmmss");
        await downloadBase64Async(result, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `iratok_${date}.xlsx`);
    });
}

const { ContextComponent: AdminDocumentListStoreContext, StoreProvider: AdminDocumentListStoreProvider, useStore: useAdminDocumentListStore, withStore: withAdminDocumentListStore } =
    createStoreProvider(() => new AdminDocumentListStore());

export {
    AdminDocumentListStoreContext,
    AdminDocumentListStoreProvider,
    useAdminDocumentListStore,
    withAdminDocumentListStore
};
