import React from "react";
import { Col, DatePicker, Modal, Row, Typography } from "antd";
import TextBox from "Pages/CommonControls/TextBox";
import DatePickerBox from "Pages/CommonControls/DatePickerBox";

const { Title } = Typography;

interface IStartPollModalProps {
    isOpen: boolean;
    onOk: () => void;
    onCancel: () => void;
    isLoading: boolean;

    endOfVoteDate: moment.Moment | null;
    setEndOfVoteDate: (value: moment.Moment | null) => void;
}

export default function StartPollModal(props: IStartPollModalProps) {

    return (
        <Modal
            title="Szavazás indítása"
            open={props.isOpen}
            onOk={props.onOk}
            confirmLoading={props.isLoading}
            onCancel={props.onCancel}
            cancelText="Mégsem"
            okText="Indítás"
        >
            <DatePickerBox
                style={{ width: "100%" }}
                date={props.endOfVoteDate}
                preventIsBeforeOrSame
                isRequired
                showTime
                label="Szavazás vége:"
                onChange={props.setEndOfVoteDate}
                format={"YYYY.MM.DD HH:mm"}
            />
        </Modal >
    );
}