import { action, makeObservable, observable } from "mobx";

class ProhibitionInfo {
    public prohibitionItemIds:  null | number[];
    @observable.ref public userName: string = "";
    @observable.ref public prohibitionReason: string = "";

    public constructor(
        prohibitionItemIds:  null | number[]
    ) {
        makeObservable(this);
        this.prohibitionItemIds = prohibitionItemIds;
    }

    @action.bound
    public setUseName(userName: string) {
        this.userName = userName;
    }

    @action.bound
    public setProhibitionReason(prohibitionReason: string) {
        this.prohibitionReason = prohibitionReason;
    }
}
export default ProhibitionInfo;