import React, { useMemo } from "react";
import { Menu } from "antd";
import {
    UserOutlined,
    UnorderedListOutlined,
    PhoneOutlined,
    LogoutOutlined,
    LinkOutlined,
    FileTextOutlined,
    StopOutlined,
    SettingOutlined,
    AreaChartOutlined,
    ToolOutlined,
    SecurityScanOutlined,
    CheckCircleOutlined,
    FilePdfOutlined
} from "@ant-design/icons";
import AuthStore from "Stores/AuthStore";
import Config from "Config";
import { useMenuController } from "./MenuController";
import IMenuItemType from "./IMenuItemType";

export default function AppMenu() {

    if (!AuthStore.user) {
        return null;
    }

    if (AuthStore.user.hasRole("PartnerAdmin")) {
        return <PartnerMenu />;
    } else if (AuthStore.user.hasRole("Admin")) {
        return <AdminMenu />;
    } else if (AuthStore.user.hasRole("Reb")) {
        return <RebMenu />;
    } else if (AuthStore.user.hasRole("Feb")) {
        return <FebMenu />;
    } else if (AuthStore.user.hasRole("RideManager")) {
        return <RideManagerMenu />;
    } else if (AuthStore.user.hasRole("Driver")) {
        return <DriverMenu />;
    }

    return null;
}

function PartnerMenu() {

    const { ControlledMenu } = useMenuController(() => [
        { label: "Partner adatok", path: "/partner/partnerData", icon: <UserOutlined />, id: "mainMenu_partnerData" },
        { label: "Azonosító szelvények", path: "/partner/vouchers", icon: <UnorderedListOutlined />, id: "mainMenu_vouchers" },
        { label: "Telefonszámok", path: "/partner/phoneNumbers", icon: <PhoneOutlined />, id: "mainMenu_phoneNumbers" },
        { label: "Taxikártyák", path: "/partner/taxiCards", icon: <UserOutlined />, id: "mainMenu_taxiCards" },
        { label: "Számlák", path: "/partner/invoices", icon: <FileTextOutlined />, id: "mainMenu_invoices" },
        { label: "Számlaösszesítők", path: "/partner/invoiceSummaries", icon: <FileTextOutlined />, id: "mainMenu_invoiceSummaries" },
        { label: "Kijelentkezés", path: Config.settings.oauth.logoutUrl, icon: <LogoutOutlined />, isAbsolutePath: true, id: "mainMenu_logout" },
    ]);

    return (
        <ControlledMenu theme="dark" mode="inline" />
    );
}

function AdminMenu() {

    const { ControlledMenu } = useMenuController(() => [
        {
            label: "Törzsadatok", id: "mainMenu_referenceData", children: [
                { label: "Partnerek", path: "/admin/partners", icon: <UnorderedListOutlined />, id: "mainMenu_partners" },
                { label: "Fuvarozók", path: "/admin/taxiDrivers", icon: <UserOutlined />, id: "mainMenu_taxiDrivers" },
            ]
        },

        {
            label: "Elszámolások", id: "mainMenu_accountings", children: [
                { label: "Számlák", path: "/admin/invoices", icon: <FileTextOutlined />, id: "mainMenu_invoices" },
                { label: "Azonosító szelvények", path: "/admin/vouchers", icon: <UnorderedListOutlined />, id: "mainMenu_vouchers" },
                { label: "Telefonszámok", path: "/admin/phoneNumbers", icon: <PhoneOutlined />, id: "mainMenu_phoneNumbers" },
                { label: "Taxikártyák", path: "/admin/taxiCards", icon: <UserOutlined />, id: "mainMenu_taxiCards" },
                { label: "Taxikártya rendelések", path: "/admin/taxiCardOrders", icon: <UserOutlined />, id: "mainMenu_taxiCardOrders" },
                { label: "Taxikártya letiltások", path: "/admin/taxiCardProhibitionEvents", icon: <UserOutlined />, id: "mainMenu_taxiCardProhibitionEvents" },
                { label: "Fuvar nélküli elszámolások", path: "/admin/offlineRides", icon: <LinkOutlined />, id: "mainMenu_offlineRides" },
                { label: "Meghiúsult fuvarok", path: "/admin/failedRides", icon: <StopOutlined />, id: "mainMenu_failedRides" },
            ]
        },


        { label: "Szavazás", path: "/admin/polls", icon: <AreaChartOutlined />, id: "mainMenu_poll" },
        { label: "Iratkezelés", path: `/admin/documents`, icon: <FileTextOutlined />, id: "mainMenu_documents" },
        { label: "Jármű állapotok", path: `/admin/vehicleConditions`, icon: <ToolOutlined />, id: "mainMenu_vehicleConditions" },

        { label: "Nyilatkozatok", path: `/admin/consents`, icon: <CheckCircleOutlined />, id: "mainMenu_consents" },
        { label: "Tevékenységnapló", path: `/admin/auditEvents`, icon: <SecurityScanOutlined />, id: "mainMenu_auditEvents" },
        { label: "Dokumentumtár", path: `/admin/commonFiles`, icon: <FilePdfOutlined />, id: "mainMenu_commonFiles" },

        { label: "Beállítások", path: "/admin/settings", icon: <SettingOutlined />, id: "mainMenu_settings" },
        { label: "Kijelentkezés", path: Config.settings.oauth.logoutUrl, icon: <LogoutOutlined />, isAbsolutePath: true, id: "mainMenu_logout" },
    ]);

    return (
        <ControlledMenu theme="dark" mode="inline" className="dense-menu" />
    );
}

function RebMenu() {

    const { ControlledMenu } = useMenuController(() => [
        { label: "Számlák", path: "/admin/invoices", icon: <FileTextOutlined />, id: "mainMenu_invoices" },
        { label: "Iratkezelés", path: `/admin/documents`, icon: <FileTextOutlined />, id: "mainMenu_documents" },
        { label: "Jármű állapotok", path: `/admin/vehicleConditions`, icon: <ToolOutlined />, id: "mainMenu_vehicleConditions" },
        { label: "Beállítások", path: "/admin/settings", icon: <SettingOutlined />, id: "mainMenu_settings" },
        { label: "Kijelentkezés", path: Config.settings.oauth.logoutUrl, icon: <LogoutOutlined />, isAbsolutePath: true, id: "mainMenu_logout" },
    ]);

    return (
        <ControlledMenu theme="dark" mode="inline" className="dense-menu" />
    );
}

function FebMenu() {

    const { ControlledMenu } = useMenuController(() => [
        { label: "Számlák", path: "/admin/invoices", icon: <FileTextOutlined />, id: "mainMenu_invoices" },
        { label: "Jármű állapotok", path: `/admin/vehicleConditions`, icon: <ToolOutlined />, id: "mainMenu_vehicleConditions" },
        { label: "Beállítások", path: "/admin/settings", icon: <SettingOutlined />, id: "mainMenu_settings" },
        { label: "Kijelentkezés", path: Config.settings.oauth.logoutUrl, icon: <LogoutOutlined />, isAbsolutePath: true, id: "mainMenu_logout" },
    ]);

    return (
        <ControlledMenu theme="dark" mode="inline" className="dense-menu" />
    );
}

function RideManagerMenu() {

    const { ControlledMenu } = useMenuController(() => [
        { label: "Fuvar nélküli elszámolások", path: "/admin/offlineRides", icon: <LinkOutlined />, id: "mainMenu_offlineRides" },
        { label: "Meghiúsult fuvarok", path: "/admin/failedRides", icon: <StopOutlined />, id: "mainMenu_failedRides" },
        { label: "Beállítások", path: "/admin/settings", icon: <SettingOutlined />, id: "mainMenu_settings" },
        { label: "Kijelentkezés", path: Config.settings.oauth.logoutUrl, icon: <LogoutOutlined />, isAbsolutePath: true, id: "mainMenu_logout" },
    ]);

    return (
        <ControlledMenu theme="dark" mode="inline" className="dense-menu" />
    );
}

function DriverMenu() {

    const driverMenuItems: IMenuItemType[] = [
        {
            key: "__logout",
            icon: <LogoutOutlined />,
            label: (
                <a href={Config.settings.oauth.logoutUrl}>
                    Kijelentkezés
                </a>
            )
        }
    ];

    return (
        <Menu theme="dark" mode="inline" items={driverMenuItems} />
    );
}
