import { ClockCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip } from "antd";
import { observer } from "mobx-react";
import UserInvitationUrl from "Models/UserInvitationUrl";
import PartnersStore from "Pages/Admin/Partners/PartnersStore";

export function showUserInvitationModal(store: PartnersStore) {
    Modal.info({
        title: "Partner meghívása",
        content: (
            <UserInvitationModal store={store} />
        ),
        onOk() { },
    });
}

const UserInvitationModal = observer((props: { store: PartnersStore }) => {
    const invitation = props.store.invitation;
    return invitation && (
        <div>
            <p>Meghívó link: <a href={invitation.url}>{invitation.url}</a></p>
            <p>Érvényesség: {invitation.validTo.format("L LT")} &nbsp;
                <Tooltip title="Érvényesség meghosszabbítása">
                    <Button type="primary" shape="circle" icon={<ClockCircleOutlined />} onClick={props.store.resetPartnerInvitationAsync.fireAndForget} />
                </Tooltip>
            </p>
        </div>
    );
});