import { useEffect, useState } from "react";
import { Select, Typography } from "antd";
import AdminPartnerAdapter from "Adapters/AdminAdapter/AdminPartnerAdapter";
import { useErrorDispatcher } from "@eblsoft/react-toolkit";
import { debounce } from "lodash";
import Paging from "Adapters/QueryHelpers/Paging";
import Ordering from "Adapters/QueryHelpers/Ordering";
import PartnerListItem from "Models/PartnerListItem";

const { Option } = Select;
const { Text } = Typography;

interface IPartnerSelectorProps {
    partnerId: number | null;
    onChange: (partnerId: number | null) => void;
    label?: string;
    isHighlighted?: boolean;
}

const debouncedSearch = debounce((searchText: string, setData: (results: PartnerListItem[]) => void, dispatchError: (e: Error) => void) => {
    if (searchText) {
        AdminPartnerAdapter.getListAsync(new Paging(1, 100), new Ordering("bpName", true), { QuickSearch: searchText })
            .then((results) => {
                setData(results.partners);
            })
            .catch(dispatchError);
    } else {
        setData([]);
    }
}, 1000);

function loadById(id: number, setData: (results: PartnerListItem[]) => void, dispatchError: (e: Error) => void) {
    AdminPartnerAdapter.getListAsync(new Paging(1, 1), new Ordering("bpName", true), { Id: id })
        .then((results) => {
            setData(results.partners);
        })
        .catch(dispatchError);
}

function PartnerSelector(props: IPartnerSelectorProps) {

    const [data, setData] = useState<PartnerListItem[]>([]);
    const dispatchError = useErrorDispatcher();
    const [loading, setLoading] = useState(false);
    const setDataAndClearLoading = (data: PartnerListItem[]) => {
        setData(data);
        setLoading(false);
    };

    useEffect(() => {
        if (props.partnerId) {
            if (!data || !data.find(d => d.id === props.partnerId)) {
                setLoading(true);
                loadById(props.partnerId, setDataAndClearLoading, dispatchError);
            }
        }
    }, []);

    const handleSearch = (searchText: string) => {
        setLoading(true);
        debouncedSearch(searchText, setDataAndClearLoading, dispatchError);
    };

    const options = data.map(d => <Option key={d.id} value={d.id} label={`${d.bpid} - ${d.bpName}`}>{d.bpid} - {d.bpName}<br /><small>Adószám: {(d.isPrivatePerson ? "Magánszemély" : d.taxNumber?.toString())}</small></Option>);

    return (
        <div>
            {props.label && <Text>{props.label}</Text>}
            <Select
                style={{ width: "100%", backgroundColor: props.isHighlighted ? "#fdb813" : "#fff" }}
                showSearch
                value={props.partnerId}
                defaultActiveFirstOption={false}
                showArrow={true}
                filterOption={false}
                onSearch={handleSearch}
                onChange={props.onChange}
                notFoundContent={null}
                loading={loading}
                optionLabelProp="label"
                dropdownMatchSelectWidth={false}
            >
                {options}
            </Select>
        </div>
    );
}

export default PartnerSelector;