import { createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { action, computed, observable, runInAction } from "mobx";
import { CostPlacementType } from "Models/CostPlacementType";
import PhoneNumberFilter from "Models/Filters/PhoneNumberFilter";
import { IPhoneNumberEditData } from "Models/IPhoneNumberEditData";
import PhoneNumber from "Models/PhoneNumber";
import PhoneNumberListItem from "Models/PhoneNumberListItem";
import { PrintOptions } from "Models/PrintOptions";
import ProhibitionInfo from "Models/ProhibitionInfo";
import moment from "moment";
import { IPhoneNumberListCoreProps } from "Pages/Partner/PhoneNumbers/PhoneNumbers";
import NotificationStore from "Stores/NotificationStore";
import { downloadBase64Async } from "Util/FileDownloader";

export default class PhoneNumbersStore extends StoreBase<IPhoneNumberListCoreProps> {
    @observable.ref public phoneNumbers: PhoneNumberListItem[] = [];
    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    @observable public visiblePhoneNumberPinId = -1;
    @observable.ref public recordIdUnderEdit: number | null = null;
    public phoneNumberFilter = new PhoneNumberFilter();

    public orderedBy = "name";
    public orderDirection = "ascend";

    @observable.ref public prohibitionModalIsVisible: boolean = false;
    @observable.ref public deletionModalIsVisible: boolean = false;
    @observable.ref public confirmIsLoading: boolean = false;
    @observable.ref public prohibitionInfo: ProhibitionInfo = new ProhibitionInfo(null);
    @observable.ref public deletionInfo: ProhibitionInfo = new ProhibitionInfo(null);
    @observable.ref public showInactive: boolean = false;

    @computed private get filter() {
        const filterResult = {
            CostPlacementType: this.phoneNumberFilter.costPlacementType,
            Name: this.phoneNumberFilter.name,
            TelephoneNumber: this.phoneNumberFilter.phoneNumber,
            CostPlacement: this.phoneNumberFilter.costPlacement,
            ShowInactive: this.showInactive,
        }
        return this.props.isSummary ? { ...filterResult, Customer: this.phoneNumberFilter.customer } : filterResult;
    }

    public readonly vLoadAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");

        const result = await this.props.phoneNumberApiAdapter.getListAsync(
            paging,
            ordering,
            this.filter,
            this.props.isSummary ? undefined : this.props.partnerId);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.phoneNumbers = result.phoneNumbers;
        });
    });

    private readonly updateAsync = this.async(async (newData: IPhoneNumberEditData) => {
        try {
            const result = await this.props.phoneNumberApiAdapter.updateAsync!(newData, this.props.partnerId);
            if (result) {
                this.clearRecordUnderEdit();
                this.vLoadAsync.fireAndForget();
                NotificationStore.success("Sikeres mentés");
            }
        } catch { }
    });

    public readonly toggleProhibitionAsync = this.async(async (id: number) => {
        const result = await this.props.phoneNumberApiAdapter.toggleProhibitionAsync!(id, this.prohibitionInfo?.prohibitionReason, this.prohibitionInfo?.userName, this.props.partnerId);
        if (result && this.prohibitionModalIsVisible) {
            runInAction(() => {
                this.prohibitionInfo = new ProhibitionInfo(null);
                this.confirmIsLoading = false;
                this.prohibitionModalIsVisible = false;
            });
        }
        await this.vLoadAsync();

    });

    public readonly deleteAsync = this.async(async (id: number) => {
        const result = await this.props.phoneNumberApiAdapter.deleteAsync!(id, this.deletionInfo?.prohibitionReason, this.deletionInfo?.userName, this.props.partnerId);
        if (result && this.deletionModalIsVisible) {
            runInAction(() => {
                this.deletionInfo = new ProhibitionInfo(null);
                this.confirmIsLoading = false;
                this.deletionModalIsVisible = false;
            });
        }
        await this.vLoadAsync();

    });

    private readonly createAsync = this.async(async (newData: IPhoneNumberEditData) => {
        try {
            const result = await this.props.phoneNumberApiAdapter.createAsync!(newData, this.props.partnerId);
            if (result) {
                this.clearRecordUnderEdit();
                this.vLoadAsync.fireAndForget();
                NotificationStore.success("Sikeres mentés");
            }
        } catch { }
    })

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<PhoneNumberListItem> | SorterResult<PhoneNumberListItem>[], extra: TableCurrentDataSource<PhoneNumberListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (Array.isArray(sorter)) {
            this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
            this.orderDirection = sorter[0].order!;
        } else {
            this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
            this.orderDirection = sorter.order!;
        }
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public setPinVisible(id: number) {
        if (id === this.visiblePhoneNumberPinId) {
            this.visiblePhoneNumberPinId = -1;
        } else {
            this.visiblePhoneNumberPinId = id;
        }

        this.phoneNumbers = [...this.phoneNumbers];
    }

    @action.bound
    public createPhoneNumberRow() {
        const newItem = new PhoneNumberListItem(-1, "", true, CostPlacementType.None, "", "", "", "", "");
        this.phoneNumbers = [newItem, ...this.phoneNumbers];
        this.setRecordUnderEdit(-1);
    }

    @action.bound
    public setRecordUnderEdit(id: number | null) {
        if (!id && this.recordIdUnderEdit === -1) {
            this.phoneNumbers = this.phoneNumbers.filter(p => p.id !== -1);
        }
        this.recordIdUnderEdit = id;
    }

    @action.bound
    public saveRecordUnderEdit() {
        const newData = this.phoneNumbers.find(p => p.id === this.recordIdUnderEdit)!;
        if (this.recordIdUnderEdit === -1) {
            this.createAsync.fireAndForget(newData);
        } else {
            this.updateAsync.fireAndForget(newData);
        }
    }

    @action
    private clearRecordUnderEdit() {
        this.recordIdUnderEdit = null;
    }

    @action.bound
    public setProhibitionModalIsVisible(id: number) {
        this.prohibitionModalIsVisible = true;
        this.prohibitionInfo = new ProhibitionInfo([id]);
    }

    @action.bound
    public setDeletionModalIsVisible(id: number) {
        this.deletionModalIsVisible = true;
        this.deletionInfo = new ProhibitionInfo([id]);
    }

    @action.bound
    public toggleProhibit() {
        this.confirmIsLoading = true;
        this.toggleProhibitionAsync.fireAndForget(this.prohibitionInfo.prohibitionItemIds![0]);
    }

    @action.bound
    public delete() {
        this.confirmIsLoading = true;
        this.deleteAsync.fireAndForget(this.deletionInfo.prohibitionItemIds![0]);
    }

    @action.bound
    public toggleShowInactive() {
        this.showInactive = !this.showInactive;
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public cancelModal() {
        this.prohibitionModalIsVisible = false;
        this.deletionModalIsVisible = false;
    }

    public readonly printAsync = this.async(async (option: PrintOptions, showPin: boolean) => {
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await this.props.phoneNumberApiAdapter.printPhoneNumbersAsync(ordering, this.filter, option, showPin, this.props.isSummary ? undefined : this.props.partnerId);
        const date = moment().format("YYYY_MM_DD");
        switch (option) {
            case PrintOptions.PrintToExcel:
                await downloadBase64Async(result, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `telefonszámok_${date}.xlsx`);
                return;
            default:
                await downloadBase64Async(result, "application/pdf", `telefonszámok_${date}.pdf`);
            // TODO other options
        }
    });

    @action.bound
    public print(option: PrintOptions, showPin: boolean) {
        this.printAsync.fireAndForget(option, showPin);
    }

    @action.bound
    public filterResult() {
        this.currentPage = 1;
        this.phoneNumberFilter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }
}

const { ContextComponent: PhoneNumberStoreContext, StoreProvider: PhoneNumberStoreProvider, useStore: usePhoneNumberStore, withStore: withPhoneNumberStore } =
    createStoreProvider<IPhoneNumberListCoreProps, PhoneNumbersStore>(() => new PhoneNumbersStore());

export {
    PhoneNumberStoreContext,
    PhoneNumberStoreProvider,
    usePhoneNumberStore,
    withPhoneNumberStore
};