import { Button, Modal, Popconfirm, Space, Upload } from "antd";
import TextBox from "Pages/CommonControls/TextBox";
import { UploadOutlined } from "@ant-design/icons";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { Observer } from "mobx-react";
import TaxiCardListItem from "Models/TaxiCardListItem";
import Title from "antd/lib/typography/Title";
import ProhibitionInfoBox from "Pages/CommonControls/ProhibitionInfoBox";
import ProhibitionInfo from "Models/ProhibitionInfo";


interface IPermanentCancellationTaxiCardModalProps {
    taxiCardIdentifier: string;
    permanentCancellationFee: number;
    isOpen: boolean;
    onOk: () => void;
    onCancel: () => void;
    prohibitionInfo: ProhibitionInfo;
    isLoading: boolean;
}

export default function PermanentCancellationTaxiCardModal(props: IPermanentCancellationTaxiCardModalProps) {

    return (
        <Modal
            title="Végleges tiltás"
            open={props.isOpen}
            onOk={props.onOk}
            confirmLoading={props.isLoading}
            onCancel={props.onCancel}
            cancelText="Mégsem"

            okText="Végrehajtás">

            {<Title level={5}>{`Biztosan véglegesen letiltja a/az ${props.taxiCardIdentifier} azonosítójú taxikártyát? A végleges letiltásért ${props.permanentCancellationFee} Ft.-ot számítunk fel.`}</Title>}
            <ProhibitionInfoBox prohibitionInfo={props.prohibitionInfo} />
        </Modal>
    );
}