import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Col, PageHeader, Row, Space } from "antd";
import { ValidationStoreProvider } from "Stores/ValidationStore";
import TextBox from "Pages/CommonControls/TextBox";
import DatePickerBox from "Pages/CommonControls/DatePickerBox";
import { DocumentStatusSelectBox } from "Pages/CommonControls/DocumentStatusSelectBox";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { useCallback } from "react";
import { Image } from 'antd';
import { useTrafficLicensesStore } from "../TrafficLicenseListStore";
import { useTrafficLicenseDetailStore, withTrafficLicenseDetailStore } from "./TrafficLicenseDetailStore";
import { KeyOutlined } from "@ant-design/icons";

export interface ITrafficLicenseDetailCoreProps {
    trafficLicenseId?: number;
}

function TrafficLicenseDetailCore(props: ITrafficLicenseDetailCoreProps) {
    const store = useTrafficLicenseDetailStore();
    const trafficLicensesStore = useTrafficLicensesStore();
    const navigate = useNavigate();

    const saveTrafficLicenseAsync = async () => {
        const success = await store.saveAsync();
        if (success) {
            trafficLicensesStore?.vLoadAsync.fireAndForget();
            navigateBack();
        }
    }

    const navigateBack = useCallback(() => navigate(-1), [navigate]);

    return (
        <>
            <PageHeader
                title={"Forgalmi engedély szerkesztése"}
            >
                {store.isAuthorized ? (
                    <ValidationStoreProvider value={store.validationStore}>
                        <Row gutter={[16, 8]}>
                            <Col xxl={11} xl={11} lg={11} xs={14} sm={14}>
                                {store.images.map((data, idx) => <Image key={idx} style={{ marginBottom: "10px" }} alt={"Forgalmi egendély képe"} src={`data:image/jpeg;base64,${data}`} />)}
                            </Col>
                            <Col xxl={13} xl={13} lg={13} xs={10} sm={10}>
                                <Row gutter={16}>
                                    <Col xl={24} xs={24}>
                                        <TextBox
                                            label="Forgalmi engedély száma:"
                                            onChange={store.setIdentifier}
                                            value={store.identifier}
                                            isRequired
                                            maxLength={16}
                                        />
                                    </Col>
                                    <Col xl={12} xs={12}>
                                        <DatePickerBox
                                            label="Első forgalomba helyezés ideje:"
                                            isRequired
                                            onChange={store.setValidFrom}
                                            date={store.validFrom}
                                        />
                                    </Col>
                                    <Col xl={12} xs={12}>
                                        <DatePickerBox
                                            label="Műszaki vizsga érvényessége:"
                                            isRequired
                                            onChange={store.setValidTo}
                                            date={store.validTo}
                                        />
                                    </Col>
                                    <Col xl={12} xs={12}>
                                        <DatePickerBox
                                            label="Utoljára ellenőrzött műszaki vizsga érvényessége:"
                                            onChange={() => {}}
                                            disabled
                                            date={store.lastConfirmedValidTo}
                                        />
                                    </Col>
                                    <Col xl={12} xs={12}>
                                        <TextBox
                                            label="Gépkocsi gyártmány:"
                                            onChange={store.setMakeOfCar}
                                            value={store.makeOfCar}
                                            isRequired
                                            maxLength={64}
                                        />
                                    </Col>
                                    <Col xl={12} xs={12}>
                                        <TextBox
                                            label="Gépkocsi típus:"
                                            onChange={store.setTypeOfCar}
                                            value={store.typeOfCar}
                                            isRequired
                                            maxLength={64}
                                        />
                                    </Col>
                                    <Col xl={12} xs={12}>
                                        <TextBox
                                            label="Rendszám:"
                                            onChange={store.setLicensePlateNumber}
                                            value={store.licensePlateNumber}
                                            isRequired
                                            maxLength={16}
                                        />
                                    </Col>
                                    <Col xl={12} xs={12}>
                                        <DocumentStatusSelectBox
                                            documentStatus={store.status}
                                            onChange={store.setSimpleDocumentStatus}
                                            style={{ width: "100%" }}
                                            label="Státusz:"
                                        />
                                    </Col>
                                </Row>
                                <Space style={{ width: "100%", marginTop: "24px" }}>
                                    <Button key="submit" type="primary" loading={store.isLoading} onClick={() => saveTrafficLicenseAsync()}>
                                        Mentés
                                    </Button>
                                    <Button loading={store.isLoading} onClick={() => navigateBack()}>Mégsem</Button>
                                </Space>
                            </Col>
                        </Row>
                    </ValidationStoreProvider>
                ) : (
                    <>
                        <Alert
                            message="Hitelesítés szükséges"
                            style={{ margin: "2rem 1rem" }}
                            description={(
                                <p>A dokumentumok ellenőrzéséhez kétfaktoros hitelesítés szükséges. A kód megadása után egy órán keresztül hozzáfér az adatokhoz.<br/><small>A hitelesítéshez szükséges beállításokat a "Beállítások" menüben találja.</small></p>
                            )}
                            type="info"
                        />
                        <Row gutter={16} justify="center">
                            <Col xs={6}>
                                <Space direction="vertical" style={{ width: "100%" }}>
                                    <TextBox label="Hitelesítő kód:" value={store.tfaCode} onChange={store.setTfaCode} onEnter={store.authenticateAsync.fireAndForget} />
                                    <Button icon={<KeyOutlined />} onClick={store.authenticateAsync.fireAndForget} style={{ width: "100%" }}>Hitelesítés</Button>
                                </Space>
                            </Col>
                        </Row>

                    </>
                )}
            </PageHeader>
        </>
    );
}

const TrafficLicenseDetailCoreWithStore = withTrafficLicenseDetailStore(TrafficLicenseDetailCore);

export default function TrafficLicenseDetail() {
    const { trafficLicenseId } = useParams();
    return (
        <TrafficLicenseDetailCoreWithStore trafficLicenseId={isNullOrUndefined(trafficLicenseId) ? undefined : parseInt(trafficLicenseId!)} />
    )
}