import { IGetTrafficLicenseListRequest, IGetTrafficLicenseResponse, IUpdateTrafficLicenseRequest, IUpdateTrafficLicenseResponse } from "Adapters/Api/Api.g";
import { mapTrafficLicenseDtoToDomainModel, mapTrafficLicenseListFilterToDto, mapTrafficLicenseListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import TrafficLicenseFilter from "Models/Filters/TrafficLicenseFilter";
import TrafficLicenseListItem from "Models/TrafficLicenseListItem";
import TrafficLicense from "Models/TrafficLicense";
import { DocumentStatus } from "Models/DocumentStatus";


class AdminTrafficLicenseAdapter extends ProtectedApiAdapterBase {
    private readonly mainRoute = "api/web/admin/trafficLicense";

    public getListAsync(paging: Paging, ordering: Ordering, filter: TrafficLicenseFilter): Promise<{ totalCount: number, trafficLicenses: TrafficLicenseListItem[] }> {
        const request: IGetTrafficLicenseListRequest = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            filter: mapTrafficLicenseListFilterToDto(filter)
        }

        return this.httpPost<{ totalCount: number, trafficLicenses: TrafficLicenseListItem[] }>(this.mainRoute, undefined, request).map(paginatedResult => {
            return {
                totalCount: paginatedResult.totalCount,
                trafficLicenses: paginatedResult.trafficLicenses.map((i: any) => {
                    return mapTrafficLicenseListItemDtoToDomainModel(i);
                }),
            };
        }).performOperationAsync();
    }

    public updateTrafficLicenseAsync(
        token: string,
        id: number,
        identifier: string,
        makeOfCar: string,
        typeOfCar: string,
        licensePlateNumber: string,
        status: DocumentStatus,
        validTo: moment.Moment,
        validFrom: moment.Moment
    ): Promise<TrafficLicense> {
        const body: IUpdateTrafficLicenseRequest = {
            token,
            identifier,
            makeOfCar,
            typeOfCar,
            licensePlateNumber,
            validFrom: validFrom.format("YYYY-MM-DD"),
            validTo: validTo.format("YYYY-MM-DD"),
            status,
            id
        };
        return this.httpPut<TrafficLicense>(this.mainRoute, ["update"], body).map((result: IUpdateTrafficLicenseResponse) => {
            return mapTrafficLicenseDtoToDomainModel(result.trafficLicense);
        }).performOperationAsync();
    }

    public getDetailsAsync(token: string, trafficLicenseId: number): Promise<TrafficLicense> {
        return this.httpGet<TrafficLicense>(this.mainRoute, [trafficLicenseId.toString()], { token }).map((result: IGetTrafficLicenseResponse) => {
            return mapTrafficLicenseDtoToDomainModel(result.trafficLicense);
        }).performOperationAsync();
    }

}

export default new AdminTrafficLicenseAdapter();