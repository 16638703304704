import { IQueryParameters } from "@eblsoft/react-toolkit";
import InvoiceSummaryForPartnerListItem from "Models/InvoiceSummaryForPartnerListItem";
import IInvoiceSummaryApiAdapter from "Adapters/IInvoiceSummaryApiAdapter";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import { mapInvoiceSummaryDetailsDtoToDomainModel, mapInvoiceSummaryForPartnerListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import { IGetInvoiceSummaryDetailsResponse, IGetInvoiceSummaryForPartnerListResponse, IGetInvoiceSummaryListRequest } from "Adapters/Api/Api.g";
import InvoiceSummaryDetails from "Models/InvoiceSummaryDetails";
import moment from "moment";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";

class PartnerInvoiceSummaryApiAdapter extends ProtectedApiAdapterBase implements IInvoiceSummaryApiAdapter {

    private readonly mainRoute = "api/web/partner/InvoiceSummary";

    public getListAsync(paging: Paging, ordering: Ordering, filter: any): Promise<{ totalCount: number, invoiceSummaries: InvoiceSummaryForPartnerListItem[] }> {
        const request: IGetInvoiceSummaryListRequest = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            ...filter
        }

        return this.httpPost<{ totalCount: number, invoiceSummaries: InvoiceSummaryForPartnerListItem[] }>(this.mainRoute, undefined, request).map((paginatedResult: IGetInvoiceSummaryForPartnerListResponse) => {
            return {
                totalCount: paginatedResult.totalCount,
                invoiceSummaries: paginatedResult.invoiceSummaries!.map(i => {
                    return mapInvoiceSummaryForPartnerListItemDtoToDomainModel(i);
                })
            };
        }).performOperationAsync();
    }

    public getPdfAsync(invoiceSummaryId: number): Promise<string> {
        return this.httpGet<string>(this.mainRoute, [invoiceSummaryId.toString(), "pdf"]).map(result => {
            return result;
        }).performOperationAsync();
    }

    public getExcelAsync(invoiceSummaryId: number): Promise<string> {
        return this.httpGet<string>(this.mainRoute, [invoiceSummaryId.toString(), "excel"]).map(result => {
            return result;
        }).performOperationAsync();
    }

    public getDetailsAsync(invoiceSummaryId: number): Promise<InvoiceSummaryDetails> {
        return this.httpGet<InvoiceSummaryDetails>(this.mainRoute, [invoiceSummaryId.toString()]).map((result: IGetInvoiceSummaryDetailsResponse) => {
            return mapInvoiceSummaryDetailsDtoToDomainModel(result.details!);
        }).performOperationAsync();
    }
}

export default new PartnerInvoiceSummaryApiAdapter();
