import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import ITaxiVoucherAdapter from "Adapters/ITaxiVoucherAdapter";
import { withVouchersStore } from "./VouchersStore";
import { Outlet } from "react-router";
import { useParams } from "react-router-dom";

export interface IVoucherListProps {
    voucherApiApadter: ITaxiVoucherAdapter;
    isSummary: boolean;
}

export interface IVoucherListCoreProps extends IVoucherListProps {
    partnerId?: number;
    isSummary: boolean;
}

function VouchersCore(props: IVoucherListCoreProps) {
    return (
        <Outlet />
    );
}

const VouchersCoreWithStore = withVouchersStore(VouchersCore);

export default function Vouchers(props: IVoucherListProps) {
    const { partnerId } = useParams();
    return (
        <VouchersCoreWithStore {...props} isSummary={props.isSummary} partnerId={isNullOrUndefined(partnerId) ? undefined : parseInt(partnerId!)} />
    )
}
