import { VehicleConditionStatus, VehicleConditionStatusEntries } from "Models/VehicleConditionStatus";
import { Select, Typography } from "antd"
const { Option } = Select;
const { Text } = Typography;

export interface IVehicleConditionStatusSelectBoxProps {
    onChange?: (vehicleConditionStatus: VehicleConditionStatus) => void;
    vehicleConditionStatus?: VehicleConditionStatus | null;
    style?: React.CSSProperties;
    label?: string
    allowClear?: boolean;
}

export function VehicleConditionStatusSelectBox(props: IVehicleConditionStatusSelectBoxProps) {
    return (
        <div style={props.style}>
            {props.label && <Text>{props.label}</Text>}
            <Select
                style={{ width: "100%" }}
                onChange={props.onChange}
                value={props.vehicleConditionStatus}
                allowClear={props.allowClear}
            >
                {VehicleConditionStatusEntries.map(i =>
                    <Option key={i.enumValue} value={i.enumValue}>
                        {i.displayValue}
                    </Option>
                )}
            </Select>
        </div>
    )
} 