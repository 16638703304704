import { DocumentStatus } from "./DocumentStatus";

export default class TrafficLicense {
    constructor(
        public readonly id: number,
        public readonly identifier: string | null,
        public readonly licensePlateNumber: string | null,
        public readonly makeOfCar: string | null,
        public readonly typeOfCar: string | null,
        public readonly validFrom: moment.Moment | null,
        public readonly validTo: moment.Moment | null,
        public readonly lastConfirmedValidTo: moment.Moment | null,
        public readonly status: DocumentStatus,
        public readonly images: string[]
    ) { }
}