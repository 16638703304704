import React from "react";
import { Col, Modal, Row, Typography } from "antd";
import TextBox from "Pages/CommonControls/TextBox";

const { Title } = Typography;

interface IDeleteOfflineRideConfirmationDialogProps {
    isOpen: boolean;
    onOk: () => void;
    onCancel: () => void;
    isLoading: boolean;

    modifierName: string;
    onModifierNameChange: (value: string | null) => void;

    modificationReason: string;
    onModificationReasonChange: (value: string | null) => void;
}

export default function DeleteOfflineRideConfirmationDialog(props: IDeleteOfflineRideConfirmationDialogProps) {

    return (
        <Modal
            title="Törlés"
            open={props.isOpen}
            onOk={props.onOk}
            confirmLoading={props.isLoading}
            onCancel={props.onCancel}
            cancelText="Mégsem"
            okText="Törlés"
            okType="danger"
        >
            <Title level={5}>Audit információk</Title>
            <TextBox label="Törlést végző neve:" value={props.modifierName} onChange={props.onModifierNameChange} isRequired maxLength={256} />
            <TextBox label="Törlés oka (nem kötelező):" value={props.modificationReason} onChange={props.onModificationReasonChange} multiline={3} maxLength={512} />
        </Modal >
    );
}