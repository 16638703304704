import { mapVoucherDtoToDomainModel, mapVoucherListItemDtoToDomainModel } from "Adapters/Mapping/Map";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { TaxiVoucherLimit } from "Models/TaxiVoucherLimit";
import Voucher from "Models/Voucher";
import { CostPlacementType } from "Models/CostPlacementType";
import { PrintOptions } from "Models/PrintOptions";
import ITaxiVoucherAdapter from "Adapters/ITaxiVoucherAdapter";
import { ICreateTaxiVouchersRequest, IDownloadTaxiVoucherListRequest, IDownloadTaxiVoucherTicketsRequest, IGetTaxiVouchersRequest } from "Adapters/Api/Api.g";
import moment from "moment";
import VoucherListItem from "Models/VoucherListItem";

class AdminTaxiVoucherAdapter extends ProtectedApiAdapterBase implements ITaxiVoucherAdapter {

    private readonly mainRoute = "api/web/admin/voucher";

    public getListAsync(paging: Paging, ordering: Ordering, filter: any): Promise<{ totalCount: number, vouchers: VoucherListItem[] }> {

        const request: IGetTaxiVouchersRequest = {
            pageNumber: paging.pageNumber,
            pageSize: paging.pageSize,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            ...filter
        }

        return this
            .httpPost<{ totalCount: number, vouchers: VoucherListItem[] }>(this.mainRoute, undefined, request)
            .map(paginatedResult => {
                return {
                    totalCount: paginatedResult.totalCount,
                    vouchers: paginatedResult.vouchers.map((v: any) => {
                        return mapVoucherListItemDtoToDomainModel(v);
                    })
                };
            }
            ).performOperationAsync();
    }

    public printVoucherListAsync(ordering: Ordering, filter: any, printOption: PrintOptions) {
        const request: IDownloadTaxiVoucherListRequest = {
            printOption,
            orderBy: ordering.orderBy,
            orderAsc: ordering.orderAsc,
            ...filter
        };

        return this.httpPost<string>(this.mainRoute, ["printList"], request).map(result => {
            return result;
        }).performOperationAsync();
    }


    public createVouchersAsync(count: number, cost: TaxiVoucherLimit, validity: moment.Moment, costPlacementType: CostPlacementType, costPlacement: string = "", partnerId: number) {
        const body:ICreateTaxiVouchersRequest = {
            count: count,
            cost: cost,
            validity: validity.toJSON(),
            costPlacementType: costPlacementType,
            costPlacement: costPlacement
        };
        return this.httpPost<{ totalCount: number, vouchers: VoucherListItem[] }>(this.mainRoute, [partnerId!.toString(), "taxiVoucher", "new"], body).map(paginatedResult => {
            return {
                totalCount: paginatedResult.totalCount,
                vouchers: paginatedResult.vouchers.map((v: any) => {
                    return mapVoucherListItemDtoToDomainModel(v);
                })
            };
        }).performOperationAsync();
    }

    // public printVoucherTicketsAsync(ordering: Ordering, filter: any, partnerId: number | undefined): Promise<string> {
    //     const queryParameters: IDownloadTaxiVoucherTicketsRequest = {
    //         orderBy: ordering.orderBy,
    //         orderAsc: ordering.orderAsc,
    //         ...filter
    //     };

    //     return this.httpPost<string>(this.mainRoute, [partnerId!.toString(), "taxiVoucher", "printTickets"], queryParameters).map(result => {
    //         return result;
    //     }).performOperationAsync();
    // }

    // public toggleProhibitVouchersAsync(ids: number[], prohibitionReason?: string, userName?: string, partnerId?: number): Promise<boolean> {
    //     const body = {
    //         Ids: ids,
    //         ProhibitionReason: prohibitionReason,
    //         UserName: userName
    //     };
    //     return this.httpPut<boolean>(this.mainRoute, [partnerId!.toString(), "taxiVoucher", "toggleProhibit"], body).map((res) => true).performOperationAsync();
    // }
}

export default new AdminTaxiVoucherAdapter();
