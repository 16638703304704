import { action, makeObservable, observable } from "mobx";
import moment from "moment";
import { VoucherType } from "./VoucherType";

export default class AccountedRide {

    @observable.ref public rideAdditionalInfo: string = "";

    constructor(
        public readonly id: number,
        public readonly publicIdentifier: string,
        public readonly rideIdentifier: string,
        public readonly voucherType: VoucherType,
        public readonly voucherInfo: string | null,
        public readonly createdAt: moment.Moment,
        public readonly driverName: string,
        public readonly driverBpid: string,
        public readonly costPlacementIdentifier: string | null,
        public readonly grossPrice: number,
        rideAdditionalInfo: string,
        public readonly customerName: string,
        public readonly customerBpid: string
    ) {
        this.rideAdditionalInfo = rideAdditionalInfo;
        makeObservable(this);

    }

    @action.bound
    public setRideAdditionalInfo(value: string) {
        this.rideAdditionalInfo = value;
    }
}