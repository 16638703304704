import moment from "moment";
import Address from "./Address";

export default class RideLitsItem {
    constructor(
        public readonly id: number,
        public readonly externalId: string,
        public readonly createdAt: moment.Moment,
        public readonly isAutoAccounted: boolean,
        public readonly phoneNumber: string | null,
        public readonly name: string,
        public readonly address: Address,
        public readonly additionalInfo: string | null
    ) { }
}