import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import IPhoneNumberAdapter from "Adapters/IPhoneNumberAdapter";
import { Outlet } from "react-router-dom";
import { withPhoneNumberStore } from "./PhoneNumbersStore";
import { useParams } from "react-router-dom";

export interface IPhoneNumberListProps {
    phoneNumberApiAdapter: IPhoneNumberAdapter;
    isSummary: boolean;
}

export interface IPhoneNumberListCoreProps extends IPhoneNumberListProps {
    partnerId?: number;
    isSummary: boolean;
}

function PhoneNumbersCore() {
    return (
        <Outlet />
    );
}

const PhoneNumbersCoreWithStore = withPhoneNumberStore(PhoneNumbersCore);

export default function PhoneNumbers(props: IPhoneNumberListProps) {
    const { partnerId } = useParams();
    return (
        <PhoneNumbersCoreWithStore {...props} isSummary={props.isSummary} partnerId={isNullOrUndefined(partnerId) ? undefined : parseInt(partnerId!)} />
    )
}