import { DocumentStatus } from "Models/DocumentStatus";
import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";

class SimpleDocumentFilter {

    @observable.ref public partnerName: string = "";
    @observable.ref public partnerBpid: string = "";
    @observable.ref public identifier: string = "";
    @observable.ref public validToFrom: moment.Moment | null = null;
    @observable.ref public validToTo: moment.Moment | null = null;
    @observable.ref public status: DocumentStatus | null = null;

    @observable public areFiltersDirty = false;

    public constructor(
    ) {
        makeObservable(this);
    }

    @computed public get isEmpty() {
        return this.partnerName === "" &&
            this.partnerBpid === "" &&
            this.identifier === "" &&
            this.validToFrom === null &&
            this.validToTo === null &&
            this.status === null
    }

    @action.bound
    public clear() {
        this.partnerName = "";
        this.partnerBpid = "";
        this.identifier = "";
        this.validToTo = null;
        this.validToFrom = null;
        this.status = null;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setStatus(status: DocumentStatus) {
        this.status = status;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setIdentifier(identifier: string) {
        this.identifier = identifier;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setPartnerName(partnerName: string) {
        this.partnerName = partnerName;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setPartnerBpid(partnerBpid: string) {
        this.partnerBpid = partnerBpid;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setValidToRange(from: moment.Moment | null, to: moment.Moment | null) {
        this.validToFrom = from?.clone().hour(0).minute(0).second(0).millisecond(0) ?? null;
        this.validToTo = to?.clone().hour(23).minute(59).second(59).millisecond(999) ?? null;
        this.areFiltersDirty = true;
    }
}
export default SimpleDocumentFilter;