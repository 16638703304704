import { PageHeader, Table, TablePaginationConfig, Button, Space, Tooltip, Row, Col, Typography, Select, } from "antd";
import { DownOutlined, EditOutlined, FileExcelOutlined, FilterOutlined, MoreOutlined, UndoOutlined } from "@ant-design/icons";
import { ColumnType } from "antd/lib/table";
import { Observer, observer } from "mobx-react";
import { useCallback, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import TextBox from "Pages/CommonControls/TextBox";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";
import PollListItem from "Models/PollListItem";
import { getFullScreenTableScroll } from "Util/TableHelpers";
import DateRangePicker from "Pages/CommonControls/DateRangePicker";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { SimpleDocumentType, getSimpleDocumentTypeDisplayValue, SimpleDocumentTypeEntries } from "Models/SimpleDocumentType";
import { DocumentStatus, DocumentStatusEntries, getDocumentStatusDisplayValue, getDocumentStatusDisplayValueWithExpirationDate } from "Models/DocumentStatus";
import SimpleDocumentListItem from "Models/SimpleDocumentListItem";
import { DocumentStatusSelectBox } from "Pages/CommonControls/DocumentStatusSelectBox";
import { useAdminDocumentListStore, withAdminDocumentListStore } from "Pages/Admin/Documents/AdminDocumentList/AdminDocumentListStore";
import { AdminDocumentListItem } from "Models/AdminDocumentListItem";
import moment from "moment";
import Styles from "./AdminDocumentList.module.css";
import AuthStore from "Stores/AuthStore";

const { Title, Text } = Typography;
const { Option } = Select;

export interface IAdminDocumentListCoreProps {
}

function renderDocumentCell(row: AdminDocumentListItem, documentTypeValue: string, createLink: boolean) {
    const document = row.documents[`${toCamelCase(documentTypeValue)}`];
    if (isNullOrUndefined(document?.status)) return null;

    if (createLink) {
        return (
            <Link
                className={Styles.cell}
                to={`${documentTypeValue}/${document.documentId}`}
            >
                {getDocumentStatusDisplayValueWithExpirationDate(document.status!, document.updatedAt, document.validTo)}
            </Link>
        );
    }

    return (
        <span>
            {getDocumentStatusDisplayValueWithExpirationDate(document.status!, document.updatedAt, document.validTo)}
        </span>
    );
}

function getDocumentCellProps(row: AdminDocumentListItem, documentTypeValue: string): React.HTMLAttributes<any> | React.TdHTMLAttributes<any> {
    const documentStatus = row.documents[`${toCamelCase(documentTypeValue)}`].status;

    if (isNullOrUndefined(documentStatus)) return {};

    const [backgroundColor, foregroundColor] = getCellColors(documentStatus!);
    return {
        style: {
            backgroundColor: backgroundColor,
            color: foregroundColor,
        }
    };
}

function getCellColors(status: DocumentStatus): [string, string] {
    switch (status) {
        case DocumentStatus.NoData:
            return ["#ffffff", "#000"];
        case DocumentStatus.CannotConfirm:
            return ["#fd6f6f", "#000"]
        case DocumentStatus.PendingConfirmation:
            return ["rgb(253, 184, 19)", "#000"]
        case DocumentStatus.Confirmed:
            return ["#3eed3e", "#000"]
        case DocumentStatus.WillBeExpired:
            return ["#0756ac", "#fff"];
        case DocumentStatus.Expired:
            return ["#be2621", "#fff"];
    }
}

function toCamelCase(value: string) {
    return `${value[0].toLowerCase()}${value.substring(1)}`;
}

function AdminDocumentListCore(props: IAdminDocumentListCoreProps) {
    const store = useAdminDocumentListStore();

    const _paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    }

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    let paginationConfig = _paginationConfig;

    const isDocReviewer = AuthStore.user?.hasRole("DocRev") === true;

    const adminDocumentColumns: Array<ColumnType<AdminDocumentListItem>> = [
        {
            title: "BPID",
            dataIndex: "bpid",
            key: "bpid",
            sorter: true,
        },
        {
            title: "Név",
            dataIndex: "driverName",
            key: "driverName",
            sorter: true
        },
        {
            title: "Számla név",
            dataIndex: "name",
            key: "name",
            sorter: true
        },
        {
            title: "Rendszám",
            dataIndex: "licensePlateNumber",
            key: "licensePlateNumber",
            sorter: true
        },
        ...SimpleDocumentTypeEntries.map<ColumnType<AdminDocumentListItem>>(sdt => ({
            title: <span className={Styles.header}>{sdt.displayValue}</span>,
            render: (_, row) => renderDocumentCell(row, sdt.enumKey, isDocReviewer),
            onCell: row => getDocumentCellProps(row, sdt.enumKey),
            key: `${toCamelCase(sdt.enumKey)}Status`,
            sorter: true,
            align: "center"
        })),
        {
            title: <span className={Styles.header}>Forgalmi engedély</span>,
            render: (_, row) => renderDocumentCell(row, "trafficLicense", isDocReviewer),
            onCell: row => getDocumentCellProps(row, "trafficLicense"),
            key: `${toCamelCase("trafficLicense")}Status`,
            sorter: true,
            align: "center"
        }
    ];

    const tableScroll = getFullScreenTableScroll(adminDocumentColumns.reduce((sum, col) => sum + (col.width as number ?? 100), 0));

    return (
        <PageHeader title="Iratkezelés" >
            <Observer>
                {() => (
                    <>
                        <Row gutter={16}>
                            <Col xxl={4} xl={10} xs={12}>
                                <TextBox
                                    label="BPID:"
                                    onChange={store.documentFilter.setBpid}
                                    value={store.documentFilter.bpid}
                                    onEnter={store.filterResult}
                                    id="filter_documents_partnerBpId"
                                />
                            </Col>
                            <Col xxl={6} xl={10} xs={12}>
                                <TextBox
                                    label="Név:"
                                    onChange={store.documentFilter.setDriverName}
                                    value={store.documentFilter.driverName}
                                    onEnter={store.filterResult}
                                    id="filter_documents_driverName"
                                />
                            </Col>
                            <Col xxl={6} xl={10} xs={12}>
                                <TextBox
                                    label="Számla név:"
                                    onChange={store.documentFilter.setName}
                                    value={store.documentFilter.name}
                                    onEnter={store.filterResult}
                                    id="filter_documents_partnerName"
                                />
                            </Col>
                            <Col xxl={6} xl={10} xs={12}>
                                <TextBox
                                    label="Rendszám:"
                                    onChange={store.documentFilter.setLicensePlateNumber}
                                    value={store.documentFilter.licensePlateNumber}
                                    onEnter={store.filterResult}
                                    id="filter_documents_licensePlateNumber"
                                />
                            </Col>
                            <Col xxl={8} xl={10} xs={12}>
                                <div style={{ width: "100%" }}>
                                    <Text>Bármely dokumentum állapota:</Text>
                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        onChange={store.documentFilter.setDocumentStatuses}
                                        value={store.documentFilter.documentStatuses}
                                        allowClear
                                    >
                                        {DocumentStatusEntries.map(i =>
                                            <Option key={i.enumValue} value={i.enumValue}>
                                                {i.displayValue}
                                            </Option>
                                        )}
                                    </Select>
                                </div>
                            </Col>
                        </Row>
                        {<Space style={{ marginTop: "16px", marginBottom: "16px" }}>

                            <Button onClick={store.downloadExcelAsync.fireAndForget} icon={<FileExcelOutlined />} disabled={store.documentFilter.areFiltersDirty} id="download_as_excel">
                                Lista letöltése Excel-ben
                            </Button>

                            <Button key="1" type="primary" onClick={store.filterResult} icon={<FilterOutlined />} id="filter_document_list">
                                Szűrés
                            </Button>
                            {!store.documentFilter.isEmpty && (
                                <Button onClick={store.clearState} icon={<UndoOutlined />} id="clear_filter_invoice_list">
                                    Szűrőfeltételek törlése
                                </Button>
                            )}
                        </Space>
                        }
                    </>
                )}
            </Observer>
            <TableSummaryHeader items={summaryItems} />
            <Table
                onChange={store.reloadList}
                pagination={paginationConfig}
                size="middle"
                columns={adminDocumentColumns}
                dataSource={store.documents}
                scroll={tableScroll}
                rowKey="bpid" />
        </PageHeader>
    );
}


const AdminDocumentListCoreWithStore = withAdminDocumentListStore(AdminDocumentListCore);

export default function AdminDocumentList() {
    return (
        <AdminDocumentListCoreWithStore />
    )
}
