import React, { useCallback, useState } from "react";
import {
  Col,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { AddressType } from "Models/AddressType";
import { AddressTypeSelectBox } from "./AddressTypeSelectBox";
import TextBox from "./TextBox";

const { Option } = Select;
const { Text } = Typography;
const { Title } = Typography;

interface IAddressTextBoxProps {
  settlementOnChange: (value: string) => void;
  settlementValue: string;
  zipCodeOnChange: (value: string) => void;
  zipCodeValue: string;
  addressLineOnChange: (value: string) => void;
  addressLineValue: string;
  addressTypeOnChange: (value: AddressType) => void;
  addressTypeValue: AddressType;
  onEnter?: () => void;
}


export default function AddressTextBox(props: IAddressTextBoxProps) {

  return (
    <>
      <Row gutter={16}>
        <Col lg={5} md={10} xs={12}>
          <AddressTypeSelectBox label="Cím típus:" AddressType={props.addressTypeValue} onChange={props.addressTypeOnChange} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={2} md={4} xs={5}>
          <TextBox label="Ir.Sz:" value={props.zipCodeValue} onChange={props.zipCodeOnChange} onEnter={props.onEnter} />
        </Col>
        <Col lg={3} md={6} xs={7}>
          <TextBox label="Település:" value={props.settlementValue} onChange={props.settlementOnChange} onEnter={props.onEnter} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={8} md={16} xs={20}>

          <TextBox label="Utca, házszám:" value={props.addressLineValue} onChange={props.addressLineOnChange} onEnter={props.onEnter} />
        </Col>
      </Row>
    </>
  );
}
