import { Button, Modal, Popconfirm, Space, Upload } from "antd";
import TextBox from "Pages/CommonControls/TextBox";
import { UploadOutlined } from "@ant-design/icons";
import CommonFileDetailStore from "./CommonFileDetailStore";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { Observer } from "mobx-react";
import { AddressTypeSelectBox } from "Pages/CommonControls/AddressTypeSelectBox";
import { TextSelectBox } from "Pages/CommonControls/TextSelectBox";


interface ICommonFileDetailModalProps {
    showOverewriteExistCommonFilePopconfirm: boolean;
    onOverWriteExistCommonFile: () => void;
    onCancelOverewriteExistCommonFilePopconfirm: () => void;
    isOpen: boolean;
    onOk: () => void;
    onCancel: () => void;
    isLoading: boolean;
    commonFileDetailStore: CommonFileDetailStore;
    categories: string[];
}

export default function CommonFileDetailModal(props: ICommonFileDetailModalProps) {

    return (
        <Modal
            title={props.commonFileDetailStore.isNew ? "Új dokumentum" : "Dokumentum frissítése"}
            open={props.isOpen}
            onCancel={props.onCancel}
            confirmLoading={props.isLoading}
            footer={[
                <Space>
                    <Button
                        key="cancel"
                        onClick={props.onCancel}>
                        Mégsem
                    </Button>
                    <Popconfirm
                        key="submit"
                        title="Ez a nevű fájl már létezik! Szeretné frissíteni?"
                        onConfirm={props.onOverWriteExistCommonFile}
                        onCancel={props.onCancelOverewriteExistCommonFilePopconfirm}
                        visible={props.showOverewriteExistCommonFilePopconfirm}
                    >
                        <Button key="upload" type="primary" onClick={props.onOk}>{props.commonFileDetailStore.isNew ? "Feltöltés" : (props.commonFileDetailStore.file ? "Feltöltés" : "Módosítás")}</Button>
                    </Popconfirm>

                </Space>]}
        >
            <Observer>
                {() => (
                    <>
                        <TextBox label="Név:" value={props.commonFileDetailStore.commonFileName} onChange={props.commonFileDetailStore.handleCommonFileNameChange} isRequired isReadOnly={!props.commonFileDetailStore.isNew} />
                        <TextSelectBox label="Kategória" options={props.categories} value={props.commonFileDetailStore.category} onChange={props.commonFileDetailStore.setCategory} />

                        <div style={{ margin: 8 }}>
                            <Upload accept="application/pdf" beforeUpload={props.commonFileDetailStore.beforeFileUpload} onRemove={props.commonFileDetailStore.handleFileRemove} fileList={isNullOrUndefined(props.commonFileDetailStore.file) ? [] : [props.commonFileDetailStore.file!]}>
                                <Button icon={<UploadOutlined />}>{props.commonFileDetailStore.isNew ? "Válasszon fájlt" : "Fájl lecserélése"}</Button>
                            </Upload>
                        </div>
                    </>)}
            </Observer>
        </Modal >
    );
}