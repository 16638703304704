import { action, makeObservable, observable } from "mobx";

export default class ManualRideStore {

    @observable.ref public rideAdditionalInfo: string = "";
    @observable.ref public rideExternalIdentifier: string = "";
    constructor(
    ) {
        makeObservable(this);
    }

    @action.bound
    public setRideAdditionalInfo(value: string) {
        this.rideAdditionalInfo = value;
    }

    @action.bound
    public setRideExternalIdentifier(value: string) {
        this.rideExternalIdentifier = value;
    }
}