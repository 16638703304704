import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import AccountedRide from "Models/AccountedRide";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { LinkOutlined } from "@ant-design/icons";

export const OfflineRideListColumns: ColumnsType<AccountedRide> = [
    {
        title: "Azonosító",
        dataIndex: "publicIdentifier",
        key: "publicIdentifier",
        sorter: true,
    },
    {
        title: "Elszámolás",
        dataIndex: "voucherInfo",
        key: "voucherInfo",
        sorter: true,
    },
    {
        title: "Teljesítés",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (date: Date) => <span>{moment(date).format('YYYY.MM.DD.')}</span>,
        sorter: true,
    },
    {
        title: "Költséghely",
        dataIndex: "costPlacementIdentifier",
        key: "costPlacementIdentifier",
        sorter: true,
    },
    {
        title: "Bruttó összeg",
        dataIndex: "grossPrice",
        key: "grossPrice",
        sorter: true,
    },
    {
        title: "Sofőr",
        dataIndex: "driverName",
        key: "driverName",
        sorter: true,
    },
    {
        title: "Sofőr BPID",
        dataIndex: "driverBpid",
        key: "driverBpid",
        sorter: true,
    },
    {
        title: "",
        dataIndex: "id",
        key: "id",
        sorter: false,
        render: (id, record) => (
            <>
                <Tooltip title="Részletek">
                    <Link to={`/admin/offlineRides/${record.id}`}>
                        <Button key={`${record.id}`} icon={<LinkOutlined />} type="primary" />
                    </Link>
                </Tooltip>
            </>
        )
    },
];