import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { Select, Typography } from "antd"
import { TaxiCardOrderStatus, TaxiCardOrderStatusEntries } from "Models/TaxiCardOrderStatus";
const { Option } = Select;
const { Text } = Typography;

export interface TaxiCardOrderStatusSelectBoxProps {
    onChange: (costPlacementType: TaxiCardOrderStatus) => void;
    taxiCardOrderStatus: TaxiCardOrderStatus | null;
    style?: React.CSSProperties;
    label?: string;
    allowClear?: boolean;
}

export function TaxiCardOrderStatusSelectBox(props: TaxiCardOrderStatusSelectBoxProps) {
    return (
        <div style={props.style}>
            {props.label && <Text> {props.label}</Text>}
            <Select
                style={{ width: "100%" }}
                onChange={props.onChange}
                value={props.taxiCardOrderStatus}
                allowClear={props.allowClear}
            >
                {TaxiCardOrderStatusEntries.map(i =>
                    <Option key={i.enumValue} value={i.enumValue}>
                        {i.displayValue}
                    </Option>
                )}
            </Select>
        </div >

    )
} 