import { IUpdateSettingRequest } from "Adapters/Api/Api.g";
import ProtectedApiAdapterBase from "Adapters/ProtectedApiAdapterBase";

class AdminSettingsApiAdapter extends ProtectedApiAdapterBase {
    private readonly mainRoute = "api/web/admin/settings";


    public getAsync(settingKey: string): Promise<string> {

        const encodedSettingsKey = encodeURIComponent(settingKey);
        return this
            .httpGet<string>(this.mainRoute, [encodedSettingsKey], undefined)
            .map(result => result.value)
            .performOperationAsync();
    }

    public updateAsync(settingKey: string, settingValue: string) {
        const encodedSettingsKey = encodeURIComponent(settingKey);

        const body: IUpdateSettingRequest = {
            value: settingValue
        }

        return this
            .httpPut(this.mainRoute, [encodedSettingsKey], body)
            .map(res=> true)
            .performOperationAsync();
    }
}

export default new AdminSettingsApiAdapter();