import { observer } from "mobx-react";
import PhoneNumber from "Models/PhoneNumber";
import PinInputBox from "Pages/CommonControls/PinInputBox";
import TextBox from "Pages/CommonControls/TextBox";
import { useMemo } from "react";

export interface IPinColumnProps {
    record: PhoneNumber;
    isUnderEdit: boolean;
    isPinVisible: boolean;
}

function PinColumn(props: IPinColumnProps) {
    
    const value = useMemo(() => {
        if (props.record.pin === null || props.record.pin.length === 0) {
            return props.isUnderEdit ? "" : "Nincs";
        }

        if (props.isPinVisible || props.isUnderEdit) {
            return props.record.pin;
        }

        return "***";
    }, [props.record, props.record.pin, props.isPinVisible, props.isUnderEdit]);

    return (
        <>
            {props.isUnderEdit ?
                <PinInputBox value={value} onChange={props.record.setPin}/> :
                <span>{value}</span>
            }
        </>
    )
}

export default observer(PinColumn);
