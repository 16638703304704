import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { ColumnsType } from "antd/lib/table"
import { CostPlacementType, getCostPlacementTypeDisplayValue } from "Models/CostPlacementType";
import { getTaxiVoucherLimitDisplayValue, TaxiVoucherLimit } from "Models/TaxiVoucherLimit";
import Voucher from "Models/Voucher"
import VoucherListItem from "Models/VoucherListItem";
import moment from "moment";
import VoucherRowActions from "./VoucherRowActions";
import Styles from "../Vouchers.module.css";
import { formatIdentifier } from "Util/IdentifierHelper";

export function getVoucherColumns(isSummary: boolean): ColumnsType<VoucherListItem> {
    const res: ColumnsType<VoucherListItem> = [
        {
            title: "Csekk azonosító",
            dataIndex: "identifier",
            key: "identifier",
            sorter: true,
            render: (identifier: string) => <span className={Styles.identifier}>{formatIdentifier(identifier)}</span>,
        },
        {
            title: "Érvényesség",
            dataIndex: "validity",
            key: "validity",
            render: (date: Date) => <span>{moment(date).format('YYYY.MM.DD.')}</span>,
            sorter: true,
        },
        {
            title: "Letiltva",
            dataIndex: "prohibitedAt",
            key: "prohibitedAt",
            render: (prohibitedAt: moment.Moment | null, record: Voucher) => {

                return <span>{prohibitedAt ? `Igen (${record.prohibitedAt!.format('YYYY.MM.DD.')})` : "Nem"}</span>
            },
            sorter: true,
        },
        {
            title: "Költséghely",
            dataIndex: "costPlacementType",
            key: "costPlacementType",
            render: (type: CostPlacementType, column: Voucher) => {
                let res = getCostPlacementTypeDisplayValue(type);
                if (column.costPlacement?.length) {
                    res += ` (${column.costPlacement})`;
                }
                return <span>{res}</span>
            },
            sorter: true,
        },
        {
            title: "Fix összeg",
            dataIndex: "cost",
            key: "cost",
            render: (cost: TaxiVoucherLimit) => <span>{getTaxiVoucherLimitDisplayValue(cost)}</span>,
            sorter: true,
        },
        {
            title: "Felhasználva",
            dataIndex: "accountedRideId",
            key: "accountedRideId",
            render: (accountedRideId: number | null) => <span>{isNullOrUndefined(accountedRideId) ? "Nem" : "Igen"}</span>,
            sorter: true,
        },

    ];

    if (isSummary) {
        res.push({
            title: "Partner",
            dataIndex: "customerName",
            key: "customerName",
            render: (_: any, row: VoucherListItem) => <span>{row.customerName} ({row.customerBpid})</span>,
            sorter: true,
        });
    }


    if (!isSummary) {
        res.push({
            title: "",
            render: (_, voucher) => <VoucherRowActions voucher={voucher} />
        }
        );
    }

    return res;

}