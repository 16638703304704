import { DocumentStatus } from "Models/DocumentStatus";
import { action, computed, makeObservable, observable } from "mobx";

class TrafficLicenseFilter {

    @observable.ref public partnerName: string = "";
    @observable.ref public partnerBpid: string = "";
    @observable.ref public identifier: string = "";
    @observable.ref public licensePlateNumber: string = "";
    @observable.ref public status: DocumentStatus | null = null;

    @observable public areFiltersDirty = false;

    public constructor(
    ) {
        makeObservable(this);
    }

    @computed public get isEmpty() {
        return this.partnerName === "" &&
            this.partnerBpid === "" &&
            this.identifier === "" &&
            this.licensePlateNumber === "" &&
            this.status === null
    }

    @action.bound
    public clear() {
        this.partnerName = "";
        this.partnerBpid = "";
        this.identifier = "";
        this.licensePlateNumber = "";
        this.status = null;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setStatus(status: DocumentStatus) {
        this.status = status;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setIdentifier(identifier: string) {
        this.identifier = identifier;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setPartnerName(partnerName: string) {
        this.partnerName = partnerName;
        this.areFiltersDirty = true;
    }

    @action.bound
    public setPartnerBpid(partnerBpid: string) {
        this.partnerBpid = partnerBpid;
        this.areFiltersDirty = true;
    }
    
    @action.bound
    public setLicensePlateNumber(licensePlateNumber: string) {
        this.licensePlateNumber = licensePlateNumber;
        this.areFiltersDirty = true;
    }
}
export default TrafficLicenseFilter;