import TaxNumber from "Models/TaxNumber";
import Address from "./Address";

export default class TaxiDriver {
    constructor(
        public readonly id: number,
        public readonly bpid: string,
        public readonly name: string,
        public readonly taxNumber: TaxNumber,
        public readonly address: Address,
        public readonly isActive: boolean
    ) { }
}