import { createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import AdminTaxiCardOrderAdapter from "Adapters/AdminAdapter/AdminTaxiCardOrderAdapter";
import Ordering from "Adapters/QueryHelpers/Ordering";
import Paging from "Adapters/QueryHelpers/Paging";
import { FilterValue, SorterResult, TableCurrentDataSource, TablePaginationConfig } from "antd/lib/table/interface";
import { action, computed, observable, runInAction } from "mobx";
import TaxiCardOrderFilter from "Models/Filters/TaxiCardOrderFilter";
import { PrintOptions } from "Models/PrintOptions";
import TaxiCardOrderListItem from "Models/TaxiCardOrderListItem";
import { downloadBase64Async } from "Util/FileDownloader";
import moment from "moment";

export default class TaxiCardOrdersStore extends StoreBase {
    @observable.ref public taxiCardOrders: TaxiCardOrderListItem[] = [];
    @observable public recordNum = 0;
    @observable public pageSize = 50;
    @observable public currentPage = 1;
    public orderedBy = "preMadeCardFrom";
    public orderDirection = "ascend";
    public taxiCardOrderFilter = new TaxiCardOrderFilter();
    @observable.ref public recordIdUnderEdit: number | null = null;

    @computed private get filter() {
        return {
            Status: this.taxiCardOrderFilter.status,
            NumberOfCards: this.taxiCardOrderFilter.numberOfCards,
            SellerName: this.taxiCardOrderFilter.sellerName,
            SellerBpid: this.taxiCardOrderFilter.sellerBpid,
            CreatedAtFrom: this.taxiCardOrderFilter.createdAtFrom,
            CreatedAtTo: this.taxiCardOrderFilter.createdAtTo,
        };
    }

    public readonly vLoadAsync = this.async(async () => {
        const paging = new Paging(this.currentPage, this.pageSize);
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await AdminTaxiCardOrderAdapter.getListAsync(paging, ordering, this.filter);

        runInAction(() => {
            this.recordNum = result.totalCount;
            this.taxiCardOrders = result.taxiCardOrders;
        });
    });

    private readonly updateAsync = this.async(async (newData: TaxiCardOrderListItem) => {
        const result = await AdminTaxiCardOrderAdapter.updateAsync(newData.id, newData.status);
        if (result) {
            this.vLoadAsync.fireAndForget();
        }
    });

    public readonly printReceiptAsync = this.async(async (taxiCardOrder: TaxiCardOrderListItem) => {
        const result = await AdminTaxiCardOrderAdapter.printReceiptAsync(taxiCardOrder.id);
        await downloadBase64Async(result, "application/pdf", `Taxikartya_rendeles_${taxiCardOrder.sellerBpid}_${taxiCardOrder.createdAt.format("YYYY_MM_DD_HH_mm")}.pdf`);
    });

    @action.bound
    public setRecordUnderEdit(id: number | null) {
        this.recordIdUnderEdit = id;
    }

    @action.bound
    public saveRecordUnderEdit() {
        const newData = this.taxiCardOrders.find(p => p.id === this.recordIdUnderEdit)!;
        this.updateAsync.fireAndForget(newData);
        this.recordIdUnderEdit = null;
    }

    @action.bound
    public filterResult() {
        this.currentPage = 1;
        this.taxiCardOrderFilter.areFiltersDirty = false;
        this.vLoadAsync.fireAndForget();
    }

    @action.bound
    public reloadList(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<TaxiCardOrderListItem> | SorterResult<TaxiCardOrderListItem>[], extra: TableCurrentDataSource<TaxiCardOrderListItem>) {
        this.pageSize = pagination.pageSize!;
        this.currentPage = pagination.current!;
        if (Array.isArray(sorter)) {
            this.orderedBy = sorter[0].column ? sorter[0].columnKey!.toString() : "";
            this.orderDirection = sorter[0].order!;
        } else {
            this.orderedBy = sorter.column ? sorter.columnKey!.toString() : "";
            this.orderDirection = sorter.order!;
        }
        this.vLoadAsync.fireAndForget();
    }

    public readonly printAsync = this.async(async (option: PrintOptions) => {
        const ordering = new Ordering(this.orderedBy, this.orderDirection === "ascend");
        const result = await AdminTaxiCardOrderAdapter.printAsync(ordering, option, this.filter);
        const date = moment().format("YYYY_MM_DD");
        switch (option) {
            case PrintOptions.PrintToExcel:
                await downloadBase64Async(result, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `taxikártya_rendelések_${date}.xlsx`);
                return;
            default:
                await downloadBase64Async(result, "application/pdf", `taxikártya_rendelések_${date}.pdf`);
            // TODO other options
        }
    });

    @action.bound
    public print(option: PrintOptions) {
        this.printAsync.fireAndForget(option);
    }
}

const { ContextComponent: TaxiCardOrdersStoreContext, StoreProvider: TaxiCardOrdersStoreProvider, useStore: useTaxiCardOrdersStore, withStore: withTaxiCardOrdersStore } = createStoreProvider(() => new TaxiCardOrdersStore());

export {
    TaxiCardOrdersStoreContext,
    TaxiCardOrdersStoreProvider,
    useTaxiCardOrdersStore,
    withTaxiCardOrdersStore
};