import { MoreOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Space, Tooltip } from "antd";
import { useCallback } from "react";
import InvoiceForPartnerListItem from "Models/InvoiceForPartnerListItem";
import { useInvoiceListStore } from "Pages/Partner/Invoices/InvoiceListStore";
import AuthStore from "Stores/AuthStore";
import { Link } from "react-router-dom";

export default function InvoiceActions({ invoice }: { invoice: InvoiceForPartnerListItem }) {

    const store = useInvoiceListStore();
    const print = useCallback(() => {
        store.downloadPdfAsync.fireAndForget(invoice);
    }, [invoice]);

    return (
        <Space size="middle">
            {(AuthStore.user?.hasRole("Admin") || AuthStore.user?.hasRole("PartnerAdmin")) && (
                <Tooltip title="Számla letöltése PDF-ben">
                    <Button onClick={print} icon={<PrinterOutlined />} />
                </Tooltip>
            )}
            {AuthStore.user?.hasRole("Admin") && (
                <Tooltip title="Részletek">
                    <Link to={`${invoice.id}`}>
                        <Button key={`${invoice.id}`} icon={<MoreOutlined />} type="primary" />
                    </Link>
                </Tooltip>
            )}
        </Space>
    );
}