import { CheckOutlined, DownOutlined, DownloadOutlined, EditOutlined, FileExcelOutlined, FilePdfOutlined, FilterOutlined, PrinterOutlined } from "@ant-design/icons";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import { Button, Col, Dropdown, Menu, PageHeader, Popconfirm, Row, Space, Table, TablePaginationConfig, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import Address from "Models/Address";
import TaxiCardOrderListItem from "Models/TaxiCardOrderListItem";
import { getTaxiCardOrderStatusDisplayValue, TaxiCardOrderStatus } from "Models/TaxiCardOrderStatus";
import TaxiDriver from "Models/TaxiDriver";
import TaxNumber from "Models/TaxNumber";
import DateRangePicker from "Pages/CommonControls/DateRangePicker";
import NumberBox from "Pages/CommonControls/NumberBox";
import PageWrapper from "Pages/CommonControls/PageWrapper/PageWrapper";
import TableSummaryHeader, { ITableSummaryHeaderItem } from "Pages/CommonControls/TableSummaryHeader/TableSummaryHeader";
import TaxiCardOrderStatusColumn from "Pages/CommonControls/TaxiCardOrderStatusColumn";
import { TaxiCardOrderStatusSelectBox } from "Pages/CommonControls/TaxiCardOrderStatusSelectBox";
import TextBox from "Pages/CommonControls/TextBox";
import { useMemo } from "react";
import { withTaxiCardOrdersStore, useTaxiCardOrdersStore } from "./TaxiCardOrderStore";
import { PrintOptions } from "Models/PrintOptions";

const { Title } = Typography;

function TaxiCardOrderList() {
    const selectedRowColor = 'rgb(37, 184, 100)';
    const store = useTaxiCardOrdersStore();

    const paginationConfig: TablePaginationConfig = {
        current: store.currentPage,
        defaultPageSize: 50,
        pageSize: store.pageSize,
        total: store.recordNum
    }


    const edit = (record: TaxiCardOrderListItem | null) => {
        if (record) {
            store.setRecordUnderEdit(record.id);
        } else {
            store.setRecordUnderEdit(null);
        }
    };

    const isUnderEdit = (record: TaxiCardOrderListItem) => store.recordIdUnderEdit === record.id;

    const TaxiCardOrderColumns: ColumnsType<TaxiCardOrderListItem> = [
        {
            title: "Partner név és BPID",
            dataIndex: "sellerName",
            key: "sellerName",
            sorter: true,
            render: (_, column: TaxiCardOrderListItem) => {
                const recordUnderEdit = isUnderEdit(column);
                const style = recordUnderEdit ? { backgroundColor: selectedRowColor, color: "white" } : {};
                return {
                    props: { style },
                    children: <span>{column.sellerName} ({column.sellerBpid})</span>
                }
            },
        },
        {
            title: "Rendelés időpontja",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: true,
            render: (date: moment.Moment, column: TaxiCardOrderListItem) => {
                const recordUnderEdit = isUnderEdit(column);
                const style = recordUnderEdit ? { backgroundColor: selectedRowColor, color: "white" } : {};
                return {
                    props: { style },
                    children: <span>{date.format("L LT")}</span>
                }
            }
            ,
        },
        {
            title: "Darabszám",
            dataIndex: "numberOfCards",
            key: "numberOfCards",
            sorter: true,
            render: (numberOfCards: number, column: TaxiCardOrderListItem) => {
                const recordUnderEdit = isUnderEdit(column);
                const style = recordUnderEdit ? { backgroundColor: selectedRowColor, color: "white" } : {};
                return {
                    props: { style },
                    children: <span>{numberOfCards}</span>
                }
            }
        },
        {
            title: "Kiadást segítő adat",
            dataIndex: "preMadeCardFrom",
            key: "preMadeCardFrom",
            sorter: true,
            defaultSortOrder: "ascend",
            render: (_: any, row: TaxiCardOrderListItem) => {
                return {
                    children: <span>{row.preMadeCardFrom} - {row.preMadeCardTo}</span>
                }
            }
        },
        {
            title: "Igénylő neve",
            dataIndex: "requesterName",
            key: "requesterName",
            sorter: true,
            render: (requesterName: string, column: TaxiCardOrderListItem) => {
                const recordUnderEdit = isUnderEdit(column);
                const style = recordUnderEdit ? { backgroundColor: selectedRowColor, color: "white" } : {};
                return {
                    props: { style },
                    children: <span>{requesterName}</span>
                }
            }
        },
        {
            title: "Igénylő telefonszáma",
            dataIndex: "requesterPhoneNumber",
            key: "requesterPhoneNumber",
            sorter: true,
            render: (requesterPhoneNumber: string, column: TaxiCardOrderListItem) => {
                const recordUnderEdit = isUnderEdit(column);
                const style = recordUnderEdit ? { backgroundColor: selectedRowColor, color: "white" } : {};
                return {
                    props: { style },
                    children: <span>{requesterPhoneNumber}</span>
                }
            }
        },
        {
            title: "Egyéb információ",
            dataIndex: "referenceNumber",
            key: "referenceNumber",
            sorter: true,
            render: (referenceNumber: number, column: TaxiCardOrderListItem) => {
                const recordUnderEdit = isUnderEdit(column);
                const style = recordUnderEdit ? { backgroundColor: selectedRowColor, color: "white" } : {};
                return {
                    props: { style },
                    children: <span>{referenceNumber}</span>
                }
            }
        },
        {
            title: "Szállítási cím",
            dataIndex: "deliveryAddres",
            key: "deliveryAddres",
            sorter: true,
            render: (deliveryAddres: Address, column: TaxiCardOrderListItem) => {
                const recordUnderEdit = isUnderEdit(column);
                const style = recordUnderEdit ? { backgroundColor: selectedRowColor, color: "white" } : {};
                return {
                    props: { style },
                    children: <span>{isNullOrUndefined(deliveryAddres) ? "" : `${deliveryAddres?.zipCode} ${deliveryAddres?.settlement} ${deliveryAddres?.addressLine}`}</span>
                }
            }
        },
        {
            title: "Állapot",
            dataIndex: "status",
            key: "status",
            render: (type: TaxiCardOrderStatus, column: TaxiCardOrderListItem) => {
                const recordUnderEdit = isUnderEdit(column);
                const style = recordUnderEdit ? { backgroundColor: selectedRowColor } : {};
                return {
                    props: { style },
                    children: <TaxiCardOrderStatusColumn
                        isUnderEdit={recordUnderEdit}
                        status={column.status}
                        onChange={column.setStatus} />
                }
            },
            sorter: true,
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (_, record: TaxiCardOrderListItem) => {
                if (isUnderEdit(record)) {
                    return {
                        props: { style: { backgroundColor: selectedRowColor } },
                        children:
                            <Space size="middle">
                                <span>
                                    <Typography.Link
                                        onClick={store.saveRecordUnderEdit}
                                        style={{
                                            marginRight: 8,
                                            color: 'white'
                                        }}
                                    >
                                        Mentés
                                    </Typography.Link>
                                    <Popconfirm title="Biztosan elveti a módosításokat?" okText="Igen" cancelText="Nem" onConfirm={() => edit(null)}>
                                        <a style={{ color: 'white' }}>Mégsem</a>
                                    </Popconfirm>
                                </span>
                            </Space>
                    }
                }
                return (
                    <Space size="middle">
                        <Tooltip title="Szerkesztés">
                            <Button onClick={() => edit(record)} icon={<CheckOutlined />} />
                        </Tooltip>
                        <Tooltip title="Kísérőlap nyomtatása">
                            <Button onClick={() => store.printReceiptAsync.fireAndForget(record)} icon={<PrinterOutlined />} />
                        </Tooltip>
                    </Space>
                )
            }
        }
    ];

    const downloadMenu = (
        <Menu>
            <div>
                <Button onClick={() => store.print(PrintOptions.PrintToPdf)} icon={<FilePdfOutlined />} style={{ width: "100%" }}>
                    Lista letöltése PDF-ben
                </Button>
            </div>
            <div>
                <Button onClick={() => store.print(PrintOptions.PrintToExcel)} icon={<FileExcelOutlined />} style={{ width: "100%" }}>
                    Lista letöltése Excelben
                </Button>
            </div>
        </Menu>
    );

    const summaryItems = useMemo<ITableSummaryHeaderItem[]>(() => [{
        title: "Mennyiség:",
        value: `${store.recordNum} db`
    }], [store.recordNum]);

    return (
        <>
            <PageHeader title={<span id="page_title">Taxikártya rendelések</span>}>
                <Row gutter={16}>
                    <Col xxl={8} xl={10} xs={12}>
                        <DateRangePicker
                            label="Időszak:"
                            from={store.taxiCardOrderFilter.createdAtFrom}
                            to={store.taxiCardOrderFilter.createdAtTo}
                            onChange={store.taxiCardOrderFilter.setDateRangeFilter}
                            allowClear
                        />
                    </Col>
                    <Col xxl={8} xl={10} xs={12}>
                        <NumberBox
                            label="Darabszám:"
                            onChange={store.taxiCardOrderFilter.setNumberOfCards}
                            value={store.taxiCardOrderFilter.numberOfCards}
                            onEnter={store.filterResult}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xxl={8} xl={10} xs={12}>
                        <TextBox
                            label="BPID:"
                            onChange={store.taxiCardOrderFilter.setSellerBpid}
                            value={store.taxiCardOrderFilter.sellerBpid}
                            onEnter={store.filterResult}
                            allowClear
                        />
                    </Col>
                    <Col xxl={8} xl={10} xs={12}>
                        <TextBox
                            label="Név:"
                            onChange={store.taxiCardOrderFilter.setSellerName}
                            value={store.taxiCardOrderFilter.sellerName}
                            onEnter={store.filterResult}
                            allowClear
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xxl={8} xl={10} xs={12}>
                        <TaxiCardOrderStatusSelectBox
                        label={"Állapot"}
                            style={{ width: "100%" }}
                            onChange={store.taxiCardOrderFilter.setStatus}
                            taxiCardOrderStatus={store.taxiCardOrderFilter.status}
                            allowClear
                        />
                    </Col>
                    <Col xxl={8} xl={10} xs={12}>
                        <TextBox
                            label="Egyéb információ:"
                            onChange={store.taxiCardOrderFilter.setReferenceNumber}
                            value={store.taxiCardOrderFilter.referenceNumber}
                            onEnter={store.filterResult}
                            allowClear
                        />
                    </Col>
                </Row>

                <Space style={{ marginTop: "16px", marginBottom: "16px" }}>
                    <Dropdown.Button style={{ width: "100%" }} onClick={() => store.print(PrintOptions.PrintToExcel)} icon={<DownOutlined />} overlay={downloadMenu}>

                        Lista letöltése
                        <DownloadOutlined />

                    </Dropdown.Button>

                    <Button style={{ width: "100%" }} type="primary" onClick={store.filterResult} icon={<FilterOutlined />}>
                        Szűrés
                    </Button>
                </Space>

                <TableSummaryHeader items={summaryItems} />
                <Table
                    onChange={store.reloadList}
                    pagination={paginationConfig}
                    size="middle"
                    columns={TaxiCardOrderColumns}
                    dataSource={store.taxiCardOrders}
                    rowKey="id"
                    key={store.recordIdUnderEdit}
                />
            </PageHeader>
        </>
    );
}


export default withTaxiCardOrdersStore(TaxiCardOrderList);